(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MomentCommentDeleteController',MomentCommentDeleteController);

    MomentCommentDeleteController.$inject = ['$uibModalInstance', 'entity', 'MomentComment'];

    function MomentCommentDeleteController($uibModalInstance, entity, MomentComment) {
        var vm = this;

        vm.momentComment = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MomentComment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
