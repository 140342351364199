(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MomentDetailController', MomentDetailController);

    MomentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Moment', 'MomentComment', 'MomentStar'];

    function MomentDetailController($scope, $rootScope, $stateParams, previousState, entity, Moment, MomentComment, MomentStar) {
        var vm = this;

        vm.moment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:momentUpdate', function(event, result) {
            vm.moment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
