(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MyEAccountDialogController', MyEAccountDialogController);

    MyEAccountDialogController.$inject = ['$rootScope','$http','$timeout', '$scope', '$state', 'entity', 'EAccount', 'User','Upload'];

    function MyEAccountDialogController ($rootScope,$http, $timeout, $scope, $state, entity, EAccount, User,Upload) {
        var vm = this;
        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from; //from为前一个页面的路由信息：url,cache,views,name
            rootPre=$rootScope.previousState.url;
        });


        vm.eAccount = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.users = User.query();
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.reEdit=false;
        vm.commitInfo = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        function getCode() {
            $http({
                url:'/api/e-account/getInvitationCode?eId='+vm.eAccount.id,
                method:'GET'
            }).success(function (res) {
                vm.eAccount.invitationCode = res;
            })
        }

        vm.showInfo = showInfo;
        function showInfo() {
            vm.reEdit=true;
        }

        vm.myEaccountDetail = myEaccountDetail;
        function myEaccountDetail() {
            $state.go("e-account-my",{},{reload:true});
        }

        vm.checkNum1 = checkNum1;
        function checkNum1(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("统一社会信用代码必须为数字")
                vm.eAccount.registerNo = null;
            }
        }
        vm.checkNum2 = checkNum2;
        function checkNum2(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("手机号码必须为数字")
                vm.eAccount.contact = null;
            }
        }

        load();
        function load() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height()>=732) $(".footer .foot").removeClass("mobile");
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }
        function clear () {
            if(rootPre!=null && rootPre=='/e-account/my/init'){
                window.history.go(-2);
            }else{
                window.history.go(-1);
            }

            addLayout();
        }

        function save () {
            // if (vm.eAccount.licence == null) {
            //     alert("请上传最新的营业执照");
            // } else
            if (vm.eAccount.contactIdCardImg == null) {
                alert("请上传法人/运营者/经办人身份证照片");
            } else {
                console.log("---保存---");
                vm.isSaving = true;
                if (vm.eAccount.id !== null) {
                    vm.eAccount.notPassReason = null;
                    EAccount.update(vm.eAccount, onSaveSuccess, onSaveError);
                    vm.commitInfo = true;
                    vm.reEdit = false;
                } else {
                    EAccount.save(vm.eAccount, onSaveSuccess, onSaveError);
                }
            }

            // if (vm.eAccount.contact!=null){
            //     var phone = vm.eAccount.contact;
            //     if(!(/^1[34578]\d{9}$/.test(phone))){
            //         alert("手机号码有误，请重填");
            //         vm.eAccount.contact = null;
            //         vm.isSaving = false;
            //         return;
            //     }
            // }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect (uploadFile, type){
            if (uploadFile!=null){
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"EACCOUNT"},
                    file: uploadFile,
                    method: 'POST'
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    // console.log(data);
                    if (type == 1) {
                        vm.eAccount.licence = data.file;
                    } else if (type == 2) {
                        vm.eAccount.contactIdCardImg = data.file;
                    } else {
                        vm.eAccount.web = data.file;
                    }

                    load();
                }).error(function (data, status, headers, config) {
                    console.log('error status: ' + status);
                });
            }
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
