(function() {
    'use strict';

    var app = angular
        .module('taskServiceApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'angular-loading-bar',
            'ngAnimate',
            'ngFileUpload',
            'ahdin',
            'ui.select',
            'ngSanitize',
            'monospaced.qrcode',
            'ng.ueditor',
            'angular-img-cropper'
        ])
        .run(run)
        .filter('propsFilter', function () {
            return function (items, props) {
                var out = [];
                var needFilter = false;

                if (angular.isArray(items)) {
                    var keys = Object.keys(props);
                    items.forEach(function (item) {
                        var itemMatches = false;

                        // console.log("keys:"+keys);

                        for (var i = 0; i < keys.length; i++) {
                            var prop = keys[i];
                            if (prop === 'private'){
                                needFilter = true;
                            }
                            // if (prop&&Object.prototype.toString.call(props) === '[object Object]'){
                            //     console.log(props);
                            //     console.log(prop);
                                var text = props[prop].toLowerCase();
                                if (item[prop]===null ? "":item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                                    itemMatches = true;
                                    break;
                                }
                            // }
                        }

                        if (itemMatches) {
                            out.push(item);
                        }
                    });
                } else {
                    // Let the output be the input untouched
                    out = items;
                }
                if (needFilter){
                    var afterOut = [];
                    out.forEach(function (item) {
                        if (item.private === false){
                            afterOut.push(item);
                        }
                    });
                    out = afterOut;
                }
                return out;
            };
        });

    run.$inject = ['$rootScope', 'stateHandler', 'translationHandler', 'DATETIME_BUTTON_BAR_OPTIONS','DEBUG_INFO_ENABLED','$templateCache'];
    app.directive("raty", function() {
        return {
            restrict: 'AE',
            link: function(scope, elem, attrs) {
                $(elem).raty({score: attrs.score, number: attrs.number,readOnly:attrs.readonly});
            }
        }
    });
    function run( $rootScope, stateHandler, translationHandler, DATETIME_BUTTON_BAR_OPTIONS,DEBUG_INFO_ENABLED,$templateCache) {
        $rootScope.DATETIME_BUTTON_BAR_OPTIONS = DATETIME_BUTTON_BAR_OPTIONS;//, DATETIME_BUTTON_BAR_OPTIONS
        $rootScope.DEBUG_INFO_ENABLED=DEBUG_INFO_ENABLED;
        stateHandler.initialize();
        translationHandler.initialize();
        $rootScope.$on('$routeChangeStart', function(event, next, current) {
            if (typeof(current) !== 'undefined') {
                console.log($templateCache.info());
                $templateCache.remove(current.templateUrl);
                console.log($templateCache.info());
            }
        });
    }
})();
