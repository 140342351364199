(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RecordsDeleteController',RecordsDeleteController);

    RecordsDeleteController.$inject = ['$uibModalInstance', 'entity', 'Records'];

    function RecordsDeleteController($uibModalInstance, entity, Records) {
        var vm = this;

        vm.records = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Records.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
