(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('NpcActivitySearch', NpcActivitySearch);

    NpcActivitySearch.$inject = ['$resource'];

    function NpcActivitySearch($resource) {
        var resourceUrl =  'api/_search/npc-activities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
