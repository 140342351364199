(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelDetailController', controller);

    controller.$inject = ['$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', 'TripModel', 'tripModelPublish', 'EAccountSearchByIDAndName',
        'tripModelToWeb','$state','tripModelConfirmToWeb','Upload'];

    function controller($scope, $rootScope, Principal, $http, $stateParams, previousState, entity, TripModel, tripModelPublish, EAccountSearchByIDAndName,tripModelToWeb,$state,tripModelConfirmToWeb,Upload) {
        var vm = this;

        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripModel = entity;
        vm.previousState = previousState.name;
        vm.showTripsButton = true;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;
        vm.cropper = 'default';
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb
        vm.showCropped = true;

        vm.getSelectedEAccount = getSelectedEAccount;
        function getSelectedEAccount() {
            $http({
                method: 'GET',
                url: '/api/trip-models/getShareEaccounts/' + vm.tripModel.id
            }).then(function (res) {
                if (res.data.errorCode == 1) {
                    vm.sharedToAll = true;
                } else {
                    vm.sharedToAll = false;
                    if (res.data != '') {
                        vm.selectedEAccounts = res.data;
                    }
                }
                // console.log(res);
            },function (err) {
                console.log(err);
            });

        }

        vm.tripNew = tripNew;
        function tripNew(id , title,platform) {
            // if(vm.auth!='IGC'){
            $state.go('trip.new',{id:id, title:encodeURI(title),platform:platform} ,{reload : false});
            // }else{
            //     $('#myModal4').modal('show');
            // }
        }
        vm.ident = ident;
        function ident() {
            // $("#myModal1").modal('hide');
            $("#myModal4").modal('hide');
            // $(".modal-backdrop").remove();
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
        vm.acfe = acfe;
        function acfe(){
            if(vm.account.eAccountId===7){
                window.open(vm.account.acfeUrl + vm.tripModel.id + '?at=' + vm.account.password + '_coach&coach='+new Date().getTime());
            }else{
                window.open(vm.account.acfeUrl + vm.tripModel.id + '?at=' + vm.account.password + '_coach');
            }
        }

        getAccount();
        function getAccount() {
            // Principal.identity().then(function(account) {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }
        function check(tripId) {
            $http({
                method: 'GET',
                url: '/api/trip-models/check/'+tripId
            }).success(function (err) {
                if (err!=null){
                    console.log(err);
                    if (err.code===0){
                        // alert("自检通过，您可以发布该线路");
                        var param = {tripId:tripId,status:'PUBLISH'};
                        tripModelPublish.tmPublish(param).success(function(data){
                            // console.log(data);
                            vm.tripModel = TripModel.get({id:vm.tripModel.id});
                            $('#myModal1').modal('hide');
                        });
                    }else if (err.code===-1){
                        vm.tripPrePublish = err;
                        if (vm.tripPrePublish.locationTasks!==null&&vm.tripPrePublish.locationTasks.length>0){
                            vm.locationTaskSize = vm.tripPrePublish.locationTasks.length;
                        }
                        if (vm.tripPrePublish.baseTasks!==null&&vm.tripPrePublish.baseTasks.length>0){
                            vm.baseTaskSize = vm.tripPrePublish.baseTasks.length;
                        }
                        if (vm.tripPrePublish.trips!==null&&vm.tripPrePublish.trips.length>0){
                            vm.tripSize = vm.tripPrePublish.trips.length;
                        }
                        if (vm.tripPrePublish.tripEmpty===true){
                            vm.tripEmptys = " 该线路未包含任何点位 ";
                        }
                        if (vm.tripPrePublish.emptyLocationTasks!==null&&vm.tripPrePublish.emptyLocationTasks.length>0){
                            vm.empLo = " 存在 "+vm.tripPrePublish.emptyLocationTasks.length+" 个空点位 ";
                            vm.empLocation = vm.tripPrePublish.emptyLocationTasks;
                        }else {
                            vm.empLoCheck = "空点位检测";
                        }
                        if (vm.tripPrePublish.b_errorPreCondition!==null&&vm.tripPrePublish.b_errorPreCondition.length>0){
                            vm.bErrorPre = " 存在任务前置条件错误 ";
                            vm.bErrorPreCondition = vm.tripPrePublish.b_errorPreCondition;
                        }
                        if (vm.tripPrePublish.baseTaskCirculate!==null&&vm.tripPrePublish.baseTaskCirculate.length>0){
                            vm.baseCir = " 存在任务出现条件死循环 ";
                            vm.baseTaskCir = vm.tripPrePublish.baseTaskCirculate;
                        }else {
                            vm.baseCirCheck = " 任务出现条件循环检测 ";
                        }
                        if (vm.tripPrePublish.clueAndEnd!==null&&vm.tripPrePublish.clueAndEnd.length>0){
                            vm.cluAndEnd = " 存在线索与结束任务冲突 ";
                            vm.cluAndEnds = vm.tripPrePublish.clueAndEnd;
                        }
                        if (vm.tripPrePublish.endInPreCondition!==null&&vm.tripPrePublish.endInPreCondition.length>0){
                            vm.endInPre = " 存在结束任务在前置条件中 ";
                            vm.endInPreCondition = vm.tripPrePublish.endInPreCondition;
                        }
                        if (vm.tripPrePublish.l_errorPreCondition!==null&&vm.tripPrePublish.l_errorPreCondition.length>0){
                            vm.errorPreLo = " 存在点位任务前置条件错误 ";
                            vm.errorPreLocation = vm.tripPrePublish.l_errorPreCondition;
                        }
                        if (vm.tripPrePublish.locationTaskCirculate!==null&&vm.tripPrePublish.locationTaskCirculate.length>0){
                            vm.loCir = " 存在点位出现条件死循环 ";
                            vm.locationCir = vm.tripPrePublish.locationTaskCirculate;
                        }else {
                            vm.loCirCheck = " 点位出现条件循环检测 ";
                        }
                        if (vm.tripPrePublish.preConditionAndEnd!==null&&vm.tripPrePublish.preConditionAndEnd.length>0){
                            vm.preConAndEnd = " 存在任务既是前置条件又是结束任务 ";
                            vm.preConditionAndEnd = vm.tripPrePublish.preConditionAndEnd;
                        }
                        if (vm.tripPrePublish.locationTaskDTOSHasLimitPre!==null&&vm.tripPrePublish.locationTaskDTOSHasLimitPre.length>0){
                            vm.ltHasLiPre = " 存在点位任务前置条件是限次点位 ";
                            vm.ltHasLiPreCondition = vm.tripPrePublish.locationTaskDTOSHasLimitPre;
                        }
                        if (vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore!==null&&vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore.length>0){
                            vm.liLtHasCEC = " 存在限次点位下任务是线索或结束或可跳过任务 ";
                            vm.liLtHasClueEndCanIgnore = vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore;
                        }
                    }
                    return err;
                }
            }).error(function(err){
                console.log(err);
            });
        }
        vm.mTitle = "发布线路";
        vm.mContent = "确定要发布线路 "+vm.tripModel.id+" ?";
        vm.publish = publish;
        function publish(tripId) {
            check(tripId);
            // var param = {tripId:tripId,status:'PUBLISH'};
            // tripModelPublish.tmPublish(param).success(function(data){
            //     // console.log(data);
            //     vm.tripModel = TripModel.get({id:vm.tripModel.id});
            //     $('#myModal1').modal('hide');
            // });
        }

        vm.showTrips = showTrips;
        function showTrips(id) {
            if (id!=null && vm.tripModel.trips==null){
                $http({
                    method: 'GET',
                    url: '/api/trip-models/getTripByTripModelId/' + id
                }).then(function (res) {
                    // console.log(res);
                    if (res!=null && vm.tripModel.trips==null){
                        vm.tripModel.trips = res.data;
                        vm.showTripsButton = false;
                    }
                },function (err) {
                    console.log(err);
                });
            }
        }

        vm.showQrCodeAll = showQrCodeAll;
        function showQrCodeAll() {
            var url = '';
            if (vm.tripModel.tradableFlag){
                url='/api/trip-models/hide/getTripByTripModelId/' + vm.tripModel.id;
            }

            $http({
                method: 'GET',
                url: url,
            }).then(function (res) {
                // console.log(res);
                if (res!=null && res.data!=null){
                    if (res.data.length>0){
                        // console.log(res.data);
                        var tripId = res.data[0].id;
                        var location = window.location.host;
                        vm.qrCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+tripId;
                        vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+tripId+"&istest=1&isweb=1";
                        document.getElementById("qrCode").style.display = 'block';
                        $('#showQrCode').modal('show');
                    }
                }
            },function (err) {
                console.log(err);
            });
        }
        vm.dismiss=dismissFunc;
        function dismissFunc(){
            $('#share_to_eaccount').modal('hide');
            vm.eaccounts=[];
            vm.selectedEAccounts=[];
        }
        this.filterEAccount = function (search) {
            if(search){
                EAccountSearchByIDAndName.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                },onSuccess);

                function onSuccess(data, headers) {
                    console.log ( data);
                   //vm.eaccounts=data
                    if(vm.selectedEAccounts){
                        var filterEaccount=[];
                        for(var i=0;i<data.length;i++){
                            var flag =true;
                            for (var j = 0; j < vm.selectedEAccounts.length; j++) {
                                if( data[i].id == vm.selectedEAccounts[j].id){
                                    flag =false;
                                    break;
                                }
                            }
                            if(flag){
                               // console.log(data[i])
                                filterEaccount.push(data[i]);
                            }
                        }
                        vm.eaccounts=filterEaccount;
                    }else{
                        vm.eaccounts=data;
                    }
                }
            }
        };

        var ids = [];
        vm.toConfirm = function toConfirm() {
            if (vm.sharedToAll) {
                $http({
                    method: 'POST',
                    url: '/api/trip-models/shareToAllEAccounts/'+ vm.tripModel.id,
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                }, function (err) {
                    //console.log(err);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                });


            } else {
                ids = [];
                // console.log(vm.selectedEAccounts);
                for (var i = 0; i < vm.selectedEAccounts.length; i++) {
                    ids[i] = vm.selectedEAccounts[i].id;
                }
                if(ids.length>80){
                    alert("共享企业超过最大限制，请复制新线路，再进行共享操作。");
                    return;
                }
                // console.log(ids);
                $http({
                    method: 'POST',
                    url: '/api/trip-models/setShareEaccounts/' + vm.tripModel.id,
                    data: ids
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    // console.log(res);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                }, function (err) {
                    // console.log(err);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                });
            }
        };

        vm.toWeb = toWeb;
        function toWeb(type) {
            if (vm.tripModel.status == 'EDIT'){
                alert("请先发布线路！！");
            }else {
                var param = {id:vm.tripModel.id,type:type};
                tripModelToWeb.toWeb(param).success(function (res) {
                    if (res.code == -1) {
                        vm.mTitle = "公开线路";
                        vm.tripModel.coverImg = null;
                        $('#myModal2').modal('show');
                    } else {
                        $state.go('trip-model-detail',{id:vm.tripModel.id},{reload:true});
                        //canToWeb();
                    }
                });
            }
        }
        vm.confirmToWeb = confirmToWeb;
        function confirmToWeb() {
            // console.log(vm.tripModel.coverImg);
            if (vm.tripModel.coverImg==null || typeof vm.tripModel.coverImg==='undefined'){
                alert("活动图片不能为空");
            }else {

                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.tripModel.coverImg)){
                    var image = document.getElementById("file_img");
                    if (image != null){
                        var height = image.height;
                        var width = image.width;
                        var bi = width / height;
                        if (bi<2.8 || bi>3.2) {
                            // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                            alert("上传图片比例应为3:1");
                            if (vm.tripModel.coverImg != null) {
                                vm.tripModel.coverImg = null;
                            }
                            vm.isSaving = false;
                            return;
                        }
                    }
                }

                var param = {id:vm.tripModel.id,type:1,tripModel:vm.tripModel};
                tripModelConfirmToWeb.toWeb(param).success(function (res) {
                    if (res.code == 0) {
                        $('#myModal2').modal('hide');
                        window.setTimeout(function () {
                            $state.go('trip-model-detail', {id: vm.tripModel.id}, {reload: true});
                        },300);
                    }
                });
            }
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.tripModel.coverImg != null) {
                vm.tripModel.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {

                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    var imgSize = compressedBlob.size/1024;
                    // console.log(imgSize);
                    var file = data.file;

                    if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                        // console.log(imgSize);
                        if (imgSize>imgLimit){
                            alert("图片大小超过"+imgLimit+"kb");
                            return;
                        }
                        vm.type = 'img';
                    }else if (/\.(mp3|mp4)$/.test(file)){
                        if (imgSize>otherLimit){
                            alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                            return;
                        }
                        vm.type = 'other';
                    }else {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }

                    //update the url
                    vm.tripModel.coverImg = data.file;
                    // console.log(vm.tripModel.coverImg);

                    // $scope.myImage = vm.IMAGE_SERVER+vm.trip.coverImg;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };

            //TODO gif no compress
            if (uploadFile != null) {
                // Ahdin.compress({
                //     sourceFile: uploadFile
                //     // maxWidth: 1280,
                //     // maxHeight: 1000,
                //     // quality: 1
                // }).then(function (compressedBlob) {
                //     console.log('compressed image by ahdin.');
                uploadImageFile(uploadFile);
                // });
            }
        }

        // canToWeb();
        // function canToWeb() {
        //     if (vm.tripModel.toWeb!=null && vm.tripModel.toWeb==1){
        //         vm.canToWeb = true;
        //     }
        // }

        var unsubscribe = $rootScope.$on('taskServiceApp:tripModelUpdate', function(event, result) {
            vm.tripModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
