(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TestAccountController', TestAccountController);

    TestAccountController.$inject = ['TestAccount', 'TestAccountSearch', 'UserInfo'];

    function TestAccountController(TestAccount, TestAccountSearch, UserInfo) {

        var vm = this;

        vm.testAccounts = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.IMAGE_SERVER = IMAGE_SERVER;

        var userInfos = [];

        loadAll();

        function loadAll() {
            TestAccount.query(function(result) {
                vm.testAccounts = result;
                for (var i=0;i<vm.testAccounts.length;i++){
                    vm.testAccounts[i].user = UserInfo.get({
                        id: vm.testAccounts[i].userId
                    })
                }
                // vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            TestAccountSearch.query({query: vm.searchQuery}, function(result) {
                vm.testAccounts = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }    }
})();
