(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('BadgesDetailController', BadgesDetailController);

    BadgesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Badges', 'User'];

    function BadgesDetailController($scope, $rootScope, $stateParams, previousState, entity, Badges, User) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.badges = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:badgesUpdate', function(event, result) {
            vm.badges = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
