(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripCommentDetailController', TripCommentDetailController);

    TripCommentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TripComment'];

    function TripCommentDetailController($scope, $rootScope, $stateParams, previousState, entity, TripComment) {
        var vm = this;

        vm.tripComment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:tripCommentUpdate', function(event, result) {
            vm.tripComment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
