(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('InitEAccountDialogController', InitEAccountDialogController);

    InitEAccountDialogController.$inject = ['$rootScope','$http','$cookies','$timeout', '$scope', '$state', 'entity', 'EAccount','Upload'];

    function InitEAccountDialogController ($rootScope,$http,$cookies,$timeout, $scope, $state, entity, EAccount,Upload) {

        var vm = this;

        vm.eAccount = entity;
        vm.clear = clear;
        vm.clearTip = clearTip;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.checkNum1 = checkNum1;
        function checkNum1(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("统一社会信用代码必须为数字");
                vm.eAccount.registerNo = null;
            }
        }
        vm.checkNum2 = checkNum2;
        function checkNum2(input) {
            var reg=/^[0-9]*$/;
            if (!reg.test(input) && input!=null){
                alert("手机号码必须为数字");
                vm.eAccount.contact = null;
            }
        }

        load();
        function load() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height()>=732) $(".footer .foot").removeClass("mobile");
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }

        function clear () {
            window.history.go(-1);
            addLayout();
        }

        function save () {
            // if (vm.eAccount.licence == null) {
            //     alert("请上传最新的营业执照");
            // } else
            if (vm.eAccount.contactIdCardImg == null) {
                alert("请上传法人/运营者/经办人身份证照片");
            } else {
                vm.isSaving = true;
                $http({
                    url:'/api/check-invate-code',
                    method:'POST',
                    params: {
                        code: vm.eAccount.invitationCode
                    }
                }).success(function (res) {
                    if (res.errorCode == -1) {
                        alert("邀请码有误");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else if (res.errorCode == -2) {
                        alert("邀请码已失效");
                        vm.isSaving = false;
                        vm.code = null;
                        return;
                    } else {
                        EAccount.save(vm.eAccount, onSaveSuccess, onSaveError);
                    }
                });
            }


            // if (vm.eAccount.contact!=null){
            //     var phone = vm.eAccount.contact;
            //     if(!(/^1[34578]\d{9}$/.test(phone))){
            //         alert("手机号码有误，请重填");
            //         vm.eAccount.contact = null;
            //         vm.isSaving = false;
            //         return;
            //     }
            // }
            // if (vm.eAccount.email != null){
            //     var email = vm.eAccount.email;
            //     var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //邮箱验证正则表达式
            //     if (!reg.test(email)){
            //         alert("邮箱格式不正确");
            //         vm.isSaving = false;
            //         return;
            //     }
            // } else {
            //     alert("邮箱不能为空");
            //     vm.isSaving = false;
            //     return;
            // }


        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            addLayout();
           // $state.go("trip-model",{},{reload:true});
           window.setTimeout(function () {
            $state.go("e-account-my.edit",{},{reload:true});
           },300);
        }

        function onSaveError (err) {
            // console.log(err);
            vm.isSaving = false;
        }

        vm.getActivationCode = function(){
            $('#myModal').modal('show');
        }

        function clearTip() {
            $('#myModal').modal('hide');
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect (uploadFile, type){
            if (uploadFile!=null){
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"EACCOUNT"},
                    file: uploadFile,
                    method: 'POST'
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    // console.log(data);
                    if (type == 1) {
                        vm.eAccount.licence = data.file;
                    } else if (type == 2) {
                        vm.eAccount.contactIdCardImg = data.file;
                    } else {
                        vm.eAccount.web = data.file;
                    }

                    load();
                }).error(function (data, status, headers, config) {
                    console.log('error status: ' + status);
                });
            }
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
            $("body").removeClass('modal-open')
        })
    }
})();
