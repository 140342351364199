(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcTeamTripDetailController', NpcTeamTripDetailController);

    NpcTeamTripDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NpcTeamTrip'];

    function NpcTeamTripDetailController($scope, $rootScope, $stateParams, previousState, entity, NpcTeamTrip) {
        var vm = this;

        vm.npcTeamTrip = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:npcTeamTripUpdate', function(event, result) {
            vm.npcTeamTrip = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
