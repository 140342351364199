(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripCheckController',TripCheckController);

    TripCheckController.$inject = ['$uibModalInstance','$state', 'check','$http','$stateParams'];

    function TripCheckController($uibModalInstance, $state, check, $http,$stateParams) {
        var vm = this;

        vm.tripPrePublish = check;
        vm.clear = clear;

        console.log(vm.tripPrePublish);

        if (vm.tripPrePublish.code===0){
            vm.mTitle = "逻辑检测";
            vm.checkOk = "未检测到逻辑问题";
        }else if (vm.tripPrePublish.code===-1){
            //修改modal内容
            vm.mTitle = "逻辑检测";
            vm.checkFail = "检测到逻辑问题，请核查修改";
        }

        vm.myTripModelId = $stateParams.id;
        vm.myTripModelTitle = vm.tripPrePublish.title;
        if (vm.tripPrePublish.locationTasks!==null&&vm.tripPrePublish.locationTasks.length>0){
            vm.locationTaskSize = vm.tripPrePublish.locationTasks.length;
        }
        if (vm.tripPrePublish.baseTasks!==null&&vm.tripPrePublish.baseTasks.length>0){
            vm.baseTaskSize = vm.tripPrePublish.baseTasks.length;
        }
        if (vm.tripPrePublish.trips!==null&&vm.tripPrePublish.trips.length>0){
            vm.tripSize = vm.tripPrePublish.trips.length;
        }
        if (vm.tripPrePublish.tripEmpty===true){
            vm.tripEmptys = " 该线路未包含任何点位 ";
        }
        if (vm.tripPrePublish.emptyLocationTasks!==null&&vm.tripPrePublish.emptyLocationTasks.length>0){
            vm.empLo = " 存在 "+vm.tripPrePublish.emptyLocationTasks.length+" 个空点位 ";
            vm.empLocation = vm.tripPrePublish.emptyLocationTasks;
        }else {
            vm.empLoCheck = "空点位检测";
        }
        if (vm.tripPrePublish.b_errorPreCondition!==null&&vm.tripPrePublish.b_errorPreCondition.length>0){
            vm.bErrorPre = " 存在任务前置条件错误 ";
            vm.bErrorPreCondition = vm.tripPrePublish.b_errorPreCondition;
        }
        if (vm.tripPrePublish.baseTaskCirculate!==null&&vm.tripPrePublish.baseTaskCirculate.length>0){
            vm.baseCir = " 存在任务出现条件死循环 ";
            vm.baseTaskCir = vm.tripPrePublish.baseTaskCirculate;
        }else {
            vm.baseCirCheck = " 任务出现条件循环检测 ";
        }
        if (vm.tripPrePublish.clueAndEnd!==null&&vm.tripPrePublish.clueAndEnd.length>0){
            vm.cluAndEnd = " 存在线索与结束任务冲突 ";
            vm.cluAndEnds = vm.tripPrePublish.clueAndEnd;
        }
        if (vm.tripPrePublish.endInPreCondition!==null&&vm.tripPrePublish.endInPreCondition.length>0){
            vm.endInPre = " 存在结束任务在前置条件中 ";
            vm.endInPreCondition = vm.tripPrePublish.endInPreCondition;
        }
        if (vm.tripPrePublish.l_errorPreCondition!==null&&vm.tripPrePublish.l_errorPreCondition.length>0){
            vm.errorPreLo = " 存在点位任务前置条件错误 ";
            vm.errorPreLocation = vm.tripPrePublish.l_errorPreCondition;
        }
        if (vm.tripPrePublish.locationTaskCirculate!==null&&vm.tripPrePublish.locationTaskCirculate.length>0){
            vm.loCir = " 存在点位出现条件死循环 ";
            vm.locationCir = vm.tripPrePublish.locationTaskCirculate;
        }else {
            vm.loCirCheck = " 点位出现条件循环检测 "
        }
        if (vm.tripPrePublish.preConditionAndEnd!==null&&vm.tripPrePublish.preConditionAndEnd.length>0){
            vm.preConAndEnd = " 存在任务既是前置条件又是结束任务 ";
            vm.preConditionAndEnd = vm.tripPrePublish.preConditionAndEnd;
        }
        if (vm.tripPrePublish.locationTaskDTOSHasLimitPre!==null&&vm.tripPrePublish.locationTaskDTOSHasLimitPre.length>0){
            vm.ltHasLiPre = " 存在点位任务前置条件是限次点位 ";
            vm.ltHasLiPreCondition = vm.tripPrePublish.locationTaskDTOSHasLimitPre;
        }
        if (vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore!==null&&vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore.length>0){
            vm.liLtHasCEC = " 存在限次点位下任务是线索或结束或可跳过任务 ";
            vm.liLtHasClueEndCanIgnore = vm.tripPrePublish.limitLocationTaskHasClueOrHasEndOrHasCanIgnore;
        }

        vm.toTrip = toTrip;
        function toTrip(id) {
            $state.go('trip-model-detail',{id:id});
            window.setTimeout(function () {
                window.location.reload();
            },300);
        }
        vm.toLocation = toLocation;
        function toLocation(id) {
            // clear();
            $state.go('location-task-detail',{id:id});
            window.setTimeout(function () {
                window.location.reload();
            },300);
        }
        vm.toBaseTask = toBaseTask;
        function toBaseTask(id) {
            // clear();
            $state.go('base-task-detail',{id:id});
            window.setTimeout(function () {
                window.location.reload();
            },300);
        }

        vm.confirmPrePublish = confirmPrePublish;
        function confirmPrePublish(id) {
            var status = null;
            if (vm.trip.status==='EDIT'){
                status = 'PREPUBLISH';
            }else if (vm.trip.status==='PREPUBLISH'){
                status = 'PUBLISH';
            }
            $http({
                method: 'PUT',
                url: '/api/trips/updateStatus/'+id+'/'+status
            });
            vm.clear();
            window.setTimeout(function () {
                window.location.reload();
            },300);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
