(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('PerfectInformationController', PerfectInformationController);


    PerfectInformationController.$inject = ['$translate', '$timeout', 'Auth', 'LoginService','$state','Principal','$http' ,'$stateParams','PerfectInformation','$interval'];

    function PerfectInformationController ($translate, $timeout, Auth, LoginService,$state,Principal,$http,$stateParams,PerfectInformation,$interval) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        // vm.login = LoginService.open;
        vm.perfectInformation = perfectInformation;
        vm.registerAccount = {};
        vm.success = null;
        vm.paramError = null;
        vm.checked = false;
        vm.dis = false;
        vm.authenticationError = false;
        vm.returnLogin = returnLogin;//返回官网登录页
        // console.log($stateParams);

        vm.id = '';//用户ID
        vm.isShowSuccessIcon = false;//默认不显示
        vm.isOpen = '';//默认日期选择器关闭状态
        vm.login = '';//兔子账号
        vm.Rpassword = '';//密码
        vm.loginBack = login;
        vm.blurLogin = blurLogin;//失去焦点 校验
        vm.isAccountExist = false;

        vm.userName = '';//真实姓名
        vm.borthDate = '';//出生年月
        vm.sex = '请选择';//性别
        vm.city = '请选择';//所在城市
        vm.email = '';//邮箱地址
        vm.authorities = [];//用户权限
        vm.parray = Array(
            "北京",
            "上海",
            "天津",
            "重庆",
            "河北",
            "山西",
            "内蒙古",
            "辽宁",
            "吉林",
            "黑龙江",
            "江苏",
            "浙江",
            "安徽",
            "福建",
            "江西",
            "山东",
            "河南",
            "湖北",
            "湖南",
            "广东",
            "广西",
            "海南",
            "四川",
            "贵州",
            "云南",
            "西藏",
            "陕西",
            "甘肃",
            "宁夏",
            "青海",
            "新疆",
            "香港",
            "澳门",
            "台湾"
        );

        //执行bootscript 气泡提示；
        $("[data-toggle='popover']").popover();


        vm.openCalendar = openCalendar;
        function openCalendar() {
            // console.log("++++++++++++");
            vm.isOpen = true;
            // alert(vm.isOpen);
        }
        // 日历
        // $('#borthDate').datetimepicker({
        //     minView: "month", //选择日期后，不会再跳转去选择时分秒
        // });

        $timeout(function (){angular.element('#login').focus();});
        loadAll();
        function loadAll() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height()>=732) $(".footer .foot").removeClass("mobile");
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }

        /**
         * 获取当前登录账号信息
         */
        function getAccount() {
            Principal.identity().then(function (account) {
                if (account){
                    vm.account = account;
                    vm.author = vm.account.authorities;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    vm.id = account.id;
                    //如果是普通用户 跳转到完善资料页面
                    if ($.inArray("ROLE_USER",vm.author)!==-1 && vm.author.length == 1) {
                        //如果是普通用户 直接提交
                        perfectInformation();
                    }else {
                        alert('您已经是设计师啦，请直接登录！！！');
                        window.setTimeout(function(){
                           window.location.href = window.location.protocol+"//"+window.location.host+'/#/rabbit';
                        },1000);

                    }

                }
            });
        }
        function getAccountAgain() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                var data = account.data;
                console.log(data);
            })
        }
        /**
         * login 登录
         */
        function login(event) {
            // alert("");
            event.preventDefault();
            Auth.login({
                username: vm.login,
                password: vm.Rpassword
            }).then(function (data) {
                vm.authenticationError = false;
                getAccount();

                // if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                //     $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                //     $state.go('guide');
                // }
                // $state.go('guide');
                // if (Auth.getPreviousState()) {
                //     var previousState = Auth.getPreviousState();
                //     Auth.resetPreviousState();
                //     $state.go(previousState.name, previousState.params);
                // }
                // console.log(data);
                // if (data!=null){
                //     if (data.data.access_token!=null){
                //         // getVersion();
                //     }
                // }
            }).catch(function (err) {
                // console.log(err);
                vm.authenticationError = true;
                vm.isAccountExist
            });
        }

        /**
         * 失去焦点时检查 账号是否存在
         */
        function blurLogin(event) {
            // alert("");
            // console.log(vm.login);
            if (vm.login == "" || vm.login == undefined || vm.login == null) {
                return;
            }
            if (vm.login.length == 11 ) {
                event.preventDefault();
                $http({
                    url: '/api/account/check-user/isUser/'+vm.login,
                    method: 'GET'
                }).then(function (res) {
                    // console.log(res);
                    vm.code = res.data.code;
                    if (vm.code == -2)  {
                        vm.isAccountExist =  true;
                        vm.showMessage = '用户不存在，请前往微信小程序注册！';
                    }
                    if (vm.code == 0)  {
                        vm.author = res.data.data;
                        // console.log(vm.author);
                        if ($.inArray("ROLE_USER",vm.author)!==-1 && vm.author.length == 1) {
                            vm.isAccountExist =  true;
                            vm.showMessage = '您已经是普通用户,请继续完善资料成为兔子吧！';
                        }else {
                            vm.isAccountExist =  true;
                            vm.showMessage = '您已经是兔子啦！直接返回登录！';
                        }
                    }
                }).catch(function (err) {
                    // vm.isAccountExist = true;
                    // vm.showMessage = '手机号错误！';
                });
            }else {
                vm.isAccountExist =  true;
                vm.showMessage = '手机号错误！';
            }

        }


        //提交
        function perfectInformation () {
            // console.log(vm.userName);
            var userInfo = {
                realName: vm.userName,
                gender: vm.sex,
                email: vm.email,
                userDetail: {
                    birth: vm.borthDate,
                    city : vm.city
                }
            };
            console.log(userInfo);
            if (vm.login  == '' || vm.Rpassword== '' || vm.userName== '' || vm.borthDate== '' || vm.sex== '' || vm.city== '' ) {
                alert("请输入带*的必填项！");
                return;
            }else if (vm.checked == false){
                alert("请先勾选 阅读并接受《兔子中心用户协议》");
            }else {
                PerfectInformation.updateData(userInfo,vm.id).then(function (res) {
                    console.log(res.data.message);
                    if (res.data.message = '更新成功') {
                        vm.isShowSuccessIcon = true;
                        var authorities = {
                            authorities:["ROLE_USER","ROLE_INDIEDESIGN"]
                        };

                    PerfectInformation.updateData(authorities,vm.id).then(function (res) {
                        if (res.data.message = '更新成功') {
                            getAccountAgain();
                            window.setTimeout(function(){
                                $("#nav, #foot").css("display", "block");
                             // $state.go('guide', {auth: 'IGC'}, { reload: true });
                                $state.go('trip-model',null,{reload: true});
                            },1000);

                        }
                    });

                    }
                }).catch(function (response) {
                   console.log(response);
                });
            }
        }

        function returnLogin() {
            window.location.href = window.location.protocol+"//"+window.location.host+'/#/login';
        }
    }
})();
