(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripTypeDeleteController',TripTypeDeleteController);

    TripTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'TripType'];

    function TripTypeDeleteController($uibModalInstance, entity, TripType) {
        var vm = this;

        vm.tripType = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TripType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
