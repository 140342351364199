(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('login', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            params: {
                loginType:{
                    value: '1',
                    squash:true
                }
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/login/login.html',
                    controller: 'LoginSelfController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();

//官网兔子俱乐部
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('rabbit', {
            parent: 'app',
            url: '/rabbit',
            data: {
                authorities: []
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/login/rabbit-home.html',
                    controller: 'RabbitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();

//官网兔子发现页
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('rabbit-findings', {
            parent: 'app',
            url: '/rabbit-findings',
            data: {
                authorities: []
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/login/rabbit-findings.html',
                    controller: 'RabbitFindingsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();


//官网兔子独立设计师页面
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('rabbit-designer', {
            parent: 'app',
            url: '/rabbit-designer/{id}',
            data: {
                authorities: []
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/login/rabbit-designer.html',
                    controller: 'RabbitDesignerController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();


//公开报名统计页面
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('open-registration', {
            parent: 'app',
            url: '/trips-pay',
            data: {
                authorities: []
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/login/trips-pay.html',
                    controller: 'OpenRegistrationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
