(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripDeleteGameController',TripDeleteGameController);

    TripDeleteGameController.$inject = ['$uibModalInstance', 'entity', 'Trip', '$http'];

    function TripDeleteGameController($uibModalInstance, entity, Trip, $http) {
        var vm = this;

        vm.trip = entity;
        vm.clear = clear;
        // vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.diag = diag;
        function diag() {
            var str = prompt("请再次输入您要清空的线路ID");
            if (str == vm.trip.id){
                confirmDelete(vm.trip.id);
            }else {
                alert("你要清空的线路与您输入的不一致，请重新操作！！");
            }
        }

        function confirmDelete (id) {

            $http.delete("/api/trips/deleteall/"+vm.trip.id).then(function (response) {
                $uibModalInstance.close(true);
                return (response.data);
            });

        }
    }
})();
