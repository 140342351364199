(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelController', controller);

    controller.$inject = ['$state', 'TripModel', 'TripModelSearch','TripModelAdminSearch', 'Principal', '$http', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','EAccountSearchByIDAndName','$sce','$cookies'];

    function controller($state, TripModel, TripModelSearch, TripModelAdminSearch,Principal, $http, ParseLinks, AlertService, paginationConstants, pagingParams,EAccountSearchByIDAndName,$sce,$cookies) {

        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        // vm.phoneLogin = false;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;
        vm.selectTripModelId = null;
        vm.getSelectedEAccount = getSelectedEAccount;
        vm.agreement = false;
        vm.searchType = pagingParams.searchType;
        inputPlaceholder();
        loadAll();
        function getSelectedEAccount(tripModelId) {
            $http({
                method: 'GET',
                url: '/api/trip-models/getShareEaccounts/' + tripModelId
            }).then(function (res) {
                if (res.data.errorCode == 1) {
                    vm.sharedToAll = true;
                } else {
                    vm.sharedToAll = false;
                    if (res.data != '') {
                        vm.selectedEAccounts = res.data;
                    }
                }
                vm.selectTripModelId = tripModelId;
                // console.log(res);
            },function (err) {
                console.log(err);
            });
        }

        vm.dismiss=dismissFunc;
        function dismissFunc(){
            $('#share_to_eaccount').modal('hide');
            vm.eaccounts=[];
            vm.selectedEAccounts=[];
        }


        var ids = [];
        vm.toConfirm = function toConfirm() {
            if (vm.sharedToAll) {
                $http({
                    method: 'POST',
                    url: '/api/trip-models/shareToAllEAccounts/'+ vm.selectTripModelId,
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        // vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];

                }, function (err) {
                    //console.log(err);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                });


            } else {
                ids = [];
                // console.log(vm.selectedEAccounts);
                for (var i = 0; i < vm.selectedEAccounts.length; i++) {
                    ids[i] = vm.selectedEAccounts[i].id;
                }
                if(ids.length>80){
                    alert("共享企业超过最大限制，请复制新线路，再进行共享操作。");
                    return;
                }
                // console.log(ids);
                $http({
                    method: 'POST',
                    url: '/api/trip-models/setShareEaccounts/' + vm.selectTripModelId,
                    data: ids
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        // vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    // console.log(res);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];
                }, function (err) {
                    // console.log(err);
                    $('#share_to_eaccount').modal('hide');
                    vm.eaccounts=[];
                    vm.selectedEAccounts=[];

                });
            }
            vm.selectedEAccounts = [];
            vm.sharedToAll = false;
            vm.selectTripModelId = null;
        };
        this.filterEAccount = function (search) {
            if(search){
                EAccountSearchByIDAndName.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                },onSuccess);

                function onSuccess(data, headers) {
                    console.log ( data);
                   //vm.eaccounts=data
                    if(vm.selectedEAccounts){
                        var filterEaccount=[];
                        for(var i=0;i<data.length;i++){
                            var flag =true;
                            for (var j = 0; j < vm.selectedEAccounts.length; j++) {
                                if( data[i].id == vm.selectedEAccounts[j].id){
                                    flag =false;
                                    break;
                                }
                            }
                            if(flag){
                               // console.log(data[i])
                                filterEaccount.push(data[i]);
                            }
                        }
                        vm.eaccounts=filterEaccount;
                    }else{
                        vm.eaccounts=data;
                    }
                }
            }
        };
        vm.labNotice = function(){
            var labNotice = $cookies.get("labNotice");
            if (typeof(labNotice) !== "undefined" && labNotice == vm.account.id) {
                $('#myModal10').modal('show');
            } else {
                vm.agreement = false;
                $('#myModal9').modal('show');
            }
        };
        vm.labModel = function(){
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 365 * 24 * 60 * 60 * 1000);
            $cookies.put('labNotice', vm.account.id, {expires: new Date(expireDate)});
            $('#myModal10').modal('show');
        };
        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
                if (vm.account!==null){
                    // var type = -1;
                    // if($(window).width() < 700 && !vm.account.mobileTipShowFlag){
                    //     $('#myModal2').modal('show');
                    //     // type = 1;
                    // } else if (vm.account.pcTipShowFlag == false) {
                    //     $('#myModal1').modal('show');
                    //     type = 0;
                    // }
                    // if (type != -1) {
                    //     $http({
                    //         url: 'api/tip/change-status/' + type,
                    //         method: 'POST'
                    //     }).then(function (res) {
                    //         console.log("修改结果：" + JSON.stringify(res));
                    //     })
                    // }

                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                    //弹出企业账户权限认证modal
                    // if (vm.account.eAccountActive==false&&vm.account.eAccountConfirmEmail==false){
                    //     // if (vm.auth=="UGC"||vm.auth=="TGC"){
                    //     if(vm.auth=='IGC'){
                    //         if (vm.account.eAccountId==null){
                    //             // $('#myModal4').modal('show');
                    //         }else {
                    //             $('#myModal6').modal('show');
                    //         }
                    //     }
                    // }else {
                    //     // if (vm.auth=="UGC"||vm.auth=="TGC"){
                    //     if(vm.auth=='IGC'){
                    //         if (vm.account.eAccountActive === false && vm.account.eAccountConfirmEmail === true) {
                    //             //若是认证被拒绝，则弹出框提示拒绝原因
                    //             if (vm.account.notPassReason != null && vm.account.notPassReason != "") {
                    //                 $('#myModal5').modal('show');
                    //                 vm.content = "您的认证申请审核未通过，未通过原因：" + vm.account.notPassReason;
                    //                 vm.toGo = "重新认证";
                    //             } else {
                    //                 $('#myModal5').modal('show');
                    //                 vm.content = "您的认证信息已提交审核，点击前往查看审核状态";
                    //                 vm.toGo = "前往";
                    //             }
                    //         }
                    //     }
                    // }
                    $(".container").removeClass("login-container-content");
                    $("#nav, #foot").css("display", "block");
                }
            });
        }

        vm.inputPlaceholder = inputPlaceholder;
        function inputPlaceholder(){
            $('#searchQuery').attr('placeholder',placeholderString());
            function placeholderString(){
                switch (Number( vm.searchType)){
                case 1:
                    return '输入企业全称或者ID';
                    break;
                case 2:
                    return '输入设计师账号';
                    break;
                default:
                    return '搜索线路';
                }
            }
        }


        vm.ident = ident;
        function ident() {
            $("#myModal4").modal('hide');
            $("#myModal6").modal('hide');
            $(".modal-backdrop").remove();
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
        vm.toPassWord = toPassWord;
        function toPassWord() {
            $("#myModal5").modal('hide');
            $(".modal-backdrop").remove();
            if (vm.account.notPassReason!=null&&vm.account.notPassReason!=""){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my",{},{reload:true});
                },300);
            }
        }
        vm.logout = logout;
        function logout() {
            $('#myModal4').modal('hide');
            window.setTimeout(function () {
                // $state.go('login');
                //返回官网登录页
                window.location.href = window.location.protocol+"//"+window.location.host+'/#/rabbit';
            },300);
        }
        // this.$onInit = function() {
        //     //页面加载版本更新日志
        //     getVersion();
        // };
        // //获取 版本更新日志信息
        // // vm.getVersion = getVersion;
        // function getVersion() {
        //     $http({
        //         method: 'GET',
        //         url: '/api/version-descs'
        //     }).then(function (res) {
        //         // console.log(res);
        //         var num = res.data.length;
        //         if (JSON.parse(window.localStorage.getItem("updateLogs"))) {
        //             var version_localStorage =  JSON.parse(window.localStorage.getItem("updateLogs"))["name"];
        //         }else {
        //             $('#myUpdateLogModal').modal('show');
        //             var num = res.data.length;
        //             if (num>0){
        //                 vm.versionDesc = res.data[num-1];
        //                 vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //             }else {
        //                 vm.versionDesc = res.data[num];
        //                 vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //             }
        //             //保存浏览器缓存中;
        //             window.localStorage.setItem("updateLogs",JSON.stringify(res.data[num-1]));
        //         }
        //
        //         var version_lastestLog  = res.data[num-1]["name"];
        //         if (res.data.length>0){
        //             if (version_lastestLog !== version_localStorage) {
        //                 $('#myUpdateLogModal').modal('show');
        //                 var num = res.data.length;
        //                 if (num>0){
        //                     vm.versionDesc = res.data[num-1];
        //                     vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //                 }else {
        //                     vm.versionDesc = res.data[num];
        //                     vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //                 }
        //                 //保存浏览器缓存中;
        //                 window.localStorage.setItem("updateLogs",JSON.stringify(res.data[num-1]));
        //             }else {
        //                 $('#myUpdateLogModal').modal('hide');
        //             }
        //
        //             // console.log(JSON.parse(window.localStorage.getItem("updateLogs")));
        //         }else {
        //             // $state.go('guide');
        //         }
        //         // console.log(vm.versionDesc);
        //     },function (err) {
        //         console.log(err);
        //     })
        // }
        // vm.toLogin = toLogin;
        // function toLogin() {
        //     $('#myUpdateLogModal').modal('hide');
        // }


        // vm.breakPhoneWarn = breakPhoneWarn;
        // function breakPhoneWarn() {
        //     if(vm.phoneLogin){
        //         $http({
        //             url: 'api/tip/change-status/' + 1,
        //             method: 'POST'
        //         }).then(function (res) {
        //             console.log("修改结果：" + JSON.stringify(res));
        //         })
        //     }
        // }

        function loadAll () {
            if (pagingParams.search) {
                var search = TripModelAdminSearch;
                if( vm.searchType == 0){
                    search = TripModelSearch;
                }
                search.query({
                    query: pagingParams.search,
                    searchType: Number( pagingParams.searchType ),
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                }, onSuccess, onError);
            } else {
                TripModel.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tripModels = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                searchType:vm.searchType,
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.publish = publish;
        function publish(tripId) {
            for (var i=0; i<vm.tripModels.length;i++){
                // console.log(vm.trips[i].id);
                if (vm.tripModels[i].id === tripId){
                    if (vm.tripModels[i].status !== 'PUBLISH'){
                        var x = confirm("确定要发布线路 "+tripId+" ?");
                        if (x){
                            // vm.trips[i].status = 'PUBLISH';
                            // Trip.update(vm.trips[i], onSaveSuccess, onSaveError);
                            $http({
                                method: 'PUT',
                                url: '/api/trip-model/updateStatus/'+tripId+'/PUBLISH'
                            }).success(function () {
                                console.log();
                            }).error(function(err){
                                console.log(err);
                            });
                            window.location.reload();
                            return;
                        }else {
                            return;
                        }
                    }else {
                        alert("该线路已为发布状态");
                    }
                }
            }
        }

        vm.tripNew = tripNew;
        function tripNew(id , title,platform) {
            $state.go('trip.new',{id:id, title:encodeURI(title),platform:platform} ,{reload : false});
        }
        // $('#myModal1').modal('show');trip.new
        // vm.pcLgoinBtn = pcLgoinBtn;
        // function pcLgoinBtn(){
        //     if(vm.tripModels.length != 1){
        //         return;
        //     }
        //     $('#myModal3').modal('show');
        //     $(".pcLoginImg").css("display", 'block');
        //     $(".pcLogin").css("z-index", '999');
        //     $(".modal-backdrop").css("z-index", '800');
        // }
        vm.newTripModel = function(){
            $('#myModal7').modal('show');
        };
        vm.newTripModelType = function(type){
            $('#myModal7').modal('hide');
            $state.go("trip-model.new",{type:type},{reload : false});
        };

        vm.feedbackNew = function(){
            $('#myModal10').modal('hide');
            $state.go("feedback.new",{reload : false});
        };

        vm.clickEditImg = clickEditImg;
        function clickEditImg(id,type) {
            if(type == 1) {
                // 如果是济南企业，禁止线路编辑器使用旁白语音上传功能； 传递coach字段到线路编辑器，用户禁用旁白上传功能
                if(vm.account.eAccountId===7){
                    window.open(vm.account.acfeUrl + id + '?at=' + vm.account.password + '_coach&coach='+new Date().getTime());
                }else{
                    window.open(vm.account.acfeUrl + id + '?at=' + vm.account.password + '_coach');
                }
            }
            $(".pcLoginImg").css("display", 'none');
            $('#myModal3').modal('hide');
        }
        vm.keyDown = function(event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };

        /*vm.prePublish = prePublish;
        function prePublish(tripModelId) {
            for (var i=0; i<vm.tripModels.length;i++){
                // console.log(vm.trips[i].id);
                if (vm.tripModels[i].id === tripModelId){
                    if (vm.tripModels[i].status !== 'PREPUBLISH' && vm.tripModels[i].status !== 'PUBLISH'){
                        var x = confirm("确定要预发布线路 "+tripModelId+" ?");
                        if (x){
                            // vm.trips[i].status = 'PREPUBLISH';
                            // Trip.update(vm.trips[i], onSaveSuccess, onSaveError);
                            $http({
                                method: 'PUT',
                                url: '/api/trip-model/updateStatus/'+tripModelId+'/PREPUBLISH'
                            }).success(function () {
                                console.log();
                            }).error(function(err){
                                console.log(err);
                            });
                            window.location.reload();
                            return;
                        }else {
                            return;
                        }
                    }else {
                        alert("该线路已为预发布或发布状态");
                    }
                }
            }
        }*/
    }

    angular
        .module('taskServiceApp')
        .controller('TripModelReleaseController', TripModelReleaseController);

    TripModelReleaseController.$inject = ['$state', 'TripModel', 'tripModelPublish','tripModelEdit','TripModelSearch','UserTripModel', 'UserTripModelSearch', 'Principal', '$http', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TripModelReleaseController($state, TripModel, tripModelPublish,tripModelEdit, TripModelSearch, UserTripModel,UserTripModelSearch, Principal, $http, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
            });
        }

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                UserTripModelSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    release:'1',
                    sort: sort()
                },onSuccess,onError);
            } else {
                UserTripModel.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                },onSuccess,onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tripModels = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.edit = edit;
        function edit(tripId) {
            vm.tripId = tripId;
            vm.mTitle = "退回编辑";
            vm.mContent = "确定要将线路 "+vm.tripId+" 退回编辑状态?";
            for (var i=0; i<vm.tripModels.length;i++){
                // console.log(vm.trips[i].id);
                if (vm.tripModels[i].id === tripId){
                    if (vm.tripModels[i].toWeb == 1) {
                        alert("请先将线路取消公开");
                    }else if (vm.tripModels[i].status !== 'EDIT'){
                        vm.confirms = confirms;
                        $('#myModal1').modal('show');
                    }else {
                        alert("该线路已为编辑状态");
                    }
                }
            }
        }
        function confirms() {
            var param = {tripId:vm.tripId,status:'EDIT'};
            tripModelPublish.tmPublish(param).success(function(data){
                // console.log(data);
                loadAll();
                $('#myModal1').modal('hide');
            });
            tripModelPublish.tmPublish(param).error(function(err){
                // console.log(err);
                if (err!=null){
                    vm.err = err;
                    vm.mTitle2 = "退回编辑";
                    $('#myModal1').modal('hide');
                    $('#myModal2').modal('show');
                    if (vm.isSuper){
                        vm.mContent2 = err.message;
                        vm.cancel = false;
                        vm.confirmsConf = confirmsConf;
                    } else {
                        vm.mContent2 = "该线路有关联活动在进行中，不可退回编辑";
                        vm.cancel = true;
                    }
                }
            });
        }
        function confirmsConf(){
            if (vm.err.errorCode==-1){
                var param = {tripId:vm.tripId,status:'EDIT'};
                tripModelEdit.tmEdit(param).success(function(data){
                    // console.log(data);
                    loadAll();
                    $('#myModal1').modal('hide');
                    $('#myModal2').modal('hide');
                });
            }
        }

        /*vm.publish = publish;
        function publish(tripId) {
            for (var i=0; i<vm.tripModels.length;i++){
                // console.log(vm.trips[i].id);
                if (vm.tripModels[i].id === tripId){
                        if (vm.tripModels[i].status !== 'PUBLISH'){
                            var x = confirm("确定要发布线路 "+tripId+" ?");
                            if (x){
                                // vm.trips[i].status = 'PUBLISH';
                                // Trip.update(vm.trips[i], onSaveSuccess, onSaveError);
                                $http({
                                    method: 'PUT',
                                    url: '/api/trip-model/updateStatus/'+tripId+'/PUBLISH'
                                }).success(function () {
                                    console.log();
                                }).error(function(err){
                                    console.log(err);
                                });
                                window.location.reload();
                                return;
                            }else {
                                return;
                            }
                        }else {
                            alert("该线路已为发布状态");
                        }
                }
            }
        }*/
    }

})();
