(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgarmCenterSearch', ProgarmCenterSearch);

        ProgarmCenterSearch.$inject = ['$resource'];

    function ProgarmCenterSearch($resource) {
        var resourceUrl =  'api/program/search/query1';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgarmSearch', ProgarmSearch);

    ProgarmSearch.$inject = ['$resource'];

    function ProgarmSearch($resource) {
        var resourceUrl =  'api/program/search/query';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgramInfo', ProgramInfo);

    ProgramInfo.$inject = ['$resource','DateUtils'];

    function ProgramInfo($resource,DateUtils) {
        var resourceUrl =  'api/program/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgramTripModel', ProgramTripModel);

    ProgramTripModel.$inject = ['$resource'];

    function ProgramTripModel($resource) {
        var resourceUrl =  'api/program/search/query';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgramTripModelSearch', ProgramTripModelSearch);

    ProgramTripModelSearch.$inject = ['$resource'];

    function ProgramTripModelSearch($resource) {
        var resourceUrl =  'api/program/search/tripmModel';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgramChangeStatus', ProgramChangeStatus);

    ProgramChangeStatus.$inject = ['$resource'];

    function ProgramChangeStatus($resource) {
        var resourceUrl =  'api/program/status';

        return $resource(resourceUrl, {}, {
            'change': { method: 'GET'}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProgramTripModelPlanFlagSearch', ProgramTripModelPlanFlagSearch);

    ProgramTripModelPlanFlagSearch.$inject = ['$resource'];

    function ProgramTripModelPlanFlagSearch($resource) {
        var resourceUrl =  'api/program/search/planflag/tripmodel';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
