(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

    function UserManagementDialogController ($scope, $stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
        var vm = this;

        // vm.authorities = ['ROLE_USER', 'ROLE_ACTIVE', 'ROLE_USERDESIGN', 'ROLE_INDIEDESIGN', 'ROLE_OPERATION', 'ROLE_DESIGNER', 'ROLE_RELEASE', 'ROLE_ADMIN', 'ROLE_SUPER'];
        vm.authorities = ['ROLE_USER', 'ROLE_USERDESIGN', 'ROLE_INDIEDESIGN', 'ROLE_OPERATION', 'ROLE_RELEASE', 'ROLE_SUPER'];
        // , 'ROLE_SPY', 'ROLE_COACH'
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;

        vm.choseAll = choseAll;
        vm.user = entity;

        function choseAll() {
            var auth = "";
            for (var i= 0;i<vm.user.authoritiesObj.length;i++){
                if (vm.user.authoritiesObj[i].value === true){
                    auth+=vm.user.authoritiesObj[i].name+',';
                }
            }
            var index = auth.lastIndexOf(',');
            auth = auth.substring(0,index);
            var authArray = auth.split(',');
            var change = authArray.length;
            if (change<7){
                for (var j= 0;j<vm.user.authoritiesObj.length;j++){
                    if (vm.user.authoritiesObj[j].value !== true){
                        vm.user.authoritiesObj[j].value = true;
                        auth+=vm.user.authoritiesObj[j].name+',';
                    }
                }
            }else {
                for (var z= 0;z<vm.user.authoritiesObj.length;z++){
                    if (vm.user.authoritiesObj[z].name === 'ROLE_USER'){
                        vm.user.authoritiesObj[z].value = true;
                    }else {
                        vm.user.authoritiesObj[z].value = false;
                    }
                    auth+=vm.user.authoritiesObj[z].name+',';
                }
            }
            // var index = auth.lastIndexOf(',');
            // auth = auth.substring(0,index);
            // var authArray = auth.split(',');
            //
            // vm.authoritiesObj = authArray;
            // return;

        }

        // this.$onInit = function() {
        //     var loginAuth = document.getElementById("pgc");
        //
        //     var auth = "";
        //     var auths = vm.user.authoritiesObj;
        //     for (var x= 0;x<auths.length();x++){
        //         if (auths[x].value === true){
        //             auth+=auths[x].name+',';
        //         }
        //     }
        //     var index = auth.lastIndexOf(',');
        //     auth = auth.substring(0,index);
        //     var authArray = auth.split(',');
        //     for (var y= 0;y<authArray.length;y++){
        //         if (authArray[y] === 'ROLE_SUPER' && loginAuth === 'Admin'){
        //             document.getElementById("user-dialog").style.display="none";
        //         }
        //     }
        // };

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            var auth = "";
            for (var i= 0;i<vm.user.authoritiesObj.length;i++){
                if (vm.user.authoritiesObj[i].value === true){
                    auth+=vm.user.authoritiesObj[i].name+',';
                }
            }
            var index = auth.lastIndexOf(',');
            auth = auth.substring(0,index);
            var authArray = auth.split(',');

            vm.user.authorities = authArray;
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
