(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('StealthTripController', StealthTripController);

    StealthTripController.$inject = ['$filter','$location','$scope', '$state', 'Trip','Principal', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants','$http','StealthTripSearch','$sce','getAvailableCouponsByEId','TripGame','PublishStealthTrip'];

    function StealthTripController ($filter, $location,$scope, $state, Trip, Principal, ParseLinks, AlertService, pagingParams,
                             paginationConstants, $http, StealthTripSearch,$sce,getAvailableCouponsByEId,TripGame,PublishStealthTrip) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.isSuper = false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.parray = ['状态', '未开始', '进行中', '已结束'];
        vm.states = pagingParams.states;
        vm.state = vm.parray[vm.states];
        vm.absURL = $location.$$absUrl;
        vm.paramTitleError = null;
        vm.paramCodeError = null;
        vm.nowTime = new Date().getTime();
        vm.maxTeamcount;
        vm.time = "2098-12-30T16:00:00.000Z";
        vm.differenceCoupon = null;
        var couponIds = [];
        vm.publishSuccess = false;
        vm.tripId = null;
        vm.isSaving = false;
        vm.addTeamCount = 0;
        vm.tripVersion = 0;
        vm.editTripFlag = false;

        vm.urlSearch = $location.$$search;
        vm.back = vm.urlSearch.back;

        vm.isAfterDate = false;
        selectIsAfterDate();
        function selectIsAfterDate() {
            $http({
                method: 'GET',
                url: '/api/trip-game/isAfterTime',
            }).then(function (res) {
                vm.isAfterDate =res.data;
            },function (err) {
            })
        }

        //跳转过来需要打开发布
        vm.back =  vm.urlSearch.back;
        if(vm.urlSearch.openRelease || ( vm.back!=null&&vm.back!="")){
            vm.tripId = null;
            vm.title = null;
            vm.startCode = null;
            vm.maxTeamcount = 5;
            $('#myModal1').modal('show');
        }

        $scope.htmlTooltip1 = $sce.trustAsHtml('<p style="text-align: left"><br>玩家在进入游戏时输入预设的口令即可开始游戏，活动无需设置日期，灵活控制，随时开启。</p>');
        $scope.htmlTooltip2 = $sce.trustAsHtml('<p style="text-align: left"><br>任何玩家开始游戏后，整场活动即自动置为开始状态。\n' +
            '活动在开始后5小时内有效，期间玩家可自由加入。\n' +
            '过期后活动失效，玩家无法加入或重新开局，正在进行的对战仍可继续。</p>');
        $scope.htmlTooltip3 = $sce.trustAsHtml('<p style="text-align: left"><br>即活动内包含的战场数。每个战场可容纳8人（4v4对战），每局对战结束后战场将重置（玩家被清出），玩家须重新选择战场加入。</p>');

        loadAll();
        vm.tripQrCode = tripQrCode;

        function tripQrCode(id, title,createDate) {
            $state.go('stealth-trip-qrcode', {id: id, title: title,createDate:createDate}, {reload: false});
        }

        vm.newTrip = function () {
            vm.tripId = null;
            vm.title = null;
            vm.startCode = null;
            vm.maxTeamcount = 5;
            if(vm.isAfterDate){
                vm.payCoupon = vm.maxTeamcount * 8;
            }else{
                vm.payCoupon  =0
            }

            $('#myModal1').modal('show');
        }

        vm.changeTeamCount = changeTeamCount;
        function changeTeamCount(){
            var maxTeamcount = vm.maxTeamcount
            if(maxTeamcount == null || isNaN(maxTeamcount) || Number(maxTeamcount) <= 0){
                vm.maxTeamcount = 1;
            }else{
                vm.maxTeamcount = Number(maxTeamcount);
            }
            console.log(vm.maxTeamcount)

        }
        vm.getMaxTeamCount = function(){
            if(isNaN(vm.maxTeamcount)){
                return 0
            }else{
                return vm.maxTeamcount * 8
            }
        }
        vm.onInputChange = onInputChange;
        function onInputChange() {
            if(vm.maxTeamcount != null){
                vm.maxTeamcount == (vm.maxTeamcount + '').replace(/\D/g, '')
                if(isNaN(vm.maxTeamcount) || Number(vm.maxTeamcount) <= 0){
                    vm.maxTeamcount = '';
                }else{
                    vm.maxTeamcount = Number(vm.maxTeamcount);
                    if(vm.isAfterDate){
                        vm.payCoupon = vm.maxTeamcount * 8;
                    }else{
                        vm.payCoupon  =0
                    }
                }
            }
        }
    function save(){
        vm.isSaving = true;
        var url = '/api/stealth/trip?startCode=' + vm.startCode + '&title=' + vm.title + "&tripId=" + vm.tripId + "&maxTeamCount=" + vm.addTeamCount;
        $http({
            method: 'POST',
            url: url
        }).success(function () {
            vm.isSaving = false;
            $('#myModal1').modal('hide');
            $('.modal-backdrop').remove();
            $state.go('stealth-trips', null, {reload: true});

        }).error(function () {
            vm.isSaving = false;
        });
    }


        vm.toPublish = function () {
            vm.paramTitleError = null;
            vm.paramCodeError = null;
            if (vm.title == null) {
                vm.paramTitleError = "请填写活动名称";
            }
            if (vm.startCode == null) {
                vm.paramCodeError = "请填写开启口令";
            }
            if (vm.maxTeamcount == null) {
                vm.paramCountError = "请填写战场数";
            }
            if(vm.tripId != null &&  vm.addTeamCount < 0){
                vm.paramAddCountError = "请正确填写增加战场数";
                return
            }
            if (vm.title != null && vm.startCode != null && vm.maxTeamcount != null) {
                $http({
                    method: 'GET',
                    url: '/api/trip-game/isAfterTime',
                }).then(function (res) {
                    if(vm.isAfterDate != res.data){
                        alert("超过免费时间，请刷新网页重新发布");
                    }else{
                        if (vm.tripId != null && vm.addTeamCount == 0) {
                            save()
                        } else {
                            vm.paramTitleError = null;
                            vm.paramCodeError = null;
                            $('#myModal1').modal('hide');
                            if (vm.addTeamCount > 0) {
                                vm.maxTeamcount = vm.addTeamCount;
                            }
                            if(vm.isAfterDate){
                                vm.payCoupon = vm.maxTeamcount * 8;
                            }
                            getCoupons();
                            $('#myModal2').modal('show');
                        }
                    }
                },function (err) {
                })
            }
        }

        function getCoupons(){
            //独立设计师不请求钱
            if(vm.auth =='IGC'){
                return
            }
            vm.isSaving = false;
            getAvailableCouponsByEId.getCouponsByEId(vm.payCoupon).then(function (res) {
                vm.couponsData = res.data;
                console.log(res.data)
                if (res.data.availableCoupons != null){
                    vm.availableCoupons = res.data.availableCoupons;
                    vm.unavailableCoupons = res.data.unavailableCoupons;
                } else {
                    if (res.data.differenceCoupons != null){
                        var differenceCoupons = res.data.differenceCoupons;
                        toReCharge(differenceCoupons[0].availableCoupon);
                    } else {
                        toReCharge();
                    }

                }
            },function (err) {
                console.log(err);
            })
        }

        $().ready(function () {
            if (vm.couponsData!=null&&vm.couponsData!=""&&vm.availableCoupons.length>0){
                $('#'+vm.availableCoupons[0].id).prop("checked",true);
            }
        });

        //将选择的点券存到couponIds数组中
        vm.chooseCoupon = chooseCoupon;
        function chooseCoupon(id){
            var length = couponIds.length;
            var checked = $('#'+id)[0].checked;
            if (checked){
                couponIds[length] = id;
            }else {
                var index = couponIds.indexOf(id);
                if (index>-1){couponIds.splice(index,1)};
            }
            $('.errText').css('display','none');
        }


        vm.publishTrip = publishTrip;
        function publishTrip() {
            $http({
                method: 'GET',
                url: '/api/trip-game/isAfterTime',
            }).then(function (res) {
                if(vm.isAfterDate != res.data){
                    alert("超过免费时间，请刷新网页重新发布");
                }else{
                    vm.isSaving = true;
                    if (vm.availableCoupons.length <= 0) {
                        vm.isSaving = false;
                        toReCharge(vm.payCoupon);
                    } else {
                       PublishStealthTrip.publish(couponIds,vm.tripId,vm.startCode,vm.title,vm.maxTeamcount).then(function () {
                           $(".modal-backdrop").remove();
                           $state.go('stealth-trips', null, {reload: true});
                       }, function (err) {
                           $('#myModal2').modal('show');
                           vm.isSaving = false;
                           console.log(err);
                           if (err.data != null) {
                               if (err.data.errorCode == -2) {
                                   selectedCouponsIsNotnough();
                               }
                           }
                       });
                    }
                }
            },function (err) {
            })


        }

        function toReCharge(difference) {
            if (difference != null) {
                vm.toReCharge = true;
                vm.showPublishTitle = false;
                vm.differenceCoupon = difference;
            } else {
                vm.mContent = "发布活动所需点数不足，请先前往充值。";
            }

            vm.mText = null;
            $('.showOrHide').css('display','none');
            vm.confirmPrePublish = confirm;

        }

        vm.goToRecharge = goToRecharge;
        function goToRecharge() {
            $(".modal-backdrop").remove();
            $state.go('e-account-my-purchase',{},{reload:true});
        }

        function selectedCouponsIsNotnough() {
            vm.errText = "所选的点券不足。";
            $('.errText').css('display','block');
        }

        vm.editTrip = function (tripId) {
            vm.tripId = tripId;
            TripGame.get({id: tripId}, function (res) {
                if (res != null) {
                    var trip = res;
                    vm.tripId = trip.id;
                    vm.startCode = trip.startCode;
                    vm.title = trip.title;
                    vm.maxTeamcount = trip.maxTeamcount;

                    $('#myModal1').modal('show');
                }
            })
        }


            vm.stateChange = stateChange;

            function stateChange() {
                vm.page = 0;
                loadAll();
            }

            vm.versionChange = versionChange;

            function versionChange() {
                vm.page = 0;
                loadAll();
            }

            getAccount();

            function getAccount() {
                document.getElementById("con").className="well";
                $http({
                    url: 'api/account',
                    method: 'GET'
                }).then(function (account) {
                    vm.account = account.data;
                    if (vm.account !== null) {
                        $(".container").removeClass("login-container-content");
                        $("#nav, #foot").css("display", "block");

                        for (var i = 0; i < vm.account.authorities.length; i++) {
                            //判断账号是否为admin
                            if (vm.account.authorities[i] === "ROLE_ADMIN") {
                                vm.isAdmin = true;
                            }
                            if (vm.account.authorities[i] === "ROLE_RELEASE") {
                                vm.isRelease = true;
                            }
                        }
                        var author = vm.account.authorities;
                        if ($.inArray("ROLE_SUPER",author)!==-1){
                            vm.auth = "Super";
                        }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                            vm.auth = "Admin";
                        }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                            vm.auth = "IGC";
                        }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                            vm.auth = "Service";
                        }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                            vm.auth = "UGC";
                        }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                            vm.auth = "User";
                        }
                    }
                });
            }

            function loadAll() {
                var type = 0;
                switch (vm.state) {
                    case '未开始':
                        type = 1;
                        break;
                    case '进行中':
                        type = 2;
                        break;
                    case '已结束':
                        type = 3;
                        break;
                }

                StealthTripSearch.query({
                    query: pagingParams.search != null ? pagingParams.search : '',
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    status: type
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.trips = data;
                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }

            function loadPage(page) {
                vm.page = page;
                vm.transition();
            }

            function transition() {
                switch (vm.state) {
                    case '状态':
                        vm.state = 0;
                        break;
                    case '未开始':
                        vm.state = 1;
                        break;
                    case '进行中':
                        vm.state = 2;
                    case '已结束':
                        vm.state = 3;
                        break;
                }
                $state.transitionTo($state.$current, {
                    page: vm.page,
                    sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                    search: vm.currentSearch,
                    states: vm.state
                });
            }

            function search(searchQuery) {
                if (!searchQuery) {
                    return vm.clear();
                }
                vm.links = null;
                vm.page = 1;
                vm.reverse = false;
                vm.currentSearch = searchQuery;
                vm.transition();
            }

            function clear() {
                if(vm.back!=null&&vm.back!=""){
                    $(".modal-backdrop").remove();
                    $('#myModal2').modal('hide');
                    $('#myModal1').modal('hide');
                    $state.go("program-center-detail",{"id":vm.back},{reload:true});
                    return;
                }

                vm.links = null;
                vm.page = 1;
                vm.predicate = 'id';
                vm.state = '状态';
                vm.reverse = false;
                vm.currentSearch = null;
                vm.errText = null;
                vm.transition();
                $(".modal-backdrop").remove();
                $('#myModal2').modal('hide');
                $('#myModal1').modal('hide');
                vm.isSaving = false;
                vm.publishSuccess = false;
            }


            vm.keyDown = function (event) {
                if (event.keyCode == 13) {
                    vm.search(vm.searchQuery);
                }
            };

        vm.ident=ident;
        function ident() {
            $(".modal-backdrop").remove();
            if (vm.account.eAccountId!=null){
                if (vm.account.eAccountConfirmEmail !== true){
                    window.setTimeout(function () {
                        $state.go("e-account-my.edit",{},{reload:true});
                    },300);
                }else {
                    window.setTimeout(function () {
                        $state.go("e-account-my",{},{reload:true});
                    },300);
                }
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
    }

    // angular
    //     .module('taskServiceApp')
    //     .controller('StealthDialogController', StealthDialogController);
    //
    // StealthDialogController.$inject = ['$filter','$location','$scope', '$state', 'Trip','Principal', 'ParseLinks', 'AlertService',
    //     'paginationConstants','$http','StealthTripSearch','$sce','getAvailableCouponsByEId','TripGame','PublishStealthTrip','entity'];
    //
    // function StealthDialogController ($filter, $location,$scope, $state, Trip, Principal, ParseLinks, AlertService,
    //                                 paginationConstants, $http, StealthTripSearch,$sce,getAvailableCouponsByEId,TripGame,PublishStealthTrip,entity) {
    //     var vm = this;
    //     vm.trip = entity;
    //     vm.account = null;
    //     vm.isAdmin = false;
    //     vm.isSuper = false;
    //     vm.clear = clear;
    //     vm.paramTitleError = null;
    //     vm.paramCodeError = null;
    //     vm.nowTime = new Date().getTime();
    //     vm.maxTeamcount;
    //     vm.time = "2098-12-30T16:00:00.000Z";
    //     vm.differenceCoupon = null;
    //     var couponIds = [];
    //     vm.publishSuccess = false;
    //     vm.tripId = null;
    //     vm.addTeamCount = 0;
    //
    //     vm.toPublish = function () {
    //         if (vm.trip.title != null && vm.trip.startCode != null && vm.trip.maxTeamcount != null) {
    //             if (vm.tripId != null && vm.addTeamCount == 0) {
    //                 var url = '/api/stealth/trip?startCode=' + vm.trip.startCode + '&title=' + vm.trip.title + "&tripId=" + vm.trip.id + "&maxTeamCount=" + vm.addTeamCount;
    //                 $http({
    //                     method: 'POST',
    //                     url: url
    //                 }).success(function () {
    //                     $('#myModal1').modal('hide');
    //                     $('.modal-backdrop').remove();
    //                     $state.go('stealth-trips', null, {reload: true});
    //
    //                 }).error(function () {
    //                     vm.isSaving = false;
    //                 });
    //
    //             } else {
    //                 vm.isSaving = true;
    //                 vm.paramTitleError = null;
    //                 vm.paramCodeError = null;
    //                 $('#myModal1').modal('hide');
    //                 if (vm.addTeamCount > 0) {
    //                     vm.payCoupon = vm.addTeamCount * 8;
    //                     vm.trip.maxTeamcount = vm.addTeamCount;
    //                 } else {
    //                     vm.payCoupon = vm.maxTeamcount * 8;
    //                 }
    //                 getCoupons();
    //                 $('#myModal2').modal('show');
    //             }
    //         }
    //
    //     }
    //
    //     function getCoupons(){
    //         getAvailableCouponsByEId.getCouponsByEId(vm.payCoupon).then(function (res) {
    //             vm.couponsData = res.data;
    //             if (res.data.availableCoupons != null){
    //                 vm.availableCoupons = res.data.availableCoupons;
    //                 vm.unavailableCoupons = res.data.unavailableCoupons;
    //             } else {
    //                 if (res.data.differenceCoupons != null){
    //                     var differenceCoupons = res.data.differenceCoupons;
    //                     toReCharge(differenceCoupons[0].availableCoupon);
    //                 } else {
    //                     toReCharge();
    //                 }
    //
    //             }
    //         },function (err) {
    //             console.log(err);
    //         })
    //     }
    //
    //     $().ready(function () {
    //         if (vm.couponsData!=null&&vm.couponsData!=""&&vm.availableCoupons.length>0){
    //             $('#'+vm.availableCoupons[0].id).prop("checked",true);
    //         }
    //     });
    //
    //     //将选择的点券存到couponIds数组中
    //     vm.chooseCoupon = chooseCoupon;
    //     function chooseCoupon(id){
    //         var length = couponIds.length;
    //         var checked = $('#'+id)[0].checked;
    //         if (checked){
    //             couponIds[length] = id;
    //         }else {
    //             var index = couponIds.indexOf(id);
    //             if (index>-1){couponIds.splice(index,1)};
    //         }
    //         $('.errText').css('display','none');
    //     }
    //
    //
    //     vm.publishTrip = publishTrip;
    //     function publishTrip() {
    //         vm.publishSuccess = true;
    //         if (vm.availableCoupons.length <= 0) {
    //             toReCharge();
    //         } else {
    //             PublishStealthTrip.publish(couponIds,null,vm.trip.startCode,vm.trip.title,vm.trip.maxTeamcount).then(function () {
    //                 clear();
    //                 $state.go("stealth-trips",{},{reload:true});
    //             }, function (err) {
    //                 vm.publishSuccess = false;
    //                 console.log(err);
    //                 if (err.data != null) {
    //                     if (err.data.errorCode == -2) {
    //                         selectedCouponsIsNotnough();
    //                     }
    //                 }
    //             });
    //         }
    //     }
    //     function toReCharge(difference) {企业
    //         if (difference != null) {
    //             vm.toReCharge = true;
    //             vm.showPublishTitle = false;
    //             vm.differenceCoupon = difference;
    //         } else {
    //             vm.mContent = "发布活动所需点数不足，请先前往充值。";
    //         }
    //
    //         vm.mText = null;
    //         $('.showOrHide').css('display','none');
    //         vm.confirmPrePublish = confirm;
    //
    //     }
    //
    //     vm.goToRecharge = goToRecharge;
    //     function goToRecharge() {
    //         $(".modal-backdrop").remove();
    //         $state.go('e-account-my-purchase',{},{reload:true});
    //     }
    //
    //     function selectedCouponsIsNotnough() {
    //         vm.errText = "所选的点券不足。";
    //         $('.errText').css('display','block');
    //     }
    //
    //     vm.editTrip = function (tripId) {
    //         TripGame.get({id: tripId}, function (res) {
    //             if (res != null) {
    //                 var trip = res;
    //                 vm.trip.id = trip.id;
    //                 vm.trip.startCode = trip.startCode;
    //                 vm.trip.title = trip.title;
    //                 vm.trip.maxTeamcount = trip.maxTeamcount;
    //
    //                 $('#myModal1').modal('show');
    //             }
    //         })
    //     }
    //
    //
    //     getAccount();
    //
    //     function getAccount() {
    //         Principal.identity().then(function (account) {
    //             vm.account = account;
    //             if (vm.account !== null) {
    //                 for (var i = 0; i < vm.account.authorities.length; i++) {
    //                     //判断账号是否为admin
    //                     if (vm.account.authorities[i] === "ROLE_ADMIN") {
    //                         vm.isAdmin = true;
    //                     }
    //                     if (vm.account.authorities[i] === "ROLE_RELEASE") {
    //                         vm.isRelease = true;
    //                     }
    //                 }
    //                 var author = vm.account.authorities;
    //                 if ($.inArray("ROLE_SUPER",author)!==-1){
    //                     vm.auth = "Super";
    //                 }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "Admin";
    //                 }else if ($.inArray("ROLE_DESIGNER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "PGC";
    //                 }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "IGC";
    //                 }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "Service";
    //                 }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "UGC";
    //                 }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
    //                     vm.auth = "User";
    //                 }
    //             }
    //         });
    //     }
    //
    //
    //     function clear() {
    //         $(".modal-backdrop").remove();
    //         window.history.go(-1);
    //     }
    //
    //     vm.ident=ident;
    //     function ident() {
    //         $(".modal-backdrop").remove();
    //         if (vm.account.eAccountId!=null){
    //             if (vm.account.eAccountConfirmEmail !== true){
    //                 window.setTimeout(function () {
    //                     $state.go("e-account-my.edit",{},{reload:true});
    //                 },300);
    //             }else {
    //                 window.setTimeout(function () {
    //                     $state.go("e-account-my",{},{reload:true});
    //                 },300);
    //             }
    //         }else {
    //             window.setTimeout(function () {
    //                 $state.go("e-account-my.init",{},{reload:true});
    //             },300);
    //         }
    //     }
    // }


    angular
        .module('taskServiceApp')
        .controller('StealthTripQrCodeController', StealthTripQrCodeController);
    StealthTripQrCodeController.$inject = ['DateUtils','$timeout', '$http','$uibModalInstance', 'Principal', '$stateParams'];

    function StealthTripQrCodeController(DateUtils,$timeout,$http, $uibModalInstance, Principal, $stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        if ($stateParams.id != null && $stateParams.id != '') {
            vm.trip = [];
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
        }
        vm.isBeforeDate = false;
        if($stateParams.createDate!=null && $stateParams.createDate!=''){
            var createDate1 = DateUtils.convertDateTimeFromServer($stateParams.createDate);
            var endDate = new Date("2021/01/04 23:59:59");
            if(createDate1<endDate){
                vm.isBeforeDate = true
            }
        }

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
            });
        }


        showQrCodeNormal();
        function showQrCodeNormal() {
            // var qrCodeUrl = "https://api.jngysz.com/";
            var qrCodeUrl = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com/" : "https://testapi.itasktour.com/";
            qrCodeUrl = qrCodeUrl + 'api/common/ittr/stealth.php?tripId=';
            vm.qrCodeNormal = qrCodeUrl + vm.trip.id;
        }



        banRight();//当前页面禁用鼠标右键
        function banRight() {
            if (window.Event)
                document.captureEvents(Event.MOUSEUP);

            function nocontextmenu() {
                event.cancelBubble = true
                event.returnvalue = false;
                return false;
            }

            function norightclick(e) {
                if (window.Event) {
                    if (e.which == 2 || e.which == 3)
                        return false;
                }
                else if (event.button == 2 || event.button == 3) {
                    event.cancelBubble = true
                    event.returnvalue = false;
                    return false;
                }
            }

            document.oncontextmenu = nocontextmenu;  // for IE5+
            document.onmousedown = norightclick;  //
        }


        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        }

        vm.downLoadQr = downLoadQr;

        function downLoadQr(codeType) {

            if (codeType == 'normal') {
                html2canvas($('.qrNormal'), {
                    onrendered: function (canvas) {
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type), 'image/octet-stream');
                        if (isIE()) {
                            $('#myIEModal').modal('show');
                        } else {
                            $('.downNor').attr({
                                'href': imgData,
                                'download': '活动' + vm.trip.id + '正式二维码.png'
                            });
                        }
                    }
                });
            } else {
                html2canvas($('.qrTest'), {
                    onrendered: function (canvas) {
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type), 'image/octet-stream');
                        if (isIE()) {
                            // console.log(isIE());
                            $('#myIEModal').modal('show');
                        } else {
                            $('.downTes').attr({
                                'href': imgData,
                                'download': '活动' + vm.trip.id + '测试二维码.png'
                            });
                        }
                    }
                });
            }

            var _fixType = function (type) {
                type = type.toLowerCase().replace(/jpg/i, 'jpeg');
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return 'image/' + r;
            };

        }

        vm.iknow = iknow;

        function iknow() {
            $('#myIEModal').modal('hide');
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {

        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }




})();

