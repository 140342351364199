(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ClientVersionService', ClientVersionService);

    ClientVersionService.$inject = ['$resource'];

    function ClientVersionService ($resource) {
        var service = $resource('api/client-versions', {}, {
            'findAll': { method: 'GET', isArray: true},
            'update': { method: 'PUT'}
        });

        return service;
    }
})();
