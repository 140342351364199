(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripRankController', TripRankController);

    TripRankController.$inject = ['$location','$scope', '$rootScope','$state', '$stateParams','$http', 'previousState', 'entity', 'Trip', 'Principal'];

    function TripRankController($location,$scope, $rootScope,$state, $stateParams,$http, previousState, entity, Trip, Principal) {
        var vm = this;
        vm.tripRanks = entity;
        vm.type = Trip.type;
        vm.tripId = $stateParams.id;
        vm.previousState = previousState.name;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        // console.log(vm.tripRanks);
        Trip.get({id:$stateParams.id},function (res) {
            // console.log(res);
            if (res!=null){
                vm.trip = res;
                var end = new Date(vm.trip.endTime);
                var now = new Date;
                if (end>now){
                    vm.onGoing = true;
                }else {
                    vm.onGoing = false;
                }
            }
        },function (err) {
            console.log(err);
        });

        if (vm.tripRanks.code==0){
            vm.finishTeamDtos = vm.tripRanks.data.finishTeamDtos;
            vm.onGoingTeamDtos = vm.tripRanks.data.onGoingTeamDtos;
            vm.overTimeTeamDtos = vm.tripRanks.data.overTimeTeamDtos;
            if (vm.finishTeamDtos.length>0){
                forList(vm.finishTeamDtos);
            }
            if (vm.onGoingTeamDtos.length>0){
                forList(vm.onGoingTeamDtos);
            }
            if (vm.overTimeTeamDtos.length>0){
                forList(vm.overTimeTeamDtos);
            }
        }
        // console.log(vm.finishTeamDtos);
        function forList(list) {
            for (var i=0;i<list.length;i++){
                var time = formatSeconds(list[i].spendTime);
                list[i].spendTime = time;
                // console.log(time);
            }
        }

        vm.getAllRanks = getAllRanks;
        function getAllRanks() {
            $http({
                method: 'GET',
                url: '/api/trips/' + vm.trip.id + '/rank_new?reloadTeam=true'
            }).then(function (res) {
                if (res != null){
                    vm.tripRanks = res.data;
                    $state.go('trip-rank', {id:vm.trip.id}, {reload:true});
                }
            },function (err) {
                console.log(err);
            });
        }

    }
    /**
     * 将秒数换成时分秒格式
     * 整理：www.jbxue.com
     */

    function formatSeconds(value) {
        var theTime = value;// 秒
        var theTime1 = 0;// 分
        var theTime2 = 0;// 小时
        if(theTime > 60) {
            theTime1 = parseInt(theTime/60);
            theTime = parseInt(theTime%60);
            if(theTime1 > 60) {
                theTime2 = parseInt(theTime1/60);
                theTime1 = parseInt(theTime1%60);
            }
        }
        var result = ""+parseInt(theTime)+"秒";
        if(theTime1 > 0) {
            result = ""+parseInt(theTime1)+"分"+result;
        }
        if(theTime2 > 0) {
            result = ""+parseInt(theTime2)+"小时"+result;
        }
        return result;
    }

})();
