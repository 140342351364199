(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE

    angular
        .module('taskServiceApp')
        .constant('VERSION', "3.7.2")//后台版本号
        .constant('DEBUG_INFO_ENABLED', true)//是否处于DEBUG模式
        .constant('PAYACP',false)//银联支付是否打开true打开，false关闭

})();
