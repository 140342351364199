(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('LocationTask', LocationTask);

    LocationTask.$inject = ['$resource', 'DateUtils'];

    function LocationTask ($resource, DateUtils) {
        var resourceUrl =  'api/location-tasks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('LocationTaskExt', LocationTaskExt);

    LocationTaskExt.$inject = ['$resource', 'DateUtils'];

    function LocationTaskExt ($resource,DateUtils) {
        var resourceUrl = 'api/base-tasks/locationTask/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            }
        });
    }
})();
