(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MyEAccountDetailController', controller);

    controller.$inject = ['MyEAccountFreezeRecords','$sce','Upload','$scope', '$cookies', '$rootScope', 'Principal', '$state','pagingParams','paginationConstants','ParseLinks','AlertService', 'previousState', 'entity', 'EAccount', 'User','UserSearch','MyEAccount','MyEAccountRecords','$http'];

    function controller(MyEAccountFreezeRecords,$sce,Upload,$scope, $cookies, $rootScope, Principal, $state, pagingParams,paginationConstants,ParseLinks,AlertService, previousState, entity, EAccount, User,UserSearch,MyEAccount,MyEAccountRecords,$http) {
        var vm = this;
        vm.phone = null;
        vm.showFreeze = false; //显示冻结记录
        vm.eAccount = entity;
        // vm.rabbitLeader=null;
        vm.eAccountId = null;
        vm.account = null;
        vm.previousState = previousState.name;
        vm.addDesiger = this.addDesiger;
        vm.deleteDesign = this.deleteDesign;
        vm.itemsPerPage = 250;
        vm.back = back;
        vm.toTrip = toTrip;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadPage = loadPage;
        vm.clear = clear;
        vm.transition = transition;

        vm.IMAGE_SERVER = IMAGE_SERVER;

        vm.allRecord = allRecord;
        function allRecord() {
            vm.showFreeze = false;
            $("#allRecord").css("color","#f44158").css("border-bottom", "solid 4px #f44158");
            $("#freezeRecord").css("color","#1b2b3c").css("border-bottom", "solid 0px #f44158");
            vm.page = 1;
            loadRecrds();
            $("#freezeTable").hide();
            $("#allTable").show();
        }
        vm.freezeRecord = freezeRecord;
        function freezeRecord() {
            vm.showFreeze = true;
            $("#freezeRecord").css("color","#f44158").css("border-bottom", "solid 4px #f44158");
            $("#allRecord").css("color","#1b2b3c").css("border-bottom", "solid 0px #f44158");
            vm.page = 1;
            loadFreezeRecrds();
            $("#allTable").hide();
            $("#freezeTable").show();
        }

        // getLeader();
        // function getLeader() {
        //     $http({
        //         url: 'api/leader/getByRabbit?rabbitId='+vm.eAccount.id+"&type=0",
        //         method: 'GET'
        //     }).then(function (res) {
        //         console.log("兔老大："+JSON.stringify(res));
        //         vm.rabbitLeader = res.data.contactName;
        //     })
        // }

        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account !== null) {
                    var type = -1;
                    if ($(window).width() < 700 && !vm.account.mobileTipShowFlag) {
                        $('#myModal3').modal('show');
                        type = 1;
                    } else if (!vm.account.pcTipShowFlag) {
                        $('#myModal2').modal('show');
                        type = 0;
                    }
                    if (type != -1) {
                        $http({
                            url: 'api/tip/change-status/' + type,
                            method: 'POST'
                        }).then(function (res) {

                        });
                    }
                    if (JSON.stringify(vm.eAccount) == "{}") {
                        vm.eAccountId = $cookies.get("eid");
                        var uid = $cookies.get("uid");
                        if (typeof(vm.eAccountId) !== 'undefined' && typeof(uid) !== 'undefined') {
                            if (vm.account !== null) {
                                MyEAccount.addDegigner({
                                    eid: vm.eAccountId,
                                    login: vm.account.login,
                                    isTrainee: 0
                                }, onSaveSuccessJoin, onSaveErrorJoin);
                            }
                        }
                    } else {
                        vm.eAccountId = $cookies.get("eid");
                        var uid = $cookies.get("uid");
                        if (typeof(vm.eAccountId) !== 'undefined' && typeof(uid) !== 'undefined') {
                            if (vm.eAccount.id == vm.eAccountId) {
                                alert("您已加入该企业，请勿重复操作。");
                            } else {
                                alert("加入失败！您已有所属企业。");
                            }

                            $cookies.remove("eid");
                            $cookies.remove("uid");
                        }
                        $("#freezeTable").hide();
                        loadRecrds();
                    }
                }
            });
        }


        function onSaveSuccessJoin() {
            vm.eAccount = EAccount.get({id: vm.eAccountId});
            loadRecrds();
            $cookies.remove("eid");
            $cookies.remove("uid");
            $state.go("e-account-my",{},{reload:true});
        }

        function onSaveErrorJoin() {
            loadRecrds;
        }

        vm.filterUser = this.filterUser;
        this.filterUser = function(search) {
            if(search){
                vm.users = UserSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };
        load();
        function load() {
            // if (vm.eAccount.confirmEmail===false){
            //     $('#myModal1').modal('show');
            // }
            addLayout();
        }
        function back() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("e-account-my.edit");
            },300);
        }
        function toTrip() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("trip");
            },300);
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            // document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }

        vm.onFileSelects = onFileSelects;
        function onFileSelects (uploadFile){
            if (uploadFile!=null){
                uploadHelp(uploadFile);
            }
            function uploadHelp(uploadFile) {
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"EACCOUNT"},
                    file: uploadFile,
                    method: 'POST'
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    // console.log(data);
                    vm.eAccount.web = data.file;
                    editLogo();
                    load();
                }).error(function (data, status, headers, config) {
                    console.log('error status: ' + status);
                });
            }
        }

        vm.editLogo = editLogo;
        function editLogo() {
            if (vm.eAccount.web != null){
                $http({
                    method: 'POST',
                    url: '/api/e-accounts/edit-logo/' + vm.eAccount.id + "?logo=" + vm.eAccount.web
                }).then(function (res) {

                },function (err) {
                    console.log(err);
                });
            }
        }

        vm.loadRecrds = loadRecrds;
        function loadRecrds(page) {
            if (page == undefined) {
                pagingParams.page = 1;
                page = pagingParams.page - 1;

            } else {
                pagingParams.page = page + 1;
            }
            MyEAccountRecords.query({
                page: page,
                size: vm.itemsPerPage,
                sort : sort()
            },onSuccess,onError);

        }

        vm.loadFreezeRecrds = loadFreezeRecrds;
        function loadFreezeRecrds(page) {
            if (page == undefined) {
                pagingParams.page = 1;
                page = pagingParams.page - 1;
            } else {
                pagingParams.page = page + 1;
            }
            MyEAccountFreezeRecords.query({
                page: page,
                size: vm.itemsPerPage,
                sort : sort()
            },onSuccess,onError);

        }

        this.addDesiger = function () {
            MyEAccount.addDegigner({eid:vm.eAccount.id,login:vm.phone},onSaveSuccess,onSaveError);
        };

        this.deleteDesign = function(hasUser){
            var index = vm.eAccount.hasUsers.indexOf(hasUser);
            var userId = vm.eAccount.hasUsers[index].id;

            MyEAccount.deleteDegigner({eid:vm.eAccount.id,uid:userId},onSaveSuccess,onSaveError);

        };

        function onSaveSuccess (result) {
            vm.phone = null;
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
        }

        function onSaveError () {
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.eAccountRecords = data;
            // console.log(vm.eAccountRecords);
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
        }
        function loadPage(page) {
            console.log("page:" + vm.page);
            vm.page = page;
            vm.transition();
        }
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            }, { reload: false, inherit: true, notify: true});
        }
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.firstRabbit = false;//第一批兔子（4.3号以前）
        vm.twoRabbit = false;//第二批兔子（4.3号-5.20号）
        vm.newRabbit = false;//第三批兔子（5.20号以后）
        // console.log(typeof(vm.eAccount.createDate));
        if (typeof(vm.eAccount.createDate) !== 'undefined'&&vm.eAccount.createDate!=null){
            var authenticationTime = vm.eAccount.createDate.getTime();
            // console.log(authenticationTime);
            var now = new Date;
            if (authenticationTime<1522684800000){
                vm.firstRabbit = true;//认证时间在4.3号之前
            }else if (authenticationTime>=1522684800000&&authenticationTime<=1526745600000&&now.getTime()<1527004800000){
                vm.twoRabbit = true;//认证时间在4.3号到5.20号之间
                if (vm.eAccount.id != null && vm.eAccount.status == 'ACTIVATED'){
                    if (now.getTime()<1527264000000&&vm.eAccount.admin){
                        // $('#myModal2').modal('show');//4.3到5.20号之间认证用户仅在5.25号前弹框提示
                    }
                }
            }else {
                vm.newRabbit = true;//认证时间在5.20号之后
                if (vm.eAccount.id != null&&vm.eAccount.status == 'ACTIVATED'&&vm.eAccount.admin){
                    // $('#myModal2').modal('show');
                }
            }
        }

        vm.toPurchase = toPurchase;
        function toPurchase() {
            $('#myModal2').modal('hide');
            window.setTimeout(function () {
                $state.go("e-account-my-purchase");
            },300);
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
