(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripExportPhotosController', TripExportPhotosController);

    TripExportPhotosController.$inject = ['$scope', '$rootScope', '$stateParams', '$location', 'previousState', 'entity', '$http', 'TripExt', 'LocationTask', 'TripType', 'User', 'Team'];

    function TripExportPhotosController($scope, $rootScope, $stateParams, $location, previousState, entity, $http, TripExt, LocationTask, TripType, User, Team) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripPhotos = entity;
        vm.tripId = $stateParams.id;
        vm.previousState = previousState.name;
        vm.absUrl =$location.$$absUrl;
        vm.chosenPhotos = {};
        vm.tripEnd = false;

        // console.log(vm.tripPhotos);
        if (vm.tripPhotos!=null && typeof vm.tripPhotos!='undefined'){
            vm.tripHasPhotos = vm.tripPhotos.photo_list;
            vm.tripHasVideos = vm.tripPhotos.video_list;
        }

        // if (vm.tripPhotos != null && vm.tripPhotos.length > 0){
        //     for( var i = 0; i < vm.tripPhotos.length; i++){
        //         var p = vm.tripPhotos[i];
        //         vm.chosenPhotos[p[0]] = false;
        //     }
        // }
        // vm.downloadUrl = vm.absUrl + "/api/trips/" + vm.tripId + "/export-photos/zip?chosen=";

        checkTripIsEnd();
        function checkTripIsEnd() {
            $http({
                url: 'api/trip/check-end/' + vm.tripId,
                method: 'GET'
            }).then(function(res) {
                console.log(res);
                vm.tripEnd = res;
            });
        }

        vm.reloadData = reloadData;
        function reloadData() {
            $http({
                url: 'api/trip/photos/reload/' + vm.tripId,
                method: 'GET'
            }).then(function() {
                window.location.reload();
            });
        }
    }
})();
