(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('GuideController', GuideController);

    GuideController.$inject = ['$scope', '$state','$http','$stateParams','pagingParams', 'paginationConstants', 'Principal','$sce'];

    function GuideController ($scope, $state,$http, $stateParams,pagingParams, paginationConstants, Principal, $sce) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.getAccount = getAccount;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.logout = logout;
        vm.becomeRabbit = becomeRabbit;

        // vm.auth = $stateParams.auth;
        $state.go('program-center');

        function logout() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                // $state.go('login');
                //返回后台登录页
                window.location.href = window.location.protocol+"//"+window.location.host+'/#/login';
            },300);
        }
        this.$onInit = function() {
            getAccount();
            //页面加载版本更新日志
            getVersion();

        };
        //
        function becomeRabbit() {
            window.location.href = window.location.protocol+"//"+window.location.host+'/#/perfect-information';
        }
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                // console.log(res);
                vm.account = res.data;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
                //弹出企业账户权限认证modal
                // if (vm.account.eAccountActive==false&&vm.account.eAccountConfirmEmail==false){
                //     if (vm.auth=="UGC"||vm.auth=="TGC"||(vm.auth=="IGC" && vm.account.eAccountId!=null)){
                //         if (vm.account.eAccountId==null){
                //             $('#myModal1').modal('show');
                //         }else {
                //             $('#myModal3').modal('show');
                //         }
                //     }
                // }else {
                //     if (vm.auth=="UGC"||vm.auth=="TGC"||(vm.auth=="IGC" && vm.account.eAccountId!=null)){
                //         if (vm.account.eAccountActive === false && vm.account.eAccountConfirmEmail === true) {
                //             //若是认证被拒绝，则弹出框提示拒绝原因
                //             if (vm.account.notPassReason != null && vm.account.notPassReason != "") {
                //                 $('#myModal2').modal('show');
                //                 vm.content = "您的认证申请审核未通过，未通过原因：" + vm.account.notPassReason;
                //                 vm.toGo = "重新认证";
                //             } else {
                //                 $('#myModal2').modal('show');
                //                 vm.content = "您已完成认证申请，点击前往查看审核状态";
                //                 vm.toGo = "前往";
                //             }
                //         }
                //     }
                // }
            },function (err) {
                console.log(err);
            })
        }

        //计算认证天数
        countTime();
        function countTime() {
            var date = new Date();
            // console.log(date+"的时间戳："+date.getTime());
            var san = new Date("Thu Mar 20 2018 00:00:00 GMT+0800 (中国标准时间)");
            // console.log(san+"的时间戳："+san.getTime());
            var leftTime = san.getTime()-date.getTime();
            //定义变量 d,h,m,s保存倒计时的时间
            var d,h,m,s;
            if (leftTime>0) {
                d = Math.floor(leftTime/1000/60/60/24);
                // h = Math.floor(leftTime/1000/60/60%24);
                // m = Math.floor(leftTime/1000/60%60);
                // s = Math.floor(leftTime/1000%60);
                //递归每秒调用countTime方法，显示动态时间效果
                // setTimeout(countTime,1000);
                vm.days = d;
            }else {
                vm.days = 0;
                $("#cancel").attr("disabled", true);
                $('.button-myself-cancel').css('background','#dcdcdc');
            }
        }
        vm.ident = ident;
        function ident() {
            $("#myModal1").modal('hide');
            $("#myModal3").modal('hide');
            $(".modal-backdrop").remove();
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
        vm.toPassWord = toPassWord;
        function toPassWord() {
            $("#myModal2").modal('hide');
            $(".modal-backdrop").remove();
            if (vm.account.notPassReason!=null&&vm.account.notPassReason!=""){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my",{},{reload:true});
                },300);
            }
        }
        // 更新日志 弹窗 显示
        vm.toLogin = toLogin;
        function toLogin() {
            $('#myUpdateLogModal').modal('hide');
            // $(".modal-backdrop").remove();
            // window.setTimeout(function () {
            //     $state.go('guide');
            // },300);
            // cancel();
        }
        //获取 版本更新日志信息
        // vm.getVersion = getVersion;
        function getVersion() {
            $http({
                method: 'GET',
                url: '/api/version-descs'
            }).then(function (res) {
                // console.log(res);
                var num = res.data.length;
                if (JSON.parse(window.localStorage.getItem("updateLogs"))) {
                    var version_localStorage =  JSON.parse(window.localStorage.getItem("updateLogs"))["name"];
                }else {
                    // $('#myUpdateLogModal').modal('show');
                    var num = res.data.length;
                    if (num>0){
                        vm.versionDesc = res.data[num-1];
                        vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                    }else {
                        vm.versionDesc = res.data[num];
                        vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                    }
                    //保存浏览器缓存中;
                    window.localStorage.setItem("updateLogs",JSON.stringify(res.data[num-1]));
                }
                if (num >= 1) {
                    var version_lastestLog = res.data[num - 1]["name"];
                    if (res.data.length > 0) {
                        if (version_lastestLog !== version_localStorage) {
                            $('#myUpdateLogModal').modal('show');
                            var num = res.data.length;
                            if (num > 0) {
                                vm.versionDesc = res.data[num - 1];
                                vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                            } else {
                                vm.versionDesc = res.data[num];
                                vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                            }
                            //保存浏览器缓存中;
                            window.localStorage.setItem("updateLogs", JSON.stringify(res.data[num - 1]));
                        } else {
                            $('#myUpdateLogModal').modal('hide');
                        }

                        // console.log(JSON.parse(window.localStorage.getItem("updateLogs")));
                    } else {
                        // $state.go('guide');
                    }
                }
                // console.log(vm.versionDesc);
            },function (err) {
                console.log(err);
            })
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
