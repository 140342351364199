(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RecordsDetailController', RecordsDetailController);

    RecordsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Records', 'EAccount'];

    function RecordsDetailController($scope, $rootScope, $stateParams, previousState, entity, Records, EAccount) {
        var vm = this;

        vm.records = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:recordsUpdate', function(event, result) {
            vm.records = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
