(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('AdDialogController', AdDialogController);

    AdDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Ad', 'Upload', 'Ahdin'];

    function AdDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Ad, Upload, Ahdin) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.ad = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.onFileSelect = onFileSelect;
        vm.showCropped = true;
        vm.cropper = 'default';

        if (vm.ad.link == null){
            vm.ad.link = 'itasktour://gototrip?tripid=';
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            console.log(vm.ad)
            vm.isSaving = true;
            if(vm.ad.type == 2){
                if (vm.ad.img !== null){
                    if (/\.(jpg|jpeg|png|bmp)$/.test(vm.ad.img)){
                        var image = document.getElementById("file");
                        if (image != null){
                            var height = image.height;
                            var width = image.width;
                            var bi = width / height;
                            if (bi<1.8 || bi>2.2) {
                                // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                                alert("上传图片比例应为2:1");
                                if (vm.ad.img != null) {
                                    vm.ad.img = null;
                                }
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                } else {
                    alert("上传图片不能为空");
                    vm.isSaving = false;
                    return;
                }

                if(vm.ad.startTime!= null && vm.ad.endTime != null){
                    if( vm.ad.endTime.getTime() < vm.ad.startTime.getTime()){
                        console.log('时间错误')
                        vm.timeWarm = true
                        return
                    }
                }
            }

            if (vm.ad.id !== null) {
                Ad.update(vm.ad, onSaveSuccess, onSaveError);
            } else {
                Ad.save(vm.ad, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:adUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect (uploadFile,type){

            var uploadImageFile = function(compressedBlob) {
                Upload.upload({

                    // url: '/api/upload',
                    // fields: {},
                    // file: compressedBlob,
                    // method: 'POST'
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"ADS"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {
                    console.log(data.file);
                    //update the url
                    if(type=='coverImg'){
                        vm.ad.img = data.file;
                    }
                     if(type=='phone'){
                        vm.ad.phoneImg = data.file;
                    }



                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);
                });
            };

            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
            //TODO gif no compress
            // if (uploadFile != null) {
            //     Ahdin.compress({
            //         sourceFile: uploadFile,
            //         maxWidth: 1280,
            //         maxHeight:1000,
            //         quality: 0.8
            //     }).then(function(compressedBlob) {
            //         console.log('compressed image by ahdin.');
            //         uploadImageFile(compressedBlob);
            //     });
            // }


        };

        vm.changeImg = changeImg;
        function changeImg(){
            vm.ad.img = null;
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.ad.img != null) {
                vm.ad.img = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

    }
})();
