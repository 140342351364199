(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('BadgesSearch', BadgesSearch);

    BadgesSearch.$inject = ['$resource'];

    function BadgesSearch($resource) {
        var resourceUrl =  'api/_search/badges/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
