(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcInOutDialogController', NpcInOutDialogController);

    NpcInOutDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'NpcInOut', 'NpcInOutEquip', 'Npc'];

    function NpcInOutDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, NpcInOut, NpcInOutEquip, Npc) {
        var vm = this;

        vm.npcInOut = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.npcinoutequips = NpcInOutEquip.query();
        vm.npcs = Npc.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.npcInOut.id !== null) {
                NpcInOut.update(vm.npcInOut, onSaveSuccess, onSaveError);
            } else {
                NpcInOut.save(vm.npcInOut, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:npcInOutUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
