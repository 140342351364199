(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripModel', TripModel);

    TripModel.$inject = ['$resource', 'DateUtils'];

    function TripModel ($resource, DateUtils) {
        var resourceUrl =  'api/trip-models/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('UserTripModel', UserTripModel);

    UserTripModel.$inject = ['$resource'];

    function UserTripModel ($resource) {
        var resourceUrl =  'api/user/trip-models/release';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelPublish', tripModelPublish);

    tripModelPublish.$inject = ['$http'];

    function tripModelPublish ($http) {
        return{
            tmPublish:function(param){
                return $http({
                    method: 'PUT',
                    url: '/api/trip-model/updateStatus/'+param.tripId+'/'+param.status
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelEdit', tripModelEdit);

    tripModelEdit.$inject = ['$http'];

    function tripModelEdit ($http) {
        return{
            tmEdit:function(param){
                return $http({
                    method: 'PUT',
                    url: '/api/trip-model/updateStatus/confirm/'+param.tripId+'/'+param.status
                });
            }
        };
    }
})();

//独立设计师申请发布活动至官网
//审核管理员审核线路
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelToWeb', tripModelToWeb);

    tripModelToWeb.$inject = ['$http'];

    function tripModelToWeb ($http) {
        return{
            toWeb:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip-model/toWeb/'+param.id+'/'+param.type
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelConfirmToWeb', tripModelConfirmToWeb);

    tripModelConfirmToWeb.$inject = ['$http'];

    function tripModelConfirmToWeb ($http) {
        return{
            toWeb:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip-model/toWeb/'+param.id+'/'+param.type+"?confirmToWeb=1",
                    data: param.tripModel
                });
            }
        };
    }
})();
//审核管理员查看待审核线路列表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelCheckList', tripModelCheckList);

    tripModelCheckList.$inject = ['$resource', 'DateUtils'];

    function tripModelCheckList ($resource, DateUtils) {
        var resourceUrl =  'api/trip-model/check/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripModelCheck', tripModelCheck);

    tripModelCheck.$inject = ['$http'];

    function tripModelCheck ($http) {
        return{
            tmCheck:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip-model/toWeb/'+param.id+'/'+param.type+'/'+param.reason
                });
            }
        };
    }
})();

//C端线路审核
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripModelToCheck', TripModelToCheck);

    TripModelToCheck.$inject = ['$http'];

    function TripModelToCheck ($http) {
        return{
            toCheck:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip-model/to-check/'+param.id+'/'+param.type+'?reason='+param.reason,
                });
            }
        };
    }
})();

//C端线路审核列表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripModelToCheckList', TripModelToCheckList);

    TripModelToCheckList.$inject = ['$resource', 'DateUtils'];

    function TripModelToCheckList ($resource, DateUtils) {
        var resourceUrl =  'api/trip-model/to-check/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

//C端线路审核列表-搜索
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('SearchTripModelToCheckList', SearchTripModelToCheckList);

    SearchTripModelToCheckList.$inject = ['$resource', 'DateUtils'];

    function SearchTripModelToCheckList ($resource, DateUtils) {
        var resourceUrl =  'api/_search/trip-model/to-check/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
