(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ProgramTripModelController', ProgramTripModelController);

    ProgramTripModelController.$inject = ['$state', 'ProgramTripModelPlanFlagSearch', 'Principal', '$http', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ProgramTripModelController($state, ProgramTripModelPlanFlagSearch, Principal, $http, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        function loadAll () {
            ProgramTripModelPlanFlagSearch.query({
                query: pagingParams.search,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                release: "0"
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tripModels = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            console.log("页数："+vm.page);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.keyDown = function(event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };

        vm.clickEditImg = clickEditImg;
        function clickEditImg(id) {
            var url = vm.account.acfeUrl + id + '?at=' + vm.account.password + '_coach'
            window.open(url);
        }

        vm.getData = function (time) {
            var newTime = time.replace('[Asia/Shanghai]','');
            var data = new Date(newTime)
            return data.toJSON()
        }
    }

})();
