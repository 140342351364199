(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('BaseTask', BaseTask);

    BaseTask.$inject = ['$resource', 'DateUtils'];

    function BaseTask ($resource, DateUtils) {
        var resourceUrl =  'api/base-tasks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('IsPreTask', IsPreTask);

    IsPreTask.$inject = ['$resource'];

    function IsPreTask ($resource) {
        var resourceUrl =  'api/check-has-pretask/:locationTaskId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        console.log(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
