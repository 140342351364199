(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripCopyController',TripCopyController);

    TripCopyController.$inject = ['$uibModalInstance', 'entity', 'Trip','$http'];

    function TripCopyController($uibModalInstance, entity, Trip , $http) {
        var vm = this;

        vm.trip = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy (id) {
            console.log("copy trip")
            vm.clear();
            $http({
                method: 'POST',
                url: '/api/trips/copy_new/'+id,
            });

        }
    }
})();
