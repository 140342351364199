(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcInOutDeleteController',NpcInOutDeleteController);

    NpcInOutDeleteController.$inject = ['$uibModalInstance', 'entity', 'NpcInOut'];

    function NpcInOutDeleteController($uibModalInstance, entity, NpcInOut) {
        var vm = this;

        vm.npcInOut = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            NpcInOut.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
