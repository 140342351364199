(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('bomb-trips', {
            parent: 'entity',
            url: '/bomb/trips?page&sort&search&states&version',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/bomb/bomb-trips.html',
                    controller: 'BombTripController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,
                states: '0',
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        states: $stateParams.states,
                        version: $stateParams.version
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('bomb-trip-qrcode', {
            parent: 'entity',
            url: '/bomb/trip-qrcode/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                id: '',
                title:'',
                createDate:''
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/bomb/bomb-trip-qr-code.html',
                    controller: 'BombTripQrCodeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                tripId: null
                            };
                        }
                    }
                }).result.then(function() {
                    window.history.go(-1)
                }, function() {
                    window.history.go(-1)
                });
            }]
        })
        // .state('bomb-trip-edit', {
        //     parent: 'entity',
        //     url: '/bomb/trip-edit/{id}',
        //     data: {
        //         authorities: ['ROLE_USER']
        //     },
        //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'app/entities/bomb/bomb-trip-dialog.html',
        //             controller: 'BombDialogController',
        //             controllerAs: 'vm',
        //             backdrop: 'static',
        //             size: 'md',
        //             resolve: {
        //                 entity: ['$stateParams', 'TripGame', function($stateParams, TripGame) {
        //                     return TripGame.get({id : $stateParams.id}).$promise;
        //                 }],
        //             }
        //         }).result.then(function() {
        //             window.history.go(-1)
        //         }, function() {
        //             window.history.go(-1)
        //         });
        //     }]
        // })
        // .state('bomb-trip-new', {
        //     parent: 'entity',
        //     url: '/bomb/trip-new',
        //     data: {
        //         authorities: ['ROLE_USER']
        //     },
        //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'app/entities/bomb/bomb-trip-dialog.html',
        //             controller: 'BombDialogController',
        //             controllerAs: 'vm',
        //             backdrop: 'static',
        //             size: 'md',
        //             resolve: {
        //                 entity: function () {
        //                     return {
        //                         title: null,
        //                         id: null,
        //                         startCode:null,
        //                         maxTeamcount:null
        //                     };
        //                 }
        //             }
        //         }).result.then(function() {
        //             window.location.reload();
        //             return false;
        //         }, function() {
        //             window.history.go(-1);
        //         });
        //     }]
        // })
        .state('bomb-trip-rank', {
            parent: 'entity',
            url: '/bomb/rank/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.rank.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/bomb/bomb-trip-rank.html',
                    controller: 'BombTripRankController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })



        .state('bomb-trip-detail', {
            parent: 'entity',
            url: '/bomb/trip-detail/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/bomb/bomb-trip-detail.html',
                    controller: 'BombTripDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TripGame', function($stateParams, TripGame) {
                    return TripGame.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

    }

})();
