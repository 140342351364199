(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('UserTripModelSearch', UserTripModelSearch);

    UserTripModelSearch.$inject = ['$resource'];

    function UserTripModelSearch($resource) {
        var resourceUrl =  'api/_search/user/trip-models/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
