(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ProgramCenterDetail1Controller', ProgramCenterDetail1Controller)
        .filter('pdfFilter', function() {
            return function(text) {
                return text+".pdf";
            }
        })
        .filter('labelFilter', function() {
            return function(text) {
                var arr = JSON.parse(text);
                return arr;
            }
        })


        ProgramCenterDetail1Controller.$inject = ['$state',  'Principal', '$http', 'ParseLinks', 'AlertService','$stateParams' ,"$sce"];

    function ProgramCenterDetail1Controller($state,  Principal, $http, ParseLinks, AlertService,$stateParams,$sce) {
        var id=$stateParams.id;
        if(window.location.href.indexOf("v3")!= -1){
            wechatConfig();
        }
        function wechatConfig() {
            $http({
                method: 'POST',
                url: '/api/program/share',
                params: {
                    id:id,
                    url:window.location.href
                }
            }).then(function (res) {
                console.log(res.data)
                wechatStart(res.data)
            },function (err) {
                console.log(err);
            })
        }

       function wechatStart(obj) {
            var linkURL = window.location.href
            wx.config({
                debug: false, // true时，手机微信环境访问会alert一些errmsg信息，如果显示errmsg:ok表示正常，否认表示出了问题
                appId: obj.appId, // 必填，公众号的唯一标识
                timestamp: obj.timestamp, // 后台返回的数据中取
                nonceStr: obj.nonceStr, // 后台返回的数据中取
                signature: obj.signature, // 后台返回的数据中取
                jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"] // 必填，需要使用的JS接口列表
            })
            // 需在用户可能点击分享按钮前就先调用，说白了就是放在文章详情信息读取完后就可以执行下面的wx.ready()方法
            wx.ready(function() {
                // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                wx.updateAppMessageShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function() {
                        // 设置成功
                    }
                })
                // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                wx.updateTimelineShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function() {
                        // 设置成功
                    }
                })
            })
        }


        document.documentElement.scrollTop = 0;
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.programDetail='';
        vm.changeTab = 1;
        vm.lxfs = 2;

        getDetailById(id);
        doChange(1);
        vm.doChange =doChange;
        vm.lxfsFun=lxfsFun;
        vm.pushActivity=pushActivity;
        vm.isNotLogin = true;

        getAccount();
        function getAccount() {
            doSaveClickNum(2);
            md("方案-打开分享页-"+id);
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account != null){
                    $state.go('program-center-detail',{id:id},{reload:true});
                }
            })
        }


        function md(strAction){
            $http({
                method: 'POST',
                url: '/api/mdLog/save',
                params: {
                    type: 1,
                    action:strAction
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }


        vm.notLoginClick = notLoginClick;
        function notLoginClick(){
            if(vm.programDetail.programType=='极限寻宝'){
                vm.pushActivity(1)
            }else if(vm.programDetail.programType=='极限潜航'){
                vm.pushActivity(2)
            }else if(vm.programDetail.programType=='极限拆弹'){
                vm.pushActivity(3)
            }else if(vm.programDetail.tripModelId!=null && vm.programDetail.tripModelId!=''){
                vm.confirmCopy(vm.programDetail.tripModelId)
            }else{
                var url= 'program-center-detail/' + $stateParams.id
                login(url)
            }
        }

        vm.doSaveClickNum = doSaveClickNum;
        function doSaveClickNum(type) {
            $http({
                method: 'POST',
                url: '/api/program/saveNum',
                params: {
                    id: id,
                    type: type
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }

        var location = window.location.host;
        vm.domain = window.location.protocol+"//"+location;
        // vm.pushActivity =pushActivity;
        // function pushActivity(type){
        //     window.open( vm.domain + "/#/" + (type == 1 ? 'ts' : 'stealth') + '/trips?openRelease=true','_blank');
        // }

        $(".container").removeClass("login-container-content");
        $("#nav, #foot").css("display", "block");

        if($(window).width() < 767){
            vm.lxfs = 0
        }

        function pushActivity(type){
            var url ="";
            if(type ==1){
                url = "ts/trips?back="+id
            }else if(type==2){
                url = "stealth/trips?back="+id
            }else if(type==3){
                url = "bomb/trips?back="+id
            }
            window.location.href= vm.domain + "/#/?url=" +decodeURIComponent(url);
        }
        function lxfsFun(lxfs){
            if($(window).width() < 767){
                $('#myModal').modal('show');
            }
            vm.lxfs = lxfs;
        }
        vm.closeLxfsFunModal = function(){
            $('#myModal').modal('hide');
            vm.lxfs = 0
        }
        function doChange(changeTab){
            vm.changeTab = changeTab;
        }
        vm.toPhone =toPhone;
        function toPhone(phone){
            window.location.href="tel://"+phone;
        }



        vm.pdfDownload = pdfDownload;
        function pdfDownload(type){
            if(type ==1){
                doSaveClickNum(3);
            }else  if(type==2){
                doSaveClickNum(4);
            }
            if($(window).width() < 767){
                var param ={id: id,type : type};
                $state.go('showPDF',{obj: JSON.stringify(param)} ,{reload:true});
                return
            }
            var url ="";
            var name = "";
            if(type ==1){
                url =vm.IMAGE_SERVER+vm.programDetail.programUrl;
                name=vm.programDetail.title+"方案介绍.pdf"
            }else if(type==2){
                url =vm.IMAGE_SERVER+vm.programDetail.manualUrl;
                name=vm.programDetail.title+"执行手册.pdf"
            }
            var url =vm.domain +"/api/program/pdfDownload?downLoadPath="+url+"&fileName="+name;
            var form = $("<form></form>").attr("action", url).attr("method", "post");
            form.appendTo('body').submit().remove();
        }

        function login(url) {
            window.location.href= vm.domain + "/#/?url=" + decodeURIComponent(url);
        }

        function getDetailById(id) {
            $http({
                method: 'GET',
                url: '/api/program/getByid/' + id
            }).then(function (res) {
                vm.programDetail = res.data;
               // document.title = vm.programDetail.title;

                var $body = $('body');
                document.title = vm.programDetail.title;
                var $iframe = $("");
                $iframe.on('load', function() {
                    setTimeout(function() {
                        $iframe.off('load').remove();
                    }, 0);
                }).appendTo($body);

                vm.programDetail.programText = $sce.trustAsHtml(vm.programDetail.programText);
                vm.programDetail.manualText = $sce.trustAsHtml(vm.programDetail.manualText);
            },function (err) {
                console.log(err);
            })
        }

        vm.confirmCopy =confirmCopy;
        vm.close=close;
        function close(){
            $('#myModal2').modal('hide');
        }
        function confirmCopy (id) {
            var url= 'program-center-detail/' + $stateParams.id + "?copy=true"
            login(url)
        }

        vm.closeLxfsFunModal = function(){
            $('#myModal').modal('hide');
            vm.lxfs = 0
        }

        $("body").css("background-color", "#1D2B3C");
    }
})();
