(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RechargeCouponsDialogController', RechargeCouponsDialogController);

    RechargeCouponsDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'RechargeCoupons'];

    function RechargeCouponsDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, RechargeCoupons) {
        var vm = this;

        vm.rechargeCoupons = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        getType();
        function getType(){
            if (vm.rechargeCoupons.type==1){
                vm.rechargeCoupons.type='1';
            }else {
                vm.rechargeCoupons.type='2';
            }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rechargeCoupons.id !== null) {
                RechargeCoupons.update(vm.rechargeCoupons, onSaveSuccess, onSaveError);
            } else {
                RechargeCoupons.save(vm.rechargeCoupons, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:rechargeCouponsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
