/**
 * Created by harlanChang on 2017/6/28.
 */
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RefreshController', RefreshController);

    RefreshController.$inject = ['$http','$scope'];

    function RefreshController ($http,$scope) {
        var vm = this;
        console.log("RefreshController");
        reset();

        function reset(){
            $http({
                method:'post',
                url:'/api/user-relations/world-rank-refresh'
            }).success(function (data) {
                $scope.result = data.message;
            }).error(function(err){
                $scope.result = "刷新失败";
            });
        }
    }
})();

