(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripDetailController', controller);

    controller.$inject = ['$filter','$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', '$state','Trip',
        'tripUpdateStatus','tripEdit','$interval','EAccountSearch',
        'tripUpdateStatusTrade', 'TripVerifyStatus', 'TripGetEAccount', 'tripNotToWeb','tripToWeb','TripLabelGetFromWeb',
        'TripPeopleNumGetFromWeb', 'tripGetFromWeb', 'TripModelBaseInfo'];

    function controller($filter,$scope, $rootScope,Principal, $http, $stateParams, previousState, entity, $state,Trip,
                                  tripUpdateStatus,tripEdit,$interval,EAccountSearch,
                                  tripUpdateStatusTrade, TripVerifyStatus, TripGetEAccount, tripNotToWeb,tripToWeb,TripLabelGetFromWeb,
                                  TripPeopleNumGetFromWeb, tripGetFromWeb, TripModelBaseInfo) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.trip = entity;
        vm.previousState = previousState.name;
        vm.showTestButton = true;
        vm.showNormalButton = true;
        vm.showNormalButtonAll = true;
        vm.showWebButton = true;
        vm.afterTripStartTime = false; //当前时间是在活动开始日期0点之后
        if (vm.trip != null) {
            var date = $filter('date')(vm.trip.startTime,'yyyy-MM-dd');
            var startDay = new Date((date + " " + "00:00:00").replace(/-/g, "/"));
            var nowTime = new Date().getTime();
            if (nowTime > startDay.getTime()){
                vm.trip.afterTripStartTime = true;
            }
        }
        getAccount();
        //获取当前登录的账号
        function getAccount() {
            //Principal.identity().then(function(account) {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    for (var i = 0; i< vm.account.authorities.length;i++){
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN"){
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE"){
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }
        vm.tripQrCodeNew = tripQrCodeNew;
        function tripQrCodeNew(id,title,attribute,platform,traceType){
            $state.go('tripQrCodeNew',{id:id, title:title, attribute:attribute, platform:platform,traceType:traceType} ,{reload : false});
        }
        vm.tripCoachQrCode = tripCoachQrCode;
        function tripCoachQrCode(id,title,attribute){
            $state.go('tripCoachQrCode',{id:id, title:title,attribute:attribute} ,{reload : false});
        }

        // if (vm.trip.status!='EDIT'){
        //     $("#tripEdit").attr("disabled", true);
        //     $("#tripPrePublish").attr("disabled", true);
        // }
        /*vm.prePublish = prePublish;
        function prePublish(id) {
            if (id==null){
                //修改modal内容
                $("#mTitle")[0].innerHTML = "逻辑检测";
                // $(".mShow").css("display","block");
                $("#conf").css("display","none");
                vm.empTripModel = true;
                vm.empTripModelMessage = "该活动未绑定任何线路";
            }else {
                $http({
                    method: 'GET',
                    url: '/api/trip-models/check/'+id
                }).success(function (err) {
                    if (err!=null){
                        console.log(err);
                        if (err.code===0){
                            //少一步判断是否在手机端进行过完整测试
                            //修改modal内容
                            $("#mTitle")[0].innerHTML = "预发布";
                            $("#mContent")[0].innerHTML = "您还没有在手机端进行过完整测试，是否仍要预发布？";
                            vm.conf = conf;
                        }else if (err.code===-1){
                            //修改modal内容
                            $("#mTitle")[0].innerHTML = "逻辑检测";
                            $(".mShow").css("display","block");
                            $("#conf").css("display","none");
                            if (err.locationTasks!==null&&err.locationTasks.length>0){
                                vm.locationTaskSize = err.locationTasks.length;
                            }
                            if (err.baseTasks!==null&&err.baseTasks.length>0){
                                vm.baseTaskSize = err.baseTasks.length;
                            }
                            if (err.trips!==null&&err.trips.length>0){
                                vm.tripSize = err.trips.length;
                                vm.tripEmpty = false;
                            }else {
                                vm.tripEmpty = true;
                                vm.tripEmptys = " 该活动绑定的线路未包含任何点位 ";
                            }
                            if (err.emptyLocationTasks!==null&&err.emptyLocationTasks.length>0){
                                vm.empLo = " 存在 "+err.emptyLocationTasks.length+" 个空点位 ";
                                vm.empLocation = err.emptyLocationTasks;
                            }
                            if (err.b_errorPreCondition!==null&&err.b_errorPreCondition.length>0){
                                vm.bErrorPre = " 存在任务前置条件错误 ";
                                vm.bErrorPreCondition = err.b_errorPreCondition;
                            }
                            if (err.baseTaskCirculate!==null&&err.baseTaskCirculate.length>0){
                                vm.baseCir = " 存在任务出现条件死循环 ";
                                vm.baseTaskCir = err.baseTaskCirculate;
                            }
                            if (err.clueAndEnd!==null&&err.clueAndEnd.length>0){
                                vm.cluAndEnd = " 存在线索与结束任务冲突 ";
                                vm.cluAndEnds = err.clueAndEnd;
                            }
                            if (err.endInPreCondition!==null&&err.endInPreCondition.length>0){
                                vm.endInPre = " 存在结束任务在前置条件中 ";
                                vm.endInPreCondition = err.endInPreCondition;
                            }
                            if (err.l_errorPreCondition!==null&&err.l_errorPreCondition.length>0){
                                vm.errorPreLo = " 存在点位任务前置条件错误 ";
                                vm.errorPreLocation = err.l_errorPreCondition;
                            }
                            if (err.locationTaskCirculate!==null&&err.locationTaskCirculate.length>0){
                                vm.loCir = " 存在点位出现条件死循环 ";
                                vm.locationCir = err.locationTaskCirculate;
                            }
                            if (err.preConditionAndEnd!==null&&err.preConditionAndEnd.length>0){
                                vm.preConAndEnd = " 存在任务既是前置条件又是结束任务 ";
                                vm.preConditionAndEnd = err.preConditionAndEnd;
                            }
                        }
                    }
                }).error(function(err){
                    console.log(err);
                });
            }
        }
        function conf() {
            $http({
                method: 'PUT',
                url: '/api/trips/updateStatus/'+vm.trip.myTripModelId+'/PREPUBLISH'
            }).success(function () {
                console.log();
                $('#myModal1').modal('hide');
                window.location.reload();
            }).error(function(err){
                console.log(err);
            });
        }*/

        // console.log($stateParams);
        if ($stateParams.status == 'PREPUBLISH'){
            vm.trip.status = 'PREPUBLISH';
            vm.trip.canEdit = false;
        }

        vm.showTeams = showTeams;
        function showTeams(param) {
            // console.log(param);
            if (param.type == 'test' && !vm.trip.hasTestTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasTestTeams = res.data;
                    vm.showTestButton = false;
                },function (err) {
                    console.log(err);
                });
            }else if (param.type == 'normal' && !vm.trip.hasNormalTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasNormalTeams = res.data;
                    vm.showNormalButton = false;
                },function (err) {
                    console.log(err);
                });
            }else if (param.type == 'web' && !vm.trip.hasWebTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasWebTeams = res.data;
                    vm.showWebButton = false;
                },function (err) {
                    console.log(err);
                });
            }
        }

        vm.showAllTeams = showAllTeams;
        function showAllTeams(param) {
            $http({
                method: 'GET',
                url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type + '?reloadTeam=true'
            }).then(function (res) {
                vm.trip.hasNormalTeams = res.data;
                vm.showNormalButtonAll = false;
            },function (err) {
                console.log(err);
            });
        }

        getTripModel();
        function getTripModel() {
            $http({
                method: 'GET',
                url: '/api/trip-models/'+vm.trip.myTripModelId,
            }).then(function (res) {
                //console.log(res);
                vm.tripModel = res.data;
            },function (err) {
                console.log(err);
            });
        }
        vm.acfe = acfe;
        function acfe(){
            window.open(vm.account.acfeUrl + vm.tripModel.id + '?at=' + vm.account.password + '_coach');
        }
        vm.IGCClick = IGCClick;
        function IGCClick(){
            $('#myModal5').modal('show');
        }
        vm.ident = ident;
        function ident() {
            $("#myModal5").modal('hide');
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
        var unsubscribe = $rootScope.$on('taskServiceApp:tripUpdate', function(event, result) {
            vm.trip = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.deleteTrip = deleteTrip;
        function deleteTrip() {
            $('#myModal6').modal('show');
        }
        // 退回编辑和上下架
        vm.isRelease = false;
        vm.phoneCode = "发送验证码";
        vm.sessionId='';
        vm.code='';
        vm.filterEAccounts = this.filterEAccounts;
        vm.notToWebFlag=false;
        vm.tripLabels = null;
        vm.regions = null;
        vm.peopleNums = null;
        vm.labelSelect = false;
        vm.tripWeb = {};
        vm.tripModel = null;
        vm.mTitle = "退回编辑";
        vm.mContent = "确定要退回发布吗？";
        function clearCodeInfo() {
            //$uibModalInstance.dismiss('cancel');
            vm.errText=null;
            vm.relatedEAccountName=null;
            vm.code=null;
            vm.imgCode=null;
            window.history.go(0);
        }
        function clearErrText() {
            vm.errText=null;
        }
        vm.edit = edit;
        function edit(tripId) {
            vm.tripId = tripId;
            //不是发布管理员身份
            if ($.inArray("ROLE_RELEASE",vm.account.authorities) ===-1) {
                var dataNew = new Date();
                var myJsDate=$filter('date')(vm.trip.startTime,'yyyy-MM-dd HH:mm:ss');
                var dateStart = new Date(myJsDate);
                if (dataNew > dateStart) {
                    vm.mContent2 = "活动已开始，不可退回发布，如需退回请联系发布管理员";
                    $('#myModal2').modal('show');
                    return;
                }
            }
            if (vm.trip.toWeb == 1) {
                alert("请先下架该活动");
            }else if (vm.trip.status !== 'EDIT'){
                vm.confirm = confirms;
                vm.sendCode = sendCode;
                vm.confirmTrade = confirmTrade;
                vm.tripStatus = vm.trip.status;
                $('#myModal1').modal('show');
            }else {
                alert("该活动已为编辑状态");
            }
        }
        function confirms() {
            //点击事件执行后将按钮置灰，请求成功后再回复按钮状态

            var param = {tripId:vm.tripId,status:'EDIT'};
            tripUpdateStatus.updateStatus(param).success(function(data){
                // console.log(data);
                // loadAll();
                // for (var i in vm.trips){
                //     if (vm.trips[i].id == param.tripId) {
                //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                //     }
                // }
                $('#myModal1').modal('hide');
                window.history.go(0);
            }).error(function(err){
                // console.log(err);
                if (err!=null){
                    vm.err = err;
                    vm.mTitle2 = "退回编辑";
                    vm.release = false;
                    if ($.inArray("ROLE_RELEASE",vm.account.authorities)!==-1){
                        vm.release = true;
                    }
                    if (vm.release){
                        vm.mContent2 = err.message;
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        vm.confirmsConf = confirmsConf;
                    } else {
                        if (err.errorCode == -1) {
                            vm.mContent2 = "活动已结束，不可退回编辑";
                        } else {
                            vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                        }
                        vm.confirmsConf = null;
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        $('#conf2').css('display','none');
                    }
                }
            });
        }
        function confirmTrade() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {tripId:vm.tripId,status:'EDIT',sessionId:vm.sessionId,validationCode:vm.code};
                tripUpdateStatusTrade.updateStatusTrade(param).success(function(data){
                    console.log("1：：：：" + data);
                    if(data.errorCode != null && data.errorCode == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        // loadAll();
                        window.history.go(0);
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }
        function setValidationCode() {
            vm.errText = "请输入验证码";
            $('.errText').css('display','block');
        }
        function getValidationCode() {
            vm.errText = "请先获取验证码";
            $('.errText').css('display','block');
        }
        function confirmsConf(){
            console.log("--------3");
            if (vm.err.errorCode!=0){
                var param = {tripId:vm.tripId,status:'EDIT'};
                tripEdit.tEdit(param).success(function(data){
                    // console.log(data);
                    // loadAll();
                    // for (var i in vm.trips){
                    //     if (vm.trips[i].id == param.tripId) {
                    //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                    //     }
                    // }
                    $('#myModal1').modal('hide');
                    $('#myModal2').modal('hide');
                    $('#myModal3').modal('hide');
                    window.history.go(0);
                });
            }
        }


        function sendCode() {
            var param = {tripId:vm.tripId};
            TripVerifyStatus.tripVerifyStatus(param).success(function(data){
                console.log(data);
                if (data.code == 0) {
                    $http({
                        url: 'api/e-accounts/'+data.message,
                        method: 'GET'
                    }).then(function (res) {
                        vm.relatedEAccountName = res.data.name;
                        vm.relatedEAccountId = res.data.id;
                        vm.phoneEAccount = res.data.adminAccount;
                    });
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                } else if (data.code == -1) {
                    vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                } else if (data.code == -3) {
                    vm.mContent2 = "线路在已结算，不可退回编辑";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                }
            }).error(function(err){
                console.log(err);
            });

        }
        vm.toWeb=toWeb;
        function toWeb(tripId) {
            vm.mTitle = "上架活动信息";
            vm.tripId = tripId;
            // console.log(tripId);

            if (vm.trip == null) {
                TripModelBaseInfo.getDetail(vm.tripId).success(function (res) {
                    vm.tripModel = res.data;
                });
            }
            if (vm.tripLabels == null) {
                TripLabelGetFromWeb.getLabel().success(function (res) {
                    if (res.code == 0) {
                        vm.tripLabels = res.data;
                        // console.log(vm.tripLabels);
                    }
                });
            }
            if (vm.peopleNums == null) {
                TripPeopleNumGetFromWeb.getPeopleNum().success(function (res) {
                    if (res.code == 0) {
                        vm.peopleNums = res.data;
                        // console.log(vm.peopleNums);
                    }
                });
            }

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                } else if (res.code == -1) {
                    vm.relatedEAccountId = null;
                }
                $('#myModal1').modal('hide');
                $('#myModal4').modal('show');
            }).error(function(err){
                console.log(err);
            });
        }

        vm.confirmToWeb=confirmToWeb;
        function confirmToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                vm.tripWeb.region_name = vm.tripModel.area;
                vm.tripWeb.duration = vm.tripModel.eTime;
                var param = {id:vm.tripId,type:0,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb,eAccountId:vm.relatedEAccountId};
                // console.log(param);
                tripToWeb.toWeb(param).success(function(res){
                    if(res.code != null && res.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        // loadAll();
                        window.history.go(0);
                        $('#myModal4').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.notToWeb=notToWeb;
        function notToWeb(tripId) {
            vm.tripId = tripId;
            vm.mTitle = "下架";
            vm.notToWebFlag=true;
            console.log(tripId);
            getTripWebInfo();

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function getTripWebInfo() {
            tripGetFromWeb.getFromWeb(vm.tripId).success(function (res) {
                if (res.code == 0) {
                    vm.tripWeb = res.data;
                }
            });
        }

        vm.confirmNotToWeb=confirmNotToWeb;
        function confirmNotToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {id:vm.tripId,type:3,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb};
                tripNotToWeb.notToWeb(param).success(function(data){
                    if(data.code != null && data.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        // loadAll();
                        window.history.go(0);
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.checkLableNum = checkLableNum;
        function checkLableNum() {
            if (vm.tripWeb.labels.length >= 3) {
                $('.overHide').css('display','none');
            }
            var labelIds = '';
            angular.forEach(vm.tripWeb.labels,function(data){
                labelIds = labelIds + data.category_id + ",";
            });
            vm.tripWeb.label = labelIds;
            // console.log(vm.tripWeb.label);
        }
        vm.showLable = showLable;
        function showLable() {
            if (vm.tripWeb.labels.length < 3) {
                $('.overHide').css('display','block');
            }
        }

        vm.setPeopleNum=setPeopleNum;
        function setPeopleNum() {
            vm.tripWeb.renshu_id = vm.tripWeb.renshu.renshu_id;
        }
        function confirmRelease() {
            $http({
                method: 'PUT',
                url: '/api/trips/updateStatus/'+vm.tripId+'/PUBLISH'
            }).success(function () {
                console.log();
                $('#myModal1').modal('hide');
                window.location.reload();
            }).error(function(err){
                console.log(err);
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        this.filterEAccounts = function (search) {
            if (search){
                vm.eAccounts = EAccountSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release: "0"
                });
            }
        };

        vm.setEaccount = setEaccount;
        function setEaccount(phone, eAccountId, eAccountName) {
            console.log(phone + "---" + eAccountId);
            vm.phoneEAccount = phone;
            vm.relatedEAccountId = eAccountId;
            vm.relatedEAccountName = eAccountName;
        }

        vm.setEaccountPhone = setEaccountPhone;
        function setEaccountPhone(phone) {
            console.log(phone);
            vm.phoneEAccount = phone;
        }

        //获取图片验证码
        //getImageCode();
        vm.getImageCode = getImageCode;
        function getImageCode() {
            $http({
                url:'/api/getImageCode',
                method:'POST'
            }).success(function (data) {
                // console.log(data);
                if (data!=null){
                    vm.imgUrl = data.url;
                    vm.verifyId = data.verifyId;
                    vm.imgCode = '';
                }
            }).error(function (err) {
                console.log(err);
            });
        }
        //设置倒计时60s
        function setTimeOut() {
            var code = 60;vm.phoneCode = 60+'s';vm.dis = true;
            // $('.checkBtn').css('background-image','none');
            var time = $interval(function () {
                code = code-1;
                vm.phoneCode = code+'s';
            },1000,60);
            time.then(function () {
                $interval.cancel(stop);
                vm.phoneCode = "发送验证码";
                $('.checkBtn').css('background-image','linear-gradient(113deg, #35e8c0, #0c9fcb)');
                vm.dis = false;
            });
        }
        //获取手机验证码
        vm.getCode = getCode;
        function getCode() {
            if (vm.imgCode!=null&&vm.verifyId!=null&&vm.relatedEAccountId!=null){
                $http({
                    url:'/api/getCode',
                    method:'POST',
                    data:{mobile:vm.phoneEAccount,verifyCode:vm.imgCode,verifyId:vm.verifyId}
                }).success(function (res) {
                    //console.log(res);
                    if (res.code==1012){
                        vm.errText="图片验证码不正确";
                        vm.paramError = 'paramError';
                    }else if (res.code==1008){
                        vm.errText="同一手机号一分钟内只能发送一次短信验证码，请稍后再试";
                        getImageCode();
                    }else {
                        //设置倒计时60s
                        setTimeOut();
                        vm.errText=null;
                        vm.sessionId = res.sessionId;
                        vm.paramError = null;
                    }
                }).error(function (err) {
                    console.log(err);
                });
            }else {
                vm.paramError = 'paramError';
            }
        }
    }
})();


