//发布到景区的线路审核
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('TripModelToCheckController', TripModelToCheckController);

    TripModelToCheckController.$inject = ['entity', '$state', 'TripModelSearch', 'Principal', '$http','$uibModalInstance','$scope','Upload','TripModel','$sce'];

    function TripModelToCheckController(entity, $state, TripModelSearch, Principal, $http, $uibModalInstance, $scope, Upload,TripModel,$sce) {

        var vm = this;
        vm.tripModel = entity;
        var iframeCss = "<link rel='stylesheet'  type='text/css'  href='https://www.itasktour.com/bgm/css/iframe.css'>"
        if(vm.tripModel.description != null ) {
            if(!vm.tripModel.description.startsWith("<link")){
                vm.tripModel.description = iframeCss + vm.tripModel.description;
            }
        }else{
            vm.tripModel.description = iframeCss;
        }
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.onFileSelect = onFileSelect;
        var imgLimit = 5000000;//上传文件限制，单位kb
        vm.showCropped = true;
        vm.toCheck = false;
        vm.clear = clear;
        if(vm.tripModel.category == null){
            vm.category = [null,null,null];
        }else{
            vm.category = JSON.parse(vm.tripModel.category);
            if( vm.category.length != 3){
                vm.category = [null,null,null];
            }
        }
        vm.timeConsuming = ['1小时','2小时','3小时','半天','全天','两天'];
        vm.placeCategory = ['场馆','街巷','公园','校园','景区','古镇','野外','商圈'];
        vm.titleCategory = ['历史','人文','红色','研学','科普','自然','探秘','美食'];
        vm.crowd = ['全年龄','青少年','亲子','情侣','单人'];

        vm.IMAGE_SERVER = IMAGE_SERVER;
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 10000;//上传视频限制，单位kb
        vm.showCropped = true;
        vm.cropper = 'default';
        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        var URL_ROOT = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/";
        vm.ueditorConfig = {
            serverUrl: URL_ROOT + "ueditor/controller",
            // toolbars: [[
            //
            //         'fontfamily', 'fontsize', 'paragraph', 'forecolor', 'backcolor', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', '|',
            //         'lineheight', 'indent', 'touppercase', 'tolowercase'
            //     ],
            //     ['link', 'unlink', 'simpleupload', 'insertimage', 'emotion', 'insertvideo', 'attachment', 'spechars', '|',
            //         'undo', 'redo', 'removeformat', 'formatmatch', 'wordimage', 'preview', 'source', 'fullscreen'
            //     ]
            // ],
            toolbars: [
                ['paragraph', 'bold', 'simpleupload'
                ]
            ],
            paragraph : {
                'p': '',
                'h1': '',
            },
            elementPathEnabled: false,
            allowDivTransToP: false,
            enableContextMenu: false,
            enableAutoSave: false,
            zIndex: 11000,
            catchRemoteImageEnable: false,
            xssFilterRules: false, //input xss过滤
            inputXssFilter: false, //output xss过滤
            outputXssFilter: false,
            lang: 'zh-cn'
        };

        this.$onInit = function () {
            $(function () {
                UE.delEditor('field_description');
                vm.descriptionUE = UE.getEditor('field_description', vm.ueditorConfig);
                // 全屏控制事件
                //https://ask.csdn.net/questions/207448
                vm.descriptionUE.addListener('beforefullscreenchange', function (event, isFullScreen) {
                });

                vm.descriptionUE.addListener('fullscreenchanged', function (event, isFullScreen) {
                    // alert(isFullScreen?'全屏':'默认')
                    $('.modal-open .modal').css("position", "fixed");
                    if (isFullScreen) {
                        $('#hid').addClass("ribbon_hid").removeClass("ribbon");
                    } else {
                        $('#hid').addClass("ribbon").removeClass("ribbon_hid");
                    }
                });
            });

        };
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        // function onFileSelect(uploadFile, name) {
        //
        //     var uploadZipFile = function (compressedBlob) {
        //
        //         var fileType = compressedBlob.type;
        //         console.log("文件格式--1--" + JSON.stringify(fileType));
        //         if (fileType!=null && typeof fileType!='undefined'){
        //             fileType = fileType.split('/')[1];
        //             console.log("文件格式--2--" + JSON.stringify(fileType));
        //         }
        //         var imgSize = compressedBlob.size/1024;
        //         console.log(imgSize);
        //         // if (fileType=='zip'){
        //         //     if (imgSize>imgLimit){
        //         //         alert("图片大小超过"+imgLimit+"kb");
        //         //         return;
        //         //     }
        //         //     vm.type = 'zip';
        //         // } else {
        //         //     alert("文件格式不正确，请重新上传！！");
        //         //     return;
        //         // }
        //
        //         Upload.upload({
        //
        //             url: '/api/postfile',
        //             fields: {},
        //             data: {type: "TRIP"},
        //             file: compressedBlob,
        //             method: 'POST'
        //
        //         }).progress(function (evt) {
        //
        //             var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        //             console.log('progress: ' + progressPercentage + '% ');
        //
        //         }).success(function (data, status, headers, config) {
        //             if (name == 'coverImg') {
        //                 vm.tripModel.coverImg = data.file;
        //             } else if (name == 'description') {
        //                 vm.tripModel.description = data.file;
        //             }
        //         }).error(function (data, status, headers, config) {
        //
        //             console.log('error status: ' + status);
        //
        //         });
        //     };
        //
        //     if (uploadFile != null) {
        //         uploadZipFile(uploadFile);
        //     }
        // }

        // vm.checkType = checkCoverType;
        // function checkCoverType(file) {
        //     if (file!=null){
        //         if (/\.(zip)$/.test(file)){
        //             vm.type = 'zip';
        //         } else {
        //             vm.type = 'other';
        //         }
        //     }
        // }


        vm.save = save;
        function save () {
            vm.toCheck = true;
            vm.tripModel.toCheck = 0;
            vm.tripModel.description = vm.descriptionUE.getContent();
            if(vm.tripModel.description.startsWith("<link")){
                vm.tripModel.description = vm.tripModel.description.substring(vm.tripModel.description.indexOf(">") + 1);
            }
            if (vm.tripModel.coverImg !== null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.tripModel.coverImg)){
                    var image = document.getElementById("file");
                    if (image != null){
                        var height = image.height;
                        var width = image.width;
                        var bi = width / height;
                        if (bi<1.9 || bi>2.1) {
                            // alert('"封面图片"比例不是推荐值2:1，显示时将被拉伸或压缩，是否仍要使用？');
                            alert("上传图片比例应为2:1");
                            if (vm.tripModel.coverImg != null) {
                                vm.tripModel.coverImg = null;
                            }
                            vm.toCheck = false;
                            return;
                        }
                    }
                }
            }else {
                alert("上传图片不能为空");
                vm.toCheck = false;
                return;
            }
            // if (vm.tripModel.description == null){
            //     alert("请上传指定的资源文件");
            //     vm.toCheck = false;
            //     return;
            // }
            var date = new Date();
            vm.tripModel.updateDate = date;
            vm.tripModel.updateBy = vm.account.firstName;
            vm.tripModel.category = JSON.stringify(vm.category);
            TripModel.update(vm.tripModel, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:tripModelUpdate', result);
            $uibModalInstance.close(result);
            vm.toCheck = false;
        }
        function onSaveError () {
            vm.toCheck = false;
        }
        vm.changeImg = changeImg;
        function changeImg(){
            vm.tripModel.coverImg = null;
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }
        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }
        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.tripModel.coverImg != null) {
                vm.tripModel.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }
        vm.deleteCoverVideo=deleteCoverVideo;
        function deleteCoverVideo() {
            vm.tripModel.coverVideo = null;
        }

        function onFileSelect(uploadFile, name) {
            var uploadImageFile = function (compressedBlob) {
                var fileType = compressedBlob.type;
                if (fileType!=null && typeof fileType!='undefined'){
                    fileType = fileType.split('/')[1];
                }
                var imgSize = compressedBlob.size/1024;
                if ( name == 'coverImg' && (fileType=='jpg'||fileType=='jpeg'||fileType=='png'||fileType=='bmp')){
                    // console.log(imgSize);
                    if (imgSize>imgLimit){
                        alert("图片大小超过"+imgLimit+"kb");
                        return;
                    }
                    vm.type = 'img';
                }else if ( name == 'coverVideo' && fileType=='mp4'){
                    if (imgSize>otherLimit){
                        alert("音视频格式只能为MP4,且大小不能超过"+otherLimit/1000+"M");
                        return;
                    }
                    vm.type = 'other'
                }else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    if (name == 'coverImg') {
                        vm.tripModel.coverImg = data.file;
                        $scope.myImage = vm.IMAGE_SERVER+vm.tripModel.coverImg;
                    }
                    if(name == 'coverVideo'){
                        vm.tripModel.coverVideo = data.file;
                    }
                }).error(function (data, status, headers, config) {
                    console.log('error status: ' + status);
                });
            };
            //TODO gif no compress
            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }
    }

})();
