(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ProgramCenterController', ProgramCenterController)
        .filter('descFilter', function() {
            return function(text) {
                var subStr = text.substring(0, 18);
                return subStr + (text.length > 18 ? '...':'');
            }
        })
        .filter('labelFilter', function() {
            return function(text) {
                if(text == null){
                    return ''
                }
                var arr = JSON.parse(text);
                return arr;
            }
        })
        ;

        ProgramCenterController.$inject = ['$state', 'ProgarmCenterSearch', 'Principal', '$http', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams','$sce','$cookies','$scope','$stateParams'];

    function ProgramCenterController($state, ProgarmCenterSearch, Principal, $http, ParseLinks, AlertService, paginationConstants, pagingParams,$sce,$cookies,$scope,$stateParams) {

        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();


        vm.doSaveClickNum = doSaveClickNum;
        function doSaveClickNum(id) {
            var  strAction = "方案-从方案中心点击-"+id
            md(strAction);

            $http({
                method: 'POST',
                url: '/api/program/saveNum',
                params: {
                    id: id,
                    type: 1
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })


        }

        vm.ad = null;
        function getAdByType() {
            $http({
                method: 'GET',
                url: '/api/ad/getByType/' + 1
            }).then(function (res) {
                console.log(res);
                if(res.data!=null){
                    vm.ad=res.data[0];
                }

            },function (err) {
                console.log(err);
            })
            if($stateParams.login){
                $http({
                    method: 'GET',
                    url: '/api/ad/getByType/' + 2
                }).then(function (res) {
                    console.log(res);
                    if(res.data!=null && res.data.length > 0){
                        vm.adModel=res.data[0];
                        $('#loginAd').modal('show');
                    }else{
                        // 页面加载版本更新日志
                        getVersion();
                    }
                },function (err) {
                    console.log(err);
                })
            }else{
                getVersion();
            }
        }

        vm.adModleClose = function(){
            $('#loginAd').modal('hide');
            // 页面加载版本更新日志
            getVersion();
        };

        vm.toAd=toAd;
        function toAd(link,id,type){
            var strAction ="";
            if(type==1){
               strAction = "广告-从方案中心点击-"+id
            }else if(type==2){
                strAction = "广告-登录后首屏-"+id
            }
            md(strAction);

            var  strAction1="";
            if(link.indexOf("program-center-detail")!=-1){
                var arr = link.split("/");
                if(type==1){
                    strAction1 = "方案-从顶部banner点击-"+arr[arr.length-1]
                 }else if(type==2){
                     strAction1 = "方案-从首屏广告点击-"+arr[arr.length-1]
                 }
                 md(strAction1);
            }
            if(link == null || link.trim().length == 0){
                return
            }
            window.open(link,'_blank');
        }

        function md(strAction){
            $http({
                method: 'POST',
                url: '/api/mdLog/save',
                params: {
                    type: 1,
                    action:strAction
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }

        // $("#foot").css("display", "block");

        //防止登录时候用户来回点击，没法进入指向登录的页面
        var loginUrl = $cookies.get("loginUrl");
        if(loginUrl != null && loginUrl.length>0){
            $cookies.remove("loginUrl")
            if(loginUrl.indexOf("copy=true") != -1){
                loginUrl = loginUrl.replace("copy=true", '');
                //防止线路多次复制使用cookies
                var expireDate = new Date();
                expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
                $cookies.put('copy', true, {expires: new Date(expireDate)});
            }
            window.location.href = loginUrl
            return;
        }

        $state.go('trip-model',{login:true});

        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(res) {
                vm.account = res.data;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
                if (vm.account!==null){
                    if($(window).width() < 767 ){
                        if(!vm.account.mobileTipShowFlag){
                            $('#myModalPh').modal('show');
                        }else{
                            getAdByType();
                        }
                    } else if (vm.account.pcTipShowFlag == false ) {
                        $('#myModal1').modal('show');
                    }else{
                        getAdByType();
                    }

                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        function loadAll () {
            ProgarmCenterSearch.query({
                query: pagingParams.search,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                release: "0"
            }, onSuccess, onError);
            function sort() {
                var result = ['updateDate' + ',' + 'desc'];
                // if (vm.predicate !== 'updateDate') {
                //     result.push('updateDate');
                // }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.programs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            console.log("页数："+vm.page);
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' +'desc',
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'updateDate';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'updateDate';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        vm.keyDown = function(event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };


        // this.$onInit = function() {
            //页面加载版本更新日志
            // getVersion();
        // };
        //获取 版本更新日志信息
        // vm.getVersion = getVersion;
        function getVersion() {
            $http({
                method: 'GET',
                url: '/api/version-descs'
            }).then(function (res) {
                // console.log(res);
                var num = res.data.length;
                if (JSON.parse(window.localStorage.getItem("updateLogs"))) {
                    var version_localStorage =  JSON.parse(window.localStorage.getItem("updateLogs"))["name"];
                }else {
                    // $('#myUpdateLogModal').modal('show');
                    var num = res.data.length;
                    if (num>0){
                        vm.versionDesc = res.data[num-1];
                        vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                    }else {
                        vm.versionDesc = res.data[num];
                        vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                    }
                    //保存浏览器缓存中;
                    window.localStorage.setItem("updateLogs",JSON.stringify(res.data[num-1]));
                }
                if (num >= 1) {
                    var version_lastestLog  = res.data[num-1]["name"];
                    if (res.data.length>0){
                        if (version_lastestLog !== version_localStorage) {
                            $('#myUpdateLogModal').modal('show');
                            var num = res.data.length;
                            if (num>0){
                                vm.versionDesc = res.data[num-1];
                                vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                            }else {
                                vm.versionDesc = res.data[num];
                                vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
                            }
                            //保存浏览器缓存中;
                            window.localStorage.setItem("updateLogs",JSON.stringify(res.data[num-1]));
                        }else {
                            $('#myUpdateLogModal').modal('hide');
                        }
                    }else {
                    }
                }
            },function (err) {
                console.log(err);
            })
        }

        //更新提示页面
        vm.toLogin = toLogin;
        function toLogin() {
            $('#myUpdateLogModal').modal('hide');
        }
        // //电脑提示首次登陆关闭
        vm.pcLgoinBtn = pcLgoinBtn;
        function pcLgoinBtn(){
            $http({
                url: 'api/tip/change-status/0',
                method: 'POST'
            }).then(function (res) {
                console.log("修改结果：" + JSON.stringify(res));
            })
            getAdByType();
        }

        vm.breakPhoneWarn = breakPhoneWarn;
        function breakPhoneWarn() {
            if(vm.phoneLogin){
                vm.account.mobileTipShowFlag = true;
                $http({
                    url: 'api/tip/change-status/' + 1,
                    method: 'POST'
                }).then(function (res) {
                    console.log("修改结果：" + JSON.stringify(res));
                })
            }
            getAdByType();
        }

        //手机提示首次登陆以后不再弹出
        vm.phoneLogin = false;
        vm.breakPhoneWarn = breakPhoneWarn;
        function breakPhoneWarn() {
            if(vm.phoneLogin){
                $http({
                    url: 'api/tip/change-status/' + 1,
                    method: 'POST'
                }).then(function (res) {
                    console.log("修改结果：" + JSON.stringify(res));
                })
            }
        }

        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
            $("body").removeClass('modal-open')
        })
    }

})();
