(function () {
    "use strict";
    angular
        .module("taskServiceApp")
        .controller("TripQrCodeNewController", controller);
    controller.$inject = [
        "$timeout",
        "$uibModalInstance",
        "TripSearchOfficial",
        "Principal",
        "$stateParams",
        "$http",
    ];
    function controller(
        $timeout,
        $uibModalInstance,
        TripSearchOfficial,
        Principal,
        $stateParams,
        $http
    ) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        if ($stateParams.id != null && $stateParams.id != "") {
            vm.trip = [];
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
            vm.trip.attribute = $stateParams.attribute;
            vm.tripModelsIdChange = true;
            vm.trip.platform = $stateParams.platform;
            vm.trip.traceType = $stateParams.traceType;
            vm.trip.teamset = $stateParams.teamset;
        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
            });
        }
        this.filterTrip = function (search) {
            if (search) {
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ["id,desc"],
                });
                // console.log(vm.trips);
            }
        };
        vm.clearQrCode = clearQrCode;
        function clearQrCode() {
            document.getElementById("qrCodeTest").style.display = "none";
            document.getElementById("qrCodeWeb").style.display = "none";
            document.getElementById("qrCodeNormal").style.display = "none";
        }
        banRight(); //当前页面禁用鼠标右键
        function banRight() {
            if (window.Event) document.captureEvents(Event.MOUSEUP);

            function nocontextmenu() {
                event.cancelBubble = true;
                event.returnvalue = false;
                return false;
            }

            function norightclick(e) {
                if (window.Event) {
                    if (e.which == 2 || e.which == 3) return false;
                } else if (event.button == 2 || event.button == 3) {
                    event.cancelBubble = true;
                    event.returnvalue = false;
                    return false;
                }
            }
            document.oncontextmenu = nocontextmenu; // for IE5+
            document.onmousedown = norightclick; //
        }

        vm.showTitle = 0;
        vm.showQrCodeNormal = function () {
            // 成都企业生成成都api地址的二维码；
            if (vm.account.eAccountId == 8 || vm.account.eAccountId == 9) {
                vm.qrCodeNormal =
                    window.location.origin +
                    "/mobile/chengdu-c.html?" +
                    (vm.trip.platform == 1 ? "platform=1&" : "") +
                    "tripId=" +
                    vm.trip.id +
                    "&version=2";
            } else {
                vm.qrCodeNormal =
                    window.location.origin +
                    "/mobile/official-trip-c.html?" +
                    (vm.trip.platform == 1 ? "platform=1&" : "") +
                    "tripId=" +
                    vm.trip.id +
                    "&version=2";
            }
            document.getElementById("qrCodeTest").style.display = "none";
            document.getElementById("qrCodeWeb").style.display = "none";
            document.getElementById("qrCodeNormal").style.display = "block";
            vm.showTitle = 1;
        };
        vm.showQrCodeTest = function (trip) {
            if (vm.account.eAccountId == 8 || vm.account.eAccountId == 9) {
                vm.qrCodeTest =
                    window.location.origin +
                    "/mobile/chengdu-c.html?" +
                    (vm.trip.platform == 1 ? "platform=1&" : "") +
                    "tripId=" +
                    vm.trip.id +
                    "&istest=1&version=2";
            } else {
                vm.qrCodeTest =
                    window.location.origin +
                    "/mobile/official-trip-c.html?" +
                    (vm.trip.platform == 1 ? "platform=1&" : "") +
                    "tripId=" +
                    vm.trip.id +
                    "&istest=1&version=2";
            }
            document.getElementById("qrCodeNormal").style.display = "none";
            document.getElementById("qrCodeWeb").style.display = "none";
            document.getElementById("qrCodeTest").style.display = "block";
        };
        vm.clickWeb = showQrCodeWeb;
        function showQrCodeWeb(trip) {
            // vm.qrCode = "itasktour://gototrip?tripid="+id;
            var location = window.location.host;
            vm.qrCodeWeb =
                window.location.protocol +
                "//" +
                location +
                "/mobile/official-trip-c.html?tripId=" +
                trip.id +
                "&istest=1&isweb=1&version=2";
            document.getElementById("qrCodeNormal").style.display = "none";
            document.getElementById("qrCodeTest").style.display = "none";
            document.getElementById("qrCodeWeb").style.display = "block";
        }
        //极限追踪生成测试二维码
        vm.clickTraceTest = showQrCodeTraceTest;
        function showQrCodeTraceTest(trip) {
            $http({
                url: "/api/trip/qrcode/" + trip.id,
                method: "GET",
            }).then(
                function suc(res) {
                    console.log(res);
                    if (res.data.result != null && res.data.result.length > 0) {
                        vm.qrCodeTest = res.data.result;
                        document.getElementById("qrCodeNormal").style.display =
                            "none";
                        document.getElementById("qrCodeWeb").style.display =
                            "none";
                        document.getElementById("qrCodeTest").style.display =
                            "block";
                    }
                },
                function err(err) {
                    console.log(err);
                }
            );

            vm.showTitle = 2;
        }
        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            } else {
                return false;
            }
        }
        vm.downLoadQr = downLoadQr;
        function downLoadQr(codeType) {
            if (codeType == "normal") {
                html2canvas($(".qrNormal"), {
                    onrendered: function (canvas) {
                        var type = "png";
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(
                            _fixType(type),
                            "image/octet-stream"
                        );
                        if (isIE()) {
                            // console.log(isIE());
                            $("#myIEModal").modal("show");
                        } else {
                            // console.log(isIE());
                            $(".downNor").attr({
                                href: imgData,
                                download:
                                    "活动" + vm.trip.id + "正式二维码.png",
                            });
                        }
                    },
                });
            } else {
                html2canvas($(".qrTest"), {
                    onrendered: function (canvas) {
                        var type = "png";
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(
                            _fixType(type),
                            "image/octet-stream"
                        );
                        if (isIE()) {
                            // console.log(isIE());
                            $("#myIEModal").modal("show");
                        } else {
                            $(".downTes").attr({
                                href: imgData,
                                download:
                                    "活动" + vm.trip.id + "测试二维码.png",
                            });
                        }
                    },
                });
            }

            var _fixType = function (type) {
                type = type.toLowerCase().replace(/jpg/i, "jpeg");
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return "image/" + r;
            };
        }
        vm.iknow = iknow;
        function iknow() {
            $("#myIEModal").modal("hide");
        }
        $timeout(function () {
            angular.element(".form-group:eq(1)>input").focus();
        });

        function clear() {
            $uibModalInstance.dismiss("cancel");
        }

        function save() {}

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
