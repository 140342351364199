(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('BombTripRankController', BombTripRankController);

    BombTripRankController.$inject = ['$location','$scope', '$rootScope','$state', '$stateParams','$http', 'previousState', 'Trip', 'Principal' ,'TripGame'];

    function BombTripRankController($location,$scope, $rootScope, $state, $stateParams,$http, previousState, Trip, Principal, TripGame) {
        var vm = this;
        vm.tripId = $stateParams.id;
        vm.tripRanks = [];
        vm.previousState = previousState.name;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account !== null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        Trip.get({id:vm.tripId},function (res) {
            if (res != null){
                vm.trip = res;
            }
        },function (err) {
            console.log(err);
        });


        rank();
        function rank(){
            $http({
                method: 'POST',
                url: '/api/bomb/rank',
                params: {
                    tripId: vm.tripId
                }
            }).then(function (res) {
                    vm.tripRanks = res.data.data;
            },function (err) {
                console.log(err);
            })
        }




    }


})();
