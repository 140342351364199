(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ExportTsTripController', ExportTsTripController);

    ExportTsTripController.$inject = ['$timeout', '$uibModalInstance'];

    function ExportTsTripController($timeout, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
