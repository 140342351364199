(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripDialogController', controller);

    controller.$inject = ['$timeout', '$filter','$scope', '$log','$sce', '$uibModalInstance', 'entity', 'Trip', 'TeamSearch',  'LocationTask', 'TripType', 'User', 'UserSearch', 'Team', 'Upload', 'Ahdin', 'Npc','UserExt','BadgesSearch','MyBadges','UserTripModelSearch', 'Principal','$state','$http','EAccount','$stateParams'];

    function controller ($timeout, $filter, $scope, $log, $sce, $uibModalInstance, entity, Trip, TeamSearch, LocationTask, TripType, User, UserSearch, Team, Upload, Ahdin, Npc,UserExt,BadgesSearch,MyBadges,UserTripModelSearch,Principal,$state,$http,EAccount,$stateParams) {
        var vm = this;
        vm.widthFlag=true;
        if($(window).width() < 767){
            vm.widthFlag=false;
        }
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.trip = entity;
        var iframeCss = '<link rel="stylesheet"  type="text/css"  href="https://www.itasktour.com/bgm/css/iframe.css">';
        if(vm.trip.attribute == "OTA" ){
            if(vm.trip.description != null){
                if(!vm.trip.description.startsWith("<link")) {
                    vm.trip.description = iframeCss + vm.trip.description;
                }
            } else{
                vm.trip.description = iframeCss;
            }
        }

        var showBuydiv=0;

        vm.afterTripStartTime = false; //当前时间是在活动开始日期0点之后
        if(vm.trip != null){
            var reg = new RegExp("(^| )" + 'trip' + "=([^;]*)(;|$)");
            var arr = document.cookie.match(reg);
            if (arr != null) {
                try {
                    var tripCookie = JSON.parse(arr[2]);
                    if(tripCookie.id == vm.trip.id && tripCookie.pay){
                        vm.trip.id= tripCookie.id;
                        vm.trip.title= tripCookie.title;
                        vm.trip.description= tripCookie.description;
                        vm.trip.coverImg= tripCookie.coverImg;
                        vm.trip.maxPeople= tripCookie.maxPeople;
                        vm.trip.teamMaxpeople= tripCookie.teamMaxpeople;
                        vm.trip.maxTeamcount= tripCookie.maxTeamcount;
                        vm.trip.startLocation = tripCookie.startLocation;
                        var expires = 30 * 60 * 1000;
                        var date = new Date(+new Date()+expires);
                        document.cookie = "trip=" + 'back' + ";expires=" + date.toUTCString();
                    }
                } catch(e) {
                    var expires = -10000;
                    var date = new Date(+new Date()+expires);
                    document.cookie = "trip=" + arr[2] + ";expires=" + date.toUTCString();
                    console.log(arr[2]);
                }
            }
            var date = $filter('date')(vm.trip.startTime,'yyyy-MM-dd');
            var startDay = new Date((date + " " + "00:00").replace(/-/g, "/"));
            var nowTime = new Date().getTime();
            if (nowTime > startDay.getTime()){
                vm.afterTripStartTime = true;
            }
        }
        vm.date = null;
        // console.log(vm.trip);
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        if (vm.trip == null || vm.trip.startType == null) {
            // vm.trip.startType = 1;
            vm.trip.startType = 3;
        }
        // skygreen: 第一阶段
        // if (vm.trip.startType == 1) {
        //     vm.trip.startType = 3;
        // }
        // skygreen: end
        if (vm.trip.startTime != null) {
            vm.date = vm.trip.startTime;
        }
        $scope.htmlTooltip1 = $sce.trustAsHtml('<p style="text-align: left">定时开启<br><br>在所选活动日期当天，到达指定时刻后，活动自动开启。活动当天支持教练端「一键开启」（仅教练组组长拥有权限</p>');
        $scope.htmlTooltip2 = $sce.trustAsHtml('<p style="text-align: left">仅教练端手动开启<br><br>在所选活动日期当天，仅能通过教练端「一键开启」功能开启活动（仅教练组组长拥有权限）<br><br>注:「一键开始」功能仅开启正式活动，测试战队不受此限制，请勿用于测试。</p>');
        $scope.htmlTooltip3 = $sce.trustAsHtml('<p style="text-align: left">口令开启(离线)<br><br>在所选活动日期当天，玩家在进入游戏时输入预设的口令即可开始游戏，口令验证无须联网（不支持教练端「一键开启」）</p>');
        $scope.htmlTooltip4 = $sce.trustAsHtml('<p style="text-align: left">免费版最多支持100人以内，如需更多人数，请将线路<a style="color:#FF224F;">转换为高级版</a>或<a style="color:#FF224F;">了解更多</a></p>');
        $scope.htmlTooltip5 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">在所选活动日期当天，活动可进入。<br>计时规则：在任意一个逃亡者进入游戏，即为活动正式开始，活动持续时间到达设置的活动时长，会自动结束。</p>');
        $scope.htmlTooltip6 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">玩家在进入游戏时输入预设的“行动口令”即可进入游戏。</p>');
        $scope.htmlTooltip7 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">到达指定时间后才可签到。</p>');
        $scope.htmlTooltip8 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">逃亡者各自为战，追踪者随机加入某个逃亡者的追踪小组内，组内集体作战。<br>组内的逃亡者被抓捕，追踪者可加入其他的追踪组，继续追踪。</p>');
        $scope.htmlTooltip9 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">活动初始，建议以2:1的人数比例分配角色。<br>活动过程中，追踪者与逃亡者的比例会变化，最大不超过8:1。</p>');
        $scope.htmlTooltip10 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">追踪者与逃亡者的距离小于一定范围，追踪者将看不到逃亡者的方向和距离。<br>不同的场地类型，该范围区间不同。</p>');
        $scope.htmlTooltip11 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">可视距离大，视野较开阔，遮挡物少。如：公园、街道。 <br>范围初始值：30米，组内每加入一个追踪者+10米，上限100米。</p>');
        $scope.htmlTooltip12 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">可视距离短，视野较狭窄，遮挡物较多。如：古镇、山地。 <br>范围初始值：10米，组内每加入一个追踪者+5米，上限50米。</p>');

        $scope.htmlTooltip15 = $sce.trustAsHtml('<p style="text-align: left;">组队设置<br>发布后不可修改<br><br>输入编号组队—输入相同编号的玩家进入同一队伍（组号最多支持4位数）。首个进入小队的为队长。 <br><br>单人无组队—无组队单人游玩。</p>');

        $scope.htmlTooltip20 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">系统自动将逃亡者分成若干组，每组最多3名逃亡者；追踪者随机加入一个组。<br> 同组的追踪者集体作战，可抓捕组内的所有逃亡者。</p>');
        $scope.htmlTooltip21 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">建议以追逃比为2:1的人数比例分配角色，且逃亡者总人数为3的倍数。<br> 每组最多3名逃亡者，超过3名会自动分配到其他组。</p>');
        $scope.htmlTooltip22 = $sce.trustAsHtml('<p style="text-align: left;width:155px;">活动初始，建议以追逃比为2:1的人数比例分配角色。 每组最多8名追踪者。</p>');




        if ( vm.trip.attribute == 'OTA' ) {
            var URL_ROOT = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/";
            vm.ueditorConfig = {
                serverUrl: URL_ROOT + "ueditor/controller",
                toolbars: [
                    ['paragraph', 'bold', 'simpleupload'
                    ]
                ],
                paragraph : {
                    'p': '',
                    'h1': '',
                },
                elementPathEnabled: false,
                allowDivTransToP: false,
                enableContextMenu: false,
                enableAutoSave: false,
                zIndex: 11000,
                catchRemoteImageEnable: false,
                xssFilterRules: false, //input xss过滤
                inputXssFilter: false, //output xss过滤
                outputXssFilter: false,
                lang: 'zh-cn',
                initialFrameWidth:570
            };
        }

        // <!--ng-ueditor="vm.ueditorConfig"-->
        // <!--ready="vm.ready($editor)"-->
        // <!--ng-change="vm.updateHtml()"-->
        // vm.ready = function(editorInstance){
        //     console.debug(
        //         'UEditor ' +
        //         editorInstance.uid +
        //         ' ready!'
        //     );
        // };
        // vm.descriptionHtml = "";
        // vm.updateHtml = function() {
        //     vm.descriptionHtml = $sce.trustAsHtml(vm.trip.description);
        // };

        vm.onFileSelect = onFileSelect;
        // vm.npcs = Npc.query();
        vm.itemsPerPage = 250;
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 10000;//上传视频限制，单位kb
        vm.showCropped = true;
        vm.cropper = 'default';
        // skygreen: 第一阶段
        if (vm.trip.leaderOnlyFlag == null) {
            vm.trip.leaderOnlyFlag = true;
        }
        // vm.trip.leaderOnlyFlag = true;
        if (vm.trip.isShowCharts == null) {
            vm.trip.isShowCharts = true;
        }
        vm.trip.teamset=String(vm.trip.teamset);
        // skygreen: end

        if($stateParams.title != null && $stateParams.title != ''){
            vm.trip.myTripModelId = $stateParams.id;
            vm.trip.myTripModelTitle =decodeURI($stateParams.title);
            vm.trip.platform = $stateParams.platform;
            if(vm.trip.platform && vm.trip.platform == 1){
                vm.trip.maxPeople = 100;
                vm.trip.startType = 3;
                vm.trip.leaderOnlyFlag = true;
            } else if (vm.trip.platform == 3) {
                vm.trip.ltWipNotLimit = true;
                vm.trip.leaderOnlyFlag = true;
            }else if (vm.trip.platform == 4) {
                vm.trip.startType = 3;
            }
            vm.tripModelsIdChange = true;
            vm.trip.status = 'EDIT';
        }else if(vm.trip.status == null){
            vm.trip.status = 'EDIT';
        }
        vm.changeImg = changeImg;
        function changeImg(){
            vm.trip.coverImg = null;
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }
        vm.clearStartCode = clearStartCode;
        function clearStartCode(){
            vm.trip.startCode = null;
        }

        vm.removeCoppedImg = removeCoppedImg;
        vm.removeMasCot = removeMasCot;

        function removeCoppedImg() {
            if (vm.trip.coverImg != null) {
                vm.trip.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function removeMasCot() {
            if (vm.trip.mascot != null) {
                vm.trip.mascot = null;
            }
        }
        vm.teamsetClick = teamsetClick;
        function teamsetClick(){
            if (vm.trip.teamset == 2||(vm.trip.teamset == 3&&vm.trip.teamMaxpeople==1)) {
                vm.trip.leaderOnlyFlag = true;
            }
        }

        vm.filterTeam = this.filterTeam;

        vm.filterUser = this.filterUser;

        vm.filterBadage = this.filterBadage;

        vm.filterTripModels = this.filterTripModels;

        this.filterTeam = function (search) {
            if (search) {
                vm.teams = TeamSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.filterTripModels = function (search) {
            if (search) {
                vm.tripModels = UserTripModelSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc'],
                    release: "0"
                });
            }
        };

        this.filterBadage = function (search) {
            if (search) {
                vm.badages = BadgesSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        //Add by Harlan
        //通过预计路程（Dt）和预计时间（Tm）计算难度系数（Hd）
        //难度系数计算公式：Hd = Dt/10 * 0.4 + Tm/1.5 * 0.6
        // function countWarStar() {
        //
        //     var Dt = vm.trip.eTripLong;
        //     var Tm = vm.trip.eTime;
        //     var Hd = Dt/25 + 0.4*Tm;
        //     // console.log(Hd.toFixed(2));
        //     var warStar = Hd.toFixed(2);
        //     if (warStar>5){
        //         vm.trip.warStar = 5;
        //     }else {
        //         vm.trip.warStar = warStar;
        //     }
        //
        // }

        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(res) {
                vm.account = res.data;
                console.log('account',vm.account);
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1) { //&& $.inArray("ROLE_SUPER",author)===-1)
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }

                    //判断该用户所属企业有没有公开报名活动权限
                    if (vm.account.eAccountId!=null){
                        EAccount.get({id:vm.account.eAccountId},function (res) {
                            if (res!=null&&res.signUpAuth){
                                vm.signUp = true;
                            }
                        },function (err) {
                            console.log(err);
                        });
                    }

                    if (vm.account.eAccountId != null){
                        getEaccountContractTime(vm.account.eAccountId);
                    }
                }
            });
        }
			//购买弹框
        vm.openBuydiv=openBuydiv;
        vm.closeBuydiv=closeBuydiv;
        vm.openBuy=0;
        function openBuydiv(){
            vm.openBuy=1;
        }
        function closeBuydiv(){
            vm.openBuy=0;
        }


        function getEaccountContractTime(id) {
            if (id != null){
                $http({
                    url: 'api/e-accounts/'+id,
                    method: 'GET'
                }).then(function (res) {
                    // console.log(res);
                    if (res != null){
                        if (res.data.contractTime!=null){
                            vm.contractTime = new Date(res.data.contractTime);
                        }else {
                            var startTime = new Date();
                            vm.contractTime = startTime;
                        }
                        vm.expireTime = new Date(res.data.expireTime);
                    }
                },function (err) {
                    console.log(err);
                });
            }
        }
            //设置爱闯闯代买保险默认为true
        vm.changeAttribute = changeAttribute;
        function changeAttribute() {
            // alert(vm.trip.attribute);
            if (vm.trip.attribute=='TIMERACE'){
                vm.trip.enableCertification = true;
                $scope.changeEnable = true;
            }else {
                $scope.changeEnable = false;
            }
            if (vm.trip.attribute=='PUBLIC'){
                vm.trip.price = 0;
                vm.date = vm.contractTime;
                vm.trip.startTime = vm.contractTime;
                vm.trip.endTime = vm.expireTime;
                // console.log(vm.trip.startTime);
                // console.log(vm.trip.endTime);
            }else {
                vm.date = null;
                vm.trip.startTime = null;
                vm.trip.endTime = null;
            }
            //切换活动状态以下三个字段置空
            vm.trip.teamMaxpeople = null;
            vm.trip.maxTeamcount = null;
            if(vm.trip.platform && vm.trip.platform == 1){
                vm.trip.maxPeople = 100;
                vm.trip.leaderOnlyFlag = true;
            }else{
                vm.trip.maxPeople = null;
            }
            // console.log(vm.trip);
            vm.trip.enableCertification = null;
            vm.trip.enableStart = true;
        }
        //设置默认未满员可开启
        if (vm.trip.enableStart==null || typeof vm.trip.enableStart==='undefined'){
            vm.trip.enableStart = true;
        }
        this.$onInit = function () {
            $(function () {
                if (vm.trip.id == 1 || vm.trip.id == 3) {
                    $(".team").css("display", "none");
                } else {
                    $(".team").css("display", "block");
                }
                checkCoverType(vm.trip.coverImg);
                if (vm.trip.myArtistId) {
                    UserExt.getUserByID(vm.trip.myArtistId).then(function (response) {
                        var user = response.data;
                        vm.trip.myArtistId = {id: user.id, login: user.login, firstName: user.firstName};
                        // console.log(vm.trip.myArtistId);
                    });
                }
                if (vm.trip.badageId) {
                    // console.log(vm.trip.badageId);
                    MyBadges.getBadgesById(vm.trip.badageId).then(function (response) {
                        var data = response.data;
                        // console.log(data)
                        vm.trip.badageId = {id: data.id , name: data.name};
                    });
                }
                // console.log(vm.trip);
                if (vm.trip.myTripModelId) {
                    vm.trip.myTripModel = {id:vm.trip.myTripModelId,title:vm.trip.myTripModelTitle};
                    // console.log(vm.trip.myTripModel);
                }

                /*for (var i = 0; i < isTeam.length; i++) {
                 if (isTeam[i] === id) {
                 $(".team").css("display","none");
                 }
                 }
                 $(".team").css("display","block");*/

                $("[name='show_score']").bootstrapSwitch('state');
                $('input[name="show_score"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.showScore = state;
                });

                $("[name='isEnableStart']").bootstrapSwitch('state');
                $('input[name="isEnableStart"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.enableStart = state;
                });

                $("[name='enableCertification']").bootstrapSwitch('state');
                $('input[name="enableCertification"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.enableCertification = state;
                });

                $("[name='ltWipNotLimit']").bootstrapSwitch('state');
                $('input[name="ltWipNotLimit"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.ltWipNotLimit = state;
                });

                // $("[name='leaderOnlyFlag']").bootstrapSwitch('state');
                // $('input[name="leaderOnlyFlag"]').on('switchChange.bootstrapSwitch', function (event, state) {
                //     vm.trip.leaderOnlyFlag = state;
                // });

                $("[name='gameStarted']").bootstrapSwitch('state');
                $('input[name="gameStarted"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.gameStarted = state;
                });
                $("[name='npcEnable']").bootstrapSwitch('state');
                $('input[name="npcEnable"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.npcEnable = state;
                });

                $("[name='is_pause']").bootstrapSwitch('state');
                if (!vm.trip.isPause) {
                    $("#pause_content_container").css("display", "none");
                }

                if (!vm.trip.pauseStartTime) {
                    var d = new Date();
                    vm.trip.pauseStartTime = d;
                }
                $('input[name="is_pause"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    if (state) {
                        $("#pause_content_container").css("display", "block");
                        if (!vm.trip.pauseStartTime) {
                            var d = new Date();
                            vm.trip.pauseStartTime = d;
                        }
                    } else {
                        $("#pause_content_container").css("display", "none");
                    }
                    vm.trip.isPause = state;
                });


                if ( vm.trip.attribute == 'OTA' ) {
                    UE.delEditor('field_description');
                    vm.descriptionUE = UE.getEditor('field_description', vm.ueditorConfig);
                    // 全屏控制事件
                    //https://ask.csdn.net/questions/207448
                    vm.descriptionUE.addListener('beforefullscreenchange', function (event, isFullScreen) {
                    });

                    vm.descriptionUE.addListener('fullscreenchanged', function (event, isFullScreen) {
                        // alert(isFullScreen?'全屏':'默认')
                        $('.modal-open .modal').css("position", "fixed");
                        if (isFullScreen) {
                            $('#hid').addClass("ribbon_hid").removeClass("ribbon");
                        } else {
                            $('#hid').addClass("ribbon").removeClass("ribbon_hid");
                        }
                    });
                }


            });
        };

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            console.log("--------save-----------");
            if (vm.trip.attribute !=null&&vm.trip.attribute !=""&& vm.trip.attribute !="TRACE"  && vm.trip.attribute != 'OUTWARD' && vm.trip.attribute != 'OUTTIMERACE') {
                vm.trip.maxPeople = vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
            }
            vm.isSaving = true;
            if(vm.trip.attribute == "OTA"){
                vm.trip.description = vm.descriptionUE.getContent();
                if(vm.trip.description.startsWith("<link")){
                    vm.trip.description = vm.trip.description.substring(vm.trip.description.indexOf(">") + 1);
                }
            }


            if(vm.trip.title==null || vm.trip.title.trim() == ""){
                alert("请设置活动名称");
                vm.isSaving = false;
                return;
            }

			/*vm.trip.eTime=parseInt(vm.trip.eTime);
			vm.trip.etime=vm.trip.eTime;*/
            // countWarStar();
            // if (vm.trip.isPause){
            //     if (typeof vm.trip.pauseStartTime === 'string' ) vm.trip.pauseStartTime = new Date(vm.trip.pauseStartTime);
            //     var pauseHour   = vm.trip.pauseStartTime.getHours();
            //     var pauseMinute = vm.trip.pauseStartTime.getMinutes();
            //     vm.trip.pauseStartTime = pauseHour+":"+pauseMinute;
            // }
            // console.log("--------save----2-------");
            if (vm.trip.attribute === 'COMMON'){
                vm.trip.teamMaxpeople = 1;
                vm.trip.maxTeamcount = 20000;
                var startDate = new Date();
                vm.date = startDate;
                vm.trip.startTime = startDate;
                vm.trip.endTime = null;
            }else if (vm.trip.attribute === 'PUBLIC'){
                //公开活动价格最低10元限制
                // if (vm.trip.price<10){
                //     alert("公开活动价格不得低于10元");
                //     vm.trip.price = 10;
                //     vm.isSaving = false;
                //     return;
                // }
            }else if (vm.trip.attribute=='OUTWARD' || vm.trip.attribute=='OUTTIMERACE'){
                //对外的业务默认未满员可开启
                vm.trip.enableStart = true;
            }

            if (vm.trip.myArtistId) {
                if (vm.trip.myArtistId.id) vm.trip.myArtistId = vm.trip.myArtistId.id;
            }

            if (vm.trip.badageId){
                if (vm.trip.badageId.id) vm.trip.badageId = vm.trip.badageId.id;
            }

            // if (vm.trip.myTripModel){
            //     if (vm.trip.myTripModelId){
            //         vm.trip.myTripModelId = vm.trip.myTripModel.id;
            //     }else {
            //         vm.trip.myTripModelId = vm.trip.myTripModel;
            //     }
            // }
            // console.log(vm.trip.myTripModel);
            if (vm.trip.myTripModel!=null){
                //活动绑定线路不为空继续执行
                if (vm.trip.myTripModelId){
                    if (vm.trip.myTripModel.id){
                        vm.trip.myTripModelId = vm.trip.myTripModel.id;
                    }else {
                        vm.trip.myTripModelId = vm.trip.myTripModel;
                    }
                } else {
                    vm.trip.myTripModelId = vm.trip.myTripModel;
                }
            }else {
                alert("活动必须引用线路，请选择活动所引用的线路");
                vm.isSaving = false;
                return;
            }
            if(vm.trip.platform != 4){
                if (vm.trip.coverImg !== null){
                    if (/\.(jpg|jpeg|png|bmp)$/.test(vm.trip.coverImg)){
                        var image = document.getElementById("file");
                        if (image != null){
                            var height = image.height;
                            var width = image.width;
                            var bi = width / height;
                            if (bi<2.8 || bi>3.2) {
                                // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                                alert("上传图片比例应为3:1");
                                if (vm.trip.coverImg != null) {
                                    vm.trip.coverImg = null;
                                }
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                } else {
                    alert("上传图片不能为空");
                    vm.isSaving = false;
                    return;
                }

            }

            var price = $('#field_price').val();
            if ( price < 0 ) {
                alert("价格不符合要求。");
                vm.trip.price = null;
                vm.isSaving = false;
                return;
            }
            var teamMaxPeople = $('#field_teamMaxpeople').val();
            if ( teamMaxPeople < 0 ) {
                alert("战队最大人数不符合要求。");
                vm.trip.teamMaxpeople = null;
                vm.isSaving = false;
                return;
            }
            var maxTeamCount = $('#field_maxTeamcount').val();
            if ( maxTeamCount < 0 ) {
                alert("战队数上限不符合要求。");
                vm.trip.maxTeamcount = null;
                vm.isSaving = false;
                return;
            }

            //创建线路时给某些字段赋默认值
            // vm.trip.status = 'EDIT';
            vm.trip.type = 'Normal';
            //创建线路时给UGC权限者赋值
            // if (vm.auth==='UGC'){
            //     vm.trip.attribute = 'OUTWARD';
            // }
            if(vm.trip.attribute==null&&vm.trip.platform!=4){
                alert("线路属性不能为空，请设置线路属性");
                vm.isSaving = false;
                return;
            }
            //判断结束时间是否在开始时间之后 及 开始时间，结束时间是否在当前时间之后
            if (vm.trip.attribute!='COMMON'){
                var date=$filter('date')(vm.date,'yyyy-MM-dd');
                if (vm.trip.startType != 1) {
                    vm.trip.startTime = "00:00";
                }
                if (vm.trip.platform == 4) {
                    vm.trip.startTime = "00:00";
                }
                var time=$filter('date')(vm.trip.startTime,'HH:mm');
                vm.trip.startTime = new Date((date + " " + time).replace(/-/g, "/"));
                if ((vm.trip.startTime!=null&&vm.trip.startTime!='underfine')&&(vm.trip.endTime!=null&&vm.trip.endTime!='underfine')){
                    var start = new Date(vm.trip.startTime).getTime();
                    var end = new Date(vm.trip.endTime).getTime();
                    if (end<=start){
                        alert("结束时间必须在开始时间之后，请重新录入结束时间！");
                        vm.trip.endTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    var now = new Date().getTime();
                    // if (start<now||end<now){
                    //     alert("活动开始时间，结束时间不能在当前时间之前！");
                    //     vm.trip.startTime = null;
                    //     vm.trip.endTime = null;
                    //     vm.isSaving = false;
                    //     return;
                    // }
                    if (end<now){
                        alert("活动结束时间不能在当前时间之前！");
                        vm.trip.endTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    // if (vm.trip.attribute == 'PUBLIC'){
                    //     var contractTime = null;
                    //     var expireTime = null;
                    //     if (vm.contractTime!=null&&vm.expireTime!=null){
                    //         contractTime = vm.contractTime.getTime();
                    //         expireTime = vm.expireTime.getTime();
                    //     }
                    //     if (start<contractTime || end>expireTime){
                    //         alert("活动开始时间及结束时间必须在合同起止时间范围内。");
                    //         vm.isSaving = false;
                    //         return;
                    //     }
                    // }
                }else {
                    //正则验证时间格式是否正确
                    var startTime = checkTime(vm.trip.startTime);
                    var endTime = checkTime(vm.trip.endTime);
                    if (startTime===false){
                        alert("请输入正确的开始时间格式!");
                        vm.trip.startTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    if(vm.trip.platform!=4){
                        if (endTime===false){
                            alert("请输入正确的结束时间格式!");
                            vm.trip.endTime = null;
                            vm.isSaving = false;
                            return;
                    	}
                    }


                }
                // console.log('开始时间：'+start+'结束时间：'+end);
            }
            //后台修改活动不对战队做修改，返回null即可
            vm.trip.hasTeams = null;
            vm.trip.hasNormalTeams = null;
            vm.trip.hasTestTeams = null;
            vm.trip.hasWebTeams = null;

            if(vm.trip.platform==4){
                vm.trip.teamMaxpeople =9;
            }

            if(vm.trip.platform==4 && vm.trip.terrainDiff==null){
                alert("请选择地图难度!");
                vm.isSaving = false;
                return;
            }

            if(vm.trip.platform==4 && vm.trip.traceType==null){
                alert("请选择组队模式!");
                vm.isSaving = false;
                return;
            }


            if(vm.trip.platform==4 && (vm.trip.etime < vm.trip.saftyTime)){
                alert("安全区开启时间不能大于活动时长,请重新输入");
                vm.trip.saftyTime =null;
                vm.isSaving = false;
                return;
            }

            if (vm.trip.teamset == 2) {
                vm.trip.teamMaxPeople = 1;
                vm.trip.maxTeamcount  = vm.trip.maxPeople;
            }


            if (vm.trip.id !== null) {
                Trip.update(vm.trip, onSaveSuccess, onSaveError);
            } else {
                Trip.save(vm.trip, onSaveSuccess, onSaveError);
            }
        }
        //正则验证时间格式
        function checkTime(str) {
            //console.log(str);
            str = String(str);
            str = str.replace(/ GMT.+$/, '');// Or str = str.substring(0, 24)
            var d = new Date(str);
            var a = [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()];
            for(var i = 0, len = a.length; i < len; i ++) {
                if(a[i] < 10) {
                    a[i] = '0' + a[i];
                }
            }
            str = a[0] + '-' + a[1] + '-' + a[2] + ' ' + a[3] + ':' + a[4] + ':' + a[5];
            var reDateTime = /^(?:19|20)[0-9][0-9]-(?:(?:[0][1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
            var isDateTime = reDateTime.test(str);
            return isDateTime;
        }

        function onSaveSuccess(result) {
            var reg = new RegExp("(^| )" + 'trip' + "=([^;]*)(;|$)");
            var arr = document.cookie.match(reg);
            if (arr != null) {
                var tripCookie = JSON.parse(arr[2]);
                if (tripCookie.id != null && tripCookie.id == vm.trip.id) {
                    var expires = -10000;
                    var date = new Date(+new Date()+expires);
                    document.cookie = "trip=" + arr[2] + ";expires=" + date.toUTCString();
                }
            }

            $scope.$emit('taskServiceApp:tripUpdate', result,{reload:true});
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('trip', null, { reload: 'trip' });
        }

        function onSaveError(result) {
            if (result.data != null && result.data.errorCode != null) {
                if (result.data.errorCode == -1 && vm.mContent == null) {
                    vm.mContent = "发布活动所需点数不足，请先前往充值。";
                } else if (result.data.errorCode == -2 && vm.mContent == null) {
                    vm.mContent = result.data.message;
                    vm.maxPeople = null;
                    vm.isSaving = false;
                    return;
                } else {
                    var expires = 24 * 60 * 60 * 1000;
                    var date = new Date(+new Date()+expires);
                    var tripCookie = {
                        id: vm.trip.id,
                        title: vm.trip.title,
                        description: vm.trip.description,
                        coverImg: vm.trip.coverImg,
                        maxPeople: vm.trip.maxPeople,
                        teamMaxpeople: vm.trip.teamMaxpeople,
                        maxTeamcount: vm.trip.maxTeamcount,
                        startLocation: vm.trip.startLocation,
                    };
                    document.cookie='trip=' + JSON.stringify(tripCookie)+ ';expires=' + date.toUTCString();
                    $uibModalInstance.close(true);
                    vm.mContent = null;
                }
            }
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
            $(".bootstrap-switch-off").css('z-index','1');
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {

                var fileType = compressedBlob.type;
                if (fileType!=null && typeof fileType!=='undefined'){
                    fileType = fileType.split('/')[1];
                }
                var imgSize = compressedBlob.size/1024;
                //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                if (fileType=='jpg'||fileType=='jpeg'||fileType=='png'||fileType=='bmp'){
                    // console.log(imgSize);
                    if (imgSize>imgLimit){
                        alert("图片大小超过"+imgLimit+"kb");
                        return;
                    }
                    vm.type = 'img';
                }else if ( name == 'coverVideo' && fileType=='mp4'){
                    if (imgSize>otherLimit){
                        alert("音视频格式只能为MP4,且大小不能超过"+otherLimit/1000+"M");
                        return;
                    }
                    vm.type = 'other';
                }else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }

                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    // var imgSize = compressedBlob.size/1024;
                    // console.log(imgSize);
                    // var file = data.file;
                    //
                    // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    //     // console.log(imgSize);
                    //     if (imgSize>imgLimit){
                    //         alert("图片大小超过"+imgLimit+"kb");
                    //         return;
                    //     }
                    //     vm.type = 'img';
                    // }else if (/\.(mp3|mp4)$/.test(file)){
                    //     if (imgSize>otherLimit){
                    //         alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                    //         return;
                    //     }
                    //     vm.type = 'other';
                    // }else {
                    //     alert("文件格式不正确，请重新上传！！");
                    //     return;
                    // }

                    //update the url
                    if (name == 'coverImg') {
                        vm.trip.coverImg = data.file;
                    } else if (name == 'mascot') {
                        vm.trip.mascot = data.file;
                    }else if(name == 'coverVideo'){
                        vm.trip.coverVideo = data.file;
                    }

                    $scope.myImage = vm.IMAGE_SERVER+vm.trip.coverImg;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };

            //TODO gif no compress
            if (uploadFile != null) {
                // Ahdin.compress({
                //     sourceFile: uploadFile
                //     // maxWidth: 1280,
                //     // maxHeight: 1000,
                //     // quality: 1
                // }).then(function (compressedBlob) {
                //     console.log('compressed image by ahdin.');
                uploadImageFile(uploadFile);
                // });
            }
        }

        vm.deleteCoverVideo=deleteCoverVideo;
        function deleteCoverVideo() {
            vm.trip.coverVideo = null;
        }

        //<!--AngularJs ngImgCrop图片裁剪，仅能正方形和圆形-->
        // $scope.myImage='';
        // $scope.myCroppedImage='';
        // angular.element(document.querySelector('#fileInput')).on('change',null);

        $scope.cropper = {};
        $scope.cropper.sourceImage = null;
        $scope.cropper.croppedImage   = null;
        $scope.bounds = {};
        $scope.bounds.left = 0;
        $scope.bounds.right = 0;
        $scope.bounds.top = 0;
        $scope.bounds.bottom = 0;

    }
})();
