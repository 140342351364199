(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('CommonTripTeamDeleteController',CommonTripTeamDeleteController);

    CommonTripTeamDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommonTripTeam'];

    function CommonTripTeamDeleteController($uibModalInstance, entity, CommonTripTeam) {
        var vm = this;

        vm.commonTripTeam = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CommonTripTeam.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
