(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('StealthTripDetailController', StealthTripDetailController);

    StealthTripDetailController.$inject = ['$filter','$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', '$state','TripGame',
        'getAvailableCouponsByEId','PublishStealthTrip','$sce'];

    function StealthTripDetailController($filter,$scope, $rootScope,Principal, $http, $stateParams, previousState, entity, $state, TripGame,
                                         getAvailableCouponsByEId,PublishStealthTrip,$sce) {
        var vm = this;
        vm.trip = entity;
        vm.time = "2098-12-30T16:00:00.000Z";
        vm.addTeamCount = 0;
        vm.isSaving = false;
        vm.previousState = previousState.name;
        vm.showTestButton = true;
        vm.showNormalButton = true;
        vm.showNormalButtonAll = true;
        vm.showWebButton = true;
        vm.afterTripStartTime = false; //当前时间是在活动开始日期0点之后
        vm.datePickerOpenStatus = {};
        var couponIds = [];
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        vm.editTrip = function(){
            $('#myModal1').modal('show');
        }

        $scope.htmlTooltip1 = $sce.trustAsHtml('<p style="text-align: left"><br>玩家在进入游戏时输入预设的口令即可开始游戏，活动无需设置日期，灵活控制，随时开启。</p>');
        $scope.htmlTooltip2 = $sce.trustAsHtml('<p style="text-align: left"><br>任何玩家开始游戏后，整场活动即自动置为开始状态。\n' +
            '活动在开始后5小时内有效，期间玩家可自由加入。\n' +
            '过期后活动失效，玩家无法加入或重新开局，正在进行的对战仍可继续。</p>');
        $scope.htmlTooltip3 = $sce.trustAsHtml('<p style="text-align: left"><br>即活动内包含的战场数。每个战场可容纳8人（4v4对战），每局对战结束后战场将重置（玩家被清出），玩家须重新选择战场加入。</p>');

        vm.toPublish = function () {
            if (vm.trip.title != null && vm.trip.startCode != null && vm.trip.maxTeamcount != null) {
                if (vm.addTeamCount == 0) {
                    var url = '/api/stealth/trip?startCode=' + vm.trip.startCode + '&title=' + vm.trip.title + "&tripId=" + vm.trip.id + "&maxTeamCount=" + vm.addTeamCount;
                    $http({
                        method: 'POST',
                        url: url
                    }).success(function () {
                        $('#myModal1').modal('hide');
                        $('.modal-backdrop').remove();
                        $state.go("stealth-trip-detail",{id: vm.trip.id},{reload:true});

                    }).error(function () {
                    });
                } else {
                    $('#myModal1').modal('hide');
                    if (vm.addTeamCount > 0) {
                        vm.payCoupon = vm.addTeamCount * 8;
                        vm.trip.maxTeamcount = vm.addTeamCount;
                    } else {
                        vm.payCoupon = vm.maxTeamcount * 8;
                    }
                    getCoupons();
                    $('#myModal2').modal('show');
                }
            }

        }

        function getCoupons(){
            getAvailableCouponsByEId.getCouponsByEId(vm.payCoupon).then(function (res) {
                vm.couponsData = res.data;
                if (res.data.availableCoupons != null){
                    vm.availableCoupons = res.data.availableCoupons;
                    vm.unavailableCoupons = res.data.unavailableCoupons;
                } else {
                    if (res.data.differenceCoupons != null){
                        var differenceCoupons = res.data.differenceCoupons;
                        toReCharge(differenceCoupons[0].availableCoupon);
                    } else {
                        toReCharge();
                    }

                }
            },function (err) {
                console.log(err);
            })
        }

        $().ready(function () {
            if (vm.couponsData!=null&&vm.couponsData!=""&&vm.availableCoupons.length>0){
                $('#'+vm.availableCoupons[0].id).prop("checked",true);
            }
        });

        //将选择的点券存到couponIds数组中
        vm.chooseCoupon = chooseCoupon;
        function chooseCoupon(id){
            var length = couponIds.length;
            var checked = $('#'+id)[0].checked;
            if (checked){
                couponIds[length] = id;
            }else {
                var index = couponIds.indexOf(id);
                if (index>-1){couponIds.splice(index,1)};
            }
            $('.errText').css('display','none');
        }


        vm.publishTrip = publishTrip;
        function publishTrip() {
            vm.isSaving = true;
            if (vm.availableCoupons.length <= 0) {
                toReCharge();
            } else {
                PublishStealthTrip.publish(couponIds,vm.trip.id,vm.trip.startCode,vm.trip.title,vm.trip.maxTeamcount).then(function () {
                    $(".modal-backdrop").remove();
                    $state.go("stealth-trip-detail",{id: vm.trip.id},{reload:true});
                }, function (err) {
                    $('#myModal2').modal('show');
                    vm.isSaving = false;
                    console.log(err);
                    if (err.data != null) {
                        if (err.data.errorCode == -2) {
                            selectedCouponsIsNotnough();
                        }
                    }
                });
            }
        }
        function toReCharge(difference) {
            if (difference != null) {
                vm.toReCharge = true;
                vm.showPublishTitle = false;
                vm.differenceCoupon = difference;
            } else {
                vm.mContent = "发布活动所需点数不足，请先前往充值。";
            }

            vm.mText = null;
            $('.showOrHide').css('display','none');
            vm.confirmPrePublish = confirm;

        }

        vm.goToRecharge = goToRecharge;
        function goToRecharge() {
            $(".modal-backdrop").remove();
            $state.go('e-account-my-purchase',{},{reload:true});
        }

        function selectedCouponsIsNotnough() {
            vm.errText = "所选的点券不足。";
            $('.errText').css('display','block');
        }

        vm.editTrip = function (tripId) {
            vm.isSaving = false;
            TripGame.get({id: tripId}, function (res) {
                if (res != null) {
                    var trip = res;
                    vm.trip.id = trip.id;
                    vm.trip.startCode = trip.startCode;
                    vm.trip.title = trip.title;
                    vm.trip.maxTeamcount = trip.maxTeamcount;

                    $('#myModal1').modal('show');
                }
            })
        }


        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (vm.account !== null) {
                    for (var i = 0; i < vm.account.authorities.length; i++) {
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN") {
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE") {
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }


        function clear() {
            $(".modal-backdrop").remove();
            vm.isSaving = false;
        }

        vm.ident=ident;
        function ident() {
            $(".modal-backdrop").remove();
            if (vm.account.eAccountId!=null){
                if (vm.account.eAccountConfirmEmail !== true){
                    window.setTimeout(function () {
                        $state.go("e-account-my.edit",{},{reload:true});
                    },300);
                }else {
                    window.setTimeout(function () {
                        $state.go("e-account-my",{},{reload:true});
                    },300);
                }
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }

        vm.showTeams = showTeams;
        function showTeams(param) {
            if (param.type == 'normal' && !vm.trip.hasNormalTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasNormalTeams = res.data;
                    vm.showNormalButton = false;
                },function (err) {
                    console.log(err);
                })
            }
        }

        vm.showAllTeams = showAllTeams;
        function showAllTeams(param) {
            $http({
                method: 'GET',
                url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type + '?reloadTeam=true'
            }).then(function (res) {
                vm.trip.hasNormalTeams = res.data;
                vm.showNormalButtonAll = false;
            },function (err) {
                console.log(err);
            })
        }


        var unsubscribe = $rootScope.$on('taskServiceApp:tripUpdate', function(event, result) {
            vm.trip = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.openEdidLocation = function (trip) {
            window.open(vm.account.acfeUrl + trip.myTripModelId + '?at=' + vm.account.password + '_coach');
        }
    }
})();


