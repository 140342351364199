(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelCheckDetailController', TripModelCheckDetailController);

    TripModelCheckDetailController.$inject = ['$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', 'TripModel', 'tripModelPublish', 'EAccountSearchByIDAndName','tripModelToWeb','$state'];

    function TripModelCheckDetailController($scope, $rootScope, Principal, $http, $stateParams, previousState, entity, TripModel, tripModelPublish, EAccountSearchByIDAndName,tripModelToWeb,$state) {
        var vm = this;

        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripModel = entity;
        vm.previousState = previousState.name;
        vm.showTripsButton = true;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;

        vm.getSelectedEAccount = getSelectedEAccount;
        function getSelectedEAccount() {
            $http({
                method: 'GET',
                url: '/api/trip-models/getShareEaccounts/' + vm.tripModel.id
            }).then(function (res) {
                if (res.data.errorCode == 1) {
                    vm.sharedToAll = true;
                } else {
                    vm.sharedToAll = false;
                    if (res.data != '') {
                        vm.selectedEAccounts = res.data;
                    }
                }
                // console.log(res);
            },function (err) {
                console.log(err);
            })

        }


        getAccount();
        function getAccount() {
            // Principal.identity().then(function(account) {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }
        vm.check = check;
        function check(id) {
            $http({
                method: 'GET',
                url: '/api/trip-models/check/'+id
            }).success(function (err) {
                if (err!=null){
                    console.log(err);
                    if (err.code===0){
                        alert("自检通过，您可以发布该线路");
                    }else if (err.code===-1){
                        vm.message = err.message+":";
                        if (err.hasLocationTasks===null||err.hasLocationTasks.length===0){
                            vm.empLocation = "该线路未设置任何点位";
                        }
                        if (err.b_errorPreCondition!==null&&err.b_errorPreCondition.length>0){
                            vm.errorPre = "任务前置条件错误："+JSON.stringify(err.b_errorPreCondition);
                        }
                        if (err.baseTaskCirculate!==null&&err.baseTaskCirculate.length>0){
                            vm.baseCir = "任务死循环："+JSON.stringify(err.baseTaskCirculate);
                        }
                        if (err.clueAndEnd!==null&&err.clueAndEnd.length>0){
                            vm.cluAndEnd = "线索与结束任务冲突："+JSON.stringify(err.clueAndEnd);
                        }
                        if (err.emptyLocationTasks!==null&&err.emptyLocationTasks.length>0){
                            vm.empLo = "空点位："+JSON.stringify(err.emptyLocationTasks);
                        }
                        if (err.endInPreCondition!==null&&err.endInPreCondition.length>0){
                            vm.endInPre = "结束任务在前置条件中："+JSON.stringify(err.endInPreCondition);
                        }
                        if (err.l_errorPreCondition!==null&&err.l_errorPreCondition.length>0){
                            vm.ErrorPreLo = "点位任务前置条件错误："+JSON.stringify(err.l_errorPreCondition);
                        }
                        if (err.locationTaskCirculate!==null&&err.locationTaskCirculate.length>0){
                            vm.loCir = "点位任务死循环："+JSON.stringify(err.locationTaskCirculate);
                        }
                        if (err.preConditionAndEnd!==null&&err.preConditionAndEnd.length>0){
                            vm.preConAndEnd = "任务既是前置条件又是结束任务："+JSON.stringify(err.preConditionAndEnd);
                        }
                    }
                }
            }).error(function(err){
                console.log(err);

            });
        }
        vm.mTitle = "发布线路";
        vm.mContent = "确定要发布线路 "+vm.tripModel.id+" ?";
        vm.publish = publish;
        function publish(tripId) {
            var param = {tripId:tripId,status:'PUBLISH'};
            tripModelPublish.tmPublish(param).success(function(data){
                // console.log(data);
                vm.tripModel = TripModel.get({id:vm.tripModel.id});
                $('#myModal1').modal('hide');
            });
            // var publish = tripModelPublish.tmPublish({id:tripId});
            // console.log(publish);
            // var x = confirm("确定要发布线路"+tripId+"?");
            // if (x){
            //     $http({
            //         method: 'PUT',
            //         url: '/api/trip-model/updateStatus/'+tripId+'/PUBLISH'
            //     }).success(function () {
            //         console.log();
            //     }).error(function(err){
            //         console.log(err);
            //     });
            //     window.location.reload();
            //     return;
            // }else {
            //     return;
            // }
        }

        vm.showTrips = showTrips;
        function showTrips(id) {
            if (id!=null && vm.tripModel.trips==null){
                $http({
                    method: 'GET',
                    url: '/api/trip-models/getTripByTripModelId/' + id
                }).then(function (res) {
                    // console.log(res);
                    if (res!=null && vm.tripModel.trips==null){
                        vm.tripModel.trips = res.data;
                        vm.showTripsButton = false;
                    }
                },function (err) {
                    console.log(err);
                })
            }
        }

        vm.showQrCodeAll = showQrCodeAll;
        function showQrCodeAll() {
            var url = '';
            if (vm.tripModel.tradableFlag){
                url='/api/trip-models/hide/getTripByTripModelId/' + vm.tripModel.id
            }

            $http({
                method: 'GET',
                url: url,
            }).then(function (res) {
                // console.log(res);
                if (res!=null && res.data!=null){
                    if (res.data.length>0){
                        // console.log(res.data);
                        var tripId = res.data[0].id;
                        var location = window.location.host;
                        vm.qrCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+tripId;
                        vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+tripId+"&istest=1";
                        document.getElementById("qrCode").style.display = 'block';
                    }
                }
            },function (err) {
                console.log(err);
            })
        }

        this.filterEAccount = function (search) {
            if(search){
                vm.eaccounts = EAccountSearchByIDAndName.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
        };

        var ids = [];
        vm.toConfirm = function toConfirm() {
            if (vm.sharedToAll) {
                $http({
                    method: 'POST',
                    url: '/api/trip-models/shareToAllEAccounts/'+ vm.tripModel.id,
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    $('#share_to_eaccount').modal('hide');
                }, function (err) {
                    //console.log(err);
                    $('#share_to_eaccount').modal('hide');
                })


            } else {
                // console.log(vm.selectedEAccounts);
                for (var i = 0; i < vm.selectedEAccounts.length; i++) {
                    ids[i] = vm.selectedEAccounts[i].id;
                }
                // console.log(ids);
                $http({
                    method: 'POST',
                    url: '/api/trip-models/setShareEaccounts/' + vm.tripModel.id,
                    data: ids
                }).then(function (res) {
                    if (res.data.errorCode = 0) {
                        vm.tripModel.share_to_eaccount = res.data.message;
                    }
                    // console.log(res);
                    $('#share_to_eaccount').modal('hide');
                }, function (err) {
                    // console.log(err);
                    $('#share_to_eaccount').modal('hide');
                })
            }
        }

        vm.tripModelCheckPass = tripModelCheckPass;
        function tripModelCheckPass() {
            var param = {id:vm.tripModel.id,type:1,reason:null};
            tripModelToWeb.toWeb(param).success(function (res) {
                console.log(res);
                $state.go('trip-model-check-detail',{id:vm.tripModel.id},{reload:true})
            })
        }
        vm.tripModelCheckNotPass = tripModelCheckNotPass;
        function tripModelCheckNotPass() {
            if (vm.tripModel.reason == null){
                alert("请输入不通过原因！！");
            }else {
                var param = {id:vm.tripModel.id,type:2,reason:vm.tripModel.reason};
                tripModelToWeb.toWeb(param).success(function (res) {
                    console.log(res);
                    $state.go('trip-model-check-detail',{id:vm.tripModel.id},{reload:true})
                })
            }
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:tripModelUpdate', function(event, result) {
            vm.tripModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
