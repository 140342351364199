(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripModelSearch', TripModelSearch);

    TripModelSearch.$inject = ['$resource'];

    function TripModelSearch($resource) {
        var resourceUrl =  'api/_search/trip-models/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripModelAdminSearch', TripModelAdminSearch);

    TripModelAdminSearch.$inject = ['$resource'];

    function TripModelAdminSearch($resource) {
        var resourceUrl =  'api/_search/admin/trip-models/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
