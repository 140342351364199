(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider.state('refresh-world-rank', {
            parent: 'admin',
            url: '/refresh-world-rank',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.admin.refresh-world-rank'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/refresh-world-rank/refresh-world-rank.html',
                    controller: 'RefreshController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // entity:['$http','$scope', function($http, $scope) {
                //     // Simple GET request example:
                //     $http({
                //         method: 'GET',
                //         url: 'api/trips/getTripsCurrentData'
                //     }).then(function successCallback(response) {
                //         // this callback will be called asynchronously
                //         // when the response is available
                //         $scope.content = response;
                //     }, function errorCallback(response) {
                //         // called asynchronously if an error occurs
                //         // or server returns response with an error status.
                //     });
                // }],
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }]
            }
        });
    }
})();
