(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('BombTripSearch', BombTripSearch);

    BombTripSearch.$inject = ['$resource'];

    function BombTripSearch($resource) {
        var resourceUrl =  'api/bomb/_search/trips/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('PublishBombTrip', PublishBombTrip);

    PublishBombTrip.$inject = ['$http'];

    function PublishBombTrip ($http) {

        return{
            publish:function(couponIds,tripId,startCode,title,testPaperCode){
                var url = '/api/trip-game/publish?title='+title+'&startCode='+startCode+'&testPaperCode='+testPaperCode+'&type=BOMB';
                // if (couponIds.length > 0) {
                    url = url + "&couponIds=" + couponIds;
                // }
                if (tripId != null) {
                    url = url + "&tripId=" + tripId;
                }
                return $http({
                    method: 'POST',
                    url: url
                });
            }

        };
    }
})();
// (function() {
//     'use strict';
//     angular
//         .module('taskServiceApp')
//         .factory('SaveBombTrip', SaveBombTrip);
//
//     SaveBombTrip.$inject = ['$http'];
//
//     function SaveBombTrip ($http) {
//
//         return{
//             save:function(couponIds,tripId,startCode,title,maxTeamCount){
//                 var url = '/api/trip-game/publish?title='+title+'&startCode='+startCode+'&maxTeamCount='+maxTeamCount+'&type=STEALTH';
//                 if (couponIds.length > 0) {
//                     url = url + "&couponIds=" + couponIds;
//                 }
//                 if (tripId != null) {
//                     url = url + "&tripId=" + tripId;
//                 }
//                 return $http({
//                     method: 'POST',
//                     url: url
//                 });
//             }
//
//         };
//     }
// })();

