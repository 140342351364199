(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('FinishEAccountDetailController', FinishEAccountDetailController);

    FinishEAccountDetailController.$inject = ['$scope', '$state','$rootScope', '$stateParams', 'previousState', 'entity', 'MyEAccountConfirm','$http'];

    function FinishEAccountDetailController($scope,$state, $rootScope, $stateParams, previousState, entity, MyEAccountConfirm,$http) {
        var vm = this;
        vm.eAccount = {};
        vm.previousState = previousState.name;
        vm.Comfirmsave =Comfirmsave;
        activityEmail();
        function activityEmail() {
            $http({
                method: 'POST',
                url: '/api/e-accounts/e-mail/confirm?key='+$stateParams.key,
            }).success(function (data) {
                //
                vm.eAccount.emailState="登录查看";
                console.log(data);
            }).error(function (err) {
                vm.eAccount.emailState="登录查看";
                console.log(err);
            });
        }

        function Comfirmsave() {
            // vm.isSaving = true;
            // MyEAccountConfirm.confirmEAccount(vm.eAccount,onSaveSuccess,onSaveError)
            $state.go("e-account-my")
        }
        function onSaveSuccess () {
            // console.log("save");
            vm.isSaving = false;
            $state.go("e-account-my")
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
