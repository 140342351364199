(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('register', {
            parent: 'app',
            url: '/register',
            data: {
                authorities: [],
                pageTitle: 'register.title'
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/register/register.html',
                    controller: 'RegisterController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('eAccount');
                    return $translate.refresh();
                }]
            }
        });
        $stateProvider.state('register-login', {
            parent: 'app',
            url: '/register-login',
            data: {
                authorities: [],
                pageTitle: '完善信息'
            },
            views: {
                'navbar@': {
                },
                'content@': {
                    templateUrl: 'app/account/register/register-login.html',
                    controller: 'RegisterLoginController',
                    controllerAs: 'vm'
                }
            },
            params: {
                id: '',
                login:'',
                pwd: ''
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('eAccount');
                    return $translate.refresh();
                }],
            }
        });
        $stateProvider.state('forget-pwd', {
            parent: 'account',
            url: '/forget',
            data: {
                authorities: [],
                pageTitle: '忘记密码'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/forget-pwd.html',
                    controller: 'ForgetPwdController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('eAccount');
                    return $translate.refresh();
                }]
            }
        });
        $stateProvider.state('perfect-information', {
            parent: 'account',
            url: '/perfect-information',
            params: {
                login: null,
                password: null
            },
            data: {
                authorities: [],
                pageTitle: '兔子完善资料'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/register/perfect-information.html',
                    controller: 'PerfectInformationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('eAccount');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
