(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('Trip', Trip);

    Trip.$inject = ['$resource', 'DateUtils'];

    function Trip ($resource, DateUtils) {
        var resourceUrl =  'api/trips/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripGame', TripGame);

    TripGame.$inject = ['$resource', 'DateUtils'];

    function TripGame ($resource, DateUtils) {
        var resourceUrl =  'api/trip/game/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripMonitor', TripMonitor);

    TripMonitor.$inject = ['$resource', 'DateUtils'];

    function TripMonitor ($resource, DateUtils) {
        var resourceUrl =  '/api/trips/getTripsByStatus';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripToday', TripToday);

    TripToday.$inject = ['$resource', 'DateUtils'];

    function TripToday ($resource, DateUtils) {
        var resourceUrl =  '/api/trips/getTripsToday';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripExt', TripExt);

    TripExt.$inject = ['DateUtils', '$http'];

    function TripExt (DateUtils, $http) {

        return {
            getRanksByTrip: function (tripId) {
                console.debug('getRankByTrip:' + tripId);
                return $http.get('/api/trips/'+ tripId +'/rank_new').then(function (response) {
                    return (response.data);
                });
            },
            getPhotosByTrip: function (tripId) {
                console.debug('export-photos:' + tripId);
                return $http.get('/api/trips/'+ tripId +'/export-photos').then(function (response) {
                    return (response.data);
                });
            },
            zipPhotosByTrip: function (tripId) {
                console.debug('zip-photos:' + tripId);
                return $http.get('/api/trips/'+ tripId +'/export-photos-videos/zip').then(function (response) {
                    // var file = new Blob([ response.data ], {
                    //     type : 'image/png'
                    // });
                    // //trick to download store a file having its URL
                    // var fileURL = URL.createObjectURL(file);
                    // var a         = document.createElement('a');
                    // a.href        = fileURL;
                    // a.target      = '_blank';
                    // a.download    = 'test.png';
                    // document.body.appendChild(a);
                    // a.click();
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripPrePublish', TripPrePublish);

    TripPrePublish.$inject = ['$resource'];

    function TripPrePublish ($resource) {
        var resourceUrl =  '/api/trip-models/check/:id';

        return $resource(resourceUrl, {}, {
            'check': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripUpdateStatus', tripUpdateStatus);

    tripUpdateStatus.$inject = ['$http'];

    function tripUpdateStatus ($http) {
        return{
            updateStatus:function(param){
                return $http({
                    method: 'PUT',
                    url: '/api/trips/updateStatus/'+param.tripId+'/'+param.status
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripUpdateStatusTrade', tripUpdateStatusTrade);

    tripUpdateStatusTrade.$inject = ['$http'];

    function tripUpdateStatusTrade ($http) {
        return{
            updateStatusTrade:function(param){
                return $http({
                    method: 'PUT',
                    url: '/api/trips/updateStatus/confirm/'+param.tripId+'/'+param.status+"?sessionId="+param.sessionId+"&validationCode="+param.validationCode
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripVerifyStatus', TripVerifyStatus);

    TripVerifyStatus.$inject = ['$http'];

    function TripVerifyStatus ($http) {
        return{
            tripVerifyStatus:function(param){
                return $http({
                    method: 'GET',
                    url: '/api/trips/verifyStatus/'+param.tripId
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripGetEAccount', TripGetEAccount);

    TripGetEAccount.$inject = ['$http'];

    function TripGetEAccount ($http) {
        return{
            getEAccount:function(param){
                return $http({
                    method: 'GET',
                    url: '/api/trips/getEAccount/'+param.tripId
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('getAvailableCoupons', getAvailableCoupons);

    getAvailableCoupons.$inject = ['$http'];

    function getAvailableCoupons ($http) {
        return{
            getCoupons:function(tripId){
                return $http({
                    method: 'GET',
                    url: '/api/e-accounts/availableCoupons/'+tripId
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('getAvailableCouponsByEId', getAvailableCouponsByEId);

    getAvailableCouponsByEId.$inject = ['$http'];
    function getAvailableCouponsByEId ($http) {
        return{
            getCouponsByEId:function(coupons){
                return $http({
                    method: 'GET',
                    url: '/api/e-accounts/availableCoupons?coupons=' + coupons
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('PublishTrip', PublishTrip);

    PublishTrip.$inject = ['$http'];

    function PublishTrip ($http) {
        return{
            publishTrip:function(couponIds,tripId){
                return $http({
                    method: 'POST',
                    url: '/api/trips/publishTrip?tripId='+tripId,
                    data: couponIds
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripEdit', tripEdit);

    tripEdit.$inject = ['$http'];

    function tripEdit ($http) {
        return{
            tEdit:function(param){
                return $http({
                    method: 'PUT',
                    url: '/api/trips/updateStatus/confirm/'+param.tripId+'/'+param.status
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('PublishTripTrade', PublishTripTrade);

    PublishTripTrade.$inject = ['$http'];

    function PublishTripTrade ($http) {
        var couponIds = [-1];
        return{
            publishTripTrade:function(tripId, eAccountId, code, sessionId){
                return $http({
                    method: 'POST',
                    url: '/api/trips/publishTrip?tripId='+tripId+"&eAccountId="+eAccountId+"&validationCode="+code+"&sessionId="+sessionId,
                    data: couponIds
                });
            }
        };
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('EAccountSearch', EAccountSearch);

    EAccountSearch.$inject = ['$resource'];

    function EAccountSearch($resource) {
        var resourceUrl =  'api/_search/e-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('EAccountV2Search', EAccountV2Search);

    EAccountV2Search.$inject = ['$resource'];

    function EAccountV2Search($resource) {
        var resourceUrl =  'api/_search/e-accounts/v2/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
//申请发布官网的活动列表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripToWebList', tripToWebList);

    tripToWebList.$inject = ['$resource', 'DateUtils'];

    function tripToWebList ($resource, DateUtils) {
        var resourceUrl =  'api/trip/check/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
//审核管理员审核线路
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripToWebCheck', tripToWebCheck);

    tripToWebCheck.$inject = ['$http'];

    function tripToWebCheck ($http) {
        return{
            toWebCheck:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip/toWeb/'+param.id+'/'+param.type+'?reason='+param.reason,
                    data: param.tripWeb
                });
            }
        };
    }
})();
//设计师下架活动
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripNotToWeb', tripNotToWeb);

    tripNotToWeb.$inject = ['$http'];

    function tripNotToWeb ($http) {
        return{
            notToWeb:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip/toWeb/'+param.id+'/'+param.type+"?sessionId="+param.sessionId+"&validationCode="+param.validationCode,
                    data: param.tripWeb
                });
            }
        };
    }
})();
//设计师申请上架活动
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripToWeb', tripToWeb);

    tripToWeb.$inject = ['$http'];

    function tripToWeb ($http) {
        return{
            toWeb:function(param){
                // console.log(param);
                return $http({
                    method: 'POST',
                    url: '/api/trip/toWeb/'+param.id+'/'+param.type+"?sessionId="+param.sessionId+"&validationCode="+param.validationCode+"&eAccountId="+param.eAccountId,
                    data: param.tripWeb
                });
            }
        };
    }
})();
//获取官网的活动详情
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('tripGetFromWeb', tripGetFromWeb);

    tripGetFromWeb.$inject = ['$http'];

    function tripGetFromWeb ($http) {
        return{
            getFromWeb:function(tripId){
                return $http({
                    method: 'GET',
                    url: '/api/trip/fromWeb/detail?id='+tripId
                });
            }
        };
    }
})();
//获取官网的活动标签
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripLabelGetFromWeb', TripLabelGetFromWeb);

    TripLabelGetFromWeb.$inject = ['$http'];

    function TripLabelGetFromWeb ($http) {
        return{
            getLabel:function(){
                return $http({
                    method: 'GET',
                    url: '/api/trip/fromWeb/labels'
                });
            }
        };
    }
})();
//获取官网活动人数列表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripPeopleNumGetFromWeb', TripPeopleNumGetFromWeb);

    TripPeopleNumGetFromWeb.$inject = ['$http'];

    function TripPeopleNumGetFromWeb ($http) {
        return{
            getPeopleNum:function(){
                return $http({
                    method: 'GET',
                    url: '/api/trip/fromWeb/peopleNum'
                });
            }
        };
    }
})();
//获取活动详情，不包括关联信息
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripModelBaseInfo', TripModelBaseInfo);

    TripModelBaseInfo.$inject = ['$http'];

    function TripModelBaseInfo ($http) {
        return{
            getDetail:function(tripId){
                return $http({
                    method: 'GET',
                    url: '/api/trip-model/baseInfo/'+tripId
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('RabbitTrip', RabbitTrip);

    RabbitTrip.$inject = ['$resource', 'DateUtils'];

    function RabbitTrip ($resource, DateUtils) {
        var resourceUrl =  '/api/trip-model/check/pass/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
//获取活动详情
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripDetail', TripDetail);

    TripDetail.$inject = ['$http'];

    function TripDetail ($http) {
        return{
            getDetail:function(tripId){
                return $http({
                    method: 'GET',
                    url: '/api/trips/'+tripId
                });
            }
        };
    }
})();
//保存可报名的公开活动
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripRegisterInfo', TripRegisterInfo);

    TripRegisterInfo.$inject = ['$http'];

    function TripRegisterInfo ($http) {
        return{
            saveInfo:function(param){
                return $http({
                    method: 'POST',
                    url: '/api/trip/publish-register-detail/save',
                    data: param.trip
                });
            }
        };
    }
})();
// 公开报名活动详情
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripRegisterDetailMethod2', TripRegisterDetailMethod2);

    TripRegisterDetailMethod2.$inject = ['$http'];

    function TripRegisterDetailMethod2 ($http) {
        return{
            getDetail:function(tripId){
                return $http({
                    method: 'GET',
                    url: '/api/trip/publish-register-detail/get/'+tripId
                });
            }
        };
    }
})();
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripRegisterDetail', TripRegisterDetail);

    TripRegisterDetail.$inject = ['$resource'];

    function TripRegisterDetail ($resource) {
        var resourceUrl =  'api/trip/publish-register-detail/get/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
//公开报名订单报表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripRegisterOrderList', TripRegisterOrderList);

    TripRegisterOrderList.$inject = ['$resource'];

    function TripRegisterOrderList ($resource) {
        var resourceUrl =  'api/trip/publish-register/order/list/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
//公开报名活动列表
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripRegisterList', TripRegisterList);

    TripRegisterList.$inject = ['$resource'];

    function TripRegisterList ($resource) {
        var resourceUrl =  'api/trip/publish-register/list';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

