(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RabbitController', RabbitController);

    RabbitController.$inject = ['$scope', '$timeout', 'Principal', '$state', '$uibModal', 'Auth','$http','$sce','$interval'];

    function RabbitController ($scope, $timeout, Principal, $state, $uibModal, Auth, $http, $sce,$interval) {
        var vm = this;
        vm.focusIndex = 2;
        vm.authenticationError = false;
        vm.focus = focus;
        // vm.location = window.location.host;
        //
        // vm.protocol = window.location.protocol;window.location.protocol+"//"+window.location.host
        getURL();
        vm.data = [
            // {id: 1},
            // {id: 2},
            // {id: 3},
            // {id: 4},
            // {id: 5},
            // {id: 6},
            // {id: 7},
            // {id: 8}
        ];
        vm.isShowQR = '';
        vm.images_service = 'https://image.itasktour.com';

        vm.showQRimg = showQRimg;
        vm.hideQRimg = hideQRimg;
        vm.login = login;
        vm.enterEvent = enterEvent;
        vm.creatArray = creatArray; //生成黄色星星
        vm.createGreyAarry = createGreyAarry; //生成灰色星星
        vm.getDateDiff = getDateDiff;//时间换算


        function getURL() {
            // console.log(window.location.protocol);
            // console.log(window.location.host);
            // console.log(window.location.href);
            var local_href = window.location.href;
            // if(local_href.indexOf('dev.itasktour.com') != -1) {
            //     vm.protocol = 'http:';
            //     vm.location = 'dev.itasktour.com';
            //     vm.location_QR = 'dev.itasktour.com';
            // }else if(local_href.indexOf('v3.itasktour.com') != -1)  {
            //     vm.protocol = 'https:';
            //     vm.location = 'www.itasktour.com';
            //     vm.location_QR = 'v3.itasktour.com';
            // }else if(local_href.indexOf('deve.itasktour.com') != -1){
            //         vm.protocol = 'http:';
            //         // console.log(vm.protocol);
            //         vm.location = 'dev.itasktour.com';
            //         vm.location_QR = 'dev.itasktour.com';
            // } else {
            //     vm.protocol = 'http:';
            //     vm.location = '127.0.0.1';
            //     vm.location_QR = '127.0.0.1';
            // }

            if(local_href.indexOf('api.jngysz.com') != -1)  {
                vm.protocol = 'https:';
                vm.location = 'api.jngysz.com';
                vm.location_QR = 'api.jngysz.com';
            }
        }

        /**
         * 监听回车键 登录
         */
        function enterEvent(e) {
            // console.log(e);
            var keycode = window.event?e.keyCode:e.which;
            if (keycode == 13)  {
                login(e);
            }
        }
        /**
         * 获取列表数据 function
         */
        getListData();
        //
        // var img_width = $(".pc_dt0").width();
        // console.log(img_width);
        // window.onresize=function(){
        //     autoHeight()
        // }
        // /*
        // *
        // *
        // * */
        // // autoHeight()
        // function autoHeight() {
        //     var img_width = $(".pc_dt0").width();
        //     console.log(img_width);
        //     var  img_height = img_width*0.56;
        //     $(".down dt .cover_img ").css('height',img_height);
        // }

        function  getListData() {
            vm.artistId = '';
            vm.page = 0;
            vm.size = 8;
            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/list?'+'artistId='+vm.artistId+'&'+'page='+vm.page+'&size='+vm.size
            }).then(
                function (res) {
                    // console.log(res.data);
                    vm.data = res.data;
                    // var img_width = $(".pc_dt0").width();
                    // console.log(img_width);
                    // var  img_height = img_width*0.56;
                    // $(".down dt .cover_img ").css('height',img_height);
                }
            )
        }

        /**
         *生成黄色星星
         */
        function creatArray(num) {
            var newStarArray = [];
            for(var i=0;i<Math.floor(num);i++){
                newStarArray.push(i);
            }
            return newStarArray;
        }

        /**
         * 生成灰色星星
         */
        function createGreyAarry(num) {
            var newStarArray = [];
            for (var i = 0; i < Math.ceil(num); i++) {
                newStarArray.push(i);
            }
            return newStarArray;
        }
        /**
         * login 登录
         */
        function login(event) {
            // alert("");
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password
            }).then(function (data) {
                // getAccount();
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('guide');
                }
                $state.go('guide');
                // if (Auth.getPreviousState()) {
                //     var previousState = Auth.getPreviousState();
                //     Auth.resetPreviousState();
                //     $state.go(previousState.name, previousState.params);
                // }
                // console.log(data);
                // if (data!=null){
                //     if (data.data.access_token!=null){
                //         // getVersion();
                //     }
                // }
            }).catch(function (err) {
                // console.log(err);
                vm.authenticationError = true;
            });
        }
        /**
         * 切换tabs
         * @param index
         */

        function focus(index) {
            vm.focusIndex = index;
            if (index == 1) {
                getQrCodeStr();
            }
        }

        function getQrCodeStr() {
            $http({
                methods: 'GET',
                url: 'api/account/rabbit-user/qrcode-login/random'
            }).then(
                function (res) {
                    console.log(res.data);
                    if (res.data.code == 0) {
                        vm.qrCodeStr = res.data.data;
                        vm.qrCode = "http://www.baidu.com/"+res.data.data;
                        console.log(vm.qrCodeStr);
                    } else {
                        console.log("Get random failed!")
                    }
                })
        }

        /**
         * 每3s轮询一次，检查是否有用户扫码登录当前网页（轮循90s(30次)后结束）
         */
        var stop = $interval(function() {
            if (vm.qrCodeStr != null) {
                console.log("轮询");
                $http({
                    method: 'GET',
                    url: '/api/account/rabbit-user/qrcode-login/login?randomStr=' + vm.qrCodeStr,
                }).success(function (res) {
                    console.log(res);
                    if (res.code==0){
                        $interval.cancel(stop);
                        console.log("登录成功");
                        $state.go('guide');
                    } else if (res.code==-2){
                        console.log("二维码已过期");
                        getQrCodeStr();

                    }
                }).error(function(err){
                    console.log(err);
                    $interval.cancel(stop);
                });
            }},3000,30);
        stop.then(function () {
            console.log("--------------");
        });
        //离开页面后停止轮询
        $scope.$on('$destroy',function(){
            $interval.cancel(stop);
        });

        /**
         * 显示线路体验demo二维码function
         */
        function showQRimg(index) {
            // console.log('show',index);
            vm.isShowQR = index;
            $(".down .pc_div").eq(index).find("#a-mask").addClass('a-mask');
        }

        /**
         * 隐藏线路体验demo二维码function
         * @param index
         */
        function hideQRimg(index) {
            // console.log('hide ',index);
            vm.isShowQR = index;
            $(".down .pc_div").eq(index).find("#a-mask").removeClass('a-mask');

        }

        /**
         *
         */
        // vm.showQrCode = showQrCode;
        // function showQrCode(id) {
        //     // vm.qrCode = "itasktour://gototrip?tripid="+id;
        //     var location = window.location.host;
        //     vm.qrCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+id;
        //     vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+id+"&istest=1";
        //     document.getElementById("qrCode").style.display = 'block';
        //     // document.getElementById("qrTestCode").style.display = 'block';
        // }

        /**
         * 换算时间function
         */
        function getDateDiff(updateTime){
            // console.log(updateTime);
            var minute = 1000 * 60;
            var hour = minute * 60;
            var day = hour * 24;
            var halfamonth = day * 15;
            var month = day * 30;
            var now = new Date().getTime();
            var commit = updateTime;
            commit = commit.replace(/-/g,"/");
            commit = commit.replace(/T/g," ");
            commit.substring(commit.length-5);
            var update = new Date(commit).getTime();//2018-08-01 09:56:53

            var diffValue = now-update;
            if(diffValue < 0){
                return;
            }
            var monthC = diffValue/month;
            var weekC = diffValue/(7*day);
            var dayC = diffValue/day;
            var hourC = diffValue/hour;
            var minC = diffValue/minute;
            var result = null;
            if(monthC>=1){
                result="" + parseInt(monthC) + "个月前";
            }
            else if(weekC>=1){
                result="" + parseInt(weekC) + "周前";
            }
            else if(dayC>=1){
                result=""+ parseInt(dayC) +"天前";
            }
            else if(hourC>=1){
                result=""+ parseInt(hourC) +"小时前";
            }
            else if(minC>=1){
                result=""+ parseInt(minC) +"分钟前";
            }else
                result="刚刚";
            return result;
        }
    }
})();
