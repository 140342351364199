/**
 * Created by snowzhang on 2017/5/31.
 */
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ResetController', ResetController);

    ResetController.$inject = ['$http','$scope'];

    function ResetController ($http,$scope) {
        var vm = this;
        console.log("ResetController");
        reset();

        function reset(){
            $http({
                method:'get',
                url:'api/trips/getTripsCurrentData'
            }).success(function (data) {
                $scope.result = data;
            }).error(function(err){
                $scope.result = "重置失败";
            });
        }
    }
})();

