
(function () {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('TsTripPayController', TsTripPayController);

    TsTripPayController.$inject = ['$timeout', '$scope', '$http', '$stateParams','entity','$state','Principal','TsTripPay','$cookies'];

    function TsTripPayController ($timeout, $scope, $http, $stateParams, entity, $state,Principal,TsTripPay,$cookies) {

        var vm = this;
        vm.clear = clear;
        vm.publishSuccess = false;
        vm.trip = entity;
        vm.checked = false;
        var pay = 0;
        var sPrice = 2000;
        var payType = "alipay";


        init();
        function init() {
            if (vm.trip != null) {
                if (vm.trip.type == 'Indoor') {
                    vm.trip.type = '室内版';
                } else {
                    vm.trip.type = '室外版';
                }
            }
        }

        //点击选择支付方式
        $('#account_mode em').each(function(i) {
            $(this).click(function(event){
                $('#account_mode em').removeClass('active2');
                $(this).addClass('active2');
                if($(this).attr("id")=="pay1"){
                    payType = "alipay";
                }else if($(this).attr("id")=="pay2"){
                    payType = "wechat";
                }else if($(this).attr("id")=="pay3"){
                    payType = "unionpay";
                }
            })
        });

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                    // $('#field_price').html(2000);
                }
            });
        }


        vm.confirmPay = function () {
            if (vm.checked===true){
                if (payType==='alipay') {
                    pay = 0;
                } else {
                    pay = 1;
                }
                TsTripPay.createOrder({eId:vm.account.eAccountId,sPrice:sPrice,eType:pay,tripId:vm.trip.id}).then(function (res) {
                    console.log(JSON.stringify(res.data));
                    vm.order = res.data;
                    if (vm.order.code == 0) {
                        $('.pay').css('display','none');
                        $('.paying').css('display','block');
                        var expireDate = new Date();
                        expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
                        $cookies.put('ts-trip', vm.trip.id, {expires: new Date(expireDate)});
                        $state.go("ts-my-pay",{orderId:vm.order.data.out_trade_no});
                    } else if (vm.order.code==10001) {
                        alert("参数异常，订单提交失败");
                    }
                },function (err) {
                    console.log(err);
                });

            }else {
                // alert("请先勾选（我已同意爱闯闯支付服务协议）");
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }




        function onSaveSuccess() {
            vm.publishSuccess = false;
        }

        function onSaveError() {
            vm.publishSuccess = false;
        }


    }
})();

