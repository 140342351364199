(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripToWebDetailController', TripToWebDetailController);

    TripToWebDetailController.$inject = ['$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', 'Trip', 'tripToWebCheck','$state','tripGetFromWeb'];

    function TripToWebDetailController($scope, $rootScope, Principal, $http, $stateParams, previousState, entity, Trip, tripToWebCheck, $state, tripGetFromWeb) {
        var vm = this;

        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.trip = entity;
        vm.previousState = previousState.name;
        vm.showTripsButton = true;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        getTripWebInfo();
        function getTripWebInfo() {
            tripGetFromWeb.getFromWeb(vm.trip.id).success(function (res) {
                if (res.code == 0) {
                    vm.tripWeb = res.data;
                }
            })
        }
        getToWebStatus();
        function getToWebStatus() {
            if(vm.trip.toWeb==1) {
                vm.pass=true;
                vm.notPass = false;
            } else if(vm.trip.toWeb==2) {
                vm.pass = false;
                vm.notPass=true;
            } else {
                vm.pass = false;
                vm.notPass = false;
            }
        }

        vm.showQrCodeAll = showQrCodeAll;
        function showQrCodeAll() {
            var location = window.location.host;
            vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+vm.trip.id+"&istest=1";
            document.getElementById("qrCode").style.display = 'block';
        }

        this.filterEAccount = function (search) {
            if(search){
                vm.eaccounts = EAccountSearchByIDAndName.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
        };

        vm.tripCheckPass = tripCheckPass;
        function tripCheckPass() {
            var param = {id:vm.trip.id,type:1,reason:null,tripWeb:vm.tripWeb};
            // console.log(param);
            tripToWebCheck.toWebCheck(param).success(function (res) {
                vm.pass=true;
                $state.go('trip-to-web-detail',{id:vm.trip.id},{reload:true})
            })
        }
        vm.tripCheckNotPass = tripCheckNotPass;
        function tripCheckNotPass() {
            if (vm.trip.reason == null){
                alert("请输入不通过原因！！");
            }else {
                var param = {id:vm.trip.id,type:2,reason:vm.trip.reason,tripWeb:vm.tripWeb};
                tripToWebCheck.toWebCheck(param).success(function (res) {
                    vm.notPass=true;
                    $state.go('trip-to-web-detail',{id:vm.trip.id},{reload:true})
                })
            }
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:tripUpdate', function(event, result) {
            vm.trip = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
