/**
 * Created by cli on 10/22/16.
 */
// var IMAGE_SERVER = "http://139.196.40.143:8082/";
// var IMAGE_SERVER = "http://139.196.242.182:8082";
var IMAGE_SERVER = "https://image.itasktour.com";
var host = window.location.host;

var _czc = _czc || [];

/**
 * 定义AR服务器，
 * http下测试服：（http://artest.itasktour.com）正式服：（http://ar.itasktour.com）
 * https下测试服：（https://ardev.itasktour.com）正式服：（https://ar.itasktour.com）
 */
var AR_SERVER = "http://artest.itasktour.com";
var OPEN_EDITOR = "https://editor.jngysz.com/";
if (window.location.href.indexOf("jngysz") != -1) {
    OPEN_EDITOR = "https://editor.jngysz.com/";
} else {
    OPEN_EDITOR = "https://testedit.itasktour.com/";
}
var protocol = window.location.protocol;
// if (protocol.indexOf('https')>-1){
//     // AR_SERVER = "https://ar.itasktour.com";//https正式AR服务器
//     AR_SERVER = "https://ardev.itasktour.com";//https测试AR服务器
// }else{
//     // AR_SERVER = "http://ar.itasktour.com";//http正式AR服务器
//     AR_SERVER = "http://artest.itasktour.com";//http测试AR服务器
// }

(function () {
    "use strict";
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular.module("taskServiceApp").constant("DATETIME_BUTTON_BAR_OPTIONS", {
        show: true,
        now: {
            show: true,
            text: "现在",
            cls: "btn-sm btn-default",
        },
        today: {
            show: true,
            text: "今天",
            cls: "btn-sm btn-default",
        },
        clear: {
            show: true,
            text: "清空",
            cls: "btn-sm btn-default",
        },
        date: {
            show: true,
            text: "日期",
            cls: "btn-sm btn-default",
        },
        time: {
            show: true,
            text: "时间",
            cls: "btn-sm btn-default",
        },
        close: {
            show: true,
            text: "关闭",
            cls: "btn-sm btn-default",
        },
    });
})();
