(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripRegistrationController', TripRegistrationController);

    TripRegistrationController.$inject = ['$filter','$timeout', '$uibModalInstance','TripPublishSearch','TripDetail','TripGetEAccount','$scope','Upload','TripRegisterInfo','$state','TripRegisterDetailMethod2'];

    function TripRegistrationController($filter,$timeout, $uibModalInstance,TripPublishSearch,TripDetail,TripGetEAccount,$scope,Upload,TripRegisterInfo, $state,TripRegisterDetailMethod2) {

        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.filterTrip = this.filterTrip;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.showCropped = true;
        vm.cropper = 'default';
        vm.trip = null;
        vm.tripRegister = null;

        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripPublishSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release : 0
                });
            }
        };

        vm.click = editTrip;
        function editTrip(id) {
            TripRegisterDetailMethod2.getDetail(id).success(function (data) {
                vm.trip = data;
                if (data.trip_id == null) {
                    //创建公开报名活动
                    TripDetail.getDetail(id).success(function (data) {
                        vm.trip = data;
                        vm.trip.price = vm.trip.price * vm.trip.teamMaxpeople;
                        var param = {tripId:id};
                        TripGetEAccount.getEAccount(param).success(function(res){
                            if (res.code == 0) {
                                vm.trip.eAccountName = res.data.name;
                                vm.trip.eAccountLogo = res.data.web;
                            }
                        }).error(function(err){
                            console.log(err);
                        });
                    }).error(function(err){
                        console.log(err);
                    });
                } else {
                    //编辑公开报名的活动
                    vm.trip.id = data.trip_id;
                    vm.trip.eAccountName = data.eaccount_name;
                    vm.trip.eAccountLogo = data.eaccount_avatar;
                    TripDetail.getDetail(id).success(function (data) {
                        vm.trip.title = data.title;
                        vm.trip.coverImg = data.coverImg;
                        vm.trip.teamMaxpeople = data.teamMaxpeople;
                        vm.trip.startLocation = data.startLocation;
                        vm.trip.startTime = data.startTime;
                        vm.trip.endTime = data.endTime;
                        vm.trip.description = data.description;
                    }).error(function(err){
                        console.log(err);
                    });
                }
            })

            document.getElementById("tripInfo").style.display = 'block';
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            window.setTimeout(function () {
                $state.go('trip-register-list', null, {reload: false});
            },300);
        }

        function save () {
            vm.tripRegister = vm.trip;
            vm.tripRegister.trip_id = vm.trip.id;
            vm.tripRegister.trip_title = vm.trip.title;
            vm.tripRegister.price = vm.trip.price;
            vm.tripRegister.team_max_people = vm.trip.teamMaxpeople;
            vm.tripRegister.eaccount_id = vm.trip.eAccountId;
            vm.tripRegister.eaccount_name = vm.trip.eAccountName;
            vm.tripRegister.eaccount_avatar = vm.trip.eAccountLogo;
            vm.tripRegister.cover_img = vm.trip.coverImg;
            vm.tripRegister.start_location = vm.trip.startLocation;
            var start_time = $filter('date')(vm.trip.startTime, "yyyy-MM-dd hh:mm:ss");
            vm.tripRegister.start_time = start_time;
            var end_time = $filter('date')(vm.trip.endTime, "yyyy-MM-dd hh:mm:ss");
            vm.tripRegister.end_time = end_time;
            vm.tripRegister.description = vm.trip.description;
            vm.tripRegister.artist_id = vm.trip.myArtistId;
            vm.tripRegister.enableCertification = vm.trip.enableCertification;
            var param = {trip:vm.tripRegister};
            TripRegisterInfo.saveInfo(param).success(function(res){
                if (res.code == 0) {
                    $uibModalInstance.dismiss('cancel');
                    window.setTimeout(function () {
                        $state.go('trip-publish-register-detail', {id: vm.trip.id}, {reload: true});
                    },300);
                }
            }).error(function(err){
                console.log(err);
            });
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                    console.log("11");
                }else {
                    vm.type = 'other';
                    console.log("22");
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            onFileSelect(img,'eAccountLogo');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.trip.eAccountLogo != null) {
                vm.trip.eAccountLogo = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {

                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    var imgSize = compressedBlob.size/1024;
                    var file = data.file;
                    console.log(file);

                    if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                        // console.log(imgSize);
                        if (imgSize>imgLimit){
                            alert("图片大小超过"+imgLimit+"kb");
                            return;
                        }
                        vm.type = 'img';
                    }else if (/\.(mp3|mp4)$/.test(file)){
                        if (imgSize>otherLimit){
                            alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                            return;
                        }
                        vm.type = 'other';
                    }else {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }

                    //update the url
                    vm.trip.eAccountLogo = data.file;
                    vm.cropper = 'newFile';

                    // $scope.myImage = vm.IMAGE_SERVER+vm.trip.coverImg;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };

            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }
    }


    angular
        .module('taskServiceApp')
        .controller('TripRegisterDetailController', TripRegisterDetailController);

    TripRegisterDetailController.$inject = ['$location','$http','$state','ParseLinks','$timeout','entity','AlertService','paginationConstants', 'pagingParams','TripRegisterOrderList','TripDetail'];

        function TripRegisterDetailController($location,$http,$state,ParseLinks,$timeout,entity,AlertService,paginationConstants, pagingParams,TripRegisterOrderList,TripDetail) {

            var vm = this;
            vm.trip = entity;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.loadPage = loadPage;
        vm.absUrl =$location.$$absUrl;
        vm.qrCodeNum = 1;

        vm.editTripInfo = editTripInfo;
        function editTripInfo() {
            document.getElementById("tripInfo").style.display = 'block';
        }

        vm.exportQrLink = exportQrLink;
        function exportQrLink() {
            window.location.href = '/api/teams/create-empty/' + vm.trip.trip_id + '/' + vm.qrCodeNum;
        }
        // getDetailFromDB();
        // function getDetailFromDB() {
        //     TripDetail.getDetail(vm.trip.trip_id).success(function (data) {
        //         vm.trip.trip_title = data.title;
        //         vm.trip.cover_img = data.coverImg;
        //         vm.trip.team_max_people = data.teamMaxpeople;
        //         vm.trip.start_location = data.startLocation;
        //         vm.trip.description = data.description;
        //         vm.trip.start_time = data.startTime;
        //         vm.trip.end_time = data.endTime;
        //     }).error(function(err){
        //         console.log(err);
        //     });
        // }

        getOrderList();
        function getOrderList() {
            TripRegisterOrderList.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                release: "0",
                id: vm.trip.trip_id
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.orders = data;
                vm.page = pagingParams.page;

                var reg = /[,，]/g;
                for (var i = 0; i < vm.orders.length; i++) {
                    var temp = vm.orders[i].contestantInfo;
                    var str = temp.replace(reg,",  \r\n");
                    vm.orders[i].contestantInfo = str;
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            TripRegisterOrderList.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                id: vm.trip.trip_id
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.orders = data;
                //vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.copyUrl = copyUrl;
        function copyUrl() {
            var url = document.getElementById("QR_URL");
            console.log(url.innerText);
            url.select(); //选择对象
            var tag = document.execCommand("Copy"); //执行浏览器复制命令
            if(tag){
                alert("复制成功。")
            }
        }

        //二维码下载事件
        vm.downloadQrcode = downloadQrcode;
        function downloadQrcode() {
            var type = 'png';
            var element = document.getElementsByClassName("qrCode");
            var domElement = element[0];
            var canvas = domElement.children[0].childNodes[0];
            var url = canvas.toDataURL(type);
            var _fixType = function(type) {
                type = type.toLowerCase().replace(/jpg/i, 'jpeg');
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return 'image/' + r;
            };
            url = url.replace(_fixType(type),'image/octet-stream');

            var saveFile = function(data, filename){
                var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
                save_link.href = data;
                save_link.download = filename;

                var event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                save_link.dispatchEvent(event);
            };

            // 下载后的问题名
            var filename = vm.trip.trip_title + '-报名.png';
            // download
            saveFile(url,filename);

            // try {//解决IE转base64时缓存不足，canvas转blob下载
            //     var blob = canvas.msToBlob();
            //     navigator.msSaveBlob(blob, 'qrcode.jpg');
            // } catch (e) {//如果为其他浏览器，使用base64转码下载
            //     var url = canvas.toDataURL('image/jpeg');
            //      console.log("url:"+url);
            //      $("#download").attr('href', url).get(0).click();
            // }
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

    }

    angular
        .module('taskServiceApp')
        .controller('TripRegisterEditController', TripRegisterEditController);

    TripRegisterEditController.$inject = ['$filter','entity','$timeout', '$uibModalInstance','TripSearchOfficial','TripDetail','TripGetEAccount','$scope','Upload','TripRegisterInfo','$state'];

    function TripRegisterEditController($filter,entity,$timeout, $uibModalInstance,TripSearchOfficial,TripDetail,TripGetEAccount,$scope,Upload,TripRegisterInfo, $state) {
        var vm = this;
        vm.trip = entity;
        vm.clear = clear;
        vm.save = save;
        vm.filterTrip = this.filterTrip;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.showCropped = true;
        vm.cropper = 'default';

        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb

        getDetailFromDB();
        function getDetailFromDB() {
            TripDetail.getDetail(vm.trip.trip_id).success(function (data) {
                vm.trip.trip_title = data.title;
                vm.trip.cover_img = data.coverImg;
                vm.trip.team_max_people = data.teamMaxpeople;
                vm.trip.start_location = data.startLocation;
                vm.trip.description = data.description;
                vm.trip.start_time = data.startTime;
                vm.trip.end_time = data.endTime;
            }).error(function(err){
                console.log(err);
            });
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            window.setTimeout(function () {
                $state.go('trip-publish-register-detail', {id: vm.trip.trip_id}, {reload: false});
            },300);
        }

        function save () {
            var start_time = $filter('date')(vm.trip.start_time, "yyyy-MM-dd hh:mm:ss");
            vm.trip.start_time = start_time;
            var end_time = $filter('date')(vm.trip.end_time, "yyyy-MM-dd hh:mm:ss");
            vm.trip.end_time = end_time;
            var param = {trip:vm.trip};
            TripRegisterInfo.saveInfo(param).success(function(res){
                if (res.code == 0) {
                    $uibModalInstance.dismiss('cancel');
                    window.setTimeout(function () {
                        $state.go('trip-publish-register-detail', {id: vm.trip.trip_id}, {reload: true});
                    },300);
                }
            }).error(function(err){
                console.log(err);
            });
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                    console.log("11");
                }else {
                    vm.type = 'other';
                    console.log("22");
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            onFileSelect(img,'eAccountLogo');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.trip.eaccount_avatar != null) {
                vm.trip.eaccount_avatar = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        vm.onFileSelect = onFileSelect;
        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {

                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    var imgSize = compressedBlob.size/1024;
                    var file = data.file;
                    console.log(file);

                    if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                        // console.log(imgSize);
                        if (imgSize>imgLimit){
                            alert("图片大小超过"+imgLimit+"kb");
                            return;
                        }
                        vm.type = 'img';
                    }else if (/\.(mp3|mp4)$/.test(file)){
                        if (imgSize>otherLimit){
                            alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                            return;
                        }
                        vm.type = 'other';
                    }else {
                        alert("文件格式不正确，请重新上传！！");
                        return;
                    }

                    //update the url
                    vm.trip.eaccount_avatar = data.file;
                    vm.cropper = 'newFile';

                    // $scope.myImage = vm.IMAGE_SERVER+vm.trip.coverImg;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };

            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }
    }

    angular
        .module('taskServiceApp')
        .controller('TripRegisterListController', TripRegisterListController);

    TripRegisterListController.$inject = ['$state','$timeout','TripRegisterList', 'TripSearch', 'Principal', '$http', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function TripRegisterListController($state,$timeout, TripRegisterList, TripSearch, Principal, $http, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();
        function loadAll () {
            Principal.identity().then(function(account) {
                vm.account = account;
                var eAccountId = vm.account.eAccountId + '';
                if (eAccountId == 'null') {
                    eAccountId = 0;
                }
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                        vm.userId = 0;
                    }
                }

                if (pagingParams.search) {
                    TripRegisterList.query({
                        query: pagingParams.search,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        release: "0",
                        eAccountId: eAccountId
                    }, onSuccess, onError);
                } else {
                    TripRegisterList.query({
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort(),
                        release: "0",
                        eAccountId: eAccountId
                    }, onSuccess, onError);
                }
                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.trips = data;
                    vm.page = pagingParams.page;
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            });

        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

    }



})();
