(function () {
    'use strict'

    angular
        .module('taskServiceApp')
        .controller('TripCoachQrCodeController', TripCoachQrCodeController);

    TripCoachQrCodeController.$inject = ['$timeout', '$uibModalInstance','TripSearchOfficial','$http','$stateParams'];

    function TripCoachQrCodeController ($timeout, $uibModalInstance,TripSearchOfficial,$http,$stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;
        vm.tripInfo = {};
        vm.coachTeamInfo = {};
        vm.showQrCodeType = 0;//0默认值，1教练段app,2教练段web

        if($stateParams.id != null && $stateParams.id != ''){
            vm.trip = [];
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
            vm.trip.attribute = $stateParams.attribute;
            vm.tripModelsIdChange = true;
            showQrCode(vm.trip.id);
        }else{
            //教练信息传递过来的，如果没有后退一步
            return
        }


        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
        };

        vm.click = function(type){
            vm.showQrCodeType= type
        }
        showQrCode(vm.trip.id == null ? vm.trip:vm.trip.id);
        function showQrCode(id) {
            // console.log(id);
            $http({
                method: 'GET',
                url: 'api/trips/'+id
            }).then(function (res) {
                console.log(res);
                if (res != null){
                    // vm.qrCode = "itasktour://gototrip?tripid="+id;
                    var location = window.location.host;
                    vm.qrCode = window.location.protocol+"//"+location+"/api/mobile/coach-teams-qr-join?coach-team-id="+res.data.myCoachTeamId;
                    vm.tripInfo = res.data
                    // vm.qrCodeWeb = 'https://www.itasktour.com/' + (window.location.href.indexOf('v3') != -1 ? "coach" : "cd") + '?tripId=' + vm.tripInfo.id;
                    var qrCodeUrl = window.location.href.indexOf('jngysz') != -1 ? "https://coach.jngysz.com" : "https://www.itasktour.com/cd";
                    vm.qrCodeWeb = qrCodeUrl + '?tripId=' + vm.tripInfo.id;
                    document.getElementById("qrCode").style.display = 'block';
                    console.log(vm.qrCode);
                    coachTeamInfoFun(res.data.myCoachTeamId);
                }
            },function (err) {
                console.log(err);
            })
        }
        function coachTeamInfoFun (id){
            $http({
                method: 'GET',
                url: 'api/mobile/coach-teams/'+id
            }).then(function (res) {
                console.log(res);
                if (res != null){
                    vm.coachTeamInfo = res.data
                }
            },function (err) {
                console.log(err);
            })
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
