(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('NpcTeamTripSearch', NpcTeamTripSearch);

    NpcTeamTripSearch.$inject = ['$resource'];

    function NpcTeamTripSearch($resource) {
        var resourceUrl =  'api/_search/npc-team-trips/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
