(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RegisterLoginController', RegisterLoginController);

    RegisterLoginController.$inject = ['$scope','$translate', '$timeout', 'Auth',"$stateParams" ,'LoginService','$state','$http','MyEAccount','$cookies'];

    function RegisterLoginController ($scope,$translate, $timeout, Auth, $stateParams,LoginService,$state,$http,MyEAccount,$cookies) {
        var vm = this;
        vm.goEAccountPage = false;
        vm.parray = Array(
            "北京",
            "上海",
            "天津",
            "重庆",
            "河北",
            "山西",
            "内蒙古",
            "辽宁",
            "吉林",
            "黑龙江",
            "江苏",
            "浙江",
            "安徽",
            "福建",
            "江西",
            "山东",
            "河南",
            "湖北",
            "湖南",
            "广东",
            "广西",
            "海南",
            "四川",
            "贵州",
            "云南",
            "西藏",
            "陕西",
            "甘肃",
            "宁夏",
            "青海",
            "新疆",
            "香港",
            "澳门",
            "台湾"
        );

        vm.login = login;
        vm.id = $stateParams.id;
        vm.username = $stateParams.login;
        vm.password = $stateParams.pwd;
        vm.rememberMe = null;
        vm.nickName = null;
        vm.firstName = null;
        vm.city = '请选择';
        vm.email = null;
        vm.emailError = false;
        vm.checkEmail = checkEmail;
        vm.infoLogin = infoLogin;
        function infoLogin() {
            if(vm.emailError){//格式错误直接为空
                vm.email = null;
            }
            $http({
                url:'/api/user/add-info',
                method:'POST',
                data:{login:vm.username, nickName:vm.nickName,firstName: vm.firstName,email: vm.email,city: vm.city}
            }).success(function (res) {
                login();
            }).error(function (res) {
                console.log(res)
                vm.emailError = 2;//'该有邮箱已被注册'
            })
        }

        function checkEmail(){
            vm.emailError = null;
            var format = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(!format.test(vm.email)){
                vm.emailError = 1;//邮箱格式不对
            }
        }

        function login() {
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function (data) {
                var eid = $cookies.get("eid");
                var uid = $cookies.get("uid");
                if (typeof(eid) != "undefined" && typeof(uid) != "undefined") {
                    vm.goEAccountPage = true;
                    MyEAccount.addDegigner({
                        eid: eid,
                        login: vm.username,
                        isTrainee: 0
                    }, onSaveSuccess, onSaveError);
                    $cookies.remove("eid");
                    $cookies.remove("uid");
                } else {
                    onSaveSuccess();
                }
            }).catch(function (err) {
                vm.authenticationError = true;
            })
        }

        function onSaveSuccess() {
            getAccount();
            vm.authenticationError = false;
            if (window.location.href.indexOf('jngysz') != -1) {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=jngysz.com";
            } else {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com";
            }
            if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                // $state.go('guide');
            }
            if (vm.goEAccountPage) {
                $state.go("e-account-my", {}, {reload: true});
            } else {
                // $state.go('trip-model', {}, {reload: true});
                $state.go('program-center',{login:true});
            }
        }

        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        })

        function onSaveError (err) {
            console.log(err);
            vm.isSaving = false;
            return;
        }

        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(account) {
                vm.account = account.data;
                if (vm.account!==null){
                    if (vm.account.avatar==null||vm.account.avatar.length<0){
                        vm.account.avatar = '/USER/23/location_task/p1599805459189_152c81a0-aa38-4b17-af08-392bf85c1e75.png';
                    }
                    var author = vm.account.authorities;
                    vm.nick = vm.account.firstName;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                        vm.author = "超级管理员";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                        vm.author = "管理员";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                        vm.author = "运营管理";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                        vm.author = "独立设计师";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                        vm.author = "全职设计师";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                        vm.author = "兼职设计师"
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                        vm.author = "普通用户";
                    }

                    //判断该用户所属企业有没有公开报名活动权限
                    if (vm.account.eAccountId!=null){
                        MyEAccount.get({id:vm.account.eAccountId},function (res) {
                            if (res!=null&&res.signUpAuth){
                                vm.signUp = true;
                            }
                        },function (err) {
                            console.log(err);
                        });
                    }

                    //判断哪些账号可以设置优惠券
                    var admin_user = [13601752336,18016073337,18917615957,13601935899,13818191225,13916144547,'admin'];
                    for (var i=0;i<admin_user.length;i++){
                        // console.log(admin_user[i]);
                        if (admin_user[i]==vm.account.login){
                            vm.canSetCoupon = true;
                        }
                    }

                    //下载结算和充值报表
                    var finance_user = [13801778172];
                    for (var i=0;i<finance_user.length;i++){
                        // console.log(admin_user[i]);
                        if (finance_user[i]==vm.account.login){
                            vm.canDownload = true;
                        }
                    }

                    var avatar = vm.account.avatar;
                    if (avatar != null && avatar.indexOf(vm.IMAGE_SERVER)===-1 && avatar.indexOf('http')===-1){
                        vm.account.avatar = vm.IMAGE_SERVER + avatar;
                    }

                }
            });
        }
        vm.openWeb  = openWeb;
        function openWeb() {
            // // window.location.href = window.location.protocol+"//"+window.location.host;
            // if(window.location.hostname.startsWith("dev")){
            //     window.location.href = "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
            // }else {
            //     window.location.href = "https://www.itasktour.com";
            // }
            // window.location.href = "https://api.jngysz.com";
            window.location.href = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com" : "https://testapi.itasktour.com";
        }

        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        })
    }
})();
