(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripController', controller);

    controller.$inject = ['$filter','$location','$scope', '$state', 'Trip','Principal','TripSearch', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants','$http','tripUpdateStatus','tripEdit','TripMonitor','$interval','EAccountSearch',
        'tripUpdateStatusTrade', 'TripVerifyStatus', 'TripGetEAccount', 'tripNotToWeb','tripToWeb','TripLabelGetFromWeb',
        'TripPeopleNumGetFromWeb', 'tripGetFromWeb', 'TripModelBaseInfo','TripSearchByStatus'];

    function controller ($filter, $location,$scope, $state, Trip, Principal,TripSearch,ParseLinks, AlertService, pagingParams,
                             paginationConstants,$http,tripUpdateStatus,tripEdit, TripMonitor,$interval,EAccountSearch,
                             tripUpdateStatusTrade, TripVerifyStatus, TripGetEAccount, tripNotToWeb,tripToWeb,TripLabelGetFromWeb,
                             TripPeopleNumGetFromWeb, tripGetFromWeb, TripModelBaseInfo,TripSearchByStatus) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.isSuper = false;
        vm.searchType = pagingParams.searchType;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.parray = [ '状态','发布','编辑'];
        vm.states = pagingParams.states;
        vm.state = vm.parray[vm.states];
        vm.platformArray = [ '版本','基础版','专业版','开放寻宝','极速追踪'];
        vm.platforms = pagingParams.platforms;
        vm.platform = vm.platformArray[vm.platforms];
        vm.absURL =$location.$$absUrl;
        inputPlaceholder();
        loadAll();

        var tempTripId="";
        vm.qrCodeNum = 1;
        vm.exportQrLink = exportQrLink;
        
        function exportQrLink() {
            $http({
                url: '/api/teams/iscan-create-empty/' + tempTripId + '/' + vm.qrCodeNum,
                method: 'GET'
            }).then(function suc(res) {
                if(res.data.success==true){
                    window.location.href = '/api/teams/create-empty/' + tempTripId + '/' + vm.qrCodeNum;
                }else{
                    alert(res.data.message);
                }
            },function err(err) {
                alert(err);
            });
            
        }
        vm.saveTempTripId = saveTempTripId;
        function saveTempTripId(tripId){
            tempTripId = tripId;
        }


        vm.tripQrCodeNew = tripQrCodeNew;
        function tripQrCodeNew(id,title,attribute,platform,traceType,teamset){

            $state.go('tripQrCodeNew',{id:id, title:title,attribute:attribute, platform:platform ,traceType:traceType,teamset:teamset} ,{reload : false});
        }
        vm.tripCoachQrCode = tripCoachQrCode;
        function tripCoachQrCode(id,title,attribute){
            $state.go('tripCoachQrCode',{id:id, title:title,attribute:attribute} ,{reload : false});
        }
        vm.stateChange = stateChange;
        function stateChange(){
            vm.page = 0;
            loadAll ();
        }
        vm.platformChange = platformChange;
        function platformChange(){
            vm.page = 0;
            loadAll ();
        }
        vm.IGCClick = IGCClick;
        function IGCClick(){
            $('#myModal5').modal('show');
        }

        vm.inputPlaceholder = inputPlaceholder;
        function inputPlaceholder(){
            $('#searchQuery').attr('placeholder',placeholderString());
            function placeholderString(){
                switch (Number( vm.searchType)){
                case 1:
                    return '输入企业全称或者ID';
                    break;
                case 2:
                    return '输入设计师账号';
                    break;
                default:
                    return '搜索活动';
                }
            }
        }
        vm.ident = ident;
        function ident() {
            $("#myModal5").modal('hide');
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }
        vm.settlement = settlement;
        function settlement(param) {
            $http({
                url: '/api/tripSettlement/'+param.tripId,
                method: 'GET'
            }).then(function suc(res) {
                console.log(res);
                if (res!=null && res.data!=null){
                    if (res.data == -1) {
                        alert("该活动已结算过了！！");
                    }else if (res.data == 0){
                        alert("结算成功！！");
                    } else {
                        alert("该活动无企业所属！！");
                    }
                }
            },function err(err) {
                console.log(err);
            });
        }
        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    for (var i = 0; i< vm.account.authorities.length;i++){
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN"){
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE"){
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }
        // var isScroll = true;
        /*$(window).on("scroll",function(){
            if(isScroll){
                setTimeout(function(){
                    var top = $(this).scrollTop();
                    if(top > 150){
                        // if(!$(".trFix").hasClass('trFix')){
                            $("#trFix").css({"position":"fixed","background-color":"#f9f9f9","margin-top":"-210px","height":"50px"});
                            // $("#status").css({"width":"80px"});
                            // $("#type").css({"text-indent":"10px"});
                            $("#title").css({"width":"80px"});
                            $("#start").css({"width":"120px"});
                            $("#startt").css({"width":"150px"});
                            $("#end").css({"width":"150px"});
                            $("#cover").css({"width":"80px"});
                            $("#tripType").css({"width":"100px"});
                            // $("#artist").css({"width":"120px"});
                            // $("#detail").css({"width":"80px"});
                            // $("#yu").css({"width":"75px"});
                        // }
                    }else{
                        $("#trFix").css({"position":"","background-color":"transparent"});
                        $("#title").css({"width":"120px"});
                        $("#start").css({"width":"100px"});
                    }
                    // isScroll = true;
                },0);
            }
            // isScroll = false;
        });*/

        function loadAll () {
            var type = 0;
            switch (vm.state) {
            case '编辑':
                type = 2;break;
            case '发布':
                type = 1;break;
            }

//platform=0-不限版本 1-基础版 2-专业版"
        //增加 3：开放寻宝  4极速追踪
            var platform = 0;
            switch (vm.platform) {
            case '基础版':
                platform = 1;break;
            case '专业版':
                platform = 2;break;
    			case '开放寻宝':
                platform = 3;break;
            case '极速追踪':
                platform = 4;break;
            }

            TripSearchByStatus.query({
                platform: platform,
                type: type,
                query: pagingParams.search != null ? pagingParams.search:'',
                searchType: Number( pagingParams.searchType ),
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                release: "0",
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trips = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            switch (vm.state) {
            case '状态':
                vm.state = 0;
                break;
            case '编辑':
                vm.state = 2;
                break;
            case '发布':
                vm.state = 1;
                break;
            }
            switch (vm.platform) {
            case '版本':
                vm.platform = 0;break;
            case '基础版':
                vm.platform = 1;break;
            case '专业版':
                vm.platform = 2;break;
            case '开放寻宝':
                vm.platform = 3;break;
            case '极速追踪':
                vm.platform = 4;break;
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                searchType:vm.searchType,
                states: vm.state,
                platforms: vm.platform
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.errText = null;
            vm.transition();
        }

        // vm.prePublish = prePublish;
        // function prePublish(tripId) {
        //     for (var i=0; i<vm.trips.length;i++){
        //         // console.log(vm.trips[i].id);
        //         if (vm.trips[i].id === tripId){
        //             if (vm.trips[i].status !== 'PREPUBLISH' && vm.trips[i].status !== 'PUBLISH'){
        //                 var x = confirm("确定要预发布活动"+tripId+"?");
        //                 if (x){
        //                     // vm.trips[i].status = 'PREPUBLISH';
        //                     // Trip.update(vm.trips[i], onSaveSuccess, onSaveError);
        //                     $http({
        //                         method: 'PUT',
        //                         url: '/api/trips/updateStatus/'+tripId+'/PREPUBLISH'
        //                     }).success(function () {
        //                         console.log();
        //                     }).error(function(err){
        //                         console.log(err);
        //                     });
        //                     window.location.reload();
        //                     return;
        //                 }else {
        //                     return;
        //                 }
        //             }else {
        //                 alert("该活动已为预发布或发布状态");
        //             }
        //         }
        //     }
        // }
        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.deleteTrip = deleteTrip;
        function deleteTrip() {
            $('#myModal6').modal('show');
        }
        vm.keyDown = function(event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };
        // 退回编辑和上下架
        vm.isRelease = false;
        vm.phoneCode = "发送验证码";
        vm.sessionId='';
        vm.code='';
        vm.filterEAccounts = this.filterEAccounts;
        vm.notToWebFlag=false;
        vm.tripLabels = null;
        vm.regions = null;
        vm.peopleNums = null;
        vm.labelSelect = false;
        vm.tripWeb = {};
        vm.tripModel = null;
        vm.mTitle = "退回编辑";
        vm.mContent = "确定要退回发布吗？";
        function clearCodeInfo() {
            //$uibModalInstance.dismiss('cancel');
            vm.errText=null;
            vm.relatedEAccountName=null;
            vm.code=null;
            vm.imgCode=null;
            window.history.go(0);
        }
        function clearErrText() {
            vm.errText=null;
        }
        vm.edit = edit;
        function edit(tripId) {
            vm.tripId = tripId;
            for (var i=0; i<vm.trips.length;i++){
                if (vm.trips[i].id === tripId){
                    //不是发布管理员身份
                    if ($.inArray("ROLE_RELEASE",vm.account.authorities) ===-1) {
                        var dataNew = new Date();
                        var myJsDate=$filter('date')(vm.trips[i].startTime,'yyyy-MM-dd HH:mm:ss');
                        var dateStart = new Date(myJsDate);
                        if (dataNew > dateStart) {
                            vm.mContent2 = "活动已开始，不可退回发布，如需退回请联系发布管理员";
                            $('#myModal2').modal('show');
                            return;
                        }
                    }
                    if (vm.trips[i].toWeb == 1) {
                        alert("请先下架该活动");
                    }else if (vm.trips[i].status !== 'EDIT'){
                        vm.confirm = confirms;
                        vm.sendCode = sendCode;
                        vm.confirmTrade = confirmTrade;
                        vm.tripStatus = vm.trips[i].status;
                        $('#myModal1').modal('show');
                    }else {
                        alert("该活动已为编辑状态");
                    }
                }
            }
        }
        function confirms() {
            //点击事件执行后将按钮置灰，请求成功后再回复按钮状态

            var param = {tripId:vm.tripId,status:'EDIT'};
            tripUpdateStatus.updateStatus(param).success(function(data){
                // console.log(data);
                loadAll();
                // for (var i in vm.trips){
                //     if (vm.trips[i].id == param.tripId) {
                //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                //     }
                // }
                // window.history.go(0);
                $('#myModal1').modal('hide');
            }).error(function(err){
                // console.log(err);
                if (err!=null){
                    vm.err = err;
                    vm.mTitle2 = "退回编辑";
                    vm.release = false;
                    if ($.inArray("ROLE_RELEASE",vm.account.authorities)!==-1){
                        vm.release = true;
                    }
                    if (vm.release){
                        vm.mContent2 = err.message;
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        vm.confirmsConf = confirmsConf;
                    } else {
                        if (err.errorCode == -1) {
                            vm.mContent2 = "活动已结束，不可退回编辑";
                        } else {
                            vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                        }
                        vm.confirmsConf = null;
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        $('#conf2').css('display','none');
                    }
                }
            });
        }
        function confirmTrade() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {tripId:vm.tripId,status:'EDIT',sessionId:vm.sessionId,validationCode:vm.code};
                tripUpdateStatusTrade.updateStatusTrade(param).success(function(data){
                    console.log("1：：：：" + data);
                    if(data.errorCode != null && data.errorCode == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }
        function setValidationCode() {
            vm.errText = "请输入验证码";
            $('.errText').css('display','block');
        }
        function getValidationCode() {
            vm.errText = "请先获取验证码";
            $('.errText').css('display','block');
        }
        function confirmsConf(){
            console.log("--------3");
            if (vm.err.errorCode!=0){
                var param = {tripId:vm.tripId,status:'EDIT'};
                tripEdit.tEdit(param).success(function(data){
                    // console.log(data);
                    loadAll();
                    // for (var i in vm.trips){
                    //     if (vm.trips[i].id == param.tripId) {
                    //         vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                    //     }
                    // }
                    $('#myModal1').modal('hide');
                    $('#myModal2').modal('hide');
                    $('#myModal3').modal('hide');
                });
            }
        }


        function sendCode() {
            var param = {tripId:vm.tripId};
            TripVerifyStatus.tripVerifyStatus(param).success(function(data){
                console.log(data);
                if (data.code == 0) {
                    $http({
                        url: 'api/e-accounts/'+data.message,
                        method: 'GET'
                    }).then(function (res) {
                        vm.relatedEAccountName = res.data.name;
                        vm.relatedEAccountId = res.data.id;
                        vm.phoneEAccount = res.data.adminAccount;
                    });
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                } else if (data.code == -1) {
                    vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                } else if (data.code == -3) {
                    vm.mContent2 = "线路在已结算，不可退回编辑";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                }
            }).error(function(err){
                console.log(err);
            });

        }
        vm.toWeb=toWeb;
        function toWeb(tripId) {
            vm.mTitle = "上架活动信息";
            vm.tripId = tripId;
            // console.log(tripId);

            if (vm.trip == null) {
                TripModelBaseInfo.getDetail(vm.tripId).success(function (res) {
                    vm.tripModel = res.data;
                });
            }
            if (vm.tripLabels == null) {
                TripLabelGetFromWeb.getLabel().success(function (res) {
                    if (res.code == 0) {
                        vm.tripLabels = res.data;
                        // console.log(vm.tripLabels);
                    }
                });
            }
            if (vm.peopleNums == null) {
                TripPeopleNumGetFromWeb.getPeopleNum().success(function (res) {
                    if (res.code == 0) {
                        vm.peopleNums = res.data;
                        // console.log(vm.peopleNums);
                    }
                });
            }

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                } else if (res.code == -1) {
                    vm.relatedEAccountId = null;
                }
                $('#myModal1').modal('hide');
                $('#myModal4').modal('show');
            }).error(function(err){
                console.log(err);
            });
        }

        vm.confirmToWeb=confirmToWeb;
        function confirmToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                vm.tripWeb.region_name = vm.tripModel.area;
                vm.tripWeb.duration = vm.tripModel.eTime;
                var param = {id:vm.tripId,type:0,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb,eAccountId:vm.relatedEAccountId};
                // console.log(param);
                tripToWeb.toWeb(param).success(function(res){
                    if(res.code != null && res.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal4').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.notToWeb=notToWeb;
        function notToWeb(tripId) {
            vm.tripId = tripId;
            vm.mTitle = "下架";
            vm.notToWebFlag=true;
            console.log(tripId);
            getTripWebInfo();

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function getTripWebInfo() {
            tripGetFromWeb.getFromWeb(vm.tripId).success(function (res) {
                if (res.code == 0) {
                    vm.tripWeb = res.data;
                }
            });
        }

        vm.confirmNotToWeb=confirmNotToWeb;
        function confirmNotToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {id:vm.tripId,type:3,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb};
                tripNotToWeb.notToWeb(param).success(function(data){
                    if(data.code != null && data.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.checkLableNum = checkLableNum;
        function checkLableNum() {
            if (vm.tripWeb.labels.length >= 3) {
                $('.overHide').css('display','none');
            }
            var labelIds = '';
            angular.forEach(vm.tripWeb.labels,function(data){
                labelIds = labelIds + data.category_id + ",";
            });
            vm.tripWeb.label = labelIds;
            // console.log(vm.tripWeb.label);
        }
        vm.showLable = showLable;
        function showLable() {
            if (vm.tripWeb.labels.length < 3) {
                $('.overHide').css('display','block');
            }
        }

        vm.toExport = toExport;
        function toExport(tripId) {
            vm.toExportTripId = tripId;
            $http({
                url: 'api/trip/maxTeamCount/' + tripId,
                method: 'GET'
            }).then(function (res) {
                vm.tripMaxTeam = res.data;
                console.log(res.data);
            });

        }
        vm.confirmExport = confirmExport;
        function confirmExport() {
            if (vm.tripMaxTeam < vm.exportNum) {
                vm.errText = "最多只能导出" + vm.tripMaxTeam + "个战队！";
            } else {
                var location = window.location.host;
                var url = window.location.protocol+"//"+location + "/api/trip/registration/team-qrCode/export/"+vm.toExportTripId + "/" + vm.exportNum;
                console.log("链接：" + url);
                window.open(url);
                $('#exportQrCode').modal('hide');
                window.location.reload();
                $state.go('trip', null, { reload: true });
            }

        }

        vm.setPeopleNum=setPeopleNum;
        function setPeopleNum() {
            vm.tripWeb.renshu_id = vm.tripWeb.renshu.renshu_id;
        }
        function confirmRelease() {
            $http({
                method: 'PUT',
                url: '/api/trips/updateStatus/'+vm.tripId+'/PUBLISH'
            }).success(function () {
                console.log();
                $('#myModal1').modal('hide');
                window.location.reload();
            }).error(function(err){
                console.log(err);
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        this.filterEAccounts = function (search) {
            if (search){
                vm.eAccounts = EAccountSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release: "0"
                });
            }
        };

        vm.setEaccount = setEaccount;
        function setEaccount(phone, eAccountId, eAccountName) {
            console.log(phone + "---" + eAccountId);
            vm.phoneEAccount = phone;
            vm.relatedEAccountId = eAccountId;
            vm.relatedEAccountName = eAccountName;
        }

        vm.setEaccountPhone = setEaccountPhone;
        function setEaccountPhone(phone) {
            console.log(phone);
            vm.phoneEAccount = phone;
        }

        //获取图片验证码
        //getImageCode();
        vm.getImageCode = getImageCode;
        function getImageCode() {
            $http({
                url:'/api/getImageCode',
                method:'POST'
            }).success(function (data) {
                // console.log(data);
                if (data!=null){
                    vm.imgUrl = data.url;
                    vm.verifyId = data.verifyId;
                    vm.imgCode = '';
                }
            }).error(function (err) {
                console.log(err);
            });
        }
        //设置倒计时60s
        function setTimeOut() {
            var code = 60;vm.phoneCode = 60+'s';vm.dis = true;
            // $('.checkBtn').css('background-image','none');
            var time = $interval(function () {
                code = code-1;
                vm.phoneCode = code+'s';
            },1000,60);
            time.then(function () {
                $interval.cancel(stop);
                vm.phoneCode = "发送验证码";
                $('.checkBtn').css('background-image','linear-gradient(113deg, #35e8c0, #0c9fcb)');
                vm.dis = false;
            });
        }
        //获取手机验证码
        vm.getCode = getCode;
        function getCode() {
            if (vm.imgCode!=null&&vm.verifyId!=null&&vm.relatedEAccountId!=null){
                $http({
                    url:'/api/getCode',
                    method:'POST',
                    data:{mobile:vm.phoneEAccount,verifyCode:vm.imgCode,verifyId:vm.verifyId}
                }).success(function (res) {
                    //console.log(res);
                    if (res.code==1012){
                        vm.errText="图片验证码不正确";
                        vm.paramError = 'paramError';
                    }else if (res.code==1008){
                        vm.errText="同一手机号一分钟内只能发送一次短信验证码，请稍后再试";
                        getImageCode();
                    }else {
                        //设置倒计时60s
                        setTimeOut();
                        vm.errText=null;
                        vm.sessionId = res.sessionId;
                        vm.paramError = null;
                    }
                }).error(function (err) {
                    console.log(err);
                });
            }else {
                vm.paramError = 'paramError';
            }
        }
    }



    angular
        .module('taskServiceApp')
        .controller('TripMonitorController', TripMonitorController);

    TripMonitorController.$inject = ['$scope', '$state', 'TripMonitor','Principal','TripSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function TripMonitorController ($scope, $state, TripMonitor, Principal,TripSearch,  ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        loadAll();

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                }
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_DESIGNER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "PGC";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        /*var isScroll = true;
        $(window).on("scroll",function(){
            if(isScroll){
                setTimeout(function(){
                    var top = $(this).scrollTop();
                    if(top > 150){
                        $("#trFix").css({"position":"fixed","background-color":"#f9f9f9","margin-top":"-210px","height":"50px"});
                        $("#title").css({"width":"80px"});
                        // $("#start").css({"width":"120px"});
                        // $("#startt").css({"width":"150px"});
                        // $("#end").css({"width":"150px"});
                        // $("#cover").css({"width":"80px"});
                        // $("#tripType").css({"width":"100px"});
                    }else{
                        $("#trFix").css({"position":"","background-color":"transparent"});
                    }
                    isScroll = true;
                },0);
            }
            isScroll = false;
        });*/

        function loadAll () {
            if (pagingParams.search) {
                TripSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    release: "0"
                }, onSuccess, onError);
            } else {
                TripMonitor.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    release: "0"
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trips = data;
                vm.page = pagingParams.page;
                // console.log("data")
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }

    angular
        .module('taskServiceApp')
        .controller('TripMonitorDialogController', TripMonitorDialogController);

    TripMonitorDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'Trip', 'TeamSearch', 'TripType', 'UserSearch', 'Npc','Principal'];

    function TripMonitorDialogController ($timeout, $scope, $uibModalInstance, entity, Trip, TeamSearch, TripType, UserSearch, Npc,Principal) {
        var vm = this;
        vm.trip = entity;
        vm.clear = clear;
        vm.pauseTimeChanged = pauseTimeChanged;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.triptypes = TripType.query();
        vm.npcs = Npc.query();
        vm.itemsPerPage = 250;

        vm.filterTeam = this.filterTeam;

        vm.filterUser = this.filterUser;

        this.filterTeam = function (search) {
            if (search) {
                vm.teams = TeamSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.$onInit = function () {
            $(function () {

                $("[name='gameStarted']").bootstrapSwitch('state');
                $('input[name="gameStarted"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.gameStarted = state;
                });
                $("[name='npcEnable']").bootstrapSwitch('state');
                $('input[name="npcEnable"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.npcEnable = state;
                });

                $("[name='is_pause']").bootstrapSwitch('state');
                if (!vm.trip.isPause) {
                    $("#pause_content_container").css("display", "none");
                }

                if (!vm.trip.pauseStartTime) {
                    var d = new Date();
                    vm.trip.pauseStartTime = d;
                }
                $('input[name="is_pause"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    if (state) {
                        $("#pause_content_container").css("display", "block");
                        if (!vm.trip.pauseStartTime) {
                            var d = new Date();
                            vm.trip.pauseStartTime = d;
                        }
                    } else {
                        $("#pause_content_container").css("display", "none");
                    }
                    vm.trip.isPause = state;
                });
            });
        };

        function pauseTimeChanged() {
            // console.log(vm.trip.pauseStartTime);
            // $log.log('Time changed to: ' + vm.trip.pauseStartTime);
            //
            // console.log(vm.trip.pauseStartTime.getHours());
            // console.log(vm.trip.pauseStartTime.getMinutes());
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;

            if (vm.trip.id !== null) {
                Trip.update(vm.trip, onSaveSuccess, onSaveError);
            } else {
                Trip.save(vm.trip, onSaveSuccess, onSaveError);
            }

        }

        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }


    angular
        .module('taskServiceApp')
        .controller('TripReleaseController', TripReleaseController);

    TripReleaseController.$inject = ['$scope', '$state', 'Trip','tripUpdateStatus','tripEdit', 'TripMonitor','Principal','TripSearch', 'ParseLinks',
        'AlertService', 'pagingParams', 'paginationConstants','$http','$interval','EAccountSearch','tripUpdateStatusTrade', 'TripVerifyStatus', 'TripGetEAccount',
        'tripNotToWeb','tripToWeb','TripLabelGetFromWeb','TripPeopleNumGetFromWeb','tripGetFromWeb','TripModelBaseInfo'];

    function TripReleaseController ($scope, $state, Trip,tripUpdateStatus,tripEdit, TripMonitor, Principal,TripSearch,  ParseLinks, AlertService, pagingParams,
                                    paginationConstants,$http,$interval,EAccountSearch,tripUpdateStatusTrade, TripVerifyStatus, TripGetEAccount, tripNotToWeb,tripToWeb,
                                    TripLabelGetFromWeb, TripPeopleNumGetFromWeb, tripGetFromWeb, TripModelBaseInfo) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.isRelease = false;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.phoneCode = "发送验证码";
        vm.sessionId='';
        vm.code='';
        vm.filterEAccounts = this.filterEAccounts;
        vm.notToWebFlag=false;
        vm.tripLabels = null;
        vm.regions = null;
        vm.peopleNums = null;
        vm.labelSelect = false;
        vm.tripWeb = {};
        vm.tripModel = null;
        loadAll();

        getAccount();
        function getAccount() {
            //Principal.identity().then(function(account) {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    for (var i = 0; i< vm.account.authorities.length;i++){
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN"){
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE"){
                            vm.isRelease = true;
                        }
                    }

                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.settlement = settlement;
        function settlement(param) {
            var now = new Date().getTime();
            var end = new Date(param.endTime).getTime();
            // console.log(now);
            // console.log(end);
            // if (now > end){
            $http({
                url: '/api/tripSettlement/'+param.tripId,
                method: 'GET'
            }).then(function suc(res) {
                console.log(res);
                if (res!=null && res.data!=null){
                    if (res.data == -1) {
                        alert("该活动已结算过了！！");
                    }else if (res.data == 0){
                        alert("结算成功！！");
                    } else {
                        alert("该活动无企业所属！！");
                    }
                }
            },function err(err) {
                console.log(err);
            });
            // }else {
            //     alert("该活动还未到结束时间！！");
            // }
        }

        // var isScroll = true;
        /*$(window).on("scroll",function(){
            if(isScroll){
                setTimeout(function(){
                    var top = $(this).scrollTop();
                    if(top > 150){
                        $("#trFix").css({"position":"fixed","background-color":"#f9f9f9","margin-top":"-210px","height":"50px"});
                        // $("#title").css({"width":"80px"});
                        // $("#start").css({"width":"120px"});
                        // $("#startt").css({"width":"150px"});
                        // $("#end").css({"width":"150px"});
                        // $("#cover").css({"width":"80px"});
                        // $("#tripType").css({"width":"100px"});
                    }else{
                        $("#trFix").css({"position":"","background-color":"transparent"});
                    }
                    // isScroll = true;
                },0);
            }
            // isScroll = false;
        });*/

        function loadAll () {
            if (pagingParams.search) {
                TripSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    release: "1"
                }, onSuccess, onError);
            } else {
                TripMonitor.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id,desc');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trips = data;
                vm.page = pagingParams.page;
                // console.log(vm.trips);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function clearCodeInfo() {
            //$uibModalInstance.dismiss('cancel');
            vm.errText=null;
            vm.relatedEAccountName=null;
            vm.code=null;
            vm.imgCode=null;
        }
        function clearErrText() {
            vm.errText=null;
        }

        vm.mTitle = "退回编辑";
        vm.mContent = "确定要将此活动退回编辑状态？";
        vm.edit = edit;
        function edit(tripId) {
            vm.tripId = tripId;
            for (var i=0; i<vm.trips.length;i++){
                // console.log(vm.trips[i].id);
                if (vm.trips[i].id === tripId){
                    if (vm.trips[i].toWeb == 1) {
                        alert("请先下架该活动");
                    }else if (vm.trips[i].status !== 'EDIT'){
                        vm.confirm = confirms;
                        vm.sendCode = sendCode;
                        vm.confirmTrade = confirmTrade;
                        vm.tripStatus = vm.trips[i].status;
                        $('#myModal1').modal('show');
                    }else {
                        alert("该活动已为编辑状态");
                    }
                }
            }
        }
        function confirms() {
            //点击事件执行后将按钮置灰，请求成功后再回复按钮状态

            var param = {tripId:vm.tripId,status:'EDIT'};
            tripUpdateStatus.updateStatus(param).success(function(data){
                // console.log(data);
                // loadAll();
                for (var i in vm.trips){
                    if (vm.trips[i].id == param.tripId) {
                        vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                    }
                }
                $('#myModal1').modal('hide');
            }).error(function(err){
                // console.log(err);
                if (err!=null){
                    vm.err = err;
                    vm.mTitle2 = "退回编辑";
                    vm.release = false;
                    if ($.inArray("ROLE_RELEASE",vm.account.authorities)!==-1){
                        vm.release = true;
                    } else {}
                    if (vm.release){
                        vm.mContent2 = err.message;
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        vm.confirmsConf = confirmsConf;
                    } else {
                        if (err.errorCode == -1) {
                            vm.mContent2 = "活动已结束，不可退回编辑";
                        } else {
                            vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                        }
                        $('#myModal1').modal('hide');
                        $('#myModal2').modal('show');
                        $('#conf2').css('display','none');
                    }
                }
            });
        }
        function confirmTrade() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {tripId:vm.tripId,status:'EDIT',sessionId:vm.sessionId,validationCode:vm.code};
                tripUpdateStatusTrade.updateStatusTrade(param).success(function(data){
                    console.log("1：：：：" + data);
                    if(data.errorCode != null && data.errorCode == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }
        function setValidationCode() {
            vm.errText = "请输入验证码";
            $('.errText').css('display','block');
        }
        function getValidationCode() {
            vm.errText = "请先获取验证码";
            $('.errText').css('display','block');
        }
        function confirmsConf(){
            console.log("--------3");
            if (vm.err.errorCode!=0){
                var param = {tripId:vm.tripId,status:'EDIT'};
                tripEdit.tEdit(param).success(function(data){
                    // console.log(data);
                    // loadAll();
                    for (var i in vm.trips){
                        if (vm.trips[i].id == param.tripId) {
                            vm.trips.splice(i,1);//从vm.trips数组中移除当前trip
                        }
                    }
                    $('#myModal1').modal('hide');
                    $('#myModal2').modal('hide');
                    $('#myModal3').modal('hide');
                });
            }
        }


        function sendCode() {
            var param = {tripId:vm.tripId};
            TripVerifyStatus.tripVerifyStatus(param).success(function(data){
                console.log(data);
                if (data.code == 0) {
                    $http({
                        url: 'api/e-accounts/'+data.message,
                        method: 'GET'
                    }).then(function (res) {
                        vm.relatedEAccountName = res.data.name;
                        vm.relatedEAccountId = res.data.id;
                        vm.phoneEAccount = res.data.adminAccount;
                    });
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                } else if (data.code == -1) {
                    vm.mContent2 = "线路在进行中，不可退回编辑，想要退回编辑请联系发布管理员";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                } else if (data.code == -3) {
                    vm.mContent2 = "线路在已结算，不可退回编辑";
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('hide');
                    $('#myModal2').modal('show');
                    $('#conf2').css('display','none');
                }
            }).error(function(err){
                console.log(err);
            });

        }

        vm.toWeb=toWeb;
        function toWeb(tripId) {
            vm.mTitle = "上架活动信息";
            vm.tripId = tripId;
            // console.log(tripId);

            if (vm.trip == null) {
                TripModelBaseInfo.getDetail(vm.tripId).success(function (res) {
                    vm.tripModel = res.data;
                });
            }
            if (vm.tripLabels == null) {
                TripLabelGetFromWeb.getLabel().success(function (res) {
                    if (res.code == 0) {
                        vm.tripLabels = res.data;
                        // console.log(vm.tripLabels);
                    }
                });
            }
            if (vm.peopleNums == null) {
                TripPeopleNumGetFromWeb.getPeopleNum().success(function (res) {
                    if (res.code == 0) {
                        vm.peopleNums = res.data;
                        // console.log(vm.peopleNums);
                    }
                });
            }

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                } else if (res.code == -1) {
                    vm.relatedEAccountId = null;
                }
                $('#myModal1').modal('hide');
                $('#myModal4').modal('show');
            }).error(function(err){
                console.log(err);
            });
        }

        vm.confirmToWeb=confirmToWeb;
        function confirmToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                vm.tripWeb.region_name = vm.tripModel.area;
                vm.tripWeb.duration = vm.tripModel.eTime;
                var param = {id:vm.tripId,type:0,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb,eAccountId:vm.relatedEAccountId};
                // console.log(param);
                tripToWeb.toWeb(param).success(function(res){
                    if(res.code != null && res.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal4').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.notToWeb=notToWeb;
        function notToWeb(tripId) {
            vm.tripId = tripId;
            vm.mTitle = "下架";
            vm.notToWebFlag=true;
            console.log(tripId);
            getTripWebInfo();

            var param = {tripId:tripId};
            TripGetEAccount.getEAccount(param).success(function(res){
                //console.log(res);
                if (res.code == 0) {
                    vm.relatedEAccountName = res.data.name;
                    vm.relatedEAccountId = res.data.id;
                    vm.phoneEAccount = res.data.adminAccount;
                    vm.updateOp=false;
                    $('#myModal1').modal('hide');
                    $('#myModal3').modal('show');
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function getTripWebInfo() {
            tripGetFromWeb.getFromWeb(vm.tripId).success(function (res) {
                if (res.code == 0) {
                    vm.tripWeb = res.data;
                }
            });
        }

        vm.confirmNotToWeb=confirmNotToWeb;
        function confirmNotToWeb() {
            vm.clearCodeInfo = clearCodeInfo;
            vm.clearErrText = clearErrText;
            if (vm.code==''){
                setValidationCode();
            }else if(vm.sessionId==''){
                getValidationCode();
            } else {
                var param = {id:vm.tripId,type:3,sessionId:vm.sessionId,validationCode:vm.code,tripWeb:vm.tripWeb};
                tripNotToWeb.notToWeb(param).success(function(data){
                    if(data.code != null && data.code == -4) {
                        vm.errText = "验证码错误";
                    } else {
                        loadAll();
                        $('#myModal3').modal('hide');
                        vm.clearCodeInfo();
                    }
                }).error(function(err){
                    console.log("err:"+err);
                });
            }
        }

        vm.checkLableNum = checkLableNum;
        function checkLableNum() {
            if (vm.tripWeb.labels.length >= 3) {
                $('.overHide').css('display','none');
            }
            var labelIds = '';
            angular.forEach(vm.tripWeb.labels,function(data){
                labelIds = labelIds + data.category_id + ",";
            });
            vm.tripWeb.label = labelIds;
            // console.log(vm.tripWeb.label);
        }
        vm.showLable = showLable;
        function showLable() {
            if (vm.tripWeb.labels.length < 3) {
                $('.overHide').css('display','block');
            }
        }

        vm.setPeopleNum=setPeopleNum;
        function setPeopleNum() {
            vm.tripWeb.renshu_id = vm.tripWeb.renshu.renshu_id;
        }

        // vm.setRegion=setRegion;
        // function setRegion() {
        //     vm.tripWeb.region_id = vm.tripWeb.region.region_id;
        //     console.log(vm.tripWeb.region_id);
        // }

        /*vm.publish = publish;
        function publish(tripId) {
            vm.tripId = tripId;
            for (var i=0; i<vm.trips.length;i++){
                if (vm.trips[i].id === tripId){
                    // if (vm.isRelease){
                        if (vm.trips[i].status !== 'PUBLISH'){
                            // var x = confirm("发布将清空之前测试数据，确定要发布活动 "+tripId+" ?");
                            //少一步判断是否有其他测试账号进行测试
                            if (vm.trips[i].fullTested===false){
                                console.log(vm.trips[i]);
                                //修改modal内容
                                $("#mTitle")[0].innerHTML = "活动发布";
                                $("#mContent")[0].innerHTML = "除了您以外，还没有其它测试账号在实地进行过完整的测试，很有可能会影响到活动执行，是否仍要发布？";
                                $("#mText")[0].innerHTML = "发布活动将清空测试数据";
                                vm.confirmRelease = confirmRelease;
                            }else {
                                $("#mTitle")[0].innerHTML = "活动发布";
                                $("#mContent")[0].innerHTML = "确定要发布该活动？";
                                $("#mText")[0].innerHTML = "发布活动将清空测试数据";
                            }
                        }else {
                            alert("该活动已为发布状态");
                        }
                    // }else {
                    //     alert('您不是活动发布管理员，请联系活动发布管理员发布此活动！！');
                    // }
                }
            }
        }*/
        function confirmRelease() {
            $http({
                method: 'PUT',
                url: '/api/trips/updateStatus/'+vm.tripId+'/PUBLISH'
            }).success(function () {
                console.log();
                $('#myModal1').modal('hide');
                window.location.reload();
            }).error(function(err){
                console.log(err);
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        this.filterEAccounts = function (search) {
            if (search){
                vm.eAccounts = EAccountSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release: "0"
                });
            }
        };

        vm.setEaccount = setEaccount;
        function setEaccount(phone, eAccountId, eAccountName) {
            console.log(phone + "---" + eAccountId);
            vm.phoneEAccount = phone;
            vm.relatedEAccountId = eAccountId;
            vm.relatedEAccountName = eAccountName;
        }

        vm.setEaccountPhone = setEaccountPhone;
        function setEaccountPhone(phone) {
            console.log(phone);
            vm.phoneEAccount = phone;
        }

        //获取图片验证码
        //getImageCode();
        vm.getImageCode = getImageCode;
        function getImageCode() {
            $http({
                url:'/api/getImageCode',
                method:'POST'
            }).success(function (data) {
                // console.log(data);
                if (data!=null){
                    vm.imgUrl = data.url;
                    vm.verifyId = data.verifyId;
                    vm.imgCode = '';
                }
            }).error(function (err) {
                console.log(err);
            });
        }
        //设置倒计时60s
        function setTimeOut() {
            var code = 60;vm.phoneCode = 60+'s';vm.dis = true;
            // $('.checkBtn').css('background-image','none');
            var time = $interval(function () {
                code = code-1;
                vm.phoneCode = code+'s';
            },1000,60);
            time.then(function () {
                $interval.cancel(stop);
                vm.phoneCode = "发送验证码";
                $('.checkBtn').css('background-image','linear-gradient(113deg, #35e8c0, #0c9fcb)');
                vm.dis = false;
            });
        }
        //获取手机验证码
        vm.getCode = getCode;
        function getCode() {
            if (vm.imgCode!=null&&vm.verifyId!=null&&vm.relatedEAccountId!=null){
                $http({
                    url:'/api/getCode',
                    method:'POST',
                    data:{mobile:vm.phoneEAccount,verifyCode:vm.imgCode,verifyId:vm.verifyId}
                }).success(function (res) {
                    //console.log(res);
                    if (res.code==1012){
                        vm.errText="图片验证码不正确";
                        vm.paramError = 'paramError';
                    }else if (res.code==1008){
                        vm.errText="同一手机号一分钟内只能发送一次短信验证码，请稍后再试";
                        getImageCode();
                    }else {
                        //设置倒计时60s
                        setTimeOut();
                        vm.errText=null;
                        vm.sessionId = res.sessionId;
                        vm.paramError = null;
                    }
                }).error(function (err) {
                    console.log(err);
                });
            }else {
                vm.paramError = 'paramError';
            }
        }
    }

    angular
        .module('taskServiceApp')
        .controller('TripNpcDialogController', TripNpcDialogController);

    TripNpcDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance','TripSearch','$http','entity','NpcSearch'];

    function TripNpcDialogController ($timeout, $scope, $stateParams, $uibModalInstance,TripSearch,$http,entity,NpcSearch) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;
        vm.tripNpc = entity;

        var npcs = {};
        vm.tripNpc = {};

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
            if (vm.tripNpc.tripId!=null){
                getNpcByTripId(vm.tripNpc.tripId);
            }
        };

        vm.filterNpc = this.filterNpc;

        this.filterNpc = function (search) {
            if (search){
                vm.npcIds = NpcSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.npcIds);
            }
        };

        if (vm.tripNpc.npcIds!=null){
            getNpc();
        }
        function getNpc(id) {
            $http({
                method: 'GET',
                url: '/api/npcs/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.tripNpc.npcIds = {id:data.id,name:data.name};
                }
                console.log(vm.tripNpc.npcIds);
            }).error(function(err){
                console.log(err);
            });
        }

        if (vm.tripNpc.tripId!=null){
            getTrip();
        }
        function getTrip(id) {
            $http({
                method: 'GET',
                url: '/api/trips/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.tripNpc.tripId = {id:data.id,title:data.title};
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function getNpcByTripId(id) {
            $http({
                method: 'GET',
                url: '/api/getNpcByTripId/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.tripNpc.npcIds = data;
                }
                // console.log(vm.tripNpc.npcIds);
            }).error(function(err){
                console.log(err);
            });
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            var tid = vm.tripNpc.tripId;
            var npcIds = vm.tripNpc.npcIds;
            if (npcIds!=null){
                for (var i=0;i<npcIds.length;i++){
                    npcIds[i] = npcIds[i].id;
                }
            }
            // console.log(npcIds);

            if (npcIds!==null&&npcIds.length>0){
                $http({
                    method: 'PUT',
                    url: '/api/tripNpc/'+tid+'/'+npcIds
                }).success(function (data) {
                    $scope.$emit('', data);
                    $uibModalInstance.close(data);
                    vm.isSaving = false;
                }).error(function(err){
                    vm.isSaving = false;
                    console.log(err);
                });
            }else {
                $http({
                    method: 'DELETE',
                    url: '/api/tripNpcDelete/'+tid
                }).success(function (data) {
                    $scope.$emit('', data);
                    $uibModalInstance.close(data);
                    vm.isSaving = false;
                }).error(function(err){
                    vm.isSaving = false;
                    console.log(err);
                });
            }

        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:npcTeamTripUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }

    angular
        .module('taskServiceApp')
        .controller('TripArCodeController', TripArCodeController);

    TripArCodeController.$inject = ['$timeout', '$uibModalInstance','TripSearchOfficial','Principal'];

    function TripArCodeController ($timeout, $uibModalInstance,TripSearchOfficial,Principal) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
        };

        banRight();//当前页面禁用鼠标右键
        function banRight(){
            if(window.Event)
                document.captureEvents(Event.MOUSEUP);

            function nocontextmenu()
            {
                event.cancelBubble = true;
                event.returnvalue = false;
                return false;
            }

            function norightclick(e)
            {
                if (window.Event)
                {
                    if (e.which == 2 || e.which == 3)
                        return false;
                }
                else if (event.button == 2 || event.button == 3)
                {
                    event.cancelBubble = true;
                    event.returnvalue = false;
                    return false;
                }
            }
            document.oncontextmenu = nocontextmenu;  // for IE5+
            document.onmousedown = norightclick;  //
        }

        vm.clickNormal = showQrCodeNormal;
        function showQrCodeNormal(trip) {
            // vm.qrCode = "itasktour://gototrip?tripid="+id;
            var location = window.location.host;
            vm.qrCodeNormal = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+trip.id;
            document.getElementById("qrCodeTest").style.display = 'none';
            document.getElementById("qrCodeWeb").style.display = 'none';
            document.getElementById("qrCodeNormal").style.display = 'block';
        }
        vm.clickTest = showQrCodeTest;
        function showQrCodeTest(trip) {
            // vm.qrCode = "itasktour://gototrip?tripid="+id;
            var location = window.location.host;
            vm.qrCodeTest = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+trip.id+"&istest=1";
            document.getElementById("qrCodeNormal").style.display = 'none';
            document.getElementById("qrCodeWeb").style.display = 'none';
            document.getElementById("qrCodeTest").style.display = 'block';
        }
        vm.clickWeb = showQrCodeWeb;
        function showQrCodeWeb(trip) {
            // vm.qrCode = "itasktour://gototrip?tripid="+id;
            var location = window.location.host;
            vm.qrCodeWeb = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+trip.id+"&istest=1&isweb=1";
            document.getElementById("qrCodeNormal").style.display = 'none';
            document.getElementById("qrCodeTest").style.display = 'none';
            document.getElementById("qrCodeWeb").style.display = 'block';
        }
        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            }else {
                return false;
            }
        }
        vm.downLoadQr = downLoadQr;
        function downLoadQr(codeType){

            if (codeType == 'normal'){
                html2canvas($('.qrNormal'),{
                    onrendered:function(canvas){
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type),'image/octet-stream');
                        if (isIE()) {
                            // console.log(isIE());
                            $('#myIEModal').modal('show');
                        }else {
                            // console.log(isIE());
                            $('.downNor').attr({
                                'href':imgData,
                                'download':'活动'+vm.trip.id+'正式二维码.png'
                            });
                        }
                    }
                });
            } else {
                html2canvas($('.qrTest'),{
                    onrendered:function(canvas){
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type),'image/octet-stream');
                        if (isIE()) {
                            // console.log(isIE());
                            $('#myIEModal').modal('show');
                        }else {
                            $('.downTes').attr({
                                'href':imgData,
                                'download':'活动'+vm.trip.id+'测试二维码.png'
                            });
                        }
                    }
                });
            }

            var _fixType = function(type) {
                type = type.toLowerCase().replace(/jpg/i, 'jpeg');
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return 'image/' + r;
            };

        }
        vm.iknow = iknow;
        function iknow () {
            $('#myIEModal').modal('hide');
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();

