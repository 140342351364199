(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ts-trips', {
            parent: 'entity',
            url: '/ts/trips?page&sort&search&states&version',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treasure-search/ts-trips.html',
                    controller: 'TsTripController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,
                states: '0',
                version: '0'
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        states: $stateParams.states,
                        version: $stateParams.version
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ts-trip-copy', {
            parent: 'entity',
            url: '/ts/trip-copy/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                id:'',
                upgrade:false
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treasure-search/ts-trip-copy-dialog.html',
                    controller: 'TsTripCopyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                tripId: null
                            };
                        }
                    }
                }).result.then(function() {
                }, function() {
                    window.history.go(-1);
                });
            }]
        })
        .state('ts-trip-publish', {
            parent: 'entity',
            url: '/ts/trip-publish/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treasure-search/ts-trip-publish.html',
                    controller: 'TsTripPublishController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['TsTrip', function(TsTrip) {
                            return TsTrip.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                }, function() {
                    window.history.go(-1);
                });
            }]
        })
        .state('ts-trip-qrcode', {
            parent: 'entity',
            url: '/ts/trip-qrcode/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                id: '',
                title:'',
                type:''
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/treasure-search/ts-trip-qr-code.html',
                    controller: 'TsTripQrCodeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                tripId: null
                            };
                        }
                    }
                }).result.then(function() {
                    window.history.go(-1)
                }, function() {
                    window.history.go(-1)
                });
            }]
        })
        .state('ts-trip-detail', {
            parent: 'entity',
            url: '/ts/trip-detail/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treasure-search/ts-trip-detail.html',
                    controller: 'TsTripDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Trip', function($stateParams, Trip) {
                    return Trip.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ts-trip-rank', {
            parent: 'entity',
            url: '/ts/trip/{id}/rank',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.rank.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treasure-search/ts-trip-rank.html',
                    controller: 'TsTripRankController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TsTripExt', function($stateParams, TsTripExt) {
                    return TsTripExt.getRanksByTsTrip($stateParams.id);
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ts-trip-publish-pay', {
            parent: 'entity',
            url: '/ts/trip-publish/pay/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/treasure-search/ts-trip-publish-pay.html',
                    controller: 'TsTripPayController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TsTrip', function($stateParams, TsTrip) {
                    return TsTrip.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
            .state('ts-my-pay', {
                url: '/ts/my/pay/orderId=:orderId',
                parent: 'entity',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/treasure-search/ts-my-pay.html',
                        controller: 'TsMyPayController',
                        controllerAs: 'vm'
                    }
                },
                params:{
                    "orderId":null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eAccount');
                        $translatePartialLoader.addPart('eStatus');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            tripId: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'e-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('ts-my-pay-success', {
                url: '/ts/my/pay/success',
                parent: 'entity',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/treasure-search/ts-my-pay-success.html',
                        controller: 'TsMyPaySuccessController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eAccount');
                        $translatePartialLoader.addPart('eStatus');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            tripId: null
                        };
                    },
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'e-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('ts-my-pay-error', {
                url: '/ts/my/pay/error',
                parent: 'entity',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/treasure-search/ts-my-pay-error.html',
                        controller: 'TsMyPayErrorController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eAccount');
                        $translatePartialLoader.addPart('eStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MyEAccount', function($stateParams, MyEAccount) {
                        return MyEAccount.get().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'e-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('ts-my-pay-wait', {
                url: '/ts/my/pay/wait',
                parent: 'entity',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/treasure-search/ts-my-pay-wait.html',
                        controller: 'TsMyPayWaitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('eAccount');
                        $translatePartialLoader.addPart('eStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MyEAccount', function($stateParams, MyEAccount) {
                        return MyEAccount.get().$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'e-account',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('exportTsTripRecord', {
                parent: 'entity',
                url: '/export-ts-trip',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/treasure-search/export-trip-publish-record.html',
                        controller: 'ExportTsTripController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    tripId: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('ts-trips', null, { reload: false });
                    }, function() {
                        $state.go('ts-trips');
                    });
                }]
            })
    }

})();
