(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TestAccountDetailController', TestAccountDetailController);

    TestAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TestAccount', 'User'];

    function TestAccountDetailController($scope, $rootScope, $stateParams, previousState, entity, TestAccount, User) {
        var vm = this;

        vm.testAccount = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:testAccountUpdate', function(event, result) {
            vm.testAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
