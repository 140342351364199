(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ProgramCenterDetailController', ProgramCenterDetailController)
        .filter('pdfFilter', function() {
            return function(text) {
                return text+".pdf";
            }
        })
        .filter('labelFilter', function() {
            return function(text) {
                if(text == null){
                    return ''
                }
                var arr = JSON.parse(text);
                return arr;
            }
        })

        ProgramCenterDetailController.$inject = ['$state',  'Principal', '$http', 'ParseLinks', 'AlertService','$stateParams' ,'$location','$scope','$cookies','$sce'];

    function ProgramCenterDetailController($state,  Principal, $http, ParseLinks, AlertService,$stateParams,$location,$scope,$cookies,$sce) {
        var vm = this;

        var id=$stateParams.id;
        var programId =id;
        if(window.location.href.indexOf("v3")!= -1){
            wechatConfig();
        }
        function wechatConfig() {
            $http({
                method: 'POST',
                url: '/api/program/share',
                params: {
                    id:id,
                    url:window.location.href
                }
            }).then(function (res) {
                console.log(res.data)
                wechatStart(res.data)
            },function (err) {
                console.log(err);
            })
        }

       function wechatStart(obj) {
            var linkURL = window.location.href
            wx.config({
                debug: false, // true时，手机微信环境访问会alert一些errmsg信息，如果显示errmsg:ok表示正常，否认表示出了问题
                appId: obj.appId, // 必填，公众号的唯一标识
                timestamp: obj.timestamp, // 后台返回的数据中取
                nonceStr: obj.nonceStr, // 后台返回的数据中取
                signature: obj.signature, // 后台返回的数据中取
                jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"] // 必填，需要使用的JS接口列表
            })
            // 需在用户可能点击分享按钮前就先调用，说白了就是放在文章详情信息读取完后就可以执行下面的wx.ready()方法
            wx.ready(function() {
                // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                wx.updateAppMessageShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function() {
                        // 设置成功
                    }
                })
                // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                wx.updateTimelineShareData({
                    title: obj.title, // 分享标题
                    desc: obj.desc, // 分享描述
                    link: linkURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: obj.img, // 分享图标
                    success: function() {
                        // 设置成功
                    }
                })
            })
        }


        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.programDetail='';
        vm.changeTab = 1;
        vm.lxfs = 2;
        var id=$stateParams.id;
        getDetailById(id);
        doChange(1);
        vm.doChange =doChange;
        vm.lxfsFun=lxfsFun;
        vm.tipSuccess=0;
        document.documentElement.scrollTop = 0;

        $("body").css("background-color", "#ecefef");
        // 发布页面灰色背景关闭
        $('.modal-backdrop').remove();


        function md(strAction){
            $http({
                method: 'POST',
                url: '/api/mdLog/save',
                params: {
                    type: 1,
                    action:strAction
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }

        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(res) {
                vm.account = res.data;
                for (var i = 0; i< vm.account.authorities.length;i++){
                    //判断账号是否为admin
                    if (vm.account.authorities[i] === "ROLE_ADMIN"){
                        vm.isAdmin = true;
                    }
                    if (vm.account.authorities[i] === "ROLE_SUPER"){
                        vm.isSuper = true;
                    }
                }
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            },function (err) {
                if(err.status == 401){//401 说明未登录所以跳转到可以查看的页面
                    $state.go('program-center-detail1',{id:id},{reload: true});
                    window.location
                }
            });
        }

        vm.toTrip= toTrip;
        function toTrip(){
            $state.go('trip-model',null,{reload: true});
        }

        vm.openModel = openModel;
        function openModel(){
            if($(window).width() < 767){
                $('#myModal').modal('show');
                vm.lxfs = 1;
            }else{
                $('#myModal2').modal('show');
            }
        }

        vm.closeModel = closeModel;
        function closeModel(){
            $('#myModal2').modal('hide');
        }

        vm.doSaveClickNum = doSaveClickNum;
        function doSaveClickNum(type) {
            $http({
                method: 'POST',
                url: '/api/program/saveNum',
                params: {
                    id: id,
                    type: type
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }

        document.documentElement.scrollTop = 0;

        vm.toTrip= toTrip;
        function toTrip(){
            $state.go('trip-model',null,{reload: true});
        }


        vm.doSaveClickNum = doSaveClickNum;
        function doSaveClickNum(type) {
            $http({
                method: 'POST',
                url: '/api/program/saveNum',
                params: {
                    id: id,
                    type: type
                }
            }).then(function (res) {

            },function (err) {
                console.log(err);
            })
        }

        var location = window.location.host;
        vm.domain = window.location.protocol+"//"+location;
        vm.pushActivity =pushActivity;
        function pushActivity(type){
                // $state.go(url,null ,{reload:true});
                // window.location.href = vm.domain + "/#/" + (type == 1 ? 'ts' : 'stealth') + '/trips?openRelease=true';
            //window.open( vm.domain + "/#/" + (type == 1 ? 'ts' : 'stealth') + '/trips?openRelease=true','_blank');
            var url ="";
            if(type ==1){
                url = vm.domain + "/#/ts/trips?back="+id
            }else if(type==2){
                url = vm.domain + "/#/stealth/trips?back="+id
            }else if(type==3){
                url = vm.domain + "/#/bomb/trips?back="+id
            }
            window.location.href=url;

        }

        if($(window).width() < 767){
            vm.lxfs = 0
        }


        function lxfsFun(lxfs){
            if($(window).width() < 767){
                $('#myModal').modal('show');
            }
            vm.lxfs = lxfs;
        }
        vm.closeLxfsFunModal = function(){
            $('#myModal').modal('hide');
            vm.lxfs = 0
        }
        function doChange(changeTab){
            vm.changeTab = changeTab;
        }
        vm.pdfDownload = pdfDownload;
        function pdfDownload(type){
            if(type ==1){
                doSaveClickNum(3);
            }else  if(type==2){
                doSaveClickNum(4);
            }
            if($(window).width() < 767){
                var param ={id: id,type : type};
                $state.go('showPDF',{obj: JSON.stringify(param)} ,{reload:true});
                return
            }
            var url ="";
            var name = "";
            if(type ==1){
                url =vm.IMAGE_SERVER+vm.programDetail.programUrl;
                name=vm.programDetail.title+"方案介绍.pdf"
            }else if(type==2){
                url =vm.IMAGE_SERVER+vm.programDetail.manualUrl;
                name=vm.programDetail.title+"执行手册.pdf"
            }
            var url =vm.domain +"/api/program/pdfDownload?downLoadPath="+url+"&fileName="+name;
            var form = $("<form></form>").attr("action", url).attr("method", "post");
            form.appendTo('body').submit().remove();
        }

        vm.toPhone =toPhone;
        function toPhone(phone){
            window.location.href="tel://"+phone;
        }


        function getDetailById(id) {
            $http({
                method: 'GET',
                url: '/api/program/' + id
            }).then(function (res) {
                vm.programDetail = res.data;

              //  document.title = vm.programDetail.title;

                var $body = $('body');
                document.title = vm.programDetail.title;
                var $iframe = $("");
                $iframe.on('load', function() {
                    setTimeout(function() {
                        $iframe.off('load').remove();
                    }, 0);
                }).appendTo($body);
                vm.programDetail.programText = $sce.trustAsHtml(vm.programDetail.programText);
                vm.programDetail.manualText = $sce.trustAsHtml(vm.programDetail.manualText);

                //当跳转过来有copy的时候开始拷贝
                //if($location.search().copy && vm.programDetail.tripModelId != null && vm.programDetail.tripModelId > 0){
                //    confirmCopy(vm.programDetail.tripModelId)
                //}
                var copy = $cookies.get("copy");
                if(copy){
                    $cookies.remove("copy")
                    confirmCopy(vm.programDetail.tripModelId)
                }
            },function (err) {
                console.log(err);
            })
        }

        vm.confirmCopy =confirmCopy;
        vm.close=close;

        vm.closeTip=closeTip;
        function closeTip(){
            vm.tipSuccess=0;
        }


        function confirmCopy (id) {
            if(vm.cannotOpt){
                return
            }
            vm.cannotOpt = true;
            doSaveClickNum(5);
            md("方案-获取线路-"+programId)
            $http({
                method: 'GET',
                url: '/api/trip-models/copy/'+id +'/' + false
            }).then(function (res) {
                // console.log(res);
                // if (res != null && res.data.errorCode == -1){
                //     alert("复制失败，被复制线路中不能有新版AR");
                //     return;
                // }
                // console.log(new Date().getTime())
                // if (res != null && res.data !=null){
                //     if (res.data.hasLocationTasks!=null && typeof(res.data.hasLocationTasks)!='undefined') {
                //         for (var i in res.data.hasLocationTasks){
                //             // console.log(res.data.hasLocationTasks[i]);
                //             if (res.data.hasLocationTasks[i].hasTasks!=null && typeof(res.data.hasLocationTasks[i].hasTasks)!='undefined'){
                //                 for (var j in res.data.hasLocationTasks[i].hasTasks){
                //                     // console.log(res.data.hasLocationTasks[i].hasTasks[j]);
                //                     if (res.data.hasLocationTasks[i].hasTasks[j].type!=null
                //                         && typeof(res.data.hasLocationTasks[i].hasTasks[j].type)!='undefined'
                //                         && res.data.hasLocationTasks[i].hasTasks[j].type == 'NEWAR'){
                //                      //   alert("复制线路中含有新版AR，后台正在识别新版AR的扫描图片，请稍后再次编辑新版AR任务查看识别情况");
                //                       //  return;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
                // console.log(new Date().getTime())
                // $('#myModal1').modal('hide');
                vm.tipSuccess=1;
                var num =2;
                vm.fun =fun;
                function fun() {
                    if(num <= 0) {
                        vm.tipSuccess=0;
                        clearInterval(inter);
                    }
                    document.getElementById("num").innerHTML = num;
                    num=num-1;
                }
                var inter = setInterval(vm.fun, 1000);

                vm.cannotOpt = false;
                // $state.go('trip-model-detail',{id:vm.tripModel.id},{reload:true});
               // $state.go('trip-model',null ,{reload:true});
               // $uibModalInstance.dismiss('cancel');
            },function (err) {
                console.log(err);
                vm.cannotOpt = false;
            });


        }

        vm.closeTopSuccess= function () {

        }

        $scope.$on("$destroy", function() {
            vm.closeLxfsFunModal()
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        })

    }
})();
