(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelToCheckDetailController', TripModelToCheckDetailController);

    TripModelToCheckDetailController.$inject = ['$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', '$state','TripModelToCheck'];

    function TripModelToCheckDetailController($scope, $rootScope, Principal, $http, $stateParams, previousState, entity, $state, TripModelToCheck) {
        var vm = this;

        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripModel = entity;
        vm.previousState = previousState.name;
        vm.showTripsButton = true;
        vm.selectedEAccounts = [];
        vm.sharedToAll = false;

        vm.descriptionLink = vm.IMAGE_SERVER + vm.tripModel.description;
        vm.coverImgLink = vm.IMAGE_SERVER + vm.tripModel.coverImg;
        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        // getToCheckStatus();
        // function getToCheckStatus() {
        //     if(vm.tripModel.toWeb==1) {
        //         vm.pass=true;
        //         vm.notPass = false;
        //     } else if(vm.trip.toWeb==2) {
        //         vm.pass = false;
        //         vm.notPass=true;
        //     } else {
        //         vm.pass = false;
        //         vm.notPass = false;
        //     }
        // }

        // vm.showQrCodeAll = showQrCodeAll;
        // function showQrCodeAll() {
        //     var location = window.location.host;
        //     vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+vm.trip.id+"&istest=1";
        //     document.getElementById("qrCode").style.display = 'block';
        // }


        vm.tripModelCheckPass = tripModelCheckPass;
        function tripModelCheckPass() {
            var param = {id:vm.tripModel.id,type:1,reason:null};
            // console.log(param);
            TripModelToCheck.toCheck(param).success(function (res) {
                vm.pass=true;
                $state.go('trip-model-to-check-list',{id:vm.tripModel.id},{reload:true})
            })
        }
        vm.tripModelCheckNotPass = tripModelCheckNotPass;
        function tripModelCheckNotPass() {
            if (vm.tripModel.reason == null){
                alert("请输入不通过原因！！");
            }else {
                var param = {id:vm.tripModel.id,type:2,reason:vm.tripModel.reason};
                TripModelToCheck.toCheck(param).success(function (res) {
                    vm.notPass=true;
                    $state.go('trip-model-to-check-list',{id:vm.tripModel.id},{reload:true})
                })
            }
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:tripModelUpdate', function(event, result) {
            vm.tripModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
