(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('GameController', GameController);

    GameController.$inject = ['$filter','$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', '$state'];

    function GameController($filter,$scope, $rootScope,Principal, $http, $stateParams, previousState, $state) {
        var vm = this;
        vm.bombProgramId = null;
        getBombProgramId();
        function getBombProgramId(){
            var url = "/api/getBombProgram"
            $http({
                method: 'POST',
                url: url
            }).success(function (res) {
                if(res!=null && res.length>0){
                    vm.bombProgramId = res[0].id
                }
            }).error(function () {
                vm.isSaving = false;
            });
        }


    }


    angular
        .module('taskServiceApp')
        .controller('StealthIntroController', StealthIntroController);

    StealthIntroController.$inject = ['$filter','$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', '$state'];

    function StealthIntroController($filter,$scope, $rootScope,Principal, $http, $stateParams, previousState, $state) {

    }
})();


