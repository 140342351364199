(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .directive('pageRibbon', pageRibbon);

    pageRibbon.$inject = ['ProfileService', '$rootScope', '$translate'];

    function pageRibbon(ProfileService, $rootScope, $translate) {
        var directive = {
            replace : true,
            restrict : 'AE',
            template : '<div class="ribbon hidden"><a href="" translate="global.ribbon.{{ribbonEnv}}">{{ribbonEnv}}</a></div>',
            link : linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            ProfileService.getProfileInfo().then(function(response) {

                if (response.ribbonEnv){
                    scope.ribbonEnv = response.ribbonEnv;
                    element.addClass(response.ribbonEnv);
                    element.removeClass('hidden');
                }else {
                    //harlanchang: 根据域名判断是否加dev标签-（debug加，v3不加）
                    var host = window.location.host;
                    // console.log(response);
                    // 经过测试发现这个地方才是决定后台管理的左上角的红色开发标识是否展示的地方，济南正式服这个地方需要配上济南正式服的域名才能去掉红色开发标识
                    if (host.indexOf('api.jngysz.')==-1){
                        scope.ribbonEnv = 'dev';
                        element.addClass('dev');
                        element.removeClass('hidden');
                    }
                }

            });
        }
    }
})();
