(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('program-center-detail1', {
            // parent: 'entity',
             url: '/program-center-detail1/:id',
             data: {
                /// authorities: ['ROLE_USER'],
                // pageTitle: 'taskServiceApp.tripModel.detail.title'
             },
             views: {
                 'content@': {
                     templateUrl: 'app/entities/program/program-center-detail.html',
                     controller: 'ProgramCenterDetail1Controller',
                     controllerAs: 'vm'
                 }
             },
             resolve: {

                 translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                     $translatePartialLoader.addPart('trip');
                     $translatePartialLoader.addPart('eTripType');
                     $translatePartialLoader.addPart('tripStatusType');
                     $translatePartialLoader.addPart('mapType');
                     $translatePartialLoader.addPart('tripAttribute');
                     $translatePartialLoader.addPart('global');
                     return $translate.refresh();
                 }]
             }
         })
        .state('showPDF', {
            // parent: 'entity',
            url: '/program-showPDF/:obj',
            data: {},
            views: {
                'content@': {
                    templateUrl: 'app/entities/program/program-showPDF.html',
                    controller: 'ProgramShowPDFController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('program-center-detail', {
            parent: 'entity',
            url: '/program-center-detail/:id',
            data: {
                // authorities: ['ROLE_USER'],
               // pageTitle: 'taskServiceApp.tripModel.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/program/program-center-detail.html',
                    controller: 'ProgramCenterDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {

                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('program-center', {
                parent: 'entity',
                url: '/program-center?page&sort&search&states&version',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/program/program-center.html',
                        controller: 'ProgramCenterController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    states: '0',
                    login:false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            states: $stateParams.states,
                            version: $stateParams.version
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('program', {
                parent: 'entity',
                url: '/program?page&sort&search&states&version',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/program/program.html',
                        controller: 'ProgramController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    states: '0',
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            states: $stateParams.states,
                            version: $stateParams.version
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('program.edit', {
                parent: 'program',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/program/program-dialog.html',
                        controller: 'ProgramDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['ProgramInfo', function(ProgramInfo) {
                                return ProgramInfo.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        window.history.go(-1)
                    }, function() {
                        window.history.go(-1)
                    });
                }]
            })
            .state('program.newEdit', {
                parent: 'program',
                url: '/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/program/program-dialog.html',
                        controller: 'ProgramDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    title: null,
                                    mark:'',
                                    description: '',
                                    coverImg: null,
                                    status: null,
                                    labels: [''],
                                    programUrl: null,
                                    programText: null,
                                    manualUrl: null,
                                    manualText: null,
                                    correlation: false,
                                    programType: '',
                                    tripModelId: null,
                                    tripModel:null,
                                    createBy: null,
                                    updateDate: null,
                                    updateBy: null,
                                    createDate:null
                                };
                            }
                        }
                    }).result.then(function() {
                        window.history.go(-1)
                    }, function() {
                        window.history.go(-1)
                    });
                }]
            })
            .state('programTripModel', {
                parent: 'entity',
                url: '/program/trip-model?page&sort&search&states&version',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/program/program-tripModel.html',
                        controller: 'ProgramTripModelController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    states: '0',
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            states: $stateParams.states,
                            version: $stateParams.version
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
