(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('MomentCommentSearch', MomentCommentSearch);

    MomentCommentSearch.$inject = ['$resource'];

    function MomentCommentSearch($resource) {
        var resourceUrl =  'api/_search/moment-comments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
