/**
 * Created by snowzhang on 2017/5/31.
 */
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ResetOneController', ResetOneController);

    ResetOneController.$inject = ['$http','$scope','$timeout','$uibModalInstance','TripSearchOfficial'];

    function ResetOneController ($http,$scope,$timeout,$uibModalInstance,TripSearchOfficial) {
        // var vm = this;
        // console.log("ResetOneController");
        // reset();
        //
        // function reset(){
        //
        // }

        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
                // console.log(vm.trips);
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            $http({
                method:'get',
                url:'api/trips/getTripsOneCurrentData?tripId='+vm.tripId
            }).success(function (data) {
                $scope.result = data;
                console.log(data);
                if (data!=null){
                    vm.word = "同步成功";
                }
            }).error(function(err){
                // $scope.result = "同步失败";
                console.log(err);
                if (err!=null){
                    vm.word = "同步失败"
                }
            });
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();

