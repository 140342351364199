(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EAccountMemberNewController', EAccountMemberNewController);

    EAccountMemberNewController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Principal'];

    function EAccountMemberNewController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Principal) {
        var vm = this;

        vm.eAccount = entity;
        vm.account = null;
        vm.clear = clear;
        vm.copy = false;
        var location = window.location.host;
        vm.domain = window.location.protocol+"//"+location;
        vm.joinLink = vm.domain + "/#/confirm-join/" + vm.eAccount.id + "/" + vm.eAccount.name;


        vm.copyLink = copyLink;
        function copyLink() {
            var Url2=document.getElementById("joinLink").value;
            var oInput = document.createElement('input');
            oInput.value = Url2;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象
            var tag = document.execCommand("Copy"); // 执行浏览器复制命令
            oInput.className = 'oInput';
            oInput.style.display='none';
            if (tag) {
                vm.copy = true;
            }

        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    vm.joinLink = vm.joinLink + "/" + vm.account.id + "/" + vm.account.firstName;
                    vm.joinLink = encodeURI(encodeURI(vm.joinLink));
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
// (function() {
//     'use strict';
//
//     angular
//         .module('taskServiceApp')
//         .controller('EAccountMemberNewController', EAccountMemberNewController);
//
//     EAccountMemberNewController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity','MyEAccount'];
//
//     function EAccountMemberNewController ($timeout, $scope, $stateParams, $uibModalInstance, entity,MyEAccount) {
//         var vm = this;
//
//         vm.eAccount = entity;
//         vm.clear = clear;
//         vm.save = save;
//         vm.deleteDesign = this.deleteDesign;
//         vm.eAccount.role = 'DESIGNER';
//
//         $timeout(function (){
//             angular.element('.form-group:eq(1)>input').focus();
//         });
//
//         function clear () {
//             $uibModalInstance.dismiss('cancel');
//         }
//
//         function save () {
//             vm.isSaving = true;
//             if (vm.eAccount.login!=null){
//                 // var phone = vm.eAccount.login;
//                 // if(!(/^1[34578]\d{9}$/.test(phone))){
//                 //     alert("手机号码有误，请重填");
//                 //     vm.eAccount.login = null;
//                 //     vm.isSaving = false;
//                 //     return;
//                 // }
//                 // console.log(vm.eAccount.role);
//                 if (vm.eAccount.role == 'DESIGNER') {
//                     //保存添加企业设计师
//                     MyEAccount.addDegigner({
//                         eid: vm.eAccount.id,
//                         login: vm.eAccount.login,
//                         isTrainee: 0
//                     }, onSaveSuccess, onSaveError);
//                 }else {
//                     //保存添加企业兼职设计师
//                     MyEAccount.addDegigner({
//                         eid: vm.eAccount.id,
//                         login: vm.eAccount.login,
//                         isTrainee: 1
//                     }, onSaveSuccess, onSaveError);
//                 }
//             }
//         }
//
//         this.deleteDesign = function(hasUser){
//             var index = vm.eAccount.hasUsers.indexOf(hasUser);
//             var userId = vm.eAccount.hasUsers[index].id;
//
//             MyEAccount.deleteDegigner({eid:vm.eAccount.id,uid:userId},onSaveSuccess,onSaveError);
//
//             // vm.eAccount.hasUsers.splice(index,1);
//             // console.log(vm.eAccount.hasUsers);
//             // EAccount.update(vm.eAccount, onSaveSuccess, onSaveError);
//         };
//
//         function onSaveSuccess (result) {
//             console.log(result);
//             vm.phone = null;
//             $scope.$emit('taskServiceApp:eAccountUpdate', result);
//             $uibModalInstance.close(result);
//         }
//
//         function onSaveError (err) {
//             console.log(err);
//             vm.eAccount.login = null;
//             vm.isSaving = false;
//             return;
//         }
//
//     }
// })();
