(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('EAccountSearch', EAccountSearch);

    EAccountSearch.$inject = ['$resource'];

    function EAccountSearch($resource) {
        var resourceUrl =  'api/_search/e-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('EAccountSearchByIDAndName', EAccountSearchByIDAndName);

    EAccountSearchByIDAndName.$inject = ['$resource'];

    function EAccountSearchByIDAndName($resource) {
        var resourceUrl =  'api/_search/e-accounts';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

