(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RabbitFindingsController', RabbitFindingsController);

    RabbitFindingsController.$inject = ['$scope', '$timeout', 'Principal', '$state', '$uibModal', 'Auth','$http','$sce','RabbitTrip'];

    function RabbitFindingsController ($scope, $timeout, Principal, $state, $uibModal, Auth, $http, $sce,RabbitTrip) {
        var vm = this;
        // vm.baseURL = 'https://www.itasktour.com';
        // vm.baseURL = 'https://dev.itasktour.com';
        vm.isShowQR = '';
        vm.publishLocation = '发布地区';
        vm.isShowLocation = false;
        vm.area = [
            '上海', '南昌', '新疆北','大洋洲'
        ]
        vm.focusIndex = 2;
        vm.authenticationError = false;
        vm.focus = focus;
        // vm.location = window.location.host;
        // vm.protocol = window.location.protocol;
        vm.count = 2;
        vm.data = [
            // {id: 1},
            // {id: 2},
            // {id: 3},
            // {id: 4},
            // {id: 5},
            // {id: 6},
            // {id: 7},
            // {id: 8}
        ];

        vm.images_service = 'https://image.itasktour.com';

        vm.showQRimg = showQRimg;
        vm.hideQRimg = hideQRimg;
        vm.login = login;
        vm.creatArray = creatArray; //生成黄色星星
        vm.createGreyAarry = createGreyAarry; //生成灰色星星
        vm.getDateDiff = getDateDiff;//时间换算
        vm.showSelectLocations = showSelectLocations;
        vm.selectOneLocation = selectOneLocation;

        getURL();
        function getURL() {
            var loacl_href = window.location.href;
            // if(loacl_href.indexOf('dev.itasktour.com') != -1) {
            //     vm.protocol = 'http:';
            //     console.log(vm.protocol);
            //     vm.location = 'dev.itasktour.com';
            //     vm.location_QR = 'dev.itasktour.com';
            // }
            // if(loacl_href.indexOf('deve.itasktour.com') != -1) {
            //     vm.protocol = 'http:';
            //     // console.log(vm.protocol);
            //     vm.location = 'dev.itasktour.com';
            //     vm.location_QR = 'dev.itasktour.com';
            // }
            // // if (loacl_href.indexOf('rabbit-findings')!= -1) {
            // //     vm.protocol = 'https:';
            // // }
            // if(loacl_href.indexOf('v3.itasktour.com') != -1)  {
            //     vm.protocol = 'https:';
            //     vm.location = 'www.itasktour.com';
            //     vm.location_QR = 'v3.itasktour.com';
            // }

            if(loacl_href.indexOf('api.jngysz.com') != -1)  {
                vm.protocol = 'https:';
                vm.location = 'api.jngysz.com';
                vm.location_QR = 'api.jngysz.com';
            }
        }

        /**
         * 获取列表总数totals
         */
        getAllTotals();
        function getAllTotals() {
            vm.artistId = '';
            vm.page = 0;
            vm.size = 10000;
            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/list?'+'page='+vm.page+'&size='+vm.size
            }).then(
                function (res) {
                    // console.log(res.data);
                    vm.totals = res.data.length;
                    // console.log(vm.totals);
                }
            )
        }

        /**
         * 获取列表数据 function
         */
        getListData();
        function  getListData() {
            vm.artistId = '';
            vm.page = 0;
            vm.size = 12;
            // $http({
            //     methods: 'GET',
            //     url: 'api/trip-model/check/pass/list?'+'page='+vm.page+'&size='+vm.size
            // }).then(
            //     function (res) {
            //         // console.log(res.data);
            //         vm.data = res.data;
            //     }
            // )
            RabbitTrip.query({
                page: vm.page,
                size: vm.size
            }, onSuccess);
        }
        function onSuccess(data, headers) {
            // vm.links = ParseLinks.parse(headers('link'));
            // vm.totalItems = headers('X-Total-Count');
            // console.log(headers);
            // console.log(data);
            vm.data = data;
        }

        /**
         *生成黄色星星
         */
        function creatArray(num) {
            var newStarArray = [];
            for(var i=0;i<Math.floor(num);i++){
                newStarArray.push(i);
            }
            return newStarArray;
        }

        /**
         * 生成灰色星星
         */
        function createGreyAarry(num) {
            var newStarArray = [];
            for (var i = 0; i < Math.ceil(num); i++) {
                newStarArray.push(i);
            }
            return newStarArray;
        }

        /**
         * 点击加载更多按钮  function
         */
        vm.getMoreData = getMoreData;
        function getMoreData(num) {
            // console.log(num);
            vm.artistId = '';
            vm.page = 0;
            vm.size = 12*num;
            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/list?'+'page='+vm.page+'&size='+vm.size
            }).then(
                function (res) {
                    // console.log(res.data);
                    vm.data = res.data;
                    vm.count++;
                    if ( vm.data.length >= vm.totals) {
                        $(".more-loading-area").css('display','none');
                    }else {
                        $(".more-loading-area").css('display','block');
                    }
                }
            )
        }
        /**
         * login 登录
         */
        function login(event) {
            // alert("");
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password
            }).then(function (data) {
                // getAccount();
                vm.authenticationError = false;
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('guide');
                }
                $state.go('guide');
                // if (Auth.getPreviousState()) {
                //     var previousState = Auth.getPreviousState();
                //     Auth.resetPreviousState();
                //     $state.go(previousState.name, previousState.params);
                // }
                // console.log(data);
                // if (data!=null){
                //     if (data.data.access_token!=null){
                //         // getVersion();
                //     }
                // }
            }).catch(function (err) {
                // console.log(err);
                vm.authenticationError = true;
            });
        }
        /**
         * 切换tabs
         * @param index
         */

        function focus(index) {
            // console.log(index);
            vm.focusIndex = index;
        }

        /**
         * 显示线路体验demo二维码function
         */
        function showQRimg(index) {
            // console.log('show',index);
            vm.isShowQR = index;
            $(".down .pc_div").eq(index).find("#a-mask").addClass('a-mask');
        }

        /**
         * 隐藏线路体验demo二维码function
         * @param index
         */
        function hideQRimg(index) {
            // console.log('hide ',index);
            vm.isShowQR = index;
            $(".down .pc_div").eq(index).find("#a-mask").removeClass('a-mask');
        }

        /**
         *
         */
        // vm.showQrCode = showQrCode;
        // function showQrCode(id) {
        //     // vm.qrCode = "itasktour://gototrip?tripid="+id;
        //     var location = window.location.host;
        //     vm.qrCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+id;
        //     vm.qrTestCode = window.location.protocol+"//"+location+"/mobile/official-trip-c.html?tripId="+id+"&istest=1";
        //     document.getElementById("qrCode").style.display = 'block';
        //     // document.getElementById("qrTestCode").style.display = 'block';
        // }

        /**
         * 换算时间function
         */
        function getDateDiff(updateTime){
            // console.log(updateTime);
            var minute = 1000 * 60;
            var hour = minute * 60;
            var day = hour * 24;
            var halfamonth = day * 15;
            var month = day * 30;
            var now = new Date().getTime();
            var commit = updateTime;
            commit = commit.replace(/-/g,"/");
            commit = commit.replace(/T/g," ");
            commit.substring(commit.length-5);
            var update = new Date(commit).getTime();//2018-08-01 09:56:53

            var diffValue = now-update;
            if(diffValue < 0){
                return;
            }
            var monthC = diffValue/month;
            var weekC = diffValue/(7*day);
            var dayC = diffValue/day;
            var hourC = diffValue/hour;
            var minC = diffValue/minute;
            var result = null;
            if(monthC>=1){
                result="" + parseInt(monthC) + "个月前";
            }
            else if(weekC>=1){
                result="" + parseInt(weekC) + "周前";
            }
            else if(dayC>=1){
                result=""+ parseInt(dayC) +"天前";
            }
            else if(hourC>=1){
                result=""+ parseInt(hourC) +"小时前";
            }
            else if(minC>=1){
                result=""+ parseInt(minC) +"分钟前";
            }else
                result="刚刚";
            return result;
        }

        /**
         *
         */
        function  showSelectLocations() {
            // alert("ss");
            vm.isShowLocation = !vm.isShowLocation;
            $('.search-area .type-ul-li').eq(0).addClass('type-ul-li-focus').siblings().removeClass('type-ul-li-focus');
            // console.log(vm.isShowLocation);
        }

        function selectOneLocation(location) {
            // console.log(location);
            vm.publishLocation = location;
            queryByCity(location);

        }

        //根据城市查询
        function queryByCity(location) {
            vm.artistId = '';
            vm.page = 0;
            vm.size = 100;
            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/list?'+'page='+vm.page+'&size='+vm.size+'&area='+location
            }).then(
                function (res) {
                    console.log(res.data);
                    vm.data = res.data;
                    $(".more-loading-area").css('display','none');
                    // if ( vm.data.length >= vm.totals) {
                    //     $(".more-loading-area").css('display','none');
                    // }else if(vm.data.length == 0){
                    //     $(".more-loading-area").css('display','none');
                    // }else {
                    //
                    // }
                }
            )
        }
        //加载所有地区数据
        vm.getAlleara = getAlleara;
        function getAlleara(){
            vm.publishLocation = '所有地区';
            getListData();
        }

        getCitys();
        function getCitys() {
            // vm.artistId = '';
            // vm.page = 0;
            // vm.size = 12*num;
            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/city/list'
            }).then(
                function (res) {
                    // console.log(res);
                    vm.area = res.data;

                }
            )
        }
    //.type-ul-li-focus
        vm.getLastTimeData = getLastTimeData;
        function  getLastTimeData() {
           $('.search-area .type-ul-li').eq(1).addClass('type-ul-li-focus').siblings().removeClass('type-ul-li-focus');
            vm.artistId = '';
            vm.page = 0;
            vm.size = 12;
            vm.sort = 'updateDate,DESC';

            $http({
                methods: 'GET',
                url: 'api/trip-model/check/pass/list?'+'page='+vm.page+'&size='+vm.size+'&sort='+vm.sort
            }).then(
                function (res) {
                    // console.log(res.data);
                    vm.data = res.data;
                    if ( vm.data.length >= vm.totals) {
                        $(".more-loading-area").css('display','none');
                    }else {
                        $(".more-loading-area").css('display','block');
                    }
                }
            )
        }

    }
})();
