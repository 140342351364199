(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider
            .state('reset-trip', {
            parent: 'admin',
            url: '/reset-trip',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.admin.reset-current-trip'
            },
            views: {
                'content@': {
                    templateUrl: 'app/admin/reset/reset-current-trip.html',
                    controller: 'ResetController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // entity:['$http','$scope', function($http, $scope) {
                //     // Simple GET request example:
                //     $http({
                //         method: 'GET',
                //         url: 'api/trips/getTripsCurrentData'
                //     }).then(function successCallback(response) {
                //         // this callback will be called asynchronously
                //         // when the response is available
                //         $scope.content = response;
                //     }, function errorCallback(response) {
                //         // called asynchronously if an error occurs
                //         // or server returns response with an error status.
                //     });
                // }],
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }]
            }
        })
    .state('reset-one-trip', {
            parent: 'admin',
            url: '/reset-one-trip/{tripId}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'global.menu.admin.reset-one-trip'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/reset/reset-one-trip.html',
                    controller: 'ResetOneController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                tripId: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('trip', null, { reload: 'trip' });
                }, function() {
                    $state.go('trip');
                });
            }]
            /*views: {
                'content@': {
                    templateUrl: 'app/admin/reset/reset-one-trip.html',
                    controller: 'ResetOneController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                // entity:['$http','$scope', function($http, $scope) {
                //     // Simple GET request example:
                //     $http({
                //         method: 'GET',
                //         url: 'api/trips/getTripsCurrentData'
                //     }).then(function successCallback(response) {
                //         // this callback will be called asynchronously
                //         // when the response is available
                //         $scope.content = response;
                //     }, function errorCallback(response) {
                //         // called asynchronously if an error occurs
                //         // or server returns response with an error status.
                //     });
                // }],
                translatePartialLoader: ['$translate', function ($translate) {
                    return $translate.refresh();
                }]
            }*/
        });
    }
})();
