(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MomentDeleteController',MomentDeleteController);

    MomentDeleteController.$inject = ['$uibModalInstance', 'entity', 'Moment'];

    function MomentDeleteController($uibModalInstance, entity, Moment) {
        var vm = this;

        vm.moment = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Moment.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
