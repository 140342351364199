(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('AdDetailController', AdDetailController)
        .filter('typeFilter', function() {
            return function(text) {
                if(text==0){
                    return "App";
                }else if(text==1){
                    return "方案中心";
                }else if(text==2){
                    return "登录后首屏";
                }else{
                    return "未知";
                }
            }
        });

    AdDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Ad'];

    function AdDetailController($scope, $rootScope, $stateParams, previousState, entity, Ad) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.ad = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:adUpdate', function(event, result) {
            vm.ad = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
