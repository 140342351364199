(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ForgetPwdController', ForgetPwdController);


    ForgetPwdController.$inject = ['$translate','$timeout','$http','Register','$interval'];

    function ForgetPwdController ($translate,$timeout,$http,Register,$interval) {
        var vm = this;

        !(function(window, document) {
            var size = 4;//设置验证码长度
            function GVerify(options) { //创建一个图形验证码对象，接收options对象为参数
                this.options = { //默认options参数值
                    id: "", //容器Id
                    canvasId: "verifyCanvas", //canvas的ID
                    width: "80", //默认canvas宽度
                    height: "30", //默认canvas高度
                    type: "blend", //图形验证码默认类型blend:数字字母混合类型、number:纯数字、letter:纯字母
                    code: "",
                }
                if(Object.prototype.toString.call(options) == "[object Object]"){//判断传入参数类型
                    for(var i in options) { //根据传入的参数，修改默认参数值
                        this.options[i] = options[i];
                    }
                }else{
                    this.options.id = options;
                }

                this.options.numArr = "0,1,2,3,4,5,6,7,8,9".split(",");
                this.options.letterArr = getAllLetter();

                this._init();
                this.refresh();
            }

            GVerify.prototype = {
                /**版本号**/
                version: '1.0.0',

                /**初始化方法**/
                _init: function() {
                    console.log(this.options.id)
                    var con = document.getElementById(this.options.id);
                    console.log(con.offsetWidth)
                    var canvas = document.createElement("canvas");
                    this.options.width = con.offsetWidth > 0 ? con.offsetWidth : "80";
                    this.options.height = con.offsetHeight > 0 ? con.offsetHeight : "30";
                    canvas.id = this.options.canvasId;
                    canvas.width = this.options.width;
                    canvas.height = this.options.height;
                    canvas.style.cursor = "pointer";
                    canvas.innerHTML = "您的浏览器版本不支持canvas";
                    con.appendChild(canvas);
                    var parent = this;
                    canvas.onclick = function(){
                        parent.refresh();
                    }
                },

                /**生成验证码**/
                refresh: function() {
                    this.options.code = "";
                    var canvas = document.getElementById(this.options.canvasId);
                    if(canvas.getContext) {
                        var ctx = canvas.getContext('2d');
                    }else{
                        return;
                    }

                    ctx.textBaseline = "middle";

                    ctx.fillStyle = randomColor(180, 240);
                    ctx.fillRect(0, 0, this.options.width, this.options.height);

                    if(this.options.type == "blend") { //判断验证码类型
                        var txtArr = this.options.numArr.concat(this.options.letterArr);
                    } else if(this.options.type == "number") {
                        var txtArr = this.options.numArr;
                    } else {
                        var txtArr = this.options.letterArr;
                    }

                    for(var i = 1; i <=size; i++) {
                        var txt = txtArr[randomNum(0, txtArr.length)];
                        this.options.code += txt;
                        ctx.font = randomNum(this.options.height/1, this.options.height) + 'px SimHei'; //随机生成字体大小
                        ctx.fillStyle = randomColor(50, 160); //随机生成字体颜色
                        ctx.shadowOffsetX = randomNum(-3, 3);
                        ctx.shadowOffsetY = randomNum(-3, 3);
                        ctx.shadowBlur = randomNum(-3, 3);
                        ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
                        var x = this.options.width / (size+1) * i;
                        var y = this.options.height / 2;
                        var deg = randomNum(-30, 30);
                        /**设置旋转角度和坐标原点**/
                        ctx.translate(x, y);
                        ctx.rotate(deg * Math.PI / 180);
                        ctx.fillText(txt, 0, 0);
                        /**恢复旋转角度和坐标原点**/
                        ctx.rotate(-deg * Math.PI / 180);
                        ctx.translate(-x, -y);
                    }
                    /**绘制干扰线**/
                    for(var i = 0; i < 0; i++) {
                        ctx.strokeStyle = randomColor(40, 180);
                        ctx.beginPath();
                        ctx.moveTo(randomNum(0, this.options.width), randomNum(0, this.options.height));
                        ctx.lineTo(randomNum(0, this.options.width), randomNum(0, this.options.height));
                        ctx.stroke();
                    }
                    /**绘制干扰点**/
                    for(var i = 0; i < this.options.width/40; i++) {
                        ctx.fillStyle = randomColor(0, 255);
                        ctx.beginPath();
                        ctx.arc(randomNum(0, this.options.width), randomNum(0, this.options.height), 1, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    uploadCode(this.options.code);

                },

                /**验证验证码**/
                validate: function(code){
                    var code = code.toLowerCase();
                    var v_code = this.options.code.toLowerCase();
                    if(code == v_code){
                        return true;
                    }else{
                        this.refresh();
                        return false;
                    }
                }
            }
            /**生成字母数组**/
            function getAllLetter() {
                var letterStr = "a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z";
                return letterStr.split(",");
            }
            /**生成一个随机数**/
            function randomNum(min, max) {
                return Math.floor(Math.random() * (max - min) + min);
            }
            /**生成一个随机色**/
            function randomColor(min, max) {
                var r = randomNum(min, max);
                var g = randomNum(min, max);
                var b = randomNum(min, max);
                return "rgb(" + r + "," + g + "," + b + ")";
            }
            window.GVerify = GVerify;
        })(window, document);


        var verifyCode = new GVerify("v_container");
        var  yzm_key=""
        function uploadCode(code){
            $http({
                url:'/api/uploadYzmCode?code='+code,
                method:'GET'
            }).success(function (data) {
                console.log(data);
                if(data.status==0){
                    yzm_key = data.data
                    console.log(yzm_key)
                }
            }).error(function (err) {
                console.log(err);
            })
        }


        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.reset = reset;
        vm.registerAccount = {};
        vm.success = null;
        vm.paramError = null;
        vm.checked = false;
        vm.code = "发送验证码";
        vm.dis = false;
        vm.rongYunServer = false;
        checkRongYunMsgServer();
        function checkRongYunMsgServer() {
            $http({
                url:'/api/check/rongyun/msg-server',
                method:'GET'
            }).success(function (data) {
                console.log("检查服务" + JSON.stringify(data));
                if (data.errorCode == 0) {

                    vm.rongYunServer = true;
                    //获取图片验证码
                    getImageCode();
                }else{
                        document.getElementById("yzm").style.display="block";
                }
            }).error(function (err) {
                console.log(err);
            })
        }

        $timeout(function (){angular.element('#login').focus();});

        //获取图片验证码
        //getImageCode();
        vm.getImageCode = getImageCode;
        function getImageCode() {
            $http({
                url:'/api/getImageCode',
                method:'POST'
            }).success(function (data) {
                // console.log(data);
                if (data!=null){
                    vm.imgUrl = data.url;
                    vm.verifyId = data.verifyId;
                }
            }).error(function (err) {
                console.log(err);
            })
        }
        //设置倒计时60s
        function setTimeOut() {
            var code = 60;vm.code = 60+'s';vm.dis = true;
            $('.checkBtn').css('background-image','none');
            var time = $interval(function () {
                code = code-1;
                vm.code = code+'s';
            },1000,60);
            time.then(function () {
                $interval.cancel(stop);
                vm.code = "发送验证码";
                $('.checkBtn').css('background-image','linear-gradient(113deg, #35e8c0, #0c9fcb)');
                vm.dis = false;
            });
        }
        //获取手机验证码
        vm.getCode = getCode;
        function getCode() {
            vm.exit = null;
            var loginID = vm.registerAccount.login;
            if(loginID == null || vm.registerAccount.login.length !=11){
                vm.exit = 2;//电话格式不对
                return;
            }
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: loginID,
                }
            }).success(function (res) {
                console.log("---" + res)
                if (res.errorCode != 0) {
                    vm.exit = 3;//当前用户不存在
                }else{
                    if (vm.rongYunServer) {
                        //融云短信
                        if (vm.registerAccount.login!=null&&vm.registerAccount.imgCode!=null&&vm.verifyId!=null){
                            $http({
                                url:'/api/getCode',
                                method:'POST',
                                data:{mobile:vm.registerAccount.login,verifyCode:vm.registerAccount.imgCode,verifyId:vm.verifyId}
                            }).success(function (data) {
                                if (data.sessionId==null||data.sessionId==""){
                                    vm.paramError = 'paramError';
                                }else {
                                    //设置倒计时60s
                                    setTimeOut();
                                    vm.registerAccount.sessionId = data.sessionId;
                                    vm.paramError = null;
                                }
                            }).error(function (err) {
                                console.log(err);
                            })
                        } else {
                            vm.paramError = 'paramError';
                        }
                    } else {
                        //阿里短信
                        if (vm.registerAccount.login!=null&& yzm_key !=""){
                            $http({
                                url:'/api/getCode',
                                method:'POST',
                                data:{mobile:vm.registerAccount.login,verifyCode:vm.registerAccount.imgCode,verifyId:yzm_key}
                            }).success(function (data) {
                                console.log(data)
                                if (data.code==200){
                                    setTimeOut()
                                    vm.paramError = null;
                                }else {
                                    vm.paramError = 'paramError';
                               }
                            }).error(function (err) {
                                console.log(err);
                            })
                        } else {
                            vm.paramError = 'paramError';
                        }
                    }
                }
            })
        }
        function reset() {
            console.log(vm.registerAccount);
            vm.exit = null;
            var loginID = vm.registerAccount.login;
            if(loginID == null || loginID.length !=11){
                vm.exit = 2;//电话格式不对
                return;
            }
            if( vm.checkPwd ){//如果电话和密码还存在格式问题就不进行请求
                return;
            }
            vm.registerAccount.langKey = $translate.use();
            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.errorEmailExists = null;
            vm.paramError = null;
            vm.codeError = null;

                // Auth.createAccount(vm.registerAccount).then(function (data) {
                Register.getRegist(vm.registerAccount).then(function (data) {
                    console.log(data);
                    if (data.data.errorCode==-1){
                        vm.errors = 'ERROR';
                    }else if (data.data.errorCode==8){
                        // vm.exit = 4;//密码找回成功，请返回登录
                        alert("找回密码成功")
                        window.location.href = window.location.protocol+"//"+window.location.host;
                    }else if(data.data.errorCode==2){
                        vm.codeError = 'codeError';
                    }
                }).catch(function (response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        vm.errorEmailExists = 'ERROR';
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            // }
        }

        load();
        function load() {
            $("#nav, #foot").css("display", "none");
            $("#con").removeClass().addClass("con");
            $(".container").addClass("login-container-content");
            $(".eAccountIdent").css("min-height", $(window).height());
            window.onresize = function (event) {
                $(".eAccountIdent").css("min-height", $(window).height());
            };
            if ($(window).height()>=732) $(".footer .foot").removeClass("mobile");
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }
        vm.openWeb  = openWeb;
        function openWeb() {
            // window.location.href = window.location.protocol+"//"+window.location.host;
            // if(window.location.hostname.startsWith("dev")){
            //     window.location.href = "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
            // }else {
            //     window.location.href = "https://www.itasktour.com";
            // }
            // window.location.href = "https://api.jngysz.com";
            window.location.href = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com" : "https://testapi.itasktour.com";
        }


        vm.checkLogin = checkLogin;
        function checkLogin() {//检查是否已经存在用户
            vm.exit = null;
            var loginID = vm.registerAccount.login;
            if(loginID == null || vm.registerAccount.login.length !=11){
                vm.exit = 2;//电话格式不对
                return;
            }
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: loginID,
                }
            }).success(function (res) {
                console.log("---" + res)
                if (res.errorCode != 0) {
                    vm.exit = 3;//当前用户不存在
                }
            })
        }

        vm.checkPwdFun = checkPwdFun ;
        function checkPwdFun(){
            vm.checkPwd = null;
            var pwd = vm.registerAccount.password;
            if(pwd == null || pwd.length < 6 || pwd.length > 16){
                vm.checkPwd = '密码格式错误';
            }
        }
    }
})();
