(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsTripDetailController', TsTripDetailController);

    TsTripDetailController.$inject = ['$filter','$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', '$state'];

    function TsTripDetailController($filter,$scope, $rootScope,Principal, $http, $stateParams, previousState, entity, $state) {
        var vm = this;
        vm.trip = entity;
        vm.previousState = previousState.name;
        vm.showTestButton = true;
        vm.showNormalButton = true;
        vm.showNormalButtonAll = true;
        vm.showWebButton = true;
        vm.afterTripStartTime = false; //当前时间是在活动开始日期0点之后
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        // if (vm.trip != null) {
        //     var date = $filter('date')(vm.trip.startTime,'yyyy-MM-dd');
        //     var startDay = new Date((date + " " + "00:00").replace(/-/g, "/"));
        //     var nowTime = new Date().getTime();
        //     if (nowTime > startDay.getTime()){
        //         vm.afterTripStartTime = true;
        //     }
        // }

        vm.tripQrCode = tripQrCode;
        function tripQrCode(id){
            $state.go('ts-trip-qrcode',{id: id} ,{reload: false});
        }

        vm.editTrip = function(){
            $('#myModal1').modal('show');
        }


        vm.save = function () {
            vm.paramTitleError = null;
            vm.paramCodeError = null;
            if (vm.trip.title == null) {
                vm.paramTitleError = "请填写活动名称";
            }
            if (vm.trip.startCode == null) {
                vm.paramCodeError = "请填写开启口令";
            }
            if (vm.trip.title != null && vm.trip.startCode != null) {
                vm.isSaving = true;
                var url = '/api/gamets/trip?startCode=' + vm.trip.startCode + "&tripId=" + vm.trip.id + "&title=" + vm.trip.title;
                $http({
                    method: 'POST',
                    url: url
                }).success(function () {
                    $('#myModal1').modal('hide');
                    $('.modal-backdrop').remove();
                    window.setTimeout(function () {
                        $state.go('ts-trip-detail', {id: vm.trip.id}, {reload: true});
                    }, 300);


                }).error(function () {
                    vm.isSaving = false;
                });
            }
                // if ((vm.trip.startTime!=null&&vm.trip.startTime!='underfine')&&(vm.trip.endTime!=null&&vm.trip.endTime!='underfine')){
                //     var time =$filter('date')(vm.trip.startTime,'yyyy-MM-dd HH:mm');
                //     vm.startTime = new Date(time.replace(/-/g, "/"));
                //     var timeEnd=$filter('date')(vm.trip.endTime,'yyyy-MM-dd HH:mm');
                //     vm.endTime = new Date(timeEnd.replace(/-/g, "/"));
                //     var start = new Date(vm.startTime).getTime();
                //     var end = new Date(vm.endTime).getTime();
                //     if (end<=start){
                //         alert("结束时间必须在开始时间之后，请重新录入结束时间！");
                //         vm.endTime = null;
                //         vm.isSaving = false;
                //         return;
                //     }
                //     var now = new Date().getTime();
                //     if (end<now){
                //         alert("活动结束时间不能在当前时间之前！");
                //         vm.endTime = null;
                //         vm.isSaving = false;
                //         return;
                //     }
                // }else {
                //     //正则验证时间格式是否正确
                //     var startTime = checkTime(vm.trip.startTime);
                //     var endTime = checkTime(vm.trip.endTime);
                //     if (startTime===false){
                //         alert("请输入正确的开始时间格式!");
                //         vm.trip.startTime = null;
                //         vm.isSaving = false;
                //         return;
                //     }else if (endTime===false){
                //         alert("请输入正确的结束时间格式!");
                //         vm.trip.endTime = null;
                //         vm.isSaving = false;
                //         return;
                //     }
                // }
        }

        //正则验证时间格式
        // function checkTime(str) {
        //     // console.log(str);
        //     str = String(str);
        //     str = str.replace(/ GMT.+$/, '');// Or str = str.substring(0, 24)
        //     var d = new Date(str);
        //     var a = [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()];
        //     for(var i = 0, len = a.length; i < len; i ++) {
        //         if(a[i] < 10) {
        //             a[i] = '0' + a[i];
        //         }
        //     }
        //     str = a[0] + '-' + a[1] + '-' + a[2] + ' ' + a[3] + ':' + a[4] + ':' + a[5];
        //     var reDateTime = /^(?:19|20)[0-9][0-9]-(?:(?:[0][1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
        //     var isDateTime = reDateTime.test(str);
        //     return isDateTime;
        // }

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    for (var i = 0; i< vm.account.authorities.length;i++){
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN"){
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE"){
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        vm.showTeams = showTeams;
        function showTeams(param) {
            if (param.type == 'test' && !vm.trip.hasTestTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasTestTeams = res.data;
                    vm.showTestButton = false;
                },function (err) {
                    console.log(err);
                })
            }else if (param.type == 'normal' && !vm.trip.hasNormalTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasNormalTeams = res.data;
                    vm.showNormalButton = false;
                },function (err) {
                    console.log(err);
                })
            }else if (param.type == 'web' && !vm.trip.hasWebTeams){
                $http({
                    method: 'GET',
                    url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type
                }).then(function (res) {
                    // console.log(res);
                    vm.trip.hasWebTeams = res.data;
                    vm.showWebButton = false;
                },function (err) {
                    console.log(err);
                })
            }
        }

        vm.showAllTeams = showAllTeams;
        function showAllTeams(param) {
            $http({
                method: 'GET',
                url: '/api/trip/getTeamsByTripId/'+param.id+'/'+param.type + '?reloadTeam=true'
            }).then(function (res) {
                vm.trip.hasNormalTeams = res.data;
                vm.showNormalButtonAll = false;
            },function (err) {
                console.log(err);
            })
        }


        vm.acfe = acfe;
        function acfe(){
            window.open(vm.account.acfeUrl + vm.tripModel.id + '?at=' + vm.account.password + '_coach');
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:tripUpdate', function(event, result) {
            vm.trip = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.openEdidLocation = function (trip) {
            window.open(vm.account.acfeUrl + trip.myTripModelId + '?at=' + vm.account.password + '_coach');
        }
    }
})();


