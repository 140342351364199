(function() {
    'use strict';


    angular
        .module('taskServiceApp')
        .controller('TsTripController', TsTripController);

    TsTripController.$inject = ['$filter','$location','$scope', '$state', 'Trip','Principal', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants','$http','TsTripSearch','$sce','getAvailableCouponsByEId','getAvailableCoupons'];

    function TsTripController ($filter, $location,$scope, $state, Trip, Principal, ParseLinks, AlertService, pagingParams,
                               paginationConstants, $http, TsTripSearch,$sce, getAvailableCouponsByEId,getAvailableCoupons) {
        var vm = this;
        vm.account = null;
        vm.isAdmin = false;
        vm.isSuper = false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.parray = [ '状态','编辑','进行中','已结束'];
        vm.states = pagingParams.states;
        vm.state = vm.parray[vm.states];
        vm.versionArray = [ '版本','室内版','室外版'];
        vm.versions = pagingParams.version;
        vm.version = vm.versionArray[vm.versions];
        vm.absURL =$location.$$absUrl;
        // vm.urlSearch = UrlSearch()
        vm.urlSearch = $location.$$search
        vm.paramTitleError = null;
        vm.paramCodeError = null;
        vm.nowTime = new Date().getTime();
        vm.publishSuccess = false;
        vm.time = "2098-12-30T16:00:00.000Z";
        var couponIds = [];
        // console.log(JSON.stringify(vm.urlSearch));
        //跳转过来需要打开发布
        vm.back = vm.urlSearch.back;
        if(vm.urlSearch.openRelease || (vm.back!=null&&vm.back!="")){
            $('#myModal1').modal('show');
        }
        vm.save = save;
        vm.tripVersion = 0;
        vm.editTripFlag = false;

        // $("body").removeClass('modal-open')
        $scope.htmlTooltip1 = $sce.trustAsHtml('<p style="text-align: left"><br>玩家在进入游戏时输入预设的口令即可开始游戏，活动无需设置日期，灵活控制，随时开启。</p>');
        $scope.htmlTooltip2 = $sce.trustAsHtml('<p style="text-align: left"><br>团队版第3组完成后的24小时内，活动有效，期间玩家可自由扫秒活动码加入。过期后活动失效，所有正在进行的战队将被迫结束，玩家无法加入。</p>');
        $scope.htmlTooltip3 = $sce.trustAsHtml('<p style="text-align: left"><br>整场活动不限制人数和组队数，可根据活动需求自由安排组队。</p>');

        loadAll();
        vm.tripQrCode = tripQrCode;
        function tripQrCode(id, title, type){
            $state.go('ts-trip-qrcode',{id:id, title:title, type:type} ,{reload : false});
        }

        vm.newTrip = function(){
            vm.tripId = null;
            vm.title = null;
            vm.startCode = null;
            $('#myModal1').modal('show');
        }

        vm.clearSave = function() {
            vm.tripVersion = 0;
        }

        vm.editTrip = function(tripId){
            Trip.get({id:tripId},function (res) {
                if (res != null) {
                    var trip = res;
                    vm.tripId = trip.id;
                    vm.startCode = trip.startCode;
                    vm.title = trip.title;
                    vm.tripStatus = trip.status;

                    if (trip.type == 'Indoor') {
                        vm.tripVersion = 1;
                    } else {
                        vm.tripVersion = 2;
                    }
                    $('#myModal1').modal('show');
                }
            })
        }
        vm.openEdidLocation = function (trip) {
            window.open(vm.account.acfeUrl + trip.myTripModelId + '?at=' + vm.account.password + '_coach');
        }

        vm.setVersion = function(version){
            vm.tripVersion = version;
        }
        vm.cancelSave = function () {
            vm.tripVersion = 0;
        }
        function save() {
            vm.isSaving = true;
            vm.paramTitleError = null;
            vm.paramCodeError = null;
            if (vm.title == null) {
                vm.paramTitleError = "请填写活动标题";
            }
            if (vm.startCode == null) {
                vm.paramCodeError = "请填写开启口令";
            }
            if (vm.title != null && vm.startCode != null) {
                vm.paramTitleError = null;
                vm.paramCodeError = null;

                if (vm.tripId == null) {
                    if(vm.tripVersion == 2){
                        var url = '/api/gamets/trip?startCode=' + vm.startCode + '&version=' + vm.tripVersion + '&title=' + vm.title;
                        if (vm.tripId != null) {
                            url = url + "&tripId=" + vm.tripId;
                        } else {
                            var mapData = {};
                            mapData.locationInfo = initLocation();
                            mapData.weatherTemplate = initWeather();
                        }

                        $http({
                            method:'POST',
                            url: url,
                            data: {mapData: mapData}
                        }).success(function (res) {
                            $('#myModal1').modal('hide');
                            $('.modal-backdrop').remove();
                            if(res.myTripModelId != null && vm.tripVersion == 2){
                                window.open(vm.account.acfeUrl + res.myTripModelId + '?at=' + vm.account.password + '_coach');
                            }
                            window.setTimeout(function () {
                                $state.go('ts-trips', null, {reload: true});
                            },300);

                        }).error(function(){
                            vm.isSaving = false;
                        });
                    }else{
                        vm.isSaving = false;
                        $('#myModal1').modal('hide');
                        vm.payCoupon = 50;
                        getCoupons();
                        $('#myModal2').modal('show');
                    }
                } else {
                    //修改，不扣点，直接保存
                    var url = '/api/gamets/trip?startCode=' + vm.startCode + '&version=' + vm.tripVersion + '&title=' + vm.title + "&tripId=" + vm.tripId;
                    $http({
                        method:'POST',
                        url: url,
                        data: {mapData: ""}
                    }).success(function (res) {
                        $('#myModal1').modal('hide');
                        $('.modal-backdrop').remove();
                        window.setTimeout(function () {
                            $state.go('ts-trips', null, {reload: true});
                        },300);
                    }).error(function(){
                        vm.isSaving = false;
                    });
                }

            }else{
                vm.isSaving = false;
            }
        }


        vm.outdoorRelease = outdoorRelease;
        function outdoorRelease(data) {
            vm.release = false;
            Trip.get({id:data.id},function (res) {
                if (res != null) {
                    var trip = res;
                    vm.tripId = trip.id;
                    vm.startCode = trip.startCode;
                    vm.title = trip.title;
                    vm.tripStatus = trip.status;

                    if (trip.type == 'Indoor') {
                        vm.tripVersion = 1;
                    } else {
                        vm.tripVersion = 2;
                        $http({
                            method:'GET',
                            url: '/api/gamets/wt?tripModelId=' + trip.myTripModelId,
                        }).success(function (res) {
                            console.log(res);
                            if(res != null && res.locationOpts != null && res.locationOpts !=''){
                                var locationOpts = JSON.parse(res.locationOpts);
                                if(Object.keys(locationOpts).length == 39){
                                    vm.release = true;
                                }
                            }
                        }).error(function(){
                        });
                    }
                }
            })
            vm.payCoupon = 50;
            getCouponsOfTripId(data);
            $('#myModal2').modal('show');
        }

        function getCouponsOfTripId(trip){
            //独立设计师不请求钱
            if(vm.auth=='IGC'){
                return
            }
            vm.isSaving = false;
            getAvailableCoupons.getCoupons(trip.id).then(function (res) {
                vm.couponsData = res.data;
                if (res.data.availableCoupons != null){
                    vm.availableCoupons = res.data.availableCoupons;
                    vm.unavailableCoupons = res.data.unavailableCoupons;
                } else {
                    if (res.data.differenceCoupons != null){
                        var differenceCoupons = res.data.differenceCoupons;
                        toReCharge(differenceCoupons[0].availableCoupon);
                    } else {
                        toReCharge(50);
                    }
                }
            },function (err) {
                console.log(err);
            })
        }

        function getCoupons(){
            //独立设计师不请求钱
            if(vm.auth=='IGC'){
                return
            }
            vm.isSaving = false;
            getAvailableCouponsByEId.getCouponsByEId(vm.payCoupon).then(function (res) {
                vm.couponsData = res.data;
                if (res.data.availableCoupons != null){
                    vm.availableCoupons = res.data.availableCoupons;
                    vm.unavailableCoupons = res.data.unavailableCoupons;
                } else {
                    if (res.data.differenceCoupons != null){
                        var differenceCoupons = res.data.differenceCoupons;
                        toReCharge(differenceCoupons[0].availableCoupon);
                    } else {
                        toReCharge(50);
                    }
                }
            },function (err) {
                console.log(err);
            })
        }

        $().ready(function () {
            if (vm.couponsData!=null&&vm.couponsData!=""&&vm.availableCoupons.length>0){
                $('#'+vm.availableCoupons[0].id).prop("checked",true);
            }
        });

        //将选择的点券存到couponIds数组中
        vm.chooseCoupon = chooseCoupon;
        function chooseCoupon(id){
            var length = couponIds.length;
            var checked = $('#'+id)[0].checked;
            if (checked){
                couponIds[length] = id;
            }else {
                var index = couponIds.indexOf(id);
                if (index>-1){couponIds.splice(index,1)};
            }
            $('.errText').css('display','none');
        }

        function toReCharge(difference) {
            if (difference != null) {
                vm.toReCharge = true;
                vm.showPublishTitle = false;
                vm.differenceCoupon = difference;
            } else {
                vm.mContent = "发布活动所需点数不足，请先前往充值。";
            }

            vm.mText = null;
            $('.showOrHide').css('display','none');
            vm.confirmPrePublish = confirm;

        }

        vm.goToRecharge = goToRecharge;
        function goToRecharge() {
            $('#myModal2').modal('hide');
            $(".modal-backdrop").remove();
            $("body").removeClass('modal-open')
            $state.go('e-account-my-purchase',{},{reload:true});
        }

        function selectedCouponsIsNotnough() {
            console.log("---点券不足---");
            vm.errText = "所选的点券不足";
            $('.errText').css('display','block');
        }

        vm.publishTrip = publishTrip;
        function publishTrip() {
            vm.publishSuccess = true;
            var url = '/api/trip-game/publish?type=TS&startCode=' + vm.startCode + '&version=' + vm.tripVersion + '&title=' + vm.title + '&couponIds=' + couponIds;
            if (vm.tripId != null) {
                url = url + "&tripId=" + vm.tripId;
            } else {
                var mapData = {};
                mapData.locationInfo = initLocation();
                mapData.weatherTemplate = initWeather();
            }

            $http({
                method:'POST',
                url: url,
                data: {mapData: mapData}
            }).success(function (res) {
                $('#myModal1').modal('hide');
                $('.modal-backdrop').remove();
                window.setTimeout(function () {
                    $state.go('ts-trips', null, {reload: true});
                },300);

            }).error(function(err){
                $('#myModal2').modal('show');
                vm.isSaving = false;
                console.log(err);
                if (err != null) {
                    if (err.errorCode == -2) {
                        selectedCouponsIsNotnough();
                    }else if(err.errorCode == -3){
                        alert("点位不足无法发布")
                    }
                }
            });
        }


        vm.stateChange = stateChange;
        function stateChange(){
            vm.page = 0;
            loadAll ();
        }
        vm.versionChange = versionChange;
        function versionChange(){
            vm.page = 0;
            loadAll ();
        }


        getAccount();
        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (account) {
                vm.account = account.data;
                if (vm.account!==null){

                    $(".container").removeClass("login-container-content");
                    $("#nav, #foot").css("display", "block");

                    for (var i = 0; i< vm.account.authorities.length;i++){
                        //判断账号是否为admin
                        if (vm.account.authorities[i] === "ROLE_ADMIN"){
                            vm.isAdmin = true;
                        }
                        if (vm.account.authorities[i] === "ROLE_RELEASE"){
                            vm.isRelease = true;
                        }
                    }
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1) { //&& $.inArray("ROLE_SUPER",author)===-1)
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
            });
        }

        vm.ident = ident;
        function ident() {
            $("#myModal2").modal('hide');
            if (vm.account.eAccountId!=null){
                window.setTimeout(function () {
                    $state.go("e-account-my.edit",{},{reload:true});
                },300);
            }else {
                window.setTimeout(function () {
                    $state.go("e-account-my.init",{},{reload:true});
                },300);
            }
        }

        function loadAll () {
            var type = 0;
            switch (vm.state) {
                case '编辑':
                    type = 1;break;
                case '进行中':
                    type = 2;break;
                case '已结束':
                    type = 3;break;
            }
            var version = 0;
            switch (vm.version) {
                case '室内版':
                    version = 1;break;
                case '室外版':
                    version = 2;break;
            }
            TsTripSearch.query({
                version: version,
                type: type,
                query: pagingParams.search != null ? pagingParams.search:'',
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.trips = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            switch (vm.state) {
                case '状态':
                    vm.state = 0;
                    break;
                case '编辑':
                    vm.state = 1;
                    break;
                case '进行中':
                    vm.state = 2;
                    break;
                case '已结束':
                    vm.state = 3;
                    break;
            }
            switch (vm.platform) {
                case '版本':
                    vm.version = 0;break;
                case '基础版':
                    vm.version = 1;break;
                case '专业版':
                    vm.version = 2;break;
            }
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                states: vm.state,
                version: vm.version
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            if(vm.back!=null&&vm.back!=""){
                $(".modal-backdrop").remove();
                $('#myModal2').modal('hide');
                $('#myModal1').modal('hide');
                $state.go("program-center-detail",{"id":vm.back},{reload:true});
                return;
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.errText = null;
            vm.transition();
            vm.urlSearch = {}
            $("body").removeClass('modal-open')
            $(".modal-backdrop").remove();
            $('#myModal2').modal('hide');
            $('#myModal1').modal('hide');
            //body会天机padding-right这里做置空
            $('body').css("padding-right" , 0)
            vm.tripId = null
        }



        vm.keyDown = function(event) {
            if (event.keyCode == 13) {
                vm.search(vm.searchQuery);
            }
        };

        // 天气变量
        // 第一列:晴天，第二列:高温，第三列:沙暴，第四列:热沙暴
        var weatherRandom = [
            [10, 0, 0, 0], //0大本营
            [7, 2, 1, 0], //1草地
            [5, 3, 1, 1], //2戈壁
            [3, 4, 2, 1], //3沙漠
            [1, 3, 3, 3], //4魔鬼城
            [10, 0, 0, 0], //5金山
            [8, 2, 0, 0], //6村庄,
            [9, 1, 0, 0], //7绿洲
        ]

        // 地形变量
        // type:6 村庄, type:7绿洲
        // location: 参考gc.js里: locationOptions 的 key
        var locationRandom = [
            {
                type: 7,
                name: 'L1',
                location: [5, 7, 9]
            },
            {
                type: 7,
                name: 'L2',
                location: [24, 26, 28]
            },
            {
                type: 7,
                name: 'L3',
                location: [11, 13]
            },
            {
                type: 6,
                name: 'C1',
                location: [23, 25, 27]
            },
            {
                type: 6,
                name: 'C2',
                location: [6, 8, 10]
            },
            {
                type: 6,
                name: 'C3',
                location: [12, 14]
            }
        ];

        // 初始化地形
        function initLocation() {
            var result = {}
            for (var i = 0; i < locationRandom.length; i++) {
                var item = locationRandom[i];
                var iRand = parseInt(item.location.length * Math.random())
                result[item.location[iRand]] = {
                    name: item.name,
                    type: item.type
                }
            }
            return result
        }

        // Fisher–Yates shuffle
        // 方案二（Fisher–Yates shuffle）（ES6）算法
        // 关于JavaScript的数组随机排序: https://oldj.net/blog/2017/01/23/shuffle-an-array-in-javascript
        function shuffle(arr) {
            var i = arr.length;
            while (i) {
                var j = Math.floor(Math.random() * i--);
                [arr[j], arr[i]] = [arr[i], arr[j]];
            }
        }

        // 初始化天气
        // 生成60天的天气
        function initWeather() {
            var result = []
            for (var x = 0; x < 20; x++) {
                for (var i = 0; i < weatherRandom.length; i++) {
                    var nds = []
                    for (var y = 0; y < weatherRandom[i].length; y++) {
                        for (var j = 0; j < weatherRandom[i][y]; j++) {
                            nds.push(y);
                        }
                        shuffle(nds)
                        for (var k = 0; k < nds.length; k++) {
                            if (!result[x * 10 + k]) result[x * 10 + k] = []
                            result[x * 10 + k][i] = nds[k]
                        }
                    }
                }
            }
            return result
        }

    }



    // angular
    //     .module('taskServiceApp')
    //     .controller('TsTripController', TsTripController);
    //
    // TsTripController.$inject = ['$filter','$location','$scope', '$state', 'Trip','Principal', 'ParseLinks', 'AlertService', 'pagingParams',
    //     'paginationConstants','$http','TsTripSearch'];
    //
    // function TsTripController ($filter, $location,$scope, $state, Trip, Principal, ParseLinks, AlertService, pagingParams,
    //                          paginationConstants, $http, TsTripSearch) {
    //     var vm = this;
    //     vm.account = null;
    //     vm.isAdmin = false;
    //     vm.isSuper = false;
    //     vm.loadPage = loadPage;
    //     vm.predicate = pagingParams.predicate;
    //     vm.reverse = pagingParams.ascending;
    //     vm.transition = transition;
    //     vm.itemsPerPage = paginationConstants.itemsPerPage;
    //     vm.clear = clear;
    //     vm.search = search;
    //     vm.loadAll = loadAll;
    //     vm.searchQuery = pagingParams.search;
    //     vm.currentSearch = pagingParams.search;
    //     vm.parray = [ '状态','编辑','进行中','已结束'];
    //     vm.states = pagingParams.states;
    //     vm.state = vm.parray[vm.states];
    //     vm.versionArray = [ '版本','室内版','室外版'];
    //     vm.versions = pagingParams.version;
    //     vm.version = vm.versionArray[vm.versions];
    //     vm.absURL =$location.$$absUrl;
    //     vm.paramTitleError = null;
    //     vm.paramCodeError = null;
    //     vm.nowTime = new Date().getTime();
    //
    //     vm.save = save;
    //     vm.tripVersion = 0;
    //     vm.editTripFlag = false;
    //
    //
    //
    //     $scope.htmlTooltip1 = $sce.trustAsHtml('<p style="text-align: left"><br>玩家在进入游戏时输入预设的口令即可开始游戏，活动无需设置日期，灵活控制，随时开启。</p>');
    //     $scope.htmlTooltip2 = $sce.trustAsHtml('<p style="text-align: left"><br>团队版第3组完成后的24小时内，活动有效，期间玩家可自由扫秒活动码加入。过期后活动失效，所有正在进行的战队将被迫结束，玩家无法加入。</p>');
    //     $scope.htmlTooltip3 = $sce.trustAsHtml('<p style="text-align: left"><br>整场活动不限制人数和组队数，可根据活动需求自由安排组队。</p>');
    //
    //     loadAll();
    //     vm.tripQrCode = tripQrCode;
    //     function tripQrCode(id, title, type){
    //         $state.go('ts-trip-qrcode',{id:id, title:title, type:type} ,{reload : false});
    //     }
    //
    //     vm.newTrip = function(){
    //         vm.tripId = null;
    //         vm.title = null;
    //         vm.startCode = null;
    //         $('#myModal1').modal('show');
    //     }
    //
    //     vm.clearSave = function() {
    //         vm.tripVersion = 0;
    //     }
    //
    //     vm.editTrip = function(tripId){
    //         Trip.get({id:tripId},function (res) {
    //             if (res != null) {
    //                 var trip = res;
    //                 vm.tripId = trip.id;
    //                 vm.startCode = trip.startCode;
    //                 vm.title = trip.title;
    //                 vm.tripStatus = trip.status;
    //
    //                 if (trip.type == 'Indoor') {
    //                     vm.tripVersion = 1;
    //                 } else {
    //                     vm.tripVersion = 2;
    //                 }
    //                 $('#myModal1').modal('show');
    //             }
    //         })
    //     }
    //     vm.openEdidLocation = function (trip) {
    //         window.open(vm.account.acfeUrl + trip.myTripModelId + '?at=' + vm.account.password + '_coach');
    //     }
    //
    //     vm.setVersion = function(version){
    //         vm.tripVersion = version;
    //     }
    //     vm.cancelSave = function () {
    //         vm.tripVersion = 0;
    //     }
    //     function save() {
    //         vm.paramTitleError = null;
    //         vm.paramCodeError = null;
    //         if (vm.title == null) {
    //             vm.paramTitleError = "请填写活动标题";
    //         }
    //         if (vm.startCode == null) {
    //             vm.paramCodeError = "请填写开启口令";
    //         }
    //         if (vm.title != null && vm.startCode != null) {
    //             vm.isSaving = true;
    //             vm.paramTitleError = null;
    //             vm.paramCodeError = null;
    //             var url = '/api/gamets/trip?startCode=' + vm.startCode + '&version=' + vm.tripVersion + '&title=' + vm.title;
    //             if (vm.tripId != null) {
    //                 url = url + "&tripId=" + vm.tripId;
    //             } else {
    //                 var mapData = {};
    //                 mapData.locationInfo = initLocation();
    //                 mapData.weatherTemplate = initWeather();
    //             }
    //
    //             $http({
    //                 method:'POST',
    //                 url: url,
    //                 data: {mapData: mapData}
    //             }).success(function (res) {
    //                 $('#myModal1').modal('hide');
    //                 $('.modal-backdrop').remove();
    //                 if(res.myTripModelId != null && vm.tripVersion == 2){
    //                     window.open(vm.account.acfeUrl + res.myTripModelId + '?at=' + vm.account.password + '_coach');
    //                 }
    //                 window.setTimeout(function () {
    //                     $state.go('ts-trips', null, {reload: true});
    //                 },300);
    //
    //             }).error(function(){
    //                 vm.isSaving = false;
    //             });
    //         }
    //     }
    //
    //
    //     vm.stateChange = stateChange;
    //     function stateChange(){
    //         vm.page = 0;
    //         loadAll ();
    //     }
    //     vm.versionChange = versionChange;
    //     function versionChange(){
    //         vm.page = 0;
    //         loadAll ();
    //     }
    //
    //     getAccount();
    //     function getAccount() {
    //         Principal.identity().then(function(account) {
    //             vm.account = account;
    //             if (vm.account!==null){
    //                 for (var i = 0; i< vm.account.authorities.length;i++){
    //                     //判断账号是否为admin
    //                     if (vm.account.authorities[i] === "ROLE_ADMIN"){
    //                         vm.isAdmin = true;
    //                     }
    //                     if (vm.account.authorities[i] === "ROLE_RELEASE"){
    //                         vm.isRelease = true;
    //                     }
    //                 }
    //                 var author = vm.account.authorities;
    //                 if ($.inArray("ROLE_SUPER",author)!==-1){
    //                     vm.auth = "Super";
    //                 }
    //             }
    //         });
    //     }
    //
    //     function loadAll () {
    //         var type = 0;
    //         switch (vm.state) {
    //             case '编辑':
    //                 type = 1;break;
    //             case '进行中':
    //                 type = 2;break;
    //             case '已结束':
    //                 type = 3;break;
    //         }
    //         var version = 0;
    //         switch (vm.version) {
    //             case '室内版':
    //                 version = 1;break;
    //             case '室外版':
    //                 version = 2;break;
    //         }
    //         TsTripSearch.query({
    //             version: version,
    //             type: type,
    //             query: pagingParams.search != null ? pagingParams.search:'',
    //             page: pagingParams.page - 1,
    //             size: vm.itemsPerPage,
    //             sort: sort()
    //         }, onSuccess, onError);
    //
    //         function sort() {
    //             var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
    //             if (vm.predicate !== 'id') {
    //                 result.push('id');
    //             }
    //             return result;
    //         }
    //         function onSuccess(data, headers) {
    //             vm.links = ParseLinks.parse(headers('link'));
    //             vm.totalItems = headers('X-Total-Count');
    //             vm.queryCount = vm.totalItems;
    //             vm.trips = data;
    //             vm.page = pagingParams.page;
    //         }
    //         function onError(error) {
    //             AlertService.error(error.data.message);
    //         }
    //     }
    //
    //     function loadPage (page) {
    //         vm.page = page;
    //         vm.transition();
    //     }
    //
    //     function transition () {
    //         switch (vm.state) {
    //             case '状态':
    //                 vm.state = 0;
    //                 break;
    //             case '编辑':
    //                 vm.state = 1;
    //                 break;
    //             case '进行中':
    //                 vm.state = 2;
    //             case '已结束':
    //                 vm.state = 3;
    //                 break;
    //         }
    //         switch (vm.platform) {
    //             case '版本':
    //                 vm.version = 0;break;
    //             case '基础版':
    //                 vm.version = 1;break;
    //             case '专业版':
    //                 vm.version = 2;break;
    //         }
    //         $state.transitionTo($state.$current, {
    //             page: vm.page,
    //             sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
    //             search: vm.currentSearch,
    //             states: vm.state,
    //             version: vm.version
    //         });
    //     }
    //
    //     function search (searchQuery) {
    //         if (!searchQuery){
    //             return vm.clear();
    //         }
    //         vm.links = null;
    //         vm.page = 1;
    //         vm.reverse = false;
    //         vm.currentSearch = searchQuery;
    //         vm.transition();
    //     }
    //
    //     function clear () {
    //         vm.links = null;
    //         vm.page = 1;
    //         vm.predicate = 'id';
    //         vm.reverse = true;
    //         vm.currentSearch = null;
    //         vm.errText = null;
    //         vm.transition();
    //     }
    //
    //
    //
    //     vm.keyDown = function(event) {
    //         if (event.keyCode == 13) {
    //             vm.search(vm.searchQuery);
    //         }
    //     };
    //
    //     // 天气变量
    //     // 第一列:晴天，第二列:高温，第三列:沙暴，第四列:热沙暴
    //     var weatherRandom = [
    //         [10, 0, 0, 0], //0大本营
    //         [7, 2, 1, 0], //1草地
    //         [5, 3, 1, 1], //2戈壁
    //         [3, 4, 2, 1], //3沙漠
    //         [1, 3, 3, 3], //4魔鬼城
    //         [10, 0, 0, 0], //5金山
    //         [8, 2, 0, 0], //6村庄,
    //         [9, 1, 0, 0], //7绿洲
    //     ]
    //
    //     // 地形变量
    //     // type:6 村庄, type:7绿洲
    //     // location: 参考gc.js里: locationOptions 的 key
    //     var locationRandom = [
    //         {
    //             type: 7,
    //             name: 'L1',
    //             location: [5, 7, 9]
    //         },
    //         {
    //             type: 7,
    //             name: 'L2',
    //             location: [24, 26, 28]
    //         },
    //         {
    //             type: 7,
    //             name: 'L3',
    //             location: [11, 13]
    //         },
    //         {
    //             type: 6,
    //             name: 'C1',
    //             location: [23, 25, 27]
    //         },
    //         {
    //             type: 6,
    //             name: 'C2',
    //             location: [6, 8, 10]
    //         },
    //         {
    //             type: 6,
    //             name: 'C3',
    //             location: [12, 14]
    //         }
    //     ];
    //
    //     // 初始化地形
    //     function initLocation() {
    //         var result = {}
    //         for (var i = 0; i < locationRandom.length; i++) {
    //             var item = locationRandom[i];
    //             var iRand = parseInt(item.location.length * Math.random())
    //             result[item.location[iRand]] = {
    //                 name: item.name,
    //                 type: item.type
    //             }
    //         }
    //         return result
    //     }
    //
    //     // Fisher–Yates shuffle
    //     // 方案二（Fisher–Yates shuffle）（ES6）算法
    //     // 关于JavaScript的数组随机排序: https://oldj.net/blog/2017/01/23/shuffle-an-array-in-javascript
    //     function shuffle(arr) {
    //         var i = arr.length;
    //         while (i) {
    //             var j = Math.floor(Math.random() * i--);
    //             [arr[j], arr[i]] = [arr[i], arr[j]];
    //         }
    //     }
    //
    //     // 初始化天气
    //     // 生成60天的天气
    //     function initWeather() {
    //         var result = []
    //         for (var x = 0; x < 20; x++) {
    //             for (var i = 0; i < weatherRandom.length; i++) {
    //                 var nds = []
    //                 for (var y = 0; y < weatherRandom[i].length; y++) {
    //                     for (var j = 0; j < weatherRandom[i][y]; j++) {
    //                         nds.push(y);
    //                     }
    //                     shuffle(nds)
    //                     for (var k = 0; k < nds.length; k++) {
    //                         if (!result[x * 10 + k]) result[x * 10 + k] = []
    //                         result[x * 10 + k][i] = nds[k]
    //                     }
    //                 }
    //             }
    //         }
    //         return result
    //     }
    //
    // }





    angular
        .module('taskServiceApp')
        .controller('TsTripQrCodeController', TsTripQrCodeController);

    TsTripQrCodeController.$inject = ['$timeout', '$uibModalInstance','TripSearchOfficial','Principal','$stateParams'];

    function TsTripQrCodeController ($timeout, $uibModalInstance,TripSearchOfficial,Principal,$stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.filterTrip = this.filterTrip;

        if($stateParams.id != null && $stateParams.id != ''){
            vm.trip = [];
            vm.trip.id = $stateParams.id;
            vm.trip.title = $stateParams.title;
            vm.trip.type = $stateParams.type;
            if (vm.trip.type == 'Indoor') {
                vm.trip.type = '室内版';
            } else {
                vm.trip.type = '室外版';
            }
        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearchOfficial.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };

        vm.clearQrCode = clearQrCode;
        function clearQrCode() {
            document.getElementById("qrCodeTest").style.display = 'none';
            document.getElementById("qrCodeNormal").style.display = 'none';
        }

        banRight();//当前页面禁用鼠标右键
        function banRight(){
            if(window.Event)
                document.captureEvents(Event.MOUSEUP);

            function nocontextmenu()
            {
                event.cancelBubble = true
                event.returnvalue = false;
                return false;
            }

            function norightclick(e)
            {
                if (window.Event)
                {
                    if (e.which == 2 || e.which == 3)
                        return false;
                }
                else if (event.button == 2 || event.button == 3)
                {
                    event.cancelBubble = true
                    event.returnvalue = false;
                    return false;
                }
            }
            document.oncontextmenu = nocontextmenu;  // for IE5+
            document.onmousedown = norightclick;  //
        }
        // var qrCodeUrl = "https://api.jngysz.com/";
        var qrCodeUrl = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com/" : "https://testapi.itasktour.com/";
        qrCodeUrl = qrCodeUrl + 'api/common/ittr/ittrts.php?tripId=';
        vm.clickNormal = showQrCodeNormal;
        function showQrCodeNormal(trip) {
            var location = window.location.host;
            // vm.qrCodeNormal = window.location.protocol+"//"+location+"/mobile/ts-trip-c.html?tripId=" + trip.id;
            vm.qrCodeNormal = qrCodeUrl  + trip.id;
            document.getElementById("qrCodeTest").style.display = 'none';
            document.getElementById("qrCodeNormal").style.display = 'block';
        }
        vm.clickTest = showQrCodeTest;
        function showQrCodeTest(trip) {
            var location = window.location.host;
            // vm.qrCodeTest = window.location.protocol+"//"+location+"/mobile/ts-trip-c.html?tripId=" + trip.id + "&istest=1";
            vm.qrCodeTest = qrCodeUrl + trip.id + "&istest=1";
            document.getElementById("qrCodeNormal").style.display = 'none';
            document.getElementById("qrCodeTest").style.display = 'block';
        }

        //判断是否为IE浏览器；
        function isIE() {
            if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return true;
            }else {
                return false;
            }
        }
        vm.downLoadQr = downLoadQr;
        function downLoadQr(codeType){

            if (codeType == 'normal'){
                html2canvas($('.qrNormal'),{
                    onrendered:function(canvas){
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type),'image/octet-stream');
                        if (isIE()) {
                            $('#myIEModal').modal('show');
                        }else {
                            $('.downNor').attr({
                                'href':imgData,
                                'download':'活动'+vm.trip.id+'正式二维码.png'
                            });
                        }
                    }
                });
            } else {
                html2canvas($('.qrTest'),{
                    onrendered:function(canvas){
                        var type = 'png';
                        var imgData = canvas.toDataURL(type);
                        imgData = imgData.replace(_fixType(type),'image/octet-stream');
                        if (isIE()) {
                            // console.log(isIE());
                            $('#myIEModal').modal('show');
                        }else {
                            $('.downTes').attr({
                                'href':imgData,
                                'download':'活动'+vm.trip.id+'测试二维码.png'
                            });
                        }
                    }
                });
            }

            var _fixType = function(type) {
                type = type.toLowerCase().replace(/jpg/i, 'jpeg');
                var r = type.match(/png|jpeg|bmp|gif/)[0];
                return 'image/' + r;
            };

        }
        vm.iknow = iknow;
        function iknow () {
            $('#myIEModal').modal('hide');
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }


    angular
        .module('taskServiceApp')
        .controller('ExportTsTripController', ExportTsTripController);

    ExportTsTripController.$inject = ['$timeout', '$uibModalInstance'];

    function ExportTsTripController($timeout, $uibModalInstance) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }


})();

