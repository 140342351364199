(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcInOutEquipDetailController', NpcInOutEquipDetailController);

    NpcInOutEquipDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NpcInOutEquip', 'NpcInOut', 'Equip'];

    function NpcInOutEquipDetailController($scope, $rootScope, $stateParams, previousState, entity, NpcInOutEquip, NpcInOut, Equip) {
        var vm = this;

        vm.npcInOutEquip = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:npcInOutEquipUpdate', function(event, result) {
            vm.npcInOutEquip = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
