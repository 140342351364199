(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('LocationTaskDialogController', LocationTaskDialogController);

    LocationTaskDialogController.$inject = ['$timeout', '$scope','$sce', '$stateParams', '$uibModalInstance', 'entity', 'LocationTask', '$http','LocationTaskSearch',  'LocationSearch', 'TripSearch', 'Upload', 'Ahdin','UserTripModelSearch','Principal','GdMapModalService'];

    function LocationTaskDialogController ($timeout, $scope,$sce, $stateParams, $uibModalInstance, entity, LocationTask, $http,LocationTaskSearch, LocationSearch,  TripSearch, Upload ,Ahdin,UserTripModelSearch,Principal,GdMapModalService) {
        var vm = this;
        vm.conditionSize = 1;
        vm.addCondition = addCondition;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.locationTask = entity;
        // console.log(vm.locationTask);
        vm.clear = clear;
        vm.location = {};
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.placeholderLocation = '选择坐标...';
        var locationData1 = [];
        var locationData2 = [];

        vm.myLocationTasks = [];
        vm.myLocationTaskId1 = [];
        vm.myLocationTaskId2 = [];
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb
        checkCoverType(vm.locationTask.coverImg);
        vm.showCropped = true;
        vm.cropper = 'default';
        vm.show = show;
        vm.choosePre = choosePreCondition;
        function choosePreCondition() {
            if (vm.locationTask.myTripModelId !== undefined && vm.locationTask.myTripModelId !== null){
                vm.locationTasks = getLocationTasksByTripId(vm.locationTask.myTripModelId);
            }
        }

        paseLocationTask();

        vm.removeTrip = removeTrip;
        vm.removeLocation = removeLocation;
        vm.removeCoverImg = removeCoverImg;
        vm.removeBgImg = removeBgImg;

        vm.onFileSelect =  onFileSelect;

        vm.itemsPerPage = 250;
        vm.filterLocation = this.filterLocation;
        vm.filterLocationTask = this.filterLocationTask;
        vm.filterTrip =this.filterTrip;
        vm.filterTripModels = this.filterTripModels;
        vm.platform = 0;

        function addCondition(index){
            vm.conditionSize = index;
        }

        vm.removeCondition = removeCondition;
        function removeCondition() {
            vm.myLocationTaskId2 = null;
            vm.conditionSize = 1;
        }

        this.$onInit = function () {
            $(function () {

                Principal.identity().then(function (account) {
                    // console.log(account);
                    vm.account = account;
                    //判断账号是否为admin
                    // console.log($.inArray("ROLE_SUPER",vm.account.authorities));
                    if (vm.account!=null && $.inArray("ROLE_SUPER",vm.account.authorities)!==-1){
                        vm.isSuper = true;
                    }
                    // console.log(vm.isSuper);
                });

                $('[data-toggle="popover"]').popover();
                $('[data-toggle="tooltip"]').tooltip();

                if (vm.locationTask.myTripModelId){
                    getLocationTasksByTripId(vm.locationTask.myTripModelId);
                }
                if (vm.locationTask.myTripModelId) {
                    vm.locationTask.myTripModelId = {id:vm.locationTask.myTripModelId,title:vm.locationTask.tripTitle}
                    // console.log(vm.locationTask.myTripModelId);
                }

                // console.log(vm.locationTask.canFinishTimes);
                if (vm.locationTask.canFinishTimes!=null && vm.locationTask.canFinishTimes == -1){
                    vm.locationTask.canFinishTimes = null;
                }else if (vm.locationTask.canFinishTimes!=null && vm.locationTask.canFinishTimes > 0){
                    vm.canFinish = true;
                }
                if (vm.locationTask.myLocationId == null && vm.locationTask.longtitude != null && vm.locationTask.longtitude != null) {
                    vm.placeholderLocation = '经度：'+ vm.locationTask.longtitude + '纬度：' + vm.locationTask.laititude;
                }

            });
        };

        // if (vm.locationTask.myLocation!=null && typeof vm.locationTask.myLocation!='undefined'){
        //     vm.locationTask.myLocation = {id:vm.locationTask.myLocation.id,description:vm.locationTask.myLocation.description};
        // }

        if (vm.locationTask.myLocationId !== undefined && vm.locationTask.myLocationId  !== null){
            getLocation(vm.locationTask.myLocationId);
        }
        function getLocation(id) {
            $http({
                method: 'GET',
                url: '/api/locations/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.locationTask.myLocation = {id:data.id,description:data.description};
                }
                // console.log(data);
            }).error(function(err){
                console.log(err);
            });
        }

        if (vm.myLocationTaskId !== undefined && vm.myLocationTaskId  !== null){
            getLocationTask(vm.myLocationTaskId);
        }
        function getLocationTask(id) {
            $http({
                method: 'GET',
                url: '/api/location-tasks/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.myLocationTaskId = {id:data.id,title:data.title};
                }
            }).error(function(err){
                console.log(err);
            });
        }

        // if (vm.locationTask.myTripId !== undefined && vm.locationTask.myTripId  !== null){
        //     getTrip(vm.locationTask.myTripId);
        // }
        // function getTrip(id) {
        //     $http({
        //         method: 'GET',
        //         url: '/api/trips/'+id
        //     }).success(function (data) {
        //         if (data!=null){
        //             vm.locationTask.myTripId = {id:data.id,title:data.title};
        //         }
        //     }).error(function(err){
        //         console.log(err);
        //     });
        // }

        vm.selectTripModel = selectTripModel;
        function selectTripModel() {
            if(vm.locationTask.myTripModelId == null){
                return;
            }
            var id
            if(vm.locationTask.myTripModelId.id){
                id = vm.locationTask.myTripModelId.id
            }else{
                id = vm.locationTask.myTripModelId
            }
            $http({
                method: 'GET',
                url: '/api/trip-models/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.platform = data.platform;
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function removeTrip() {
            if (vm.locationTask.myTripModelId !=null){
                vm.locationTask.myTripModelId = null;
            }
        }
        function removeLocation() {
            if (vm.locationTask.myLocation !=null){
                vm.locationTask.myLocation = null;
            }
        }
        function removeCoverImg() {
            if (vm.locationTask.coverImg != null){
                vm.locationTask.coverImg = null;
                vm.type = null;
            }
        }
        function removeBgImg() {
            if (vm.locationTask.bgImg != null){
                vm.locationTask.bgImg = null;
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.locationTask.coverImg != null){
                vm.locationTask.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        this.filterLocation = function (search) {
            if(search){
                vm.locations = LocationSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };

        function getDefaultLocation() {
            $http({
                method: 'GET',
                url: '/api/locations/281'
            }).success(function (data) {
                if (data!=null){
                    vm.location = data;
                }
            }).error(function(err){
                console.log(err);
            });
        }

        function show () {
            //[高德地图] (https://lbs.amap.com/)

            if (vm.locationTask.laititude == null && vm.locationTask.longtitude == null){
                getDefaultLocation();
            } else {
                vm.location.laititude = vm.locationTask.laititude;
                vm.location.longtitude = vm.locationTask.longtitude;
            }
            GdMapModalService.showModal(vm.location).result.then(function (data) {
                vm.locationTask.laititude = data.point.lat;
                vm.locationTask.longtitude = data.point.lng;
                // console.log(data);
                // console.log(vm.baseTask.laititude);
                // console.log(vm.baseTask.longtitude);
            });
        }

        // this.filterLocationTask = function (search) {
        //     if(search){
        //         vm.locationTasks = LocationTaskSearch.query({
        //             query: search,
        //             page :  0,
        //             size : vm.itemsPerPage,
        //             sort : ['id,desc']
        //         });
        //     }
        // };

        function getLocationTasksByTripId(tripId) {
            var locationTaskId = null;
            if (vm.locationTask.id!=null){
                locationTaskId = vm.locationTask.id;
            }
            $http({
                method: 'POST',
                // url: '/api/location-tasks/trips/'+tripId
                url: '/api/location-task/can-be-preconditions',
                data: {locationTaskId:locationTaskId, tripId: tripId}
            }).success(function (data) {
                if (data!=null){
                    vm.locationTasks = data;
                    /*vm.locationTaskss = [];
                    for (var x in vm.locationTasks){
                        if (vm.locationTasks[x].id!=vm.locationTask.id){
                            vm.locationTaskss[x] = vm.locationTasks[x];
                        }
                    }
                    console.log(vm.locationTaskss);*/
                    if (vm.myLocationTaskId1!=null && vm.myLocationTaskId1!=""){
                        for (var i=0;i<vm.myLocationTaskId1.length;i++){
                            for (var j=0;j<vm.locationTasks.length;j++){
                                if (vm.myLocationTaskId1[i] == vm.locationTasks[j].id){
                                    locationData1.id = vm.locationTasks[j].id;
                                    locationData1.title = vm.locationTasks[j].title;
                                    vm.myLocationTaskId1[i] = locationData1;
                                    vm.locationTasks.splice(j, 1);
                                    locationData1 = {};
                                }
                            }
                        }
                    }else {
                        vm.myLocationTaskId1 = null;
                    }
                    if (vm.myLocationTaskId2!=null && vm.myLocationTaskId2!=""){
                        for (var x=0;x<vm.myLocationTaskId2.length;x++){
                            for (var y=0;y<vm.locationTasks.length;y++){
                                if (vm.myLocationTaskId2[x] == vm.locationTasks[y].id){
                                    locationData2.id = vm.locationTasks[y].id;
                                    locationData2.title = vm.locationTasks[y].title;
                                    vm.locationTasks.splice(y, 1);
                                    vm.myLocationTaskId2[x] = locationData2;
                                    locationData2 = {};
                                }
                            }
                        }
                    }else {
                        vm.myLocationTaskId2 = null;
                    }
                }
            }).error(function(err){
                console.log(err);
            });
        }

        this.filterTrip = function (search) {
            if(search){
                vm.trips = TripSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };

        this.filterTripModels = function (search) {
            if (search){
                vm.tripModels = UserTripModelSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release: "0"
                });
            }
        };

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function trim(s){
            return s.replace(/(^\s*)|(\s*$)/g, "");
        }

        function getIdsFromStr(str,side) {
            var ids1 = [];
            if (side === 'left'){
                if (str.indexOf('AND')>-1){
                    var data = str.split('AND');
                    vm.relation1 = 'AND';
                    for (var i = 0 ; i<data.length;i++){
                        if (i === 0){
                            var index = data[i].lastIndexOf('(');
                            ids1[i] =trim(data[i].substring(index+1,data[i].length));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] = trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }else {
                    var data = str.split('OR');
                    vm.relation1 = 'OR';
                    for (var i = 0 ; i<data.length;i++){
                        if (i === 0){
                            var index = data[i].lastIndexOf('(');
                            ids1[i] =  trim(data[i].substring(index+1,data[i].length));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] =  trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }
                return ids1;
            }else if (side === 'right'){
                if (str.indexOf('AND')>-1){
                    var data = str.split('AND');
                    vm.relation2 = 'AND';
                    for (var i = 0 ; i<data.length;i++){
                        if (i === data.length-1){
                            var index = data[i].lastIndexOf(')');
                            ids1[i] = trim(data[i].substring(0,index-1));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] = trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }else {
                    var data = str.split('OR');
                    vm.relation2 = 'OR'
                    for (var i = 0 ; i<data.length;i++){
                        if (i === data.length-1){
                            var index = data[i].lastIndexOf(')');
                            ids1[i] = trim(data[i].substring(0,index-1));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] =  trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }
            }else {
                if (str.indexOf('AND')>-1){
                    var data = str.split('AND');
                    vm.relation1 = 'AND';
                    for (var i = 0 ; i<data.length;i++){
                        if (i === 0){
                            ids1[i] =trim(data[i].substring(2,data[i].length));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else if (i === data.length-1){
                            ids1[i] = trim(data[i].substring(0,data[i].length-1));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] = trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }else if (str.indexOf('OR')>-1){
                    var data = str.split('OR');
                    vm.relation1 = 'OR';
                    for (var i = 0 ; i<data.length;i++){
                        if (i === 0){
                            ids1[i] = trim(data[i].substring(2,data[i].length));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else if (i === data.length-1){
                            ids1[i] = trim(data[i].substring(0,data[i].length-1));
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }else {
                            ids1[i] = trim(data[i]);
                            if (ids1[i].indexOf('(')>-1 || ids1[i].indexOf(')')>-1){
                                ids1[i] = trim(ids1[i].substring(1,ids1[i].length-1));
                            }
                        }
                    }
                }else {
                    if (str.indexOf('(')>-1 || str.indexOf(')')>-1){
                        ids1[0] = trim(str.substring(1,str.length-1));
                    }else {
                        ids1[0] = trim(str);
                    }
                }
            }
            return ids1;
        }

        function paseLocationTask() {
            var ids1=[];
            var ids2=[];
            // console.log(vm.locationTask.preCondition);
            var array=[];
            if (vm.locationTask.preCondition!= null){
                if (vm.locationTask.preCondition.indexOf(')OR(')>-1){
                    array = vm.locationTask.preCondition.split(')OR(');
                    vm.relation3 = 'OR';
                    vm.conditionSize = 2;
                    ids1 = getIdsFromStr(array[0],'left');
                    ids2 = getIdsFromStr(array[1],'right');
                }else if (vm.locationTask.preCondition.indexOf(')AND(')>-1){
                    array = vm.locationTask.preCondition.split(')AND(');
                    vm.relation3 = 'AND';
                    vm.conditionSize = 2;
                    ids1 = getIdsFromStr(array[0],'left');
                    ids2 = getIdsFromStr(array[1],'right');
                }else {
                    ids1 = getIdsFromStr(vm.locationTask.preCondition,'both');
                }
                // console.log(ids1);
                // console.log(ids2);
                vm.myLocationTaskId1 = ids1;
                vm.myLocationTaskId2 = ids2;
            }
            // if (vm.locationTask.preCondition!=null){
            //     var and = vm.locationTask.preCondition.indexOf(") AND (");
            //     var or = vm.locationTask.preCondition.indexOf(") OR (");
            //     if (and>0){
            //         var a = vm.locationTask.preCondition.split(") AND (");
            //         for (var i=0;i<a.length;i++){
            //             vm.myLocationTaskId1 = a[0];
            //             vm.myLocationTaskId2 = a[1];
            //         }
            //
            //         var x = vm.myLocationTaskId1.indexOf("AND");
            //         if (x>0){
            //             vm.relation1 = "AND";
            //         }else {
            //             vm.relation1 = "OR";
            //         }
            //         var y = vm.myLocationTaskId2.indexOf("AND");
            //         if (y>0){
            //             vm.relation2 = "AND";
            //         }else {
            //             vm.relation2 = "OR";
            //         }
            //         vm.relation3 = "AND";
            //
            //         var conditionReg = /(\d+)/g;
            //         if (vm.myLocationTaskId1 !== null) {
            //             vm.preCondition1 = vm.myLocationTaskId1.match(conditionReg);
            //         }
            //         if (vm.myLocationTaskId2 !== null){
            //             vm.preCondition2 = vm.myLocationTaskId2.match(conditionReg);
            //         }
            //         vm.myLocationTaskId1 = vm.preCondition1;
            //         vm.myLocationTaskId2 = vm.preCondition2;
            //     }else if (or>0){
            //         var a1 = vm.locationTask.preCondition.split(") OR (");
            //         for (var j=0;j<a1.length;j++){
            //             vm.myLocationTaskId1 = a1[0];
            //             vm.myLocationTaskId2 = a1[1];
            //         }
            //
            //         var x1 = vm.myLocationTaskId1.indexOf("AND");
            //         if (x1>0){
            //             vm.relation1 = "AND";
            //         }else {
            //             vm.relation1 = "OR";
            //         }
            //         var y1 = vm.myLocationTaskId2.indexOf("AND");
            //         if (y1>0){
            //             vm.relation2 = "AND";
            //         }else {
            //             vm.relation2 = "OR";
            //         }
            //         vm.relation3 = "AND";
            //
            //         var conditionReg = /(\d+)/g;
            //         if (vm.myLocationTaskId1 !== null) {
            //             vm.preCondition1 = vm.myLocationTaskId1.match(conditionReg);
            //         }
            //         if (vm.myLocationTaskId2 !== null){
            //             vm.preCondition2 = vm.myLocationTaskId2.match(conditionReg);
            //         }
            //         vm.myLocationTaskId1 = vm.preCondition1;
            //         vm.myLocationTaskId2 = vm.preCondition2;
            //     }else if (and<0&&or<0){
            //         var conditionReg = /(\d+)/g;
            //         vm.myLocationTaskId1 = vm.locationTask.preCondition.match(conditionReg);
            //         var x2 = vm.myLocationTaskId1.indexOf("AND");
            //         if (x2>0){
            //             vm.relation1 = "AND";
            //         }else {
            //             vm.relation1 = "OR";
            //         }
            //     }
            // }
        }

        /**
         * 将点位转成条件
         * @param relation
         * @param conditions
         * @returns {string}
         */
        function replaceCondition(relation,conditions) {
            if (conditions.length>1){
                var result = '';
                for (var i = 0;i<conditions.length;i++){
                    if (i < conditions.length-1){
                        result += conditions[i].id+" "+relation+" ";
                    }else {
                        result += conditions[i].id;
                    }
                }
                // console.log(result);
                return '( '.concat(result).concat(' )')
            }else {
                // console.log(conditions.id);
                return '( '.concat(conditions[0].id).concat(' )');
            }
        }

        function locationTaskSave() {
            var condition1='';
            var condition2='';
            if (vm.myLocationTaskId1!==null&&vm.myLocationTaskId1.length>0){
                // console.log(vm.myLocationTaskId1);
                condition1=replaceCondition(vm.relation1, vm.myLocationTaskId1);
                vm.locationTask.preCondition = condition1;
            }
            if(vm.conditionSize >1){
                if (vm.myLocationTaskId2!==null&&vm.myLocationTaskId2.length>0){
                    // console.log(vm.myLocationTaskId2);
                    condition2 = replaceCondition(vm.relation2, vm.myLocationTaskId2);
                    if (vm.relation3!==null){
                        vm.locationTask.preCondition = '('.concat(condition1).concat(vm.relation3).concat(condition2).concat(')');
                    }
                }
            }
            if ((vm.myLocationTaskId1==null||vm.myLocationTaskId1.length==0)&&(vm.myLocationTaskId2==null||vm.myLocationTaskId2.length==0)){
                vm.locationTask.preCondition = null;
            }
        }

        //根据前置条件判断所属线路是否可编辑
        // console.log(vm.locationTask.otherPer);
        // console.log(vm.myLocationTaskId1);
        if (vm.locationTask.otherPer===true||vm.myLocationTaskId1.length>0){
            // $("#field_myTrip").attr("disabled", true);
            vm.isdisabled = true;
        }else {
            vm.isdisabled = false;
        }
        vm.change = change;
        function change() {
            if (vm.locationTask.otherPer===true||vm.myLocationTaskId1.length>0){
                // $("#field_myTrip").attr("disabled", true);
                vm.isdisabled = true;
            }else {
                vm.isdisabled = false;
            }
        }
        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }
        function save () {
            if(vm.platform == 1){
                alert("该线路属于基础版无法保存");
                return;
            }
            vm.isSaving = true;
            locationTaskSave();

            // console.log(vm.locationTask.preCondition);
            if (vm.locationTask.preCondition!=null && vm.locationTask.preCondition.indexOf('undefined')>0){
                alert("当前点位前置条件不正确，请重新选择");
                vm.isSaving = false;
                return;
            }

            if (vm.locationTask.myTripModelId){
                if (vm.locationTask.myTripModelId.id) vm.locationTask.myTripModelId = vm.locationTask.myTripModelId.id;
            }else {
                alert("点位所属线路不能为空，请选择所属线路");
                vm.isSaving = false;
                return;
            }
            if (vm.locationTask.myLocation){
                if (vm.locationTask.myLocation.id) {
                    vm.locationTask.myLocationId = vm.locationTask.myLocation.id;
                }
            }else {
                if (typeof vm.locationTask.laititude=='undefined' || vm.locationTask.laititude==null || vm.locationTask.laititude==""
                    || typeof vm.locationTask.longtitude=='undefined' || vm.locationTask.longtitude==null || vm.locationTask.longtitude==""
                    || typeof vm.locationTask.locationDesc=='undefined' || vm.locationTask.locationDesc==null || vm.locationTask.locationDesc=="") {
                    alert("点位所属坐标及坐标名称不能为空，请重新填写所属坐标");
                    vm.isSaving = false;
                    return;
                }else {
                    var laititude = vm.locationTask.laititude;
                    if (typeof laititude!=='undefined' && laititude!=null){
                        laititude = Math.abs(laititude);
                        if (laititude>90.000000){
                            alert("坐标纬度不合法");
                            vm.isSaving = false;
                            return;
                        }
                    }
                    var longtitude = vm.locationTask.longtitude;
                    if (typeof longtitude!=='undefined' && longtitude!=null){
                        longtitude = Math.abs(longtitude);
                        if (longtitude>180.000000){
                            alert("坐标经度不合法");
                            vm.isSaving = false;
                            return;
                        }
                    }
                }
            }
            if (vm.locationTask.coverImg !== null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.locationTask.coverImg)){
                    //暂时隐去点位图片2比1限制
                    // var image = document.getElementById("file");
                    // var height = image.height;
                    // var width = image.width;
                    // var bi = width / height;
                    // if (bi<1.8 || bi>2.2) {
                    //     // alert('点位图片"比例不是推荐值2:1，显示时将被拉伸或压缩，是否仍要使用？');
                    //     alert("上传图片比例2:1，且大小不得大于500kb");
                    //     removeBgImg();
                    //     vm.isSaving = false;
                    //     return;
                    // }
                }
            }else {
                alert("点位图片不能为空，请选择点位图片");
                vm.isSaving = false;
                return;
            }
            if (vm.myLocationTaskId1!=null&&vm.myLocationTaskId1.length>1){
                if (vm.relation1==null){
                    alert("请选择点位间逻辑关系");
                    vm.isSaving = false;
                    return;
                }
            }
            if (vm.myLocationTaskId2!=null&&vm.myLocationTaskId2.length>1){
                if (vm.relation2==null){
                    alert("请选择点位间逻辑关系");
                    vm.isSaving = false;
                    return;
                }
            }
            if ((vm.myLocationTaskId1!=null&&vm.myLocationTaskId1.length>0)&&(vm.myLocationTaskId2!=null&&vm.myLocationTaskId2.length>0)){
                if (vm.relation3==null){
                    alert("请选择条件间逻辑关系");
                    vm.isSaving = false;
                    return;
                }
            }

            var workRange = $('#field_workRange').val();
            if (workRange<0){
                alert("点位限制距离不符合要求。");
                vm.trip.workRange = null;
                vm.isSaving = false;
                return;
            }

            //点位任务类型默认设置为点位
            vm.locationTask.type = 'NORMAL';

            if (vm.canFinish){
                // console.log(vm.locationTask.canFinishTimes);
                if (vm.locationTask.canFinishTimes!=null){
                    if (vm.locationTask.canFinishTimes<=0){
                        alert("限次点位次数必须为大于0的正整数!!");
                        vm.isSaving = false;
                        return;
                    }
                }else {
                    vm.canFinish = false;
                    vm.locationTask.canFinishTimes = -1;
                }
                // console.log(vm.locationTask.otherPer);
                if (vm.locationTask.otherPer){
                    alert("限次点位不能作为其他点位的前置条件");
                    vm.isSaving = false;
                    return;
                }
            }else {
                vm.locationTask.canFinishTimes = -1;
            }
            // if (vm.locationTask.preCondition==null){
            //     vm.locationTask.preCondition = "";
            // }

            // console.log(vm.locationTask);
            if (vm.locationTask.myLocation==null || typeof vm.locationTask.myLocation=='undefined'){
                vm.locationTask.myLocationId = null;
            }
            if (vm.locationTask.id !== null) {
                LocationTask.update(vm.locationTask, onSaveSuccess, onSaveError);
            } else {
                LocationTask.save(vm.locationTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // console.log(result);
            if (result!=null){
                if (result.errorCode == -1){
                    alert(result.message);
                    vm.isSaving = false;
                    return;
                }
            }
            $scope.$emit('taskServiceApp:locationTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (err) {
            // console.log(err);
            // if (err!=null && err.data!=null){
            //     if (err.data.errorCode == -1){
            //         alert(err.data.message);
            //     }
            // }
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        function onFileSelect (uploadFile, name){

            var uploadImageFile = function(compressedBlob) {

                var fileType = compressedBlob.type;
                if (fileType!=null && typeof fileType!='undefined'){
                    fileType = fileType.split('/')[1];
                }
                var imgSize = compressedBlob.size/1024;
                //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                if (fileType=='jpg'||fileType=='jpeg'||fileType=='png'||fileType=='bmp'){
                    // console.log(imgSize);
                    if (imgSize>imgLimit){
                        alert("图片大小超过"+imgLimit+"kb");
                        return;
                    }
                    vm.type = 'img';
                }else if (fileType=='mp3'||fileType=='mp4'){
                    if (imgSize>otherLimit){
                        alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                        return;
                    }
                    vm.type = 'other';
                }else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }

                Upload.upload({

                    // url: '/api/upload',
                    // fields: {},
                    // file: compressedBlob,
                    // method: 'POST'
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"LOCA"},
                    file: compressedBlob,
                    method: 'POST'


                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    // var imgSize = compressedBlob.size/1024;
                    // // console.log(imgSize);
                    // var file = data.file;
                    // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    //     if (imgSize>imgLimit){
                    //         alert("图片大小超过"+imgLimit+"kb");
                    //         return;
                    //     }
                    //     vm.type = 'img';
                    // }else if (/\.(mp3|mp4)$/.test(file)){
                    //     if (imgSize>otherLimit){
                    //         alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                    //         return;
                    //     }
                    //     vm.type = 'other';
                    // }else {
                    //     alert("文件格式不正确，请重新上传！！");
                    //     return;
                    // }
                    //update the url
                    if (name == 'coverImg'){
                        vm.locationTask.coverImg = data.file;
                    } else if (name == 'bgImg'){
                        vm.locationTask.bgImg = data.file;
                    }

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };


            //TODO gif no compress
            if (uploadFile != null){
                // Ahdin.compress({
                //     sourceFile: uploadFile,
                //     maxWidth: 1280,
                //     maxHeight:1000,
                //     quality: 0.8
                // }).then(function(compressedBlob) {
                //     console.log('compressed image by ahdin.');
                uploadImageFile(uploadFile);
                // });
            }


        };
    }
})();
