(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcTeamTripDeleteController',NpcTeamTripDeleteController);

    NpcTeamTripDeleteController.$inject = ['$uibModalInstance', 'entity', 'NpcTeamTrip'];

    function NpcTeamTripDeleteController($uibModalInstance, entity, NpcTeamTrip) {
        var vm = this;

        vm.npcTeamTrip = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            NpcTeamTrip.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
