(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripCollectDetailController', TripCollectDetailController);

    TripCollectDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TripCollect'];

    function TripCollectDetailController($scope, $rootScope, $stateParams, previousState, entity, TripCollect) {
        var vm = this;

        vm.tripCollect = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:tripCollectUpdate', function(event, result) {
            vm.tripCollect = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
