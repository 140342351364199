(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcDialogController', NpcDialogController);

    NpcDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity','Principal', 'Npc', 'NpcInOut', '$http', 'GdMapModalService','TripModelSearch','TripModel','LocationSearch','Location','EquipSearch','Equip'];

    function NpcDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,Principal, Npc, NpcInOut, $http, GdMapModalService,TripModelSearch,TripModel,LocationSearch,Location,EquipSearch,Equip) {
        var vm = this;

        vm.npc = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.npcinouts = NpcInOut.query();
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.show = show;
        vm.point = {};

        vm.filterTripModels = this.filterTripModels;

        vm.filterLocations = this.filterLocations;

        vm.locationChose=this.locationChose;

        vm.filterEquips = this.filterEquips;

        this.filterEquips = function (search) {
            if (search){
                vm.equips = EquipSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
                console.log(vm.equips);
            }
        };

        this.locationChose = function () {
            // console.log(vm.npc.location);
            vm.npc.latitude = vm.npc.location.laititude;
            vm.npc.longitude = vm.npc.location.longtitude;
            vm.npc.locationId = vm.npc.location.id;
        };

        this.filterLocations = function (search) {
            if (search){
                vm.locations = LocationSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.filterTripModels = function (search) {
            if (search) {
                vm.tripModels = TripModelSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.$onInit = function () {
            $(function () {
                if (vm.npc.npcTripModelId) {
                    vm.npc.npcTripModel = TripModel.get({id : vm.npc.npcTripModelId});
                }
                if (vm.npc.locationId){
                    vm.npc.location = Location.get({id:vm.npc.locationId})
                }
                if (vm.npc.npcEquipId){
                    vm.npc.npcEquip = Equip.get({id:vm.npc.npcEquipId})
                }
            })
        };
        getAvatars();
        function getAvatars() {
            $http({
                method: 'GET',
                url: '/api/avatars/type/'+'NPC'
            }).success(function (data) {
                if (data!=null){
                    vm.avatars = data;
                }
            }).error(function(err){
                console.log(err);
            });
        }

        vm.npcImg = npcImg;
        function npcImg($index) {
            for (var i=0;i<vm.avatars.length;i++){
                if ($index==i){
                    vm.npc.img = vm.avatars[i].avatar;
                }
            }
        }

        if (vm.npc.img == null){
            vm.npc.img = '/PROF/Aside/a1503557908353_23413fde-01bd-454f-af46-2413cb06522f.png';
        }

        function show () {
            //[高德地图] (https://lbs.amap.com/)
            GdMapModalService.showModal(vm.npc).result.then(function (data) {
                vm.npc.latitude = data.point.lat;
                vm.npc.longitude = data.point.lng;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function save () {
            vm.isSaving = true;
            if (vm.npc.npcTripModel){
                if (vm.npc.npcTripModel.id){
                    vm.npc.npcTripModelId = vm.npc.npcTripModel.id;
                }else {
                    vm.npc.npcTripModelId = vm.npc.npcTripModel;
                }
            }
            if (vm.npc.npcEquip){
                vm.npc.npcEquipId = vm.npc.npcEquip.id;
            }
            vm.npc.latitude = vm.npc.location.laititude;
            vm.npc.longitude = vm.npc.location.longtitude;
            vm.npc.locationId = vm.npc.location.id;
            var date = new Date();
            if (vm.npc.id !== null) {
                vm.npc.updateDate = date;
                vm.npc.updateBy = vm.account.firstName;
                Npc.update(vm.npc, onSaveSuccess, onSaveError);
            } else {
                vm.npc.createDate = date;
                vm.npc.createBy = vm.account.firstName;
                Npc.save(vm.npc, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:npcUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
