(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripTypeDialogController', TripTypeDialogController);

    TripTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TripType', 'Upload', 'Ahdin'];

    function TripTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TripType, Upload, Ahdin) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripType = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.onFileSelect = onFileSelect;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.tripType.id !== null) {
                TripType.update(vm.tripType, onSaveSuccess, onSaveError);
            } else {
                TripType.save(vm.tripType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:tripTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect (uploadFile){

            var uploadImageFile = function(compressedBlob) {
                Upload.upload({

                    // url: '/api/upload',
                    // fields: {},
                    // file: compressedBlob,
                    // method: 'POST'
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"TYPE"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    //update the url
                    vm.tripType.img = data.file;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);
                });
            };


            //TODO gif no compress
            if (uploadFile != null){
                Ahdin.compress({
                    sourceFile: uploadFile,
                    maxWidth: 1280,
                    maxHeight:1000,
                    quality: 0.8
                }).then(function(compressedBlob) {
                    console.log('compressed image by ahdin.');
                    uploadImageFile(compressedBlob);
                });
            }


        };
    }
})();
