(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ConfirmJoinItasktourController', ConfirmJoinItasktourController);

    ConfirmJoinItasktourController.$inject = ['$scope', '$stateParams', '$state', '$uibModal','$http', '$uibModalInstance', '$cookies'];

    function ConfirmJoinItasktourController ($scope, $stateParams, $state, $uibModal, $http, $uibModalInstance, $cookies) {
        var vm = this;

        vm.fromName = decodeURI($stateParams.uName);
        vm.join = join;
        function join() {
            $uibModalInstance.dismiss('cancel');
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
            $cookies.put('rabbitLeaderId', $stateParams.uid, {expires: new Date(expireDate)});
            $state.go("register",{},{reload:false});
        }
    }
})();
