(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcDetailController', NpcDetailController);

    NpcDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Npc', 'NpcInOut'];

    function NpcDetailController($scope, $rootScope, $stateParams, previousState, entity, Npc, NpcInOut) {
        var vm = this;

        vm.npc = entity;
        vm.previousState = previousState.name;
        vm.IMAGE_SERVER = IMAGE_SERVER;

        var unsubscribe = $rootScope.$on('taskServiceApp:npcUpdate', function(event, result) {
            vm.npc = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
