(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('NpcActivityDetailController', NpcActivityDetailController);

    NpcActivityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'NpcActivity'];

    function NpcActivityDetailController($scope, $rootScope, $stateParams, previousState, entity, NpcActivity) {
        var vm = this;

        vm.npcActivity = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:npcActivityUpdate', function(event, result) {
            vm.npcActivity = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
