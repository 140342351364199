(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ts-introduction', {
            parent: 'entity',
            url: '/ts/introduction',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/game/ts-intro.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('stealth-introduction', {
            parent: 'entity',
            url: '/stealth/introduction',
            data: {
                authorities: ['ROLE_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/game/stealth-intro.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
