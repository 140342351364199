(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('records', {
            parent: 'entity',
            url: '/records?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.records.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/records/records.html',
                    controller: 'RecordsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('records');
                    $translatePartialLoader.addPart('rStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('records-detail', {
            parent: 'records',
            url: '/records/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.records.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/records/records-detail.html',
                    controller: 'RecordsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('records');
                    $translatePartialLoader.addPart('rStatus');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Records', function($stateParams, Records) {
                    return Records.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'records',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('records-detail.edit', {
            parent: 'records-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/records/records-dialog.html',
                    controller: 'RecordsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Records', function(Records) {
                            return Records.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('records.new', {
            parent: 'records',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/records/records-dialog.html',
                    controller: 'RecordsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                orderId: null,
                                type: null,
                                activityId: null,
                                coupons: null,
                                explain: null,
                                createDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('records', null, { reload: 'records' });
                }, function() {
                    $state.go('records');
                });
            }]
        })
        .state('records.edit', {
            parent: 'records',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/records/records-dialog.html',
                    controller: 'RecordsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Records', function(Records) {
                            return Records.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('records', null, { reload: 'records' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('records.delete', {
            parent: 'records',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/records/records-delete-dialog.html',
                    controller: 'RecordsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Records', function(Records) {
                            return Records.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('records', null, { reload: 'records' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
