(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ConfirmJoinController', ConfirmJoinController);

    ConfirmJoinController.$inject = ['$scope', '$stateParams', '$state', '$uibModal','$http', '$uibModalInstance', '$cookies'];

    function ConfirmJoinController ($scope, $stateParams, $state, $uibModal, $http, $uibModalInstance, $cookies) {
        var vm = this;

        vm.eAccountName = decodeURI($stateParams.eName);
        vm.fromName = decodeURI($stateParams.uName);
        vm.join = join;
        function join() {
            $uibModalInstance.dismiss('cancel');
            var expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000); // cookies10分钟有效期
            $cookies.put('eid', $stateParams.eid, {expires: new Date(expireDate)});
            $cookies.put('uid', $stateParams.uid, {expires: new Date(expireDate)});
            $state.go("e-account-my",{},{reload:false});
        }
    }
})();
