(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('Badges', Badges);

    Badges.$inject = ['$resource', 'DateUtils','$http'];

    function Badges ($resource, DateUtils,$http) {
        var resourceUrl =  'api/badges/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
(function () {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('MyBadges', MyBadges);
    MyBadges.$inject = ['$http'];
    function MyBadges ($http) {
        return {
            getBadgesById: function (id) {
                return $http.get('/api/badges/'+ id);
            }
        }
    };
})();
