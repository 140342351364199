(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('RabbitLeaderLoginController', RabbitLeaderLoginController);

    RabbitLeaderLoginController.$inject = ['$scope', '$timeout', 'Principal', '$state', '$uibModal', 'Auth','$http', '$cookies', 'MyEAccount'];

    function RabbitLeaderLoginController ($scope, $timeout, Principal, $state, $uibModal, Auth,$http, $cookies, MyEAccount) {
        var vm = this;

        vm.authenticationError = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.author = null;
        vm.login = login;
        vm.username = null;
        vm.password = null;
        vm.cancel = cancel;
        vm.exit = false;
        vm.confirmJoin = false;
        $("#activateCode").hide();
        $timeout(function (){angular.element('#username').focus();});

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        function getAccount() {
            Principal.identity().then(function (account) {
                if (account){
                    vm.account = account;
                    vm.author = vm.account.authorities;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    $state.go("my-service",{},{reload:false});
                }
            });
        }

        function login(event) {
            event.preventDefault();
            vm.checkPwd = null;
            vm.exit = null;
            if(vm.username == null || vm.username == ''){
                vm.exit = 2;
                return;
            }
            loginConfirm();
        }

        function loginConfirm() {
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: vm.username,
                    activateCode: vm.code,
                    rabbitLeaderFlag: true
                }
            }).success(function (res) {
                if (res.errorCode == -1) {
                    vm.exit = 3;//当前用户不存在
                } else if (res.errorCode == -2) {
                    vm.exit = 2;//无效邀请码
                } else {
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function () {
                        onSaveSuccess();
                    }).catch(function () {
                        vm.checkPwd = '密码错误';
                    });
                }
            })
        }

        function onSaveSuccess () {
            getAccount();
            // vm.authenticationError = false;
            // document.cookie="isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com";
            // if ($state.current.name === 'register' || $state.current.name === 'activate' ||
            //     $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
            // }
            // $state.go('my-service');
        }


        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModal.dismiss('cancel');
        }
        vm.openWeb  = openWeb;
        function openWeb() {
            // if(window.location.hostname.startsWith("dev")){
            //     window.location.href = "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
            // }else {
            //     window.location.href = "https://www.itasktour.com";
            // }
            // window.location.href = "https://api.jngysz.com";
            window.location.href = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com" : "https://testapi.itasktour.com";
        }
        vm.checkLogin = checkLogin;
        function checkLogin() {//检查是否已经存在用户
            vm.exit = null;
            var loginID = vm.username;
            if(loginID == null || loginID == ''){
                vm.exit = 3;
                return;
            }
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: loginID,
                }
            }).success(function (res) {
                if (res.errorCode != 0) {
                    vm.exit = 3;//当前用户不存在
                }
            })
        }
    }
})();
