(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('CommonTripTeamSearch', CommonTripTeamSearch);

    CommonTripTeamSearch.$inject = ['$resource'];

    function CommonTripTeamSearch($resource) {
        var resourceUrl =  'api/_search/common-trip-teams/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
