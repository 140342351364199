(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('AdsMbitestResultSearch', AdsMbitestResultSearch);

    AdsMbitestResultSearch.$inject = ['$resource'];

    function AdsMbitestResultSearch($resource) {
        var resourceUrl =  'api/_search/ads-mbitest-results/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
