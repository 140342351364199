(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('trip', {
            parent: 'entity',
            url: '/trip?page&sort&search&states&searchType',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trip/trips.html',
                    controller: 'TripController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                searchType: {
                    value: '0',
                    squash: true
                },
                search: null,
                states: '0',
                platforms: '0'
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        searchType:$stateParams.searchType,
                        search: $stateParams.search,
                        states: $stateParams.states,
                        platforms: $stateParams.platforms
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('trip-detail', {
            parent: 'entity',
            url: '/trip-detail/{id}/{status}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trip/trip-detail.html',
                    controller: 'TripDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    $translatePartialLoader.addPart('eTripType');
                    $translatePartialLoader.addPart('tripStatusType');
                    $translatePartialLoader.addPart('mapType');
                    $translatePartialLoader.addPart('tripAttribute');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Trip', function($stateParams, Trip) {
                    return Trip.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trip-export-photos', {
            parent: 'entity',
            url: '/trips/{id}/export-photos',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.export-photos.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trip/trip-export-photos.html',
                    controller: 'TripExportPhotosController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TripExt', function ($stateParams, TripExt) {
                    return TripExt.getPhotosByTrip($stateParams.id);
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trip-rank', {
            parent: 'entity',
            url: '/trips/{id}/rank',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.rank.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trip/trip-rank.html',
                    controller: 'TripRankController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TripExt', function($stateParams, TripExt) {
                    return TripExt.getRanksByTrip($stateParams.id);
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('trip.new', {
            parent: 'trip',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            params: {
                id: '',
                title:'',
                platform:0

            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trip/trip-dialog.html',
                    controller: 'TripDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                traceType:null,
                                saftyTime:null,
                                terrainDiff:null,
                                // skygreen: 第一阶段
                                // startType:$stateParams.platform==4?3:1,
                                startType:3,
                                // end
                                type: null,
                                status: null,
                                title: null,
                                description: null,
                                startLocation: null,
                                eTime: null,
                                eTripLong: null,
                                startTime: null,
                                endTime: null,
                                coverImg: null,
                                mascot: null,
                                mapType: null,
                                attribute: null,
                                area: null,
                                showScore: null,
                                warStar: null,
                                maxPeople: null,
                                tripStars: null,
                                createDate: null,
                                createBy: null,
                                updateDate: null,
                                updateBy: null,
                                gameStarted: false,
                                winScore: null,
                                lostScore: null,
                                gameCircle: null,
                                npcEnable: null,
                                isPause:false,
                                pauseStartTime:null,
                                timeLength:null,
                                pauseContent:null,
                                id: null,
                                unit:null,
                                teamMaxpeople:null,
                                maxTeamcount:null
                            };
                        }
                    }
                }).result.then(function() {
                    window.location.href = window.location.origin +"/#/trip";
                    window.location.reload();
                    return false;
                }, function() {
                    window.history.go(-1);
                });
            }]
        })
            .state('trip.new1', {
                parent: 'trip',
                url: '/new1/{id}/{title}/{platform}',
                data: {
                    authorities: ['ROLE_USER']
                },
                // params: {
                //     id: '',
                //     title:''
                // },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-dialog.html',
                        controller: 'TripDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    traceType:null,
                                    type: null,
                                    status: null,
                                    title: null,
                                    description: null,
                                    startLocation: null,
                                    eTime: null,
                                    eTripLong: null,
                                    startTime: null,
                                    endTime: null,
                                    coverImg: null,
                                    mascot: null,
                                    mapType: null,
                                    attribute: null,
                                    area: null,
                                    showScore: null,
                                    warStar: null,
                                    maxPeople: null,
                                    tripStars: null,
                                    createDate: null,
                                    createBy: null,
                                    updateDate: null,
                                    updateBy: null,
                                    gameStarted: false,
                                    winScore: null,
                                    lostScore: null,
                                    gameCircle: null,
                                    npcEnable: null,
                                    isPause:false,
                                    pauseStartTime:null,
                                    timeLength:null,
                                    pauseContent:null,
                                    id: null,
                                    unit:null,
                                    teamMaxpeople:null,
                                    maxTeamcount:null
                                };
                            }
                        }
                    }).result.then(function() {
                        window.location.href = window.location.origin +"/#/trip";
                        window.location.reload();
                        return false;
                    }, function() {
                        // window.history.go(-1);
                        window.close();
                    });
                }]
            })

            .state('trip.edit', {
                parent: 'trip',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-dialog.html',
                        controller: 'TripDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Trip', function(Trip) {
                                return Trip.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('trip', null, { reload: 'trip' });
                        //又该缓存信息跳转至企业充值
                        var reg = new RegExp("(^| )" + 'trip' + "=([^;]*)(;|$)");
                        var arr = document.cookie.match(reg);
                        if (arr != null) {
                            if(arr[2] == 'back'){
                                var expires = -10000;
                                var date = new Date(+new Date()+expires);
                                document.cookie = "trip=" + 'back' + ";expires=" + date.toUTCString();
                                $state.go('trip');
                                return;
                            }else{
                                try {
                                    var tripCookie = JSON.parse(arr[2]);
                                    if (tripCookie.id != null && tripCookie.id == $stateParams.id) {
                                        $state.go('e-account-my');
                                        return;
                                    }
                                } catch(e) {
                                    var expires = -10000;
                                    var date = new Date(+new Date()+expires);
                                    document.cookie = "trip=" + arr[2] + ";expires=" + date.toUTCString();
                                    console.log(arr[2]);
                                }
                            }
                        }
                        window.history.go(-1);
                    }, function() {
                        // $state.go('^');
                        window.history.go(-1);
                    });
                }]
            })
            .state('trip-detail.edit', {
                parent: 'trip-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-dialog.html',
                        controller: 'TripDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Trip', function(Trip) {
                                return Trip.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('trip-detail', {id:$stateParams.id}, { reload: true });
                    }, function() {
                        $state.go('trip-detail', {id:$stateParams.id}, { reload: true });
                    });
                }]
            })
            .state('trade-trip-new', {
                parent: 'trip',
                url: '/new/{myTripModelId}',
                data: {
                    authorities: ['ROLE_USER','ROLE_INDIEDESIGN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    // console.log($stateParams);
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trade-trip-dialog.html',
                        controller: 'TradeTripDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    traceType:null,
                                    type: null,
                                    status: null,
                                    title: null,
                                    description: null,
                                    startLocation: null,
                                    eTime: null,
                                    eTripLong: null,
                                    startTime: null,
                                    endTime: null,
                                    coverImg: null,
                                    mascot: null,
                                    mapType: null,
                                    attribute: null,
                                    area: null,
                                    showScore: null,
                                    warStar: null,
                                    maxPeople: null,
                                    tripStars: null,
                                    createDate: null,
                                    createBy: null,
                                    updateDate: null,
                                    updateBy: null,
                                    gameStarted: false,
                                    winScore: null,
                                    lostScore: null,
                                    gameCircle: null,
                                    npcEnable: null,
                                    isPause:false,
                                    pauseStartTime:null,
                                    timeLength:null,
                                    pauseContent:null,
                                    id: null,
                                    unit:null,
                                    teamMaxpeople:null,
                                    maxTeamcount:null,
                                    myTripModelId:$stateParams.myTripModelId
                                };
                            }
                        }
                    }).result.then(function() {
                        // $state.go('trip-model-detail', {id:$stateParams.myTripModelId}, { reload: true });
                        window.history.go(-1);
                    }, function() {
                        // $state.go('trip-model-detail', {id:$stateParams.myTripModelId}, { reload: true });
                        window.history.go(-1);
                    });
                }]
            })
            .state('trade-trip-edit', {
                parent: 'trip',
                url: '/trade/{id}/edit',
                data: {
                    authorities: ['ROLE_USER','ROLE_INDIEDESIGN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trade-trip-dialog.html',
                        controller: 'TradeTripDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Trip', function(Trip) {
                                return Trip.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('trip-detail', {id:$stateParams.id}, { reload: true });
                        window.history.go(-1);
                    }, function() {
                        // $state.go('trip-detail', {id:$stateParams.id}, { reload: true });
                        window.history.go(-1);
                    });
                }]
            })
        .state('trip.import', {
            parent: 'entity',
            url: '/trips/{id}/import',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.trip.import.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/trip/trip-import.html',
                    controller: 'TripImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('trip');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Trip', function ($stateParams, Trip) {
                    return Trip.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'trip',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })

        .state('trip.delete-game', {
            parent: 'trip',
            url: '/{id}/delete-game',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trip/trip-delete-game-dialog.html',
                    controller: 'TripDeleteGameController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Trip', function(Trip) {
                            return Trip.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trip-release', null, { reload: 'trip' });
                }, function() {
                    $state.go('trip');
                });
            }]
        })
        .state('trip-detail.delete', {
            parent: 'trip-detail',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trip/trip-delete-dialog.html',
                    controller: 'TripDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Trip', function(Trip) {
                            return Trip.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    // $state.go('trip', null, { reload: 'trip' });
                    //编辑页面返回线路页面
                    if(window.location.href.indexOf('trip-detail') == -1){
                        window.history.go(-1);
                    }else{
                        $state.go('trip', null, { reload: 'trip' });
                    }
                }, function() {
                    // $state.go('^');
                    window.history.go(-1);
                });
            }]
        })
        .state('trip-detail-copy', {
            parent: 'trip-detail',
            url: '/{id}/copy',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/trip/trip-copy-dialog.html',
                    controller: 'TripCopyController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Trip', function(Trip) {
                            return Trip.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('trip', null, { reload: 'trip' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('base-task-trip-id', {
            parent: 'entity',
            url: '/base-tasks/locationTask/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.baseTask.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/base-task/base-tasks-trip.html',
                    controller: 'BaseTaskController_Trip',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('baseTask');
                    $translatePartialLoader.addPart('taskType');
                    $translatePartialLoader.addPart('showMode');
                    $translatePartialLoader.addPart('challengeMode');
                    $translatePartialLoader.addPart('arResultType');
                    $translatePartialLoader.addPart('pswAnswerType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })


            .state('trip-monitor', {
                parent: 'entity',
                url: '/trip-monitor?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.trip.home.monitor'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trips-monitor.html',
                        controller: 'TripMonitorController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('trip-today', {
                parent: 'entity',
                url: '/trip-today?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: '今日活动'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trips-today.html',
                        controller: 'TripTodayController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('trip-monitor-dialog', {
                parent: 'trip-monitor',
                url: '/{id}/monitor-edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-monitor-dialog.html',
                        controller: 'TripMonitorDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Trip', function(Trip) {
                                return Trip.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('trip-monitor', null, { reload: 'trip-monitor' });
                    }, function() {
                        $state.go('trip-monitor');
                    });
                }]
            })
            .state('trip-release', {
                parent: 'entity',
                url: '/trip-release?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.trip.home.release'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trips-release.html',
                        controller: 'TripReleaseController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

    .state('tripNpc', {
        parent: 'entity',
        url: '/trip-npc',
        data: {
            authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            $uibModal.open({
                templateUrl: 'app/entities/trip/trip-npc-dialog.html',
                controller: 'TripNpcDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            tripId: null,
                            npcIds: null
                        };
                    }
                }
            }).result.then(function() {
                $state.go('guide', null, { reload: 'guide' });
            }, function() {
                $state.go('guide');
            });
        }]
    })

            .state('tripArCode', {
                parent: 'entity',
                url: '/trip-arCode',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-ar-code.html',
                        controller: 'TripArCodeController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    tripId: null
                                };
                            }
                        }
                    }).result.then(function() {
                        $state.go('trip', null, { reload: 'trip' });
                    }, function() {
                        $state.go('trip');
                    });
                }]
            })
            .state('tripQrCodeNew', {
                parent: 'entity',
                url: '/trip-qr-code-new',
                data: {
                    authorities: ['ROLE_USER']
                },
                params: {
                    id: '',
                    title:'',
                    attribute:'',
                    platform:'',
                    traceType:''
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-qr-code-new.html',
                        controller: 'TripQrCodeNewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    tripId: null
                                };
                            }
                        }
                    }).result.then(function() {
                        // $state.go('trip', null, { reload: 'trip' });
                        window.history.go(-1);
                    }, function() {
                        // $state.go('trip');
                        window.history.go(-1);
                    });
                }]
            })
            .state('tripCoachQrCode', {
                parent: 'entity',
                url: '/trip-coach-qrCode',
                data: {
                    authorities: ['ROLE_USER']
                },
                params: {
                    id: '',
                    title:'',
                    attribute:''
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-coach-qr-code.html',
                        controller: 'TripCoachQrCodeController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    tripId: null
                                };
                            }
                        }
                    }).result.then(function() {
                        // $state.go('trip', null, { reload: 'trip' });
                        window.history.go(-1);
                    }, function() {
                        // $state.go('trip');
                        window.history.go(-1);
                    });
                }]
            })
            .state('trip-prePublish', {
                parent: 'entity',
                url: '/trip-prePublish/{tripId}/{status}/{igc}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    // console.log($stateParams);
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-prePublish.html',
                        controller: 'TripPrePublishController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            // check: ['TripPrePublish', function(TripPrePublish) {
                            //     if ($stateParams.tripModelId===null||$stateParams.tripModelId===""){
                            //         return null;
                            //     }
                            //     return TripPrePublish.check({id:$stateParams.tripModelId}).$promise;
                            // }],
                            entity: ['Trip', function(Trip) {
                                // console.log($stateParams);
                                return Trip.get({id:$stateParams.tripId}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        // if ($stateParams.status == 'PREPUBLISH'){
                        //     $state.go('trip-detail', {id:$stateParams.tripId,status:'PREPUBLISH'}, { reload: true });
                        // }else if ($stateParams.status == 'PUBLISH'){
                        //     $state.go('trip-release', {}, { reload: true });
                        // }
                        window.history.go(-1);
                    }, function() {
                        // window.history.go(-1);
                    });
                }]
            })
            .state('trip-check', {
                parent: 'entity',
                url: '/trip-check/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    // console.log($stateParams);
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-check.html',
                        controller: 'TripCheckController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            check: ['TripPrePublish', function(TripPrePublish) {
                                // console.log($stateParams);
                                return TripPrePublish.check({id:$stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        // $state.go('^', {}, { reload: false });
                        window.history.go(-1);
                    }, function() {
                        // $state.go('^');
                        window.history.go(-1);
                    });
                }]
            })
            .state('trip-to-web-detail', {
                parent: 'trip',
                url: '/toWeb/detail/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.trip.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trip-to-web-detail.html',
                        controller: 'TripToWebDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Trip', function($stateParams, Trip) {
                        return Trip.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'trip',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('trip-to-web-list', {
                parent: 'entity',
                url: '/trip/check/list?page&sort&search',
                data: {
                    pageTitle: '活动审核列表'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trip-to-web.html',
                        controller: 'TripToWebController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');

                        return $translate.refresh();
                    }]
                }
            })
            .state('publicRegistration', {
                parent: 'entity',
                url: '/trip-publish-registration',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-publish-registration.html',
                        controller: 'TripRegistrationController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    tripId: null
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    });
                }]
            })
            .state('publicRegisterEdit', {
                parent: 'entity',
                url: '/trip-publish-register-edit/{id}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/trip/trip-publish-register-edit.html',
                        controller: 'TripRegisterEditController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['$stateParams', 'TripRegisterDetail', function($stateParams, TripRegisterDetail) {
                                return TripRegisterDetail.get({id : $stateParams.id}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }]
                        }
                    });
                }]
            })
            .state('trip-publish-register-detail', {
                parent: 'entity',
                url: '/trip-publish-register-detail/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.trip.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trip-publish-register-detail.html',
                        controller: 'TripRegisterDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    entity: ['$stateParams', 'TripRegisterDetail', function($stateParams, TripRegisterDetail) {
                        return TripRegisterDetail.get({id : $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }
            })
            .state('trip-register-list', {
                parent: 'entity',
                url: '/trip-register-list?page&sort&search',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: '公开报名活动列表'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trip-publish-register-list.html',
                        controller: 'TripRegisterListController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('trip-create-qr', {
                parent: 'entity',
                url: '/trip-qr-detail/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'taskServiceApp.trip.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/trip/trip-qr-detail.html',
                        controller: 'TripQrDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('trip');
                        $translatePartialLoader.addPart('eTripType');
                        $translatePartialLoader.addPart('tripStatusType');
                        $translatePartialLoader.addPart('mapType');
                        $translatePartialLoader.addPart('tripAttribute');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Trip', function($stateParams, Trip) {
                        return Trip.get({id : $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'trip',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            });
    }

})();
