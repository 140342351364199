(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ClientVersionController', ClientVersionController);

    ClientVersionController.$inject = ['ClientVersionService'];

    function ClientVersionController (ClientVersionService) {
        var vm = this;
        vm.canSkip = false;

        vm.clientVersions = ClientVersionService.findAll();

        // console.log(vm.clientVersions);
        vm.clientUpgrade = clientUpgrade;
        vm.clientDegrade = clientDegrade;
        vm.confirm = confirm;

        vm.showEdit = showEdit;
        function showEdit(id) {
            vm.clientVer = vm.clientVersions[id-1];
            // console.log(vm.clientVer);
            $('#myModal1').modal(true);
            vm.versionCode = vm.clientVer.versionCode;
            vm.description = vm.clientVer.description;
            vm.canSkip = vm.clientVer.canSkip;
        }

        function clientUpgrade(id) {
            vm.clientVersions[id-1].versionCode++;
        }

        function clientDegrade(id) {
            if(vm.clientVersions[id-1].versionCode >= 2){
                vm.clientVersions[id-1].versionCode--;
            }else {
                window.alert("版本不能小于1")
            }
            // console.log(vm.clientVersions[id-1]);
        }

        function confirm() {
            console.log(vm.versionCode);
            if (vm.versionCode < 1){
                alert("客户端版本不能小于1");
                return;
            }
            if (vm.description!=null){
                vm.description = vm.description.replace(/<br>/g, "\n");
            }
            vm.clientVer.versionCode = vm.versionCode;
            vm.clientVer.description = vm.description;
            vm.clientVer.canSkip = vm.canSkip;
            var data = ClientVersionService.update(vm.clientVer);
            if (data.versionCode === vm.clientVer.versionCode){
                // console.log("成功");
                $('#myModal1').modal('hide');
                // window.alert("修改成功");
            }
            vm.versionCode = null;
            vm.description = null;
        }

        // function confirm(id) {
        //     var data = ClientVersionService.update(vm.clientVersions[id-1]);
        //     if (data.versionCode === vm.clientVersions[id-1].versionCode){
        //         // console.log("成功");
        //         window.alert("修改成功")
        //     }
        // }
    }
})();
