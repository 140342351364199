(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('Register', Register);

    Register.$inject = ['$http'];

    function Register ($http) {

        return {
            getRegist: function (registerAccount) {
                var url = '/api/registerBackGroundNew';
                if (registerAccount.rabbitLeaderId != null) {
                    url = url + '?rabbitLeaderId=' + registerAccount.rabbitLeaderId;
                }
                return $http({
                    // url: '/api/registerBackGround',
                    url: url,
                    method: 'POST',
                    data: {login:registerAccount.login,validationCode:registerAccount.code,password:registerAccount.password,sessionId:registerAccount.sessionId,idCard:registerAccount.idCard}
                })
            }
        }

    }

})();

//更新完善资料
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('PerfectInformation', PerfectInformation);

    PerfectInformation.$inject = ['$http'];

    function PerfectInformation ($http) {

        return {
            updateData: function (userInfo, id) {
                return $http({
                    url: 'api/usersinfo/'+id+'',
                    method: 'PUT',
                    data: userInfo
                })
            }
        }

    }

})();

