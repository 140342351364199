(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('LoginSelfController', LoginSelfController);

    LoginSelfController.$inject = ['$rootScope','$scope', '$timeout', 'Principal', '$state', '$uibModal', 'Auth','$http', '$cookies', 'MyEAccount','$location'];

    function LoginSelfController ($rootScope,$scope, $timeout, Principal, $state, $uibModal, Auth,$http, $cookies, MyEAccount,$location) {

        $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
            $rootScope.previousState = from; //from为前一个页面的路由信息：url,cache,views,name
            rootPre=$rootScope.previousState.url;
        });


        var vm = this;

        vm.authenticationError = false;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.author = null;
        vm.login = login;
        vm.username = null;
        vm.password = null;
        vm.cancel = cancel;
        vm.exit = false;
        vm.confirmJoin = false;
        vm.tripInfo = null;
        $("#activateCode").css("display", "none");
        $("#activateCode").hide();
        $timeout(function (){angular.element('#username').focus();});
        vm.loginType = $location.search().loginType
        vm.url = $location.search().url

        // $("#login").hide();
        // $("#activateCode").show();

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        // getAccount();

        var location = window.location.host;
        vm.domain = window.location.protocol+"//"+location;
        function getAccount() {
            Principal.identity().then(function (account) {
                if (account){
                    vm.account = account;
                    vm.author = vm.account.authorities;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    if(vm.tripInfo){
                        var expires = 60 * 1000;
                        var date = new Date(+new Date() - expires);
                        // var url = 'https://coach.jngysz.com/';
                        var url = window.location.href.indexOf('jngysz') != -1 ? "https://coach.jngysz.com/" : "https://www.itasktour.com/cd";
                        if (window.location.href.indexOf('jngysz') != -1) {
                            document.cookie = "joinTrip=back;expires=" + date.toUTCString() + ';Path=/;domain=jngysz.com';
                        } else {
                            document.cookie = "joinTrip=back;expires=" + date.toUTCString() + ';Path=/;domain=itasktour.com';
                        }
                        window.location.href = url + "?tripId=" + vm.tripInfo.tripId+ "&coachTeamId=" +vm.tripInfo.cocahTeamId +
                            (vm.tripInfo.myTripModelId != null? "tripModelId=" + vm.tripInfo.myTripModelId :'') + "&at=" +vm.account.password + '_coach';
                        // window.location.href = "http://192.168.3.20:8080/coach?tripId=" + vm.tripInfo.tripId+ "&coachTeamId=" +vm.tripInfo.cocahTeamId +
                        //     (vm.tripInfo.myTripModelId != null? "tripModelId=" + vm.tripInfo.myTripModelId :'') +  "&at=" +vm.account.password + '_coach';
                        return
                    }
                    //如果是普通用户 跳转到完善资料页面
                    if ($.inArray("ROLE_USER",vm.author)!==-1 && vm.author.length == 1 && !vm.confirmJoin) {
                        // var obj = {
                        //     login: vm.username,
                        //     password: vm.password
                        // }
                        $state.go('perfect-information');
                    } else if (vm.confirmJoin) {
                        $state.go("e-account-my",{},{reload:false});
                    }
                }
            });
        }

        function login(event) {
            event.preventDefault();
            vm.checkPwd = null;
            vm.exit = null;
            if(vm.username == null || vm.username == ''){
                vm.exit = 2;//电话格式不对
                return;
            }
            //激活
            if (vm.username != 'admin') {
                $http({
                    url:'/api/e-accounts/check-activate?login=' + vm.username + "&password=" + vm.password,
                    method:'GET'
                }).success(function (res) {
                    if (res == 0) {
                        loginConfirm();
                    } else if (res == -2) {
                        vm.exit = 3;//当前用户不存在
                    } else if (res == -3) {
                        vm.checkPwd = '密码错误';
                        console.log("密码错误");
                    } else {
                        //输入激活码
                        vm.exit = null;
                        $("#login").hide();
                        $("#activateCode").show();
                        $("#activateCode").css("display", "block");
                    }
                })
            } else {
                loginConfirm();
            }


        }

        vm.openWebAfterCode = openWebAfterCode;
        function openWebAfterCode() {
            if(vm.code == null || vm.code.length == 0){
                vm.exit = 5;//邀请码不能为空
                return
            }
            loginConfirm();
        }

        function loginConfirm() {
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: vm.username,
                    code: vm.code,
                    rabbitLeaderFlag: false
                }
            }).success(function (res) {
                if (res.errorCode == -1) {
                    vm.exit = 3;//当前用户不存在
                } else if (res.errorCode == -2) {
                    vm.exit = 2;//邀请码错误
                } else if (res.errorCode == -3) {
                    vm.exit = 4;//邀请码已失效
                } else {
                    Auth.login({
                        username: vm.username,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function (data) {
                        var eid = $cookies.get("eid");
                        var uid = $cookies.get("uid");
                        if (typeof(eid) != "undefined" && typeof(uid) != "undefined") {
                            Principal.identity().then(function(account) {
                                vm.account = account;
                                if (vm.account !== null) {
                                    if (vm.account.eAccountId == eid) {
                                        alert("您已加入该企业，请勿重复操作。");
                                        onSaveSuccess();
                                    } else if (vm.account.eAccountId != null) {
                                        alert("加入失败！您已有所属企业。");
                                        onSaveSuccess();
                                        $cookies.remove("eid");
                                        $cookies.remove("uid");
                                    } else {
                                        MyEAccount.addDegigner({
                                            eid: eid,
                                            login: vm.account.login,
                                            isTrainee: 0
                                        }, onSaveSuccessJoin, onSaveError);
                                        $cookies.remove("eid");
                                        $cookies.remove("uid");
                                    }
                                }
                            });
                        } else {
                            onSaveSuccess();
                        }
                    }).catch(function (err) {
                        // console.log(err);
                        // vm.authenticationError = true;
                        vm.checkPwd = '密码错误';
                    });
                }
            })
        }

        vm.getActivationCode = function(){
            $('#myModal').modal('show');
        }


        function getAccount1() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(account) {
                console.log(account)
                var url = window.location.href.indexOf('jngysz') != -1 ? "https://coach.jngysz.com" : "https://www.itasktour.com/cd";
                // var url = 'https://coach.jngysz.com';

                url = url + "?at=" + account.data.password + '_coach';
                window.location.href = url;
                return;
            });
        }

        function onSaveSuccess () {
            getAccount();
            vm.authenticationError = false;
            if (window.location.href.indexOf('jngysz') != -1) {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=jngysz.com";
            } else {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com";
            }
            if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                // $state.go('guide');
            }
            var reg = new RegExp("(^| )" + 'joinTrip' + "=([^;]*)(;|$)");
            var arr = document.cookie.match(reg)
            if(arr != null){
                vm.tripInfo = JSON.parse(arr[2]);
                // vm.tripInfo = {};
                // vm.tripInfo.tripId = 1;
                // vm.tripInfo.cocahTeamId  = 1;
                return;
            }
            // $state.go('trip-model');

            if(vm.loginType != null && vm.loginType == 1){
                getAccount1();

            }
            if(vm.url  != null && vm.url .length > 0){
                // console.log(vm.domain + "/#/" + vm.url )
                // $cookies.remove("loginUrl")
                // window.location.href =  vm.domain + "/#/" + vm.url;
                // return
                vm.clickState();
            }
            $state.go('program-center',{login:true});

        }

        function onSaveSuccessJoin () {
            vm.confirmJoin = true;
            getAccount();
            vm.authenticationError = false;
            if (window.location.href.indexOf('jngysz') != -1) {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=jngysz.com";
            } else {
                document.cookie = "isV3LoginUser=isV3LoginUser;Path=/;domain=itasktour.com";
            }
            $state.go("e-account-my",{}, {reload:false});
        }

        function onSaveError () {

        }
        // vm.toLogin = toLogin;
        // function toLogin() {
        //     $('#myModal1').modal('hide');
        //     // $(".modal-backdrop").remove();
        //     window.setTimeout(function () {
        //         $state.go('guide');
        //     },300);
        //     // cancel();
        // }
        // function getVersion() {
        //     $http({
        //         method: 'GET',
        //         url: '/api/version-descs'
        //     }).then(function (res) {
        //         // console.log(res);
        //         if (res.data.length>0){
        //             $('#myModal1').modal('show');
        //             var num = res.data.length;
        //             if (num>0){
        //                 vm.versionDesc = res.data[num-1];
        //                 vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //             }else {
        //                 vm.versionDesc = res.data[num];
        //                 vm.versionDesc.description = $sce.trustAsHtml(vm.versionDesc.description);
        //             }
        //         }else {
        //             $state.go('guide');
        //         }
        //         // console.log(vm.versionDesc);
        //     },function (err) {
        //         console.log(err);
        //     })
        // }

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModal.dismiss('cancel');
        }
        vm.openWeb  = openWeb;
        function openWeb() {
            console.log("----------openWeb-------");
            // window.location.href = window.location.protocol+"//"+window.location.host;
            // if(window.location.hostname.startsWith("dev")){
            //     window.location.href = "http://dev.itasktour.com/index.php?go=ittrweb.index.index";
            // }else {
            //     window.location.href = "https://www.itasktour.com";
            // }
            window.location.href = window.location.href.indexOf('jngysz') != -1 ? "https://api.jngysz.com" : "https://testapi.itasktour.com";

            // window.location.href = "https://api.jngysz.com";
        }
        vm.checkLogin = checkLogin;
        function checkLogin() {//检查是否已经存在用户
            vm.exit = null;
            var loginID = vm.username;
            if(loginID == null || loginID == ''){
                vm.exit = 3;//电话格式不对
                return;
            }
            $http({
                url:'/api/registerCheckLogin',
                method:'POST',
                params: {
                    login: loginID
                }
            }).success(function (res) {
                console.log("----------open-2-------");
                if (res.errorCode != 0) {
                    vm.exit = 3;//当前用户不存在
                }
            })
        }

        vm.clickState = function () {
            if(vm.url  != null && vm.url.length > 0){
                var expireDate = new Date();
                expireDate.setTime(expireDate.getTime() + 10 * 60 * 1000);
                $cookies.put("loginUrl", vm.domain + "/#/" + vm.url, {expires: new Date(expireDate)});
            }
            // $state.go(url,{}, {reload:false});
        }


        $scope.$on("$destroy", function() {
            $(".container").removeClass("login-container-content");
            $("#nav, #foot").css("display", "block");
        })

    }
})();
