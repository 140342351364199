(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TradeTripDialogController', TradeTripDialogController);

    TradeTripDialogController.$inject = ['$timeout', '$scope', '$log','$sce', '$uibModalInstance', 'entity', 'Trip', 'TeamSearch',  'LocationTask', 'TripType', 'User', 'UserSearch', 'Team', 'Upload', 'Ahdin', 'Npc','UserExt','BadgesSearch','MyBadges','UserTripModelSearch', 'Principal','$state','$http','EAccountSearch','EAccountV2Search'];

    function TradeTripDialogController ($timeout, $scope, $log,$sce, $uibModalInstance, entity, Trip, TeamSearch, LocationTask, TripType, User, UserSearch, Team, Upload, Ahdin, Npc,UserExt,BadgesSearch,MyBadges,UserTripModelSearch,Principal,$state,$http,EAccountSearch,EAccountV2Search) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.trip = entity;
        // console.log(vm.trip);
        vm.clear = clear;
        vm.pauseTimeChanged = pauseTimeChanged;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.triptypes = TripType.query();
        vm.onFileSelect = onFileSelect;
        vm.npcs = Npc.query();
        vm.itemsPerPage = 250;
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb
        vm.showCropped = true;
        vm.cropper = 'default';
        vm.eAccountId = null;
        vm.removeCoppedImg = removeCoppedImg;
        vm.removeMasCot = removeMasCot;
        vm.filterEAccounts = this.filterEAccounts;

        function removeCoppedImg() {
            if (vm.trip.coverImg != null) {
                vm.trip.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function removeMasCot() {
            if (vm.trip.mascot != null) {
                vm.trip.mascot = null;
            }
        }

        vm.filterTeam = this.filterTeam;

        vm.filterUser = this.filterUser;

        vm.filterBadage = this.filterBadage;

        vm.filterTripModels = this.filterTripModels;

        this.filterTeam = function (search) {
            if (search) {
                vm.teams = TeamSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };
        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.filterTripModels = function (search) {
            if (search) {
                vm.tripModels = UserTripModelSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc'],
                    release: "0"
                });
            }
        };

        this.filterBadage = function (search) {
            if (search) {
                vm.badages = BadgesSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.filterEAccounts = function (search) {
            if (search){
                vm.eAccounts = EAccountV2Search.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc'],
                    release: "0"
                });
            }
        };

        getAccount();
        function getAccount() {
            //Principal.identity().then(function(account) {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function (res) {
                vm.account = res.data;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN_V2",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC_V2";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }
                }
                // console.log(vm.account);
            });
        }

        if (vm.trip.eAccountId != null){
            getEaccountContractTime(vm.trip.eAccountId);
        }
        vm.getEaccountContractTime = getEaccountContractTime;
        function getEaccountContractTime(id) {
            $http({
                url: 'api/e-accounts/'+id,
                method: 'GET'
            }).then(function (res) {
                //console.log(res);
                if (res != null) {
                    if (res.data.contractTime != null) {
                        vm.contractTime = new Date(res.data.contractTime);
                    } else {
                        var startTime = new Date();
                        vm.contractTime = startTime;
                    }
                    vm.expireTime = new Date(res.data.expireTime);

                    vm.trip.startTime = vm.contractTime;
                    vm.trip.endTime = vm.expireTime;
                }
            },function (err) {
                console.log(err);
            })
        }

        //设置爱闯闯代买保险默认为true
        vm.changeAttribute = changeAttribute;
        function changeAttribute() {
            // alert(vm.trip.attribute);
            if (vm.trip.attribute=='TIMERACE'){
                vm.trip.enableCertification = true;
                $scope.changeEnable = true;
            }else {
                $scope.changeEnable = false;
            }
            if (vm.trip.attribute=='PUBLIC'){
                if (vm.auth=='IGC') {
                    if (vm.trip.eAccountId!=null){
                        getEaccountContractTime(vm.trip.eAccountId);
                    }
                } else {
                    if(vm.account.eAccountId != null){
                        getEaccountContractTime(vm.account.eAccountId);
                    }
                }

                vm.trip.startTime = vm.contractTime;
                vm.trip.endTime = vm.expireTime;
                // console.log(vm.trip.startTime);
                // console.log(vm.trip.endTime);
            }else {
                vm.trip.startTime = null;
                vm.trip.endTime = null;
            }
            //切换活动状态以下三个字段置空
            vm.trip.teamMaxpeople = null;
            vm.trip.maxTeamcount = null;
            vm.trip.maxPeople = null;
            // console.log(vm.trip);
            vm.trip.enableCertification = null;
            vm.trip.enableStart = true;
        }
        this.$onInit = function () {
            $(function () {
                if (vm.trip.eaccounts != null) {
                    vm.eAccountId = vm.trip.eaccounts;
                }
                if (vm.trip.eAccountId != null) {
                    $http({
                        url: 'api/e-accounts/'+vm.trip.eAccountId,
                        method: 'GET'
                    }).then(function (res) {
                        if (res != null) {
                            vm.eAccountName = res.data.name;
                        }
                    },function (err) {
                        console.log(err);
                    })
                }

                //设置默认未满员可开启
                if (vm.trip.enableStart==null || typeof vm.trip.enableStart=='undefined'){
                    vm.trip.enableStart = true;
                }

                //设置默认活动类型为公开计分活动
                if (vm.trip.attribute == null){
                    vm.trip.attribute = 'OUTWARD';
                }

                if (vm.trip.id == 1 || vm.trip.id == 3) {
                    $(".team").css("display", "none");
                } else {
                    $(".team").css("display", "block");
                }
                checkCoverType(vm.trip.coverImg);
                if (vm.trip.myArtistId) {
                    UserExt.getUserByID(vm.trip.myArtistId).then(function (response) {
                        var user = response.data;
                        vm.trip.myArtistId = {id: user.id, login: user.login, firstName: user.firstName};
                        // console.log(vm.trip.myArtistId);
                    });
                }

                $("[name='show_score']").bootstrapSwitch('state');
                $('input[name="show_score"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.showScore = state;
                });

                $("[name='isEnableStart']").bootstrapSwitch('state');
                $('input[name="isEnableStart"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.enableStart = state;
                });

                $("[name='enableCertification']").bootstrapSwitch('state');
                $('input[name="enableCertification"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.enableCertification = state;
                });

                $("[name='gameStarted']").bootstrapSwitch('state');
                $('input[name="gameStarted"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.gameStarted = state;
                });
                $("[name='npcEnable']").bootstrapSwitch('state');
                $('input[name="npcEnable"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    vm.trip.npcEnable = state;
                });

                $("[name='is_pause']").bootstrapSwitch('state');
                if (!vm.trip.isPause) {
                    $("#pause_content_container").css("display", "none");
                }

                if (!vm.trip.pauseStartTime) {
                    var d = new Date();
                    vm.trip.pauseStartTime = d;
                }
                $('input[name="is_pause"]').on('switchChange.bootstrapSwitch', function (event, state) {
                    if (state) {
                        $("#pause_content_container").css("display", "block");
                        if (!vm.trip.pauseStartTime) {
                            var d = new Date();
                            vm.trip.pauseStartTime = d;
                        }
                    } else {
                        $("#pause_content_container").css("display", "none");
                    }
                    vm.trip.isPause = state;
                });

            });
        };
        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        function pauseTimeChanged() {
            // console.log(vm.trip.pauseStartTime);
            // $log.log('Time changed to: ' + vm.trip.pauseStartTime);
            //
            // console.log(vm.trip.pauseStartTime.getHours());
            // console.log(vm.trip.pauseStartTime.getMinutes());
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (vm.trip.attribute != 'OUTWARD' && vm.trip.attribute != 'OUTTIMERACE') {
                vm.trip.maxPeople = vm.trip.teamMaxpeople * vm.trip.maxTeamcount;
            }
            vm.isSaving = true;

            if (vm.trip.attribute === 'COMMON'){
                vm.trip.teamMaxpeople = 1;
                vm.trip.maxTeamcount = 20000;
                var startDate = new Date();
                vm.trip.startTime = startDate;
                vm.trip.endTime = null;
            }else if (vm.trip.attribute === 'PUBLIC'){
                //公开活动价格最低10元限制
                if (vm.trip.price<10){
                    alert("公开活动价格不得低于10元");
                    vm.trip.price = 10;
                    vm.isSaving = false;
                    return;
                }
            }

            if (vm.trip.myArtistId) {
                if (vm.trip.myArtistId.id) vm.trip.myArtistId = vm.trip.myArtistId.id;
            }

            if (vm.trip.coverImg !== null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(vm.trip.coverImg)){
                    var image = document.getElementById("file");
                    if (image != null){
                        var height = image.height;
                        var width = image.width;
                        var bi = width / height;
                        if (bi<2.8 || bi>3.2) {
                            // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                            alert("上传图片比例应为3:1");
                            if (vm.trip.coverImg != null) {
                                vm.trip.coverImg = null;
                            }
                            vm.isSaving = false;
                            return;
                        }
                    }
                }
            }else {
                alert("上传图片不能为空");
                vm.isSaving = false;
                return;
            }
            var price = $('#field_price').val();
            if (price<0){
                alert("价格不符合要求。");
                vm.trip.price = null;
                vm.isSaving = false;
                return;
            }
            var teamMaxPeople = $('#field_teamMaxpeople').val();
            if (teamMaxPeople<0){
                alert("战队最大人数不符合要求。");
                vm.trip.teamMaxpeople = null;
                vm.isSaving = false;
                return;
            }
            var maxTeamCount = $('#field_maxTeamcount').val();
            if (maxTeamCount<0){
                alert("战队数上限不符合要求。");
                vm.trip.maxTeamcount = null;
                vm.isSaving = false;
                return;
            }

            //创建线路时给某些字段赋默认值
            vm.trip.status = 'EDIT';
            vm.trip.type = 'Normal';
            if(vm.trip.attribute==null){
                alert("线路属性不能为空，请设置线路属性");
                vm.isSaving = false;
                return;
            }
            //判断结束时间是否在开始时间之后 及 开始时间，结束时间是否在当前时间之后
            if (vm.trip.attribute!='COMMON'){
                if ((vm.trip.startTime!=null&&vm.trip.startTime!='underfine')&&(vm.trip.endTime!=null&&vm.trip.endTime!='underfine')){
                    var start = new Date(vm.trip.startTime).getTime();
                    var end = new Date(vm.trip.endTime).getTime();
                    if (end<start){
                        alert("结束时间必须在开始时间之后，请重新录入结束时间！");
                        vm.trip.endTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    var now = new Date().getTime();
                    if (end<now){
                        alert("活动结束时间不能在当前时间之前！");
                        vm.trip.endTime = null;
                        vm.isSaving = false;
                        return;
                    }
                    if (vm.trip.attribute == 'PUBLIC'){
                        var contractTime = null;
                        var expireTime = null;
                        if (vm.contractTime!=null&&vm.expireTime!=null){
                            contractTime = vm.contractTime.getTime();
                            expireTime = vm.expireTime.getTime();
                        }
                        if (start<contractTime || end>expireTime){
                            alert("活动开始时间及结束时间必须在合同起止时间范围内。");
                            vm.isSaving = false;
                            return;
                        }
                    }
                }else {
                    //正则验证时间格式是否正确
                    var startTime = checkTime(vm.trip.startTime);
                    var endTime = checkTime(vm.trip.endTime);
                    if (startTime===false){
                        alert("请输入正确的开始时间格式!");
                        vm.trip.startTime = null;
                        vm.isSaving = false;
                        return;
                    }else if (endTime===false){
                        alert("请输入正确的结束时间格式!");
                        vm.trip.endTime = null;
                        vm.isSaving = false;
                        return;
                    }
                }
                // console.log('开始时间：'+start+'结束时间：'+end);
            }
            // console.log(vm.trip);
            //后台修改活动不对战队做修改，返回null即可
            vm.trip.hasTeams = null;
            vm.trip.hasNormalTeams = null;
            vm.trip.hasTestTeams = null;
            vm.trip.hasWebTeams = null;
            if (vm.trip.id !== null) {
                Trip.update(vm.trip, onSaveSuccess, onSaveError);
            } else {
                Trip.save(vm.trip, onSaveSuccess, onSaveError);
            }
        }
        //正则验证时间格式
        function checkTime(str) {
            // console.log(str);
            str = String(str);
            str = str.replace(/ GMT.+$/, '');// Or str = str.substring(0, 24)
            var d = new Date(str);
            var a = [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()];
            for(var i = 0, len = a.length; i < len; i ++) {
                if(a[i] < 10) {
                    a[i] = '0' + a[i];
                }
            }
            str = a[0] + '-' + a[1] + '-' + a[2] + ' ' + a[3] + ':' + a[4] + ':' + a[5];
            var reDateTime = /^(?:19|20)[0-9][0-9]-(?:(?:[0][1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
            var isDateTime = reDateTime.test(str);
            return isDateTime;
        }

        function onSaveSuccess(result) {
            $scope.$emit('taskServiceApp:tripUpdate', result,{reload:true});
            $uibModalInstance.close(result);
            vm.isSaving = false;
            // console.log(result);
            // $state.go('trip-detail',{id:vm.trip.id},{reload:true});
            // clear();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.videoUrl = function (url) {
            return $sce.trustAsResourceUrl(url);
        };

        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {

                var fileType = compressedBlob.type;
                if (fileType!=null && typeof fileType!='undefined'){
                    fileType = fileType.split('/')[1];
                }
                var imgSize = compressedBlob.size/1024;
                //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
                // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                if (fileType=='jpg'||fileType=='jpeg'||fileType=='png'||fileType=='bmp'){
                    // console.log(imgSize);
                    if (imgSize>imgLimit){
                        alert("图片大小超过"+imgLimit+"kb");
                        return;
                    }
                    vm.type = 'img';
                }else if (fileType=='mp3'||fileType=='mp4'){
                    if (imgSize>otherLimit){
                        alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                        return;
                    }
                    vm.type = 'other';
                }else {
                    alert("文件格式不正确，请重新上传！！");
                    return;
                }

                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    // var imgSize = compressedBlob.size/1024;
                    // console.log(imgSize);
                    // var file = data.file;
                    //
                    // if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    //     // console.log(imgSize);
                    //     if (imgSize>imgLimit){
                    //         alert("图片大小超过"+imgLimit+"kb");
                    //         return;
                    //     }
                    //     vm.type = 'img';
                    // }else if (/\.(mp3|mp4)$/.test(file)){
                    //     if (imgSize>otherLimit){
                    //         alert("音视频格式只能为MP3/MP4,且大小不能超过"+otherLimit/1000+"M");
                    //         return;
                    //     }
                    //     vm.type = 'other';
                    // }else {
                    //     alert("文件格式不正确，请重新上传！！");
                    //     return;
                    // }

                    //update the url
                    if (name == 'coverImg') {
                        vm.trip.coverImg = data.file;
                    } else if (name == 'mascot') {
                        vm.trip.mascot = data.file;
                    }

                    $scope.myImage = vm.IMAGE_SERVER+vm.trip.coverImg;

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);

                });
            };

            if (uploadFile != null) {
                uploadImageFile(uploadFile);
            }
        }

        $scope.cropper = {};
        $scope.cropper.sourceImage = null;
        $scope.cropper.croppedImage   = null;
        $scope.bounds = {};
        $scope.bounds.left = 0;
        $scope.bounds.right = 0;
        $scope.bounds.top = 0;
        $scope.bounds.bottom = 0;

    }
})();
