(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripQrDetailController', TripQrDetailController);

    TripQrDetailController.$inject = ['$scope', '$rootScope', 'Principal', '$http', '$stateParams', 'previousState', 'entity', '$state'];

    function TripQrDetailController($scope, $rootScope,Principal, $http, $stateParams, previousState, entity, $state) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.trip = entity;
        // console.log(vm.trip);
        vm.teamCount = 0;

        getTeamCount();
        function getTeamCount(){
            $http({
                method: 'GET',
                url: '/api/trip/getTeamsByTripId/'+vm.trip.id+'/all'
            }).then(function (res) {
                // console.log(res);
                if (res != null){
                    for (var team in res.data){
                        if (res.data[team].type != 2){
                            vm.teamCount++;
                        }
                    }
                }
            },function (err) {
                console.log(err);
            });
        }

        vm.createTeamQr = createTeamQr;
        function createTeamQr() {
            // console.log(vm.people);
            vm.error = "";
            if (vm.people!=null && typeof vm.people!=='undefined'){
                if (vm.people>vm.trip.maxPeople || vm.people>(vm.trip.maxPeople-vm.teamCount)){
                    vm.error = "不可超出活动可加最大人数";
                }else {
                    console.log("123");
                }
            }
        }


        var unsubscribe = $rootScope.$on('taskServiceApp:tripUpdate', function(event, result) {
            vm.trip = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();


