(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ProgramShowPDFController', ProgramShowPDFController)
        .filter('pdfFilter', function() {
            return function(text) {
                return text+".pdf";
            }
        })
        .filter('labelFilter', function() {
            return function(text) {
                var arr = JSON.parse(text);
                return arr;
            }
        })


    ProgramShowPDFController.$inject = ['$state',  'Principal', '$http', 'ParseLinks', 'AlertService','$stateParams' ];

    function ProgramShowPDFController($state,  Principal, $http, ParseLinks, AlertService,$stateParams) {

        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        var obj=JSON.parse($stateParams.obj);
        var id=obj.id;
        vm.type = obj.type;
        getDetailById(id);

        function getDetailById(id) {
            $http({
                method: 'GET',
                url: '/api/program/getByid/' + id
            }).then(function (res) {
                vm.programDetail = res.data;
                if(vm.type == null || vm.type == 1){
                    vm.imageUrl =  vm.programDetail.programUrlImg
                }else{
                    vm.imageUrl =  vm.programDetail.manualUrlImg
                }
            },function (err) {
                console.log(err);
            })
        }



    }
})();
