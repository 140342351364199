(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelDialogController', TripModelDialogController);

    TripModelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TripModel','Ahdin','Upload','UserExt','UserSearch','Principal','$http'];

    function TripModelDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TripModel,Ahdin,Upload,UserExt,UserSearch,Principal,$http) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.tripModel = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveAndOpen = saveAndOpen;
        vm.onFileSelect = onFileSelect;
        vm.filterUser = this.filterUser;
        vm.showCropped = true;
        vm.cropper = 'default';
        var newWindow;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        if ($stateParams.type != null) {
            vm.tripModel.platform=$stateParams.type;//0为专业版（旧版）1为基础版（小程序）
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        this.filterUser = function (search) {
            if (search) {
                vm.users = UserSearch.query({
                    query: search,
                    page: 0,
                    size: vm.itemsPerPage,
                    sort: ['id,desc']
                });
            }
        };

        this.$onInit = function () {

            getAccount();

        };

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.tripModel.coverImg != null) {
                vm.tripModel.coverImg = null;
                vm.type = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function getAccount() {
            $http({
                url: 'api/account',
                method: 'GET'
            }).then(function(res) {
                vm.account = res.data;
                if (vm.account!==null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }else if ($.inArray("ROLE_ADMIN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Admin";
                    }else if ($.inArray("ROLE_OPERATION",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "Service";
                    }else if ($.inArray("ROLE_INDIEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "IGC";
                    }else if ($.inArray("ROLE_USERDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "UGC";
                    }else if ($.inArray("ROLE_TRAINEEDESIGN",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "TGC";
                    }else if ($.inArray("ROLE_USER",author)!==-1 && $.inArray("ROLE_SUPER",author)===-1){
                        vm.auth = "User";
                    }

                    if (false && vm.auth == 'IGC'){
                        getTripByTripModel();
                    }

                }
            });
        }

        function getTripByTripModel(){
            if (vm.tripModel.id!=null){
                $http({
                    method: 'GET',
                    url: '/api/trip-models/hide/getTripByTripModelId/' + vm.tripModel.id
                }).then(function (res) {
                    // console.log(res);
                    if (res!=null && res.data!=null && res.data.length>0){
                        vm.trip = res.data[0];
                    }
                },function (err) {
                    console.log(err);
                });
            }
        }

        vm.checkType = checkCoverType;
        function checkCoverType(file) {
            if (file!=null){
                if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                    vm.type = 'img';
                }else {
                    vm.type = 'other';
                }
            }
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        function save () {
            vm.isSaving = true;

            //创建线路时给某些字段赋默认值
            vm.tripModel.status = 'EDIT';
            vm.tripModel.type = 'Normal';

            var eTime = $('#field_eTime').val();
            if (eTime<0 || eTime>10000){
                vm.tripModel.eTime = 8;
                // alert("时长不符合要求。");
                // vm.tripModel.eTime = null;
                // vm.isSaving = false;
                // return;
            }
            var eTripLong = $('#field_eTripLong').val();
            if (eTripLong<0 || eTripLong>10000){
                vm.tripModel.eTripLong = 10;
                // alert("距离不符合要求。");
                // vm.tripModel.eTripLong = null;
                // vm.isSaving = false;
                // return;
            }
            if (false && vm.auth=='IGC'){//暂时不用
                if (vm.tripModel.tradableFlag == null || typeof vm.tripModel.tradableFlag==='undefined') {
                    vm.tripModel.tradableFlag = true;
                }
                // console.log(vm.tripModel.coverImg);
                if (vm.trip.coverImg != null){
                    if (/\.(jpg|jpeg|png|bmp)$/.test(vm.trip.coverImg)){
                        var image = document.getElementById("file");
                        if (image != null){
                            var height = image.height;
                            var width = image.width;
                            var bi = width / height;
                            if (bi<2.8 || bi>3.2) {
                                // alert('"封面图片"比例不是推荐值3:1，显示时将被拉伸或压缩，是否仍要使用？');
                                alert("上传图片比例应为3:1");
                                if (vm.trip.coverImg != null) {
                                    vm.trip.coverImg = null;
                                }
                                vm.isSaving = false;
                                return;
                            }
                        }
                    }
                }else {
                    alert("上传图片不能为空");
                    vm.isSaving = false;
                    return;
                }
            }

            var date = new Date();

            if (vm.tripModel.id !== null) {
                vm.tripModel.updateDate = date;
                vm.tripModel.updateBy = vm.account.id;
                TripModel.update(vm.tripModel, onSaveSuccess, onSaveError);
            } else {
                if(vm.tripModel.platform == 4){
                    vm.tripModel.mapStyle = 3;
                }
                vm.tripModel.createDate = date;
                vm.tripModel.createBy = vm.account.id;
                TripModel.save(vm.tripModel, onSaveSuccess, onSaveError);
                // console.log(vm.tripModel);
            }
        }
        function saveAndOpen () {
            save();
            // window.location.href = "http://acfe.itasktour.com/editor/"+vm.tripModel.id+"?at="  +vm.account.password+"_coach";
            vm.tripModel.isOpenWindow = 1;

        }

        function onSaveSuccess (result) {
            // console.log(result);
            if (false &&  vm.auth == 'IGC'){
                saveTrip(result);
            }else {
                $scope.$emit('taskServiceApp:tripModelUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
            if(vm.tripModel.isOpenWindow == 1) {
                newWindow = window.open();
                // 如果是济南企业，禁止线路编辑器使用旁白语音上传功能； 传递coach字段到线路编辑器，用户禁用旁白上传功能
                if(vm.account.eAccountId===7){
                    newWindow.location.href = vm.account.acfeUrl + result.id + "?at=" + vm.account.password + "_coach&coach="+new Date().getTime();
                }else{
                    newWindow.location.href = vm.account.acfeUrl + result.id + "?at=" + vm.account.password + "_coach";
                }
                vm.tripModel.isOpenWindow = 0;
            }
        }
        function onSaveError () {
            vm.isSaving = false;
        }

        function saveTrip(result){
            result.startLocation = vm.trip.startLocation;
            result.description = vm.trip.description;
            $http({
                method: 'POST',
                url: '/api/trip/tradable/create',
                data: result
            }).then(function suc(res) {
                // console.log(res);
                $scope.$emit('taskServiceApp:tripModelUpdate', result);
                $uibModalInstance.close(result);
                vm.isSaving = false;
            },function err(err) {
                console.log(err);
                vm.isSaving = false;
            });
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect(uploadFile, name) {

            var uploadImageFile = function (compressedBlob) {
                Upload.upload({

                    url: '/api/postfile',
                    fields: {},
                    data: {type: "TRIP"},
                    file: compressedBlob,
                    method: 'POST'

                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    //update the url
                    if (name == 'coverImg') {
                        vm.tripModel.coverImg = data.file;
                        vm.trip.coverImg = data.file;
                    } else if (name == 'mascot') {
                        vm.tripModel.mascot = data.file;
                    }


                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);
                });
            };


            //TODO gif no compress
            if (uploadFile != null) {
                // Ahdin.compress({
                //     sourceFile: uploadFile,
                //     maxWidth: 1280,
                //     maxHeight: 1000,
                //     quality: 0.8
                // }).then(function (compressedBlob) {
                //     console.log('compressed image by ahdin.');
                uploadImageFile(uploadFile);
                // });
            }
        }
    }
})();
