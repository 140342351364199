(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('MyEAccountMemberController', MyEAccountMemberController);

    MyEAccountMemberController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'EAccount', 'User','UserSearch','MyEAccount','MyEAccountRecords','MyEAccontUpdate','$http'];

    function MyEAccountMemberController($scope, $rootScope, $state, $stateParams, previousState, entity, EAccount, User,UserSearch,MyEAccount,MyEAccountRecords,MyEAccontUpdate,$http) {
        var vm = this;
        vm.phone = null;
        vm.eAccount = entity;
        // console.log(vm.eAccount);
        vm.previousState = previousState.name;
        vm.addDesiger = this.addDesiger;
        vm.deleteDesign = this.deleteDesign;
        vm.updateToDesign = this.updateToDesign;
        vm.updateToTrainee = this.updateToTrainee;
        vm.itemsPerPage = 250;
        vm.back = back;
        vm.toTrip = toTrip;
        vm.filterUser = this.filterUser;
        this.filterUser = function(search) {
            if(search){
                vm.users = UserSearch.query({
                    query: search,
                    page :  0,
                    size : vm.itemsPerPage,
                    sort : ['id,desc']
                });
            }
        };
        load();
        function load() {
            // if (vm.eAccount.confirmEmail===false){
            //     $('#myModal1').modal('show');
            // }
            addLayout();
        }
        function back() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("e-account-my.edit");
            },300);
        }
        function toTrip() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $state.go("trip");
            },300);
        }
        function addLayout() {
            document.getElementById("hid").style.display="block";
            document.getElementById("nav").style.display="block";
            // document.getElementById("foot").style.display="block";
            document.getElementById("con").className="well";
            $(function() {
                $(".container").removeClass("login-container-content");
                if ($(window).width()<768) $(".nav-user .dropdown-menu").removeClass("pull-right");
            });
        }
        showHasUsers();
        function showHasUsers() {
            if (vm.eAccount.id != null){
                $http({
                    method: 'GET',
                    url: '/api/e-accounts/findAllUserByEAccountId/' + vm.eAccount.id
                }).then(function (res) {
                    // console.log(res);
                    vm.eAccount.hasUsers = res.data.hasUsers;
                    vm.eAccount.hasDesignUsers = res.data.hasDesignUsers;
                    vm.eAccount.hasTraineeUsers = res.data.hasTraineeUsers;
                },function (err) {
                    console.log(err);
                });
            }
        }
        loadRecrds();
        function loadRecrds() {
            vm.records = MyEAccountRecords.query({
                page : 0,
                size : 20,
                sort : ['id,asc']
            });
        }
        this.addDesiger = function () {
            MyEAccount.addDegigner({eid:vm.eAccount.id,login:vm.phone},onSaveSuccess,onSaveError);
        };

        this.deleteDesign = function(hasUser){
            console.log(vm.eAccount.hasTraineeUsers);
            var index = vm.eAccount.hasTraineeUsers.indexOf(hasUser);
            if (index == -1) {
                index = vm.eAccount.hasDesignUsers.indexOf(hasUser);
                var userId = vm.eAccount.hasDesignUsers[index].id;
            } else {

                var userId = vm.eAccount.hasTraineeUsers[index].id;
            }

            MyEAccount.deleteDegigner({eid:vm.eAccount.id,uid:userId,isTrainee:0},onSaveSuccess,onSaveError);

        };

        this.updateToDesign = function(hasUser){
            var index = vm.eAccount.hasTraineeUsers.indexOf(hasUser);
            var userId = vm.eAccount.hasTraineeUsers[index].id;
            //console.log(vm.eAccount.hasTraineeUsers);

            MyEAccontUpdate.updateDesign({eid:vm.eAccount.id,uid:userId,isTrainee:0},onSaveSuccess,onSaveError);

            // vm.eAccount.hasUsers.splice(index,1);
            // console.log(vm.eAccount.hasUsers);
            // EAccount.update(vm.eAccount, onSaveSuccess, onSaveError);
        };

        this.updateToTrainee = function(hasUser){
            var index = vm.eAccount.hasDesignUsers.indexOf(hasUser);
            var userId = vm.eAccount.hasDesignUsers[index].id;

            MyEAccontUpdate.updateDesign({eid:vm.eAccount.id,uid:userId,isTrainee:1},onSaveSuccess,onSaveError);

            // vm.eAccount.hasUsers.splice(index,1);
            // console.log(vm.eAccount.hasUsers);
            // EAccount.update(vm.eAccount, onSaveSuccess, onSaveError);
        };

        function onSaveSuccess (result) {
            vm.phone = null;
            $scope.$emit('taskServiceApp:eAccountUpdate', result);
            // console.log(result);
            showHasUsers();
        }

        function onSaveError () {
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
