(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('base-task', {
            parent: 'entity',
            url: '/base-task?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.baseTask.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/base-task/base-tasks.html',
                    controller: 'BaseTaskController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('baseTask');
                    $translatePartialLoader.addPart('taskType');
                    $translatePartialLoader.addPart('showMode');
                    $translatePartialLoader.addPart('eRacingTagType');
                    $translatePartialLoader.addPart('challengeMode');
                    $translatePartialLoader.addPart('arResultType');
                    $translatePartialLoader.addPart('pswAnswerType');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('arModelNew');
                    $translatePartialLoader.addPart('arModelType');
                    return $translate.refresh();
                }]
            }
        })
        .state('base-task-detail', {
            parent: 'entity',
            url: '/base-task/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'taskServiceApp.baseTask.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/base-task/base-task-detail.html',
                    controller: 'BaseTaskDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('baseTask');
                    $translatePartialLoader.addPart('taskType');
                    $translatePartialLoader.addPart('showMode');
                    $translatePartialLoader.addPart('eRacingTagType');
                    $translatePartialLoader.addPart('challengeMode');
                    $translatePartialLoader.addPart('arResultType');
                    $translatePartialLoader.addPart('pswAnswerType');
                    $translatePartialLoader.addPart('arModelNew');
                    $translatePartialLoader.addPart('arModelType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'BaseTask', function($stateParams, BaseTask) {
                    return BaseTask.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'base-task',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('base-task-detail.edit', {
            parent: 'base-task-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/base-task/base-task-dialog.html',
                    controller: 'BaseTaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['BaseTask', function(BaseTask) {
                            return BaseTask.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: true });
                }, function() {
                    // $state.go('base-task');
                    $state.go('^');
                });
            }]
        })
        .state('base-task.new', {
            parent: 'base-task',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/base-task/base-task-dialog.html',
                    controller: 'BaseTaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                type: null,
                                seq: null,
                                img: null,
                                latticeType: null,
                                success_desc:null,
                                preCondition: null,
                                showMode: null,
                                challengeMode: null,
                                myLocationTaskId: null,
                                myArModelId: null,
                                description: null,
                                topScore: 0,
                                question: null,
                                answer: null,
                                selection: null,
                                scanImg: null,
                                scanResult: null,
                                resultType: null,
                                bgAudio: null,
                                lifeTime: null,
                                racingTag: null,
                                createDate: null,
                                createBy: null,
                                updateDate: null,
                                updateBy: null,
                                isPreTask: false,
                                canIgnore: true,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('guide', null, { reload: 'guide' });
                }, function() {
                    $state.go('guide');
                });
            }]
        })
        .state('base-task.edit', {
            parent: 'base-task',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/base-task/base-task-dialog.html',
                    controller: 'BaseTaskDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['BaseTask', function(BaseTask) {
                            return BaseTask.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    // $state.go('base-task', null, { reload: 'base-task' });
                    window.history.go(-1);
                }, function() {
                    // $state.go('^');
                    window.history.go(-1);
                });
            }]
        })
        .state('base-task.delete', {
            parent: 'base-task',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/base-task/base-task-delete-dialog.html',
                    controller: 'BaseTaskDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BaseTask', function(BaseTask) {
                            return BaseTask.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('base-task', null, { reload: 'base-task' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
