(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TripModelCopyController',TripModelCopyController);

    TripModelCopyController.$inject = ['$uibModalInstance', 'entity', '$http','$state','$stateParams'];

    function TripModelCopyController($uibModalInstance, entity,  $http,$state,$stateParams) {
        var vm = this;

        vm.tripModel = entity;
        vm.clear = clear;
        vm.confirmCopy = confirmCopy;
        vm.cannotOpt = false;
        // console.log($stateParams)
        if($stateParams.upgrade != null){
            vm.tripModel.upgrade = $stateParams.upgrade;
        }else {
            vm.tripModel.upgrade = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy (tripModel) {
            vm.cannotOpt = true;
            vm.clear();
            $http({
                method: 'GET',
                url: '/api/trip-models/copy/'+tripModel.id +'/' + tripModel.upgrade,
            }).then(function (res) {
                // console.log(res);
                // if (res != null && res.data.errorCode == -1){
                //     alert("复制失败，被复制线路中不能有新版AR");
                //     return;
                // }
                if (res != null && res.data !=null){
                    if (res.data.hasLocationTasks!=null && typeof(res.data.hasLocationTasks)!='undefined') {
                        for (var i in res.data.hasLocationTasks){
                            // console.log(res.data.hasLocationTasks[i]);
                            if (res.data.hasLocationTasks[i].hasTasks!=null && typeof(res.data.hasLocationTasks[i].hasTasks)!='undefined'){
                                for (var j in res.data.hasLocationTasks[i].hasTasks){
                                    // console.log(res.data.hasLocationTasks[i].hasTasks[j]);
                                    if (res.data.hasLocationTasks[i].hasTasks[j].type!=null
                                        && typeof(res.data.hasLocationTasks[i].hasTasks[j].type)!='undefined'
                                        && res.data.hasLocationTasks[i].hasTasks[j].type == 'NEWAR'){
                                        alert("复制线路中含有新版AR，后台正在识别新版AR的扫描图片，请稍后再次编辑新版AR任务查看识别情况");
                                        return;
                                    }
                                }
                            }
                        }
                    }
                }
                // $state.go('trip-model-detail',{id:vm.tripModel.id},{reload:true});
                $state.go('trip-model',null ,{reload:true});
                $uibModalInstance.dismiss('cancel');
            },function (err) {
                console.log(err);
            });
        }
    }
})();
